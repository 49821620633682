import {
    ClearPrototypeStartingPointEditingStateCommand,
    UpdatePrototypeStartingPointNameCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { KeyboardCode } from '../../../../kernel/keyboard/keyboard-event-handler'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand } from '../../../context/document-context'
import { useImperativeViewport } from '../../../hooks/use-imperative-viewport'
import { toCanvasRelativePosition } from '../../../utils/viewport'
import styles from './starting-point-name-input.module.less'

export function PrototypeStartingPointNameInput() {
    const editingNameViewState = useViewState('prototypeStartingPointEditingNameViewState')
    if (editingNameViewState?.show) {
        return <PrototypeStartingPointNameInputInternal editingNameViewState={editingNameViewState} />
    }
    return null
}

interface Props {
    editingNameViewState: Wukong.DocumentProto.IPrototypeStartingPointEditingNameViewState
}

function PrototypeStartingPointNameInputInternal({ editingNameViewState }: Props) {
    const command = useCommand()
    const [editingName, setEditingName] = useState(editingNameViewState.name)

    const { viewport } = useImperativeViewport({ sync: true })
    const { left, top } = toCanvasRelativePosition({
        left: editingNameViewState.positionLeft,
        top: editingNameViewState.positionTop,
        viewport,
    })

    useEffect(() => {
        // props中的name变更时同步到input的编辑内容
        setEditingName(editingNameViewState.name)
    }, [editingNameViewState.name])

    const onSubmit = () => {
        if (editingName) {
            command.DEPRECATED_invokeBridge(UpdatePrototypeStartingPointNameCommand, { value: editingName })
        }

        command.DEPRECATED_invokeBridge(ClearPrototypeStartingPointEditingStateCommand)
        command.commitUndo()
    }

    const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.nativeEvent.isComposing) {
            return
        }

        e.stopPropagation()
        switch (e.key) {
            case KeyboardCode.ENTER:
            case KeyboardCode.ESCAPE:
                onSubmit()
                break
            default:
                break
        }
    }

    // input自动宽度
    const [inputWidth, setInputWidth] = useState(0)
    const spanRef = useRef<HTMLSpanElement>(null)

    useLayoutEffect(() => {
        setInputWidth(spanRef.current?.scrollWidth ?? 0)
    }, [editingName])

    // input自动全选内容
    const htmlInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        htmlInputRef.current?.focus()
        htmlInputRef.current?.select()
    }, [])

    return (
        <div
            className={styles.root}
            style={{
                left: `${left}px`,
                top: `${top}px`,
                transform: `translateX(-100%)`,
            }}
            data-testid="prototype-starting-point-name-input-container"
        >
            <span className={styles.measure} ref={spanRef}>
                {editingName}
            </span>
            <input
                ref={htmlInputRef}
                className={styles.input}
                value={editingName}
                onChange={(e) => setEditingName(e.target.value)}
                style={{ width: `${inputWidth}px` }}
                onBlur={onSubmit}
                onKeyDown={onKeydown}
                autoComplete="off"
                autoCorrect="false"
                autoCapitalize="false"
                spellCheck="false"
                dir="auto"
                data-testid="prototype-starting-point-name-input"
            />
        </div>
    )
}
