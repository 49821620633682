import classes from './card.module.less'

function ExclusiveListItem(prop: {
    renderItem: (index: number, expanded: boolean) => JSX.Element
    index: number
    expandedIdx: number | null
    setExpandedIdx: (index: number | null) => void
}) {
    const { renderItem, index, expandedIdx, setExpandedIdx } = prop
    const expanded = expandedIdx !== null && expandedIdx === index

    return (
        <div
            onClick={() => {
                if (expanded) {
                    setExpandedIdx(null)
                } else {
                    setExpandedIdx(index)
                }
            }}
        >
            {renderItem(index, expanded)}
        </div>
    )
}

export function ExclusiveList(prop: {
    renderItem: (index: number, expanded: boolean) => JSX.Element
    title?: string
    expandedId: number | null
    setExpandedId: (index: number | null) => void
    indexes: number[]
}) {
    const { renderItem, title, expandedId, setExpandedId, indexes } = prop

    return (
        <div className={classes.card_list_container}>
            {title && <div className={classes.card_list_title}>{title}</div>}

            {indexes.map((index) => (
                <ExclusiveListItem
                    key={index}
                    renderItem={renderItem}
                    expandedIdx={expandedId}
                    setExpandedIdx={setExpandedId}
                    index={index}
                ></ExclusiveListItem>
            ))}
        </div>
    )
}
