import classNames from 'classnames'
import { ReactNode } from 'react'

export const AnchorWrapper = ({
    children,
    className,
    dataTestId,
    linkInfo,
}: {
    children: ReactNode
    className?: string
    dataTestId?: string
    linkInfo?: { href: string }
}) => {
    if (linkInfo?.href) {
        return (
            <a
                data-testid={dataTestId}
                onClick={(e) => {
                    if (!e.metaKey && !e.shiftKey) {
                        e.preventDefault()
                    }
                }}
                className={classNames(className, 'cursor-default')}
                href={linkInfo.href}
            >
                {children}
            </a>
        )
    }
    return <>{children}</>
}
