import { useEffect, useState } from 'react'
import { TransitionContainer, TransitionContainerProps } from '../transition/transition-container'
import { TransitionView } from '../transition/transition-view'

export interface SmartAnimationEffects {
    block: { play?: boolean; transition: string }
    shouldStartTransition?: TransitionContainerProps['shouldStartTransition']
}

export function SmartAnimationEffects(props: SmartAnimationEffects) {
    const { block, shouldStartTransition } = props
    const [play, setPlay] = useState<boolean>(false)

    useEffect(() => setPlay(true), [block.transition])

    return (
        <TransitionContainer
            shouldStartTransition={() => (setPlay(true), shouldStartTransition?.())}
            shouldEndTransition={() => setPlay(false)}
        >
            <TransitionView>
                <div className="flex flex-col items-center gap-3px mx-4px mt-4px">
                    <div
                        className="w-44px b b-$wk-gray-13 rounded-1px"
                        style={{
                            height: play ? '18px' : '10px',
                            backgroundColor: play ? 'var(--wk-brand-7)' : 'var(--wk-gray-6)',
                            transition: play ? block.transition : 'all ease-out 300ms',
                        }}
                    ></div>
                    <div className="w-44px h-8px b b-$wk-gray-13 rounded-1px"></div>
                    <div className="w-44px h-8px b b-$wk-gray-13 rounded-1px"></div>
                </div>
            </TransitionView>
        </TransitionContainer>
    )
}
