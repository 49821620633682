import { safeCall } from '../../../utils/safe-call'

// mac 系统可以通过系统设置或者 蓝牙鼠标的变更切换滚动条的系统行为，这里处理这种变更
class _AuxiliaryScrollbarService {
    private scrollbarWidth: undefined | number = undefined
    private auxiliaryScrollbarElementId = 'auxiliary-scrollbar-width-element'

    private observer: ResizeObserver // mac 系统滚动条变化不会触发 MutationObserver，因此这里使用 ResizeObserver
    private observeScrollbarWidthSet = new Set<(scrollbarWidth: number) => void>()

    constructor() {
        this.observer = this.initResizeObserver()
    }

    private initResizeObserver = () => {
        if (window.ResizeObserver) {
            return new window.ResizeObserver(this.observerAuxiliaryScrollbarElementResize)
        }
        const forTest = () => {}
        return { observe: forTest, unobserve: forTest, disconnect: forTest }
    }

    private createAuxiliaryScrollbarElement = () => {
        const auxiliaryScrollbarElement = document.getElementById(this.auxiliaryScrollbarElementId)
        if (auxiliaryScrollbarElement) {
            return auxiliaryScrollbarElement
        }
        const div = document.createElement('div')
        div.id = this.auxiliaryScrollbarElementId
        div.setAttribute(
            'style',
            `width: 100px;height: 100px;position: absolute;top: -9999px;overflow: scroll;-ms-overflow-style: scrollbar;`
        )
        document.body.appendChild(div)
        this.observer.observe(div)
        this.scrollbarWidth = div.offsetWidth - div.clientWidth
        return div
    }

    private getAuxiliaryScrollbarElement = () => {
        const auxiliaryScrollbarElement = document.getElementById(this.auxiliaryScrollbarElementId)
        return auxiliaryScrollbarElement ? auxiliaryScrollbarElement : this.createAuxiliaryScrollbarElement()
    }

    private observerAuxiliaryScrollbarElementResize = () => {
        this.callScrollbarWidth()
    }

    private callScrollbarWidth = () => {
        const oldScrollbarWidth = this.scrollbarWidth
        this.scrollbarWidth = undefined
        const scrollbarWidth = this.getScrollbarWidth()
        if (oldScrollbarWidth === scrollbarWidth) {
            return
        }
        for (const callback of this.observeScrollbarWidthSet) {
            safeCall(callback, scrollbarWidth)
        }
    }

    public observeScrollbarWidth = (callback: (scrollbarWidth: number) => void) => {
        this.createAuxiliaryScrollbarElement()
        this.observeScrollbarWidthSet.add(callback)
    }

    public unobserveScrollbarWidth = (callback: (scrollbarWidth: number) => void) => {
        this.observeScrollbarWidthSet.delete(callback)
    }

    public getScrollbarWidth = () => {
        if (this.scrollbarWidth === undefined) {
            const auxiliaryScrollbarElement = this.getAuxiliaryScrollbarElement()
            this.scrollbarWidth = auxiliaryScrollbarElement.offsetWidth - auxiliaryScrollbarElement.clientWidth
        }
        return this.scrollbarWidth
    }
}

export const AuxiliaryScrollbarService = new _AuxiliaryScrollbarService()
