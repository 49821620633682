/* eslint-disable no-restricted-imports */
import { getCommitId, getReleaseTag } from '../../../util/src'
import { HttpPrefixKey, IN_JEST_TEST, environment } from '../environment'
import { WK } from '../window'
import { WkCLog } from './clog/wukong/instance'
import { featureSwitchManager } from './switch/core'

// metric名字和参数需要在服务端同步添加
export enum MetricName {
    FRAME_COUNTER = 'frame_counter',
    PERFORMANCE_BAD_CASE_COUNTER = 'performance_bad_case_counter',
    DOCUMENT_FIRST_NOTIFY_RATIO = 'document_first_notify_ratio',
    WASM_USED_MEMORY = 'wasm_used_memory',
    DOCUMENT_SYNCED_DURATION = 'document_synced_duration',
    // js heap 的绝对大小， 单位是 byte
    JS_HEAP_SIZE = 'js_heap_size',
    WASM_HEAP_SIZE = 'wasm_heap_size',
    SYNERGY_GET_TICKET_DURATION = 'synergy_get_ticket_duration',
    SYNERGY_WS_CONNECT_DURATION = 'synergy_ws_connect_duration',
    SYNERGY_GET_ARCHIVE_DURATION = 'synergy_get_archive_duration',
    SYNERGY_APPLY_ARCHIVE_DURATION = 'synergy_apply_archive_duration',
    SYNERGY_GET_SYNC_DURATION = 'synergy_get_sync_duration',
    SYNERGY_APPLY_SYNC_DURATION = 'synergy_apply_sync_duration',
    SYNERGY_LOAD_DOC_DURATION = 'synergy_load_doc_duration',
    SYNERGY_SYNC_DOC_DURATION = 'synergy_sync_doc_duration',
    SYNERGY_ERROR = 'synergy_error',
    SYNERGY_RECEIVE_SERVER_ERROR = 'synergy_receive_server_error',
    SYNERGY_RECEIVE_SERVER_KICK = 'synergy_receive_server_kick',
    SYNERGY_SOCKET_ERROR = 'synergy_socket_error',
    SYNERGY_HEARTBEAT_TIMEOUT = 'synergy_heartbeat_timeout',
    SYNERGY_OFFLINE_TIMES = 'synergy_offline_times_v2', // 离线次数
    SYNERGY_OFFLINE_DURATION = 'synergy_offline_duration', // 协同离线时长
    SYNERGY_NETWORK_OFFLINE_DURATION = 'synergy_network_offline_duration', // 网络断线时长
    SYNERGY_OFFLINE_SYNC_SUCCESS_TIMES = 'synergy_offline_sync_success_times', // 离线同步成功次数
    SYNERGY_FIRST_LOAD_SUCCESS = 'synergy_first_load_success', // 首次加载文档成功
    SYNERGY_CREATE_DOC_DURATION = 'synergy_create_doc_duration', // 新建文档耗时

    SYNERGY_SAVE_OFFLINE_DATA_DURATION = 'synergy_save_offline_data_duration', // 保存离线数据耗时
    SYNERGY_SAVE_OFFLINE_DATA_QUEUED_TIMES = 'synergy_save_offline_data_queued_times', // 保存离线数据任务排队的次数
    SYNERGY_SAVE_OFFLINE_OP_COUNT = 'synergy_save_offline_op_count', // 保存离线数据的 operation 个数
    SYNERGY_SAVE_OFFLINE_BLOB_COUNT = 'synergy_save_offline_blob_count', // 保存离线数据的 blob 个数
    SYNERGY_SAVE_OFFLINE_REFERENCED_NODES_COUNT = 'synergy_save_offline_referenced_nodes_count',
    SYNERGY_CREATE_OFFLINE_SESSION = 'synergy_create_offline_session', // 新建 offline-session, 表示刚开始进入离线编辑
    SYNERGY_UPDATE_OFFLINE_SESSION = 'synergy_update_offline_session', // 更新 offline-session, 表示持续离线编辑
    SYNERGY_CLEAR_OFFLINE_DATA_DURATION = 'synergy_clear_offline_data_duration', // 清理离线数据耗时
    SYNERGY_CLEAR_OFFLINE_SESSION_COUNT = 'synergy_clear_offline_session_count', // 清理离线数据 offline-session 数量
    SYNERGY_GET_OFFLINE_SESSION_COUNT = 'synergy_get_offline_session_count', // 打开文档时获取离线数据 session 个数
    SYNERGY_GET_OFFLINE_OP_COUNT = 'synergy_get_offline_op_count', // 打开文档时获取离线数据的 operation 个数
    SYNERGY_GET_OFFLINE_BLOB_COUNT = 'synergy_get_offline_blob_count', // 打开文档时获取离线数据的 blob 个数
    SYNERGY_GET_OFFLINE_REFERENCED_NODES_COUNT = 'synergy_get_offline_referenced_nodes_count',
    SYNERGY_GET_OFFLINE_SESSION_LOCK = 'synergy_get_offline_session_lock', // 获得 offline-session 锁, 如果失败则表示产生离线数据的页面还没关闭
    SYNERGY_BROWSE_CURRENT_WINDOW_DURATION = 'synergy_browse_current_window_duration', // 浏览当前窗口的持续时间

    WASM_BRIDGE_CALL_DURATION = 'bridge_wasm_call_bucket',
    WASM_BRIDGE_CALL_SUM = 'bridge_wasm_call_sum',
    WASM_BRIDGE_CALL_COUNT = 'bridge_wasm_call_count',
    WASM_BRIDGE_CALL_ERROR_COUNT = 'bridge_wasm_call_error_count',
    WASM_BRIDGE_CALL_V2_DURATION = 'bridge_wasm_call_duration',

    COMPUTED_PROP_READ_COUNT = 'computed_prop_read_count',

    RENDER_UNTIL_STABLE_DURATION = 'render_until_stable_duration',

    // for benchmark
    CREATE_FILE_START = 'create_file_start',
    DOC_READY = 'doc_ready',
    START_LOAD_DOC = 'start_load_doc',
    SELECT_ALL_DURATION = 'select_all_duration',
    SWITCH_PAGE_DURATION = 'switch_page_duration',
    SET_MISS_FONT_DURATION = 'set_miss_font_duration',
    OPTION_COPY_DURATION = 'option_copy_duration',
    ON_BATCH_OPEEATION_DURATION = 'on_batch_operation_duration',
    ZOOM_CENTER_DURATION = 'zoom_center_duration',
    ZOOM_CENTER_OP_AVG = 'zoom_center_op_avg',
    ZOOM_CENTER_OP_MAX = 'zoom_center_op_max',
    MOVE_VIEWPORT_DURATION = 'move_viewport_duration',
    MOVE_VIEWPORT_OP_AVG = 'move_viewport_op_avg',
    MOVE_VIEWPORT_OP_MAX = 'move_viewport_op_max',
    DRAG_NODE_DURATION = 'drag_node_duration',
    DRAG_NODE_OP_AVG = 'drag_node_op_avg',
    DRAG_NODE_OP_MAX = 'drag_node_op_max',
    DRAG_TANGENT_DURATION = 'drag_tangent_duration',
    DRAG_TANGENT_OP_AVG = 'drag_tangent_op_avg',
    DRAG_TANGENT_OP_MAX = 'drag_tangent_op_max',
    DRAG_VERTEX_DURATION = 'drag_vertex_duration',
    DRAG_VERTEX_OP_AVG = 'drag_vertex_op_avg',
    DRAG_VERTEX_OP_MAX = 'drag_vertex_op_max',
    LIBRARY_PUBLISH = 'library_publish',
    DELETE_PAGE = 'delete_page',
    SCALE_DURATION = 'scale_duration',
    DELETE_ALL = 'delete_all',
    DELETE_ALL_AND_UNDO = 'delete_all_and_undo',
    ADD_FILLS_TO_ALL = 'add_fills_to_all',
    EXPORT_DURATION = 'export_duration',

    // system cost metrics
    COMMAND_COST = 'command_cost',
    SYSTEM_FLUSH_COST = 'system_flush_cost',
    SYSTEM_NAME_TO_COST = 'system_name_to_cost',
    RENDER_COST = 'render_cost',
    USER_PERFORMANCE_SCORE = 'user_perforce_score',

    MEMORY_REPORT = 'memory_report',
    VARIABLE_CHANGE_MODE = 'variable_change_mode',
    VARIABLE_CHANGE_COLOR = 'variable_change_color',
}

export interface Metric {
    metricName: string
    value: number
    extraLabelName2Value?: object
}

const SECOND = 1000

export class MetricCollector {
    private static sessionId?: number
    private static clientId?: number
    private static tsBegin?: number
    private static framesCount = 0
    private static metrics: Metric[] = []
    private static disabled = false
    private static clientType = ''

    public static disable() {
        MetricCollector.disabled = true
    }
    public static enable() {
        MetricCollector.disabled = false
    }
    public static reset() {
        this.sessionId = undefined
        this.clientId = undefined
        this.clientType = ''
        this.tsBegin = performance.now()
        this.framesCount = 0
        window.addEventListener('beforeunload', () => this.flush())
    }
    public static setClientId(clientId: number) {
        this.clientId = clientId
    }
    public static setClientType(clientType: string) {
        this.clientType = clientType
    }

    public static getClientType(): string {
        return this.clientType
    }

    public static setSessionId(sessionId: number) {
        if (this.sessionId == sessionId) {
            return
        }
        this.flush()
        this.sessionId = sessionId
    }

    public static onExitEditor() {
        this.flush()
    }

    public static pushMetric(metricName: string, value: number | Record<string, number>) {
        if (MetricCollector.disabled) {
            return
        }
        WK.performance.set(metricName, value)
    }

    public static pushMetricToServer(metricName: string, value: number, labels?: object) {
        if (MetricCollector.disabled) {
            return
        }
        WK.performance.set(metricName, value)
        this.metrics.push({
            metricName,
            value,
            extraLabelName2Value: labels,
        })
    }

    public static pushMemoryMetricOnDocumentLoaded() {
        if (MetricCollector.disabled) {
            return
        }
        if (!WK.getUsedMemory) {
            return
        }
        const jsHeapSize = (performance as any).memory?.usedJSHeapSize
        if (jsHeapSize) {
            this.pushMetric(MetricName.JS_HEAP_SIZE, jsHeapSize)
        }
        const wasmHeapSize = WK.getUsedMemory() / 1024 / 1024
        this.pushMetric(MetricName.WASM_HEAP_SIZE, wasmHeapSize)
    }

    public static onAnimationFrame() {
        this.framesCount += 1
        if (this.framesCount >= 300) {
            const jsHeapSize = (performance as any).memory?.usedJSHeapSize
            if (jsHeapSize) {
                this.pushMetric(MetricName.JS_HEAP_SIZE, jsHeapSize)
            }
            this.flush()
        }
    }

    public static async onCrash() {
        await this.internalReportMetrics([
            {
                metricName: MetricName.FRAME_COUNTER,
                extraLabelName2Value: {
                    success: 'true',
                },
                value: this.framesCount,
            },
            {
                metricName: MetricName.FRAME_COUNTER,
                extraLabelName2Value: {
                    success: 'false',
                    isMaintainer: featureSwitchManager.isCurrentUserMaintainer(),
                },
                value: 1,
            },
        ])
        this.framesCount = 0
        WkCLog.log('frame crashed')
    }

    public static flush(mustSend = false) {
        if (this.framesCount != 0) {
            this.metrics.push({
                metricName: MetricName.FRAME_COUNTER,
                extraLabelName2Value: {
                    success: 'true',
                },
                value: this.framesCount,
            })
            this.framesCount = 0
        }
        if (this.metrics.length) {
            this.internalReportMetrics(this.metrics, mustSend)
            this.metrics = []
        }
    }

    public static reportMetrics(metrics: Metric[]) {
        this.internalReportMetrics(metrics)
    }

    private static async internalReportMetrics(metrics: Metric[], mustSend = false) {
        if (MetricCollector.disabled) {
            return
        }
        const commitId = getCommitId() || 'local'
        if (typeof window.fetch == 'undefined') {
            return
        }

        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        const res = await fetch(environment.httpPrefixes[HttpPrefixKey.COMMON_API] + '/client-metric', {
            method: 'POST',
            headers,
            credentials: 'include',
            body: JSON.stringify({
                sessionId: this.sessionId,
                clientId: this.clientId,
                commitId: commitId,
                releaseId: getReleaseTag(),
                metricData: metrics,
                mustSend: mustSend,
                clientType: this.clientType,
            }),
        })
        if (!res.ok) {
            console.error('failed to report metrics ' + res.status + ': ' + res.status)
        }
    }
}

if (!IN_JEST_TEST) {
    setInterval(() => {
        MetricCollector.flush()
    }, 10 * SECOND)
}

WK.pushMetric = (metricName: string, value: number, labels?: object) => {
    MetricCollector.pushMetricToServer(metricName, value, labels)
    MetricCollector.flush(true)
}
