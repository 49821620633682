import { translation } from './stroke-miter-angle.translation'
import { Wukong } from '@wukong/bridge-proto'
import { SingleGrid } from '../../../atom/grid/single-grid'
import { ScrubbableInputDegree } from '../../../atom/inputs/scrubbable-input-degree'
import style from '../advance-stroke/advance-stroke.module.less'
import { useStrokeMiterAngle } from './use-stroke-miter-angle'

// 描边-直角{translation('MiterAngle')}
export function StrokeMiterAngle() {
    const { miterAngleState, onChangeStrokeMiterAngle } = useStrokeMiterAngle()
    return miterAngleState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_HIDDEN ? (
        <></>
    ) : (
        <SingleGrid className={style.grid}>
            <SingleGrid.Item start={5} span={28}>
                {translation('MiterAngle')}
            </SingleGrid.Item>
            <SingleGrid.Item start={35} span={22}>
                <ScrubbableInputDegree
                    isMixed={
                        miterAngleState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    value={miterAngleState?.value}
                    onChange={onChangeStrokeMiterAngle}
                    scrubbingDisabled={
                        miterAngleState?.type === Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_MIXED
                    }
                    min={7.17}
                    max={180}
                />
            </SingleGrid.Item>
        </SingleGrid>
    )
}
