export const enum ShortcutToken {
    JiraIssueCollector,
    Undo,
    Redo,
    SwitchEditorModeToDrawRectangle,
    SwitchEditorModeToDrawLine,
    SwitchEditorModeToDrawArrow,
    SwitchEditorModeToDrawText,
    SwitchEditorModeToDrawEllipse,
    SwitchEditorModeToDrawFrame,
    SwitchEditorModeToHandTool,
    GroupSelection,
    UngroupSelection,
    FrameSelection,
    Rename,
    Remove,
    SwitchFillsStrokes,
    DeleteFills,
    DeleteStrokes,
    BringToFront,
    SendToBack,
    BringForward,
    SendBackward,
    ZoomToNextFrame,
    ZoomToPrevFrame,
    ViewNextFrame,
    ViewPrevFrame,
    ToggleShownForSelectedNodes,
    ToggleLockedForSelectedNodes,
    ToggleFullscreen,
    ToggleRuler,
    TextLeftAlign,
    TextCenterAlign,
    TextRightAlign,
    SelectAll,
    VectorDelete,
    VectorDeleteAndHeal,
    ArrangeAlignLeft,
    ArrangeAlignHorizontalCenter,
    ArrangeDistributeHorizontal,
    ComponentSelection,
    DetachInstanceSelection,
    ToggleMaskSelection,
    DeleteGuide,
    Flatten,
    OutlineStroke,
    RemoveStyle,
    AutoLayoutAdd,
    AutoLayoutRemove,
    PasteToReplace,
    LayerPanelCollapseSelection,
    ToggleCommentVisiable,
    CloseCommentDraftAndDetail,
    SelectInverse,
    FlipHorizontal,
    FlipVertical,
    ToggleAILayout,
    AiCreateAutoLayout,
}
