import { Wukong } from '@wukong/bridge-proto'

export const enum PreviewAppType {
    Present, // 原型预览
    Mirror, // 新镜像 —— 支持移动端
    InlinePreview, // 原型内联预览
    MobilePreview, // 移动端原型预览
}

export function getPreviewSceneByAppType(type: PreviewAppType): Wukong.DocumentProto.PreviewScene {
    switch (type) {
        case PreviewAppType.Present:
            return Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_P_R_O_T_O_T_Y_P_E
        case PreviewAppType.Mirror:
            return Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_M_I_R_R_O_R
        case PreviewAppType.InlinePreview:
            return Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_P_R_O_T_O_T_Y_P_E
        case PreviewAppType.MobilePreview:
            return Wukong.DocumentProto.PreviewScene.VIEW_WITH_MOBILE
    }
}

export const previewSenceEnum2value: Record<Wukong.DocumentProto.PreviewScene, string> = {
    [Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_P_R_O_T_O_T_Y_P_E]: 'prototype',
    [Wukong.DocumentProto.PreviewScene.PREVIEW_SCENE_M_I_R_R_O_R]: 'mirror',
    [Wukong.DocumentProto.PreviewScene.VIEW_WITH_MOBILE]: 'viewWithMobile',
}
