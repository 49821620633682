import { command, computed, state } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { GetUserRequest } from '../../kernel/request/user'

// 请求用户信息的 atom, 当不存在是返回 undefined
export const [optionalUserInfo$, updateUserInfo$] = (() => {
    const refresh$ = state(0)

    return [
        traceable(
            'hulh01@kanyun.com',
            computed(async (get) => {
                get(refresh$)

                return new GetUserRequest().start().catch(() => {
                    // 鉴权失败后重置 userInfo 为 undefined
                    return undefined
                })
            })
        ),
        traceable(
            'hulh01@kanyun.com',
            command(({ set }) => set(refresh$, (v) => v + 1))
        ),
    ]
})()

// 在鉴权后可使用的 userInfo, 保证其可用性
export const userInfo$ = traceable(
    'hulh01@kanyun.com',
    computed(async (get) => {
        const userInfo = await get(optionalUserInfo$)

        if (!userInfo) {
            throw new Error('using unauth userInfo, should login first')
        }

        return userInfo
    })
)
