import {
    ChangeSmartSelectionHorizontalCommand,
    ChangeSmartSelectionVerticalCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { default as classnames } from 'classnames'
import { HTMLAttributes, useMemo, useState } from 'react'
import {
    Checkbox,
    IconAngle,
    IconConstraintScale,
    IconCoordinateH,
    IconCoordinateW,
    IconCoordinateX,
    IconCoordinateY,
    IconCorners,
    IconCorners2,
    IconFreeScale,
    IconOvalEdit,
    IconRatio,
    IconResizeToFit,
    IconSmartSelectionHorizontal,
    IconSmartSelectionVertical,
    MonoIconPanelHeightExtremes16,
    MonoIconPanelMaxHeight16,
    MonoIconPanelMaxWidth16,
    MonoIconPanelMinHeight16,
    MonoIconPanelMinWidth16,
    MonoIconPanelWidthExtremes16,
    Tooltip,
} from '../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../kernel/switch'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { EditorDataTestId } from '../../../../window'
import { IconButton } from '../../atom/button/icon-button'
import { SingleGrid } from '../../atom/grid/single-grid'
import { ScrubbableInputDegree } from '../../atom/inputs/scrubbable-input-degree'
import { ScrubbableInputInteger } from '../../atom/inputs/scrubbable-input-integer'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { useAutoLayoutState } from '../../design-panel-v2/attributes/components/auto-layout'
import { useSizeInput } from '../../design-panel-v2/attributes/components/auto-layout/components/size-input'
import { useTextState } from '../text/hooks/use-text-state'
import { AttrAboutWidthHeight } from './attr-about-width-height'
import style from './attribute.module.less'
import { translation } from './attribute.translation'
import { CornerRadius, IndependentCornerRadius } from './corner-radius'
import { CornerSmoothing } from './corner-smoothing'
import { FramePreset } from './frame-preset'
import {
    MinAndMaxSizeOption,
    MinMaxSizeDropdown,
    MinMaxSizeInput,
    MinMaxSizeInputLayout,
    useMinMaxSizeDropdownInputProps,
} from './min-max-size'
import { useAttributeV2 } from './use-attribute'

export function Attribute(props: HTMLAttributes<HTMLDivElement>) {
    const {
        polygonStarIcon,
        tooltipTitle,
        innerRadiusMixed,
        innerRadiusValue,
        sweepMixed,
        sweepValue,
        arcDataStartingAngleValue,
        arcDataStartingAngleMixed,
        batchUpdateBaseAttribute,
        onChangeNew,
        getScrubStartValueMixed,
        onChangeMixed,
        constrainProportions,
        cornerRadius,
        cornerSmoothing,
        topLeftRadius,
        topRightRadius,
        bottomLeftRadius,
        bottomRightRadius,
        independentCornerRadius,
        showPointCount,
        pointCountMixed,
        pointCountValue,
        starInnerRadiusValue,
        starInnerRadiusMixed,
        showStarInnerRadius,
        clipsContent,
        onChange,
        hiddenArc,
        rotation,
        onScrubChangeAllMixed,
        selectionX,
        selectionY,
        resizeToFit,
        disabledByAILayout,
    } = useAttributeV2()
    const minMaxSizeSelectInputProps = useMinMaxSizeDropdownInputProps()
    const showCornerRadius = cornerRadius?.show
    const showCornerSmoothing = cornerSmoothing?.show
    const showIndependentCornerRadius = independentCornerRadius?.show
    const expandIndependentCornerRadius = independentCornerRadius?.expand || false
    const command = useCommand()
    const flag = useFeatureSwitch('float-variable')

    const { missFontInfo } = useTextState()
    const missFontStyle = missFontInfo.isMissStyle

    const panelStateUnderAILayout = useViewState('panelStateUnderAILayout')
    const autoLayoutState = useAutoLayoutState()
    const { widthType, heightType, absPosBtn } = useSizeInput({
        ...autoLayoutState,
        disableSizeMode: !!panelStateUnderAILayout?.disableSizeMode,
        disableAbsolutePosition: !!panelStateUnderAILayout?.disableAbsolutePosition,
    })
    const state = useViewState('baseAttributeV2')
    const smartSelectionState = useViewState('smartSelection')

    const [forceIndex, updateForceIndex] = useState(0)
    const framePresetState = useViewState('FramePresetState')
    const flexibleFeatureBtn = useMemo(() => {
        if (absPosBtn) {
            return absPosBtn
        }
        if (!framePresetState?.available && framePresetState?.allowShowStackModeToggleAndResizeToFitButton) {
            return (
                <Tooltip title={translation('ResizeTo')}>
                    <IconButton
                        onClick={resizeToFit}
                        selected={false}
                        icon={<IconResizeToFit />}
                        disabled={disabledByAILayout}
                        dataTestId="resizeToFit"
                    />
                </Tooltip>
            )
        }
        return null
    }, [
        absPosBtn,
        framePresetState?.available,
        framePresetState?.allowShowStackModeToggleAndResizeToFitButton,
        resizeToFit,
        disabledByAILayout,
    ])
    if (!state) {
        return <></>
    }
    return (
        <div className={classnames('pt-8px pb-12px', props.className)}>
            <FramePreset />
            <SingleGrid>
                <SingleGrid.Item start={5} span={22}>
                    <ScrubbableInputNumber
                        disabled={
                            state?.x?.editType ===
                            Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                        }
                        icon={<IconCoordinateX />}
                        labelTestId="coordinate-x-label"
                        isMixed={
                            selectionX?.valueType ===
                            Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                        }
                        value={selectionX?.value}
                        onChange={onChangeNew('x')}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.x.values)),
                            onScrubChangeAllMixed: (values) => {
                                batchUpdateBaseAttribute('x', Object.fromEntries(values) as Record<string, number>)
                            },
                            onChangeMixed: onChangeMixed('x'),
                        }}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={29} span={22}>
                    <ScrubbableInputNumber
                        disabled={
                            state?.y?.editType ===
                            Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                        }
                        icon={<IconCoordinateY />}
                        labelTestId="coordinate-y-label"
                        isMixed={
                            selectionY?.valueType ===
                            Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                        }
                        value={selectionY?.value}
                        onChange={onChangeNew('y')}
                        mixedMathHandler={{
                            getScrubStartValueMixed: () => new Map(Object.entries(state.y.values)),
                            onScrubChangeAllMixed: (values) => {
                                batchUpdateBaseAttribute('y', Object.fromEntries(values) as Record<string, number>)
                            },
                            onChangeMixed: onChangeMixed('y'),
                        }}
                    />
                </SingleGrid.Item>
                <SingleGrid.Item start={51} span={12} horizontalCenter>
                    {flexibleFeatureBtn}
                </SingleGrid.Item>
            </SingleGrid>
            {flag ? (
                <AttrAboutWidthHeight />
            ) : (
                <>
                    <SingleGrid>
                        <SingleGrid.Item start={5} span={22}>
                            <MinMaxSizeDropdown
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                                disabled={
                                    missFontStyle ||
                                    state.w.editType ===
                                        Wukong.DocumentProto.BaseAttributePropEditType
                                            .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                }
                                placement="bottom left"
                                inputLabel={
                                    <ScrubbableInputNumber
                                        disabled={
                                            missFontStyle ||
                                            state.w.editType ===
                                                Wukong.DocumentProto.BaseAttributePropEditType
                                                    .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                        }
                                        icon={
                                            minMaxSizeSelectInputProps.hasMinMaxWidth ? (
                                                <MonoIconPanelWidthExtremes16 />
                                            ) : (
                                                <IconCoordinateW />
                                            )
                                        }
                                        labelTestId="coordinate-width-label"
                                        defaultShowEmpty={
                                            state.w.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        }
                                        isMixed={
                                            state.w.valueType ===
                                            Wukong.DocumentProto.BaseAttributePropValueType
                                                .BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                                        }
                                        placeholder={
                                            state.w.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                                ? `${state.w.value}`
                                                : undefined
                                        }
                                        value={
                                            state.w.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                                ? undefined
                                                : state.w.value
                                        }
                                        onChange={onChangeNew('width')}
                                        mixedMathHandler={{
                                            getScrubStartValueMixed: () => new Map(Object.entries(state.w.values)),
                                            onScrubChangeAllMixed: (values) => {
                                                batchUpdateBaseAttribute(
                                                    'w',
                                                    Object.fromEntries(values) as Record<string, number>
                                                )
                                            },
                                            onChangeMixed: onChangeMixed('width'),
                                        }}
                                        min={0.01}
                                        testId="width-input"
                                    />
                                }
                                dropdownTriggerTestId={EditorDataTestId.BaseAttribute.WidthMinMaxSizeDropdownTrigger}
                                min={{
                                    value: state.minW,
                                    add: {
                                        icon: <MonoIconPanelMinWidth16 />,
                                        value: MinAndMaxSizeOption.addMinWidth,
                                        text: translation('AddMinWidth'),
                                    },
                                    remove: {
                                        value: MinAndMaxSizeOption.removeMinWidth,
                                        text: translation('RemoveMinWidth'),
                                    },
                                }}
                                max={{
                                    value: state.maxW,
                                    add: {
                                        icon: <MonoIconPanelMaxWidth16 />,
                                        value: MinAndMaxSizeOption.addMaxWidth,
                                        text: translation('AddMaxWidth'),
                                    },
                                    remove: {
                                        value: MinAndMaxSizeOption.removeMaxWidth,
                                        text: translation('RemoveMaxWidth'),
                                    },
                                }}
                                onChange={minMaxSizeSelectInputProps.onMinMaxSizeOptionChange}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={29} span={22}>
                            <MinMaxSizeDropdown
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                                disabled={
                                    missFontStyle ||
                                    state.h.editType ===
                                        Wukong.DocumentProto.BaseAttributePropEditType
                                            .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                }
                                placement="bottom right"
                                inputLabel={
                                    <ScrubbableInputNumber
                                        disabled={
                                            missFontStyle ||
                                            state.h.editType ===
                                                Wukong.DocumentProto.BaseAttributePropEditType
                                                    .BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                                        }
                                        icon={
                                            minMaxSizeSelectInputProps.hasMinMaxHeight ? (
                                                <MonoIconPanelHeightExtremes16 />
                                            ) : (
                                                <IconCoordinateH />
                                            )
                                        }
                                        labelTestId="coordinate-height-label"
                                        defaultShowEmpty={
                                            state.h.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                        }
                                        isMixed={
                                            state.h.valueType ===
                                            Wukong.DocumentProto.BaseAttributePropValueType
                                                .BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                                        }
                                        placeholder={
                                            state.h.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                                ? `${state.h.value}`
                                                : undefined
                                        }
                                        value={
                                            state.h.editType ===
                                            Wukong.DocumentProto.BaseAttributePropEditType
                                                .BASE_ATTRIBUTE_PROP_EDIT_TYPE_SECONDARY
                                                ? undefined
                                                : state.h.value
                                        }
                                        onChange={onChangeNew('height')}
                                        mixedMathHandler={{
                                            getScrubStartValueMixed: () => new Map(Object.entries(state.h.values)),
                                            onScrubChangeAllMixed: (values) => {
                                                batchUpdateBaseAttribute(
                                                    'h',
                                                    Object.fromEntries(values) as Record<string, number>
                                                )
                                            },
                                            onChangeMixed: onChangeMixed('height'),
                                        }}
                                        min={0.01}
                                        testId="height-input"
                                    />
                                }
                                dropdownTriggerTestId={EditorDataTestId.BaseAttribute.HeightMinMaxSizeDropdownTrigger}
                                min={{
                                    value: state.minH,
                                    add: {
                                        icon: <MonoIconPanelMinHeight16 />,
                                        value: MinAndMaxSizeOption.addMinHeight,
                                        text: translation('AddMinHeight'),
                                    },
                                    remove: {
                                        value: MinAndMaxSizeOption.removeMinHeight,
                                        text: translation('RemoveMinHeight'),
                                    },
                                }}
                                max={{
                                    value: state.maxH,
                                    add: {
                                        icon: <MonoIconPanelMaxHeight16 />,
                                        value: MinAndMaxSizeOption.addMaxHeight,
                                        text: translation('AddMaxHeight'),
                                    },
                                    remove: {
                                        value: MinAndMaxSizeOption.removeMaxHeight,
                                        text: translation('RemoveMaxHeight'),
                                    },
                                }}
                                onChange={minMaxSizeSelectInputProps.onMinMaxSizeOptionChange}
                            />
                        </SingleGrid.Item>
                        {constrainProportions?.show && (
                            <SingleGrid.Item start={51} span={12} horizontalCenter>
                                <Tooltip title={translation('ConstrainProportions')} canMouseDownClose>
                                    <IconButton
                                        onChange={onChange('constrainProportions')}
                                        selected={constrainProportions.mixed ? true : !!constrainProportions.value}
                                        selectedIcon={<IconConstraintScale />}
                                        icon={<IconFreeScale />}
                                        disabled={constrainProportions.disabled}
                                    />
                                </Tooltip>
                            </SingleGrid.Item>
                        )}
                    </SingleGrid>
                    <MinMaxSizeInputLayout
                        inputMinW={
                            <MinMaxSizeInput
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                                tooltipTitle={translation('MinWidthToolTip')}
                                placeholder={translation('MinWidthPlaceHolder')}
                                propType={
                                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_WIDTH
                                }
                                propValue={state.minW}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.minW.values)),
                                    onScrubChangeAllMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMinWidth()
                                        onScrubChangeAllMixed('minW')(...args)
                                    },
                                    onChangeMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMinWidth()
                                        onChangeMixed('minW')(...args)
                                    },
                                }}
                                onChange={(...args) => {
                                    minMaxSizeSelectInputProps.markForceShowMinWidth()
                                    onChangeNew('minW')(...args)
                                }}
                                labelTestId={EditorDataTestId.BaseAttribute.MinWInputLabel}
                                icon={<MonoIconPanelMinWidth16 />}
                            />
                        }
                        inputMaxW={
                            <MinMaxSizeInput
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_WIDTH}
                                tooltipTitle={translation('MaxWidthToolTip')}
                                placeholder={translation('MaxWidthPlaceHolder')}
                                propType={
                                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_WIDTH
                                }
                                propValue={state.maxW}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.maxW.values)),
                                    onScrubChangeAllMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMaxWidth()
                                        onScrubChangeAllMixed('maxW')(...args)
                                    },
                                    onChangeMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMaxWidth()
                                        onChangeMixed('maxW')(...args)
                                    },
                                }}
                                onChange={(...args) => {
                                    minMaxSizeSelectInputProps.markForceShowMaxWidth()
                                    onChangeNew('maxW')(...args)
                                }}
                                labelTestId={EditorDataTestId.BaseAttribute.MaxWInputLabel}
                                icon={<MonoIconPanelMaxWidth16 />}
                            />
                        }
                        inputMinH={
                            <MinMaxSizeInput
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                                tooltipTitle={translation('MinHeightToolTip')}
                                placeholder={translation('MinHeightPlaceHolder')}
                                propType={
                                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MIN_HEIGHT
                                }
                                propValue={state.minH}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.minH.values)),
                                    onScrubChangeAllMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMinHeight()
                                        onScrubChangeAllMixed('minH')(...args)
                                    },
                                    onChangeMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMinHeight()
                                        onChangeMixed('minH')(...args)
                                    },
                                }}
                                onChange={(...args) => {
                                    minMaxSizeSelectInputProps.markForceShowMinHeight()
                                    onChangeNew('minH')(...args)
                                }}
                                labelTestId={EditorDataTestId.BaseAttribute.MinHInputLabel}
                                icon={<MonoIconPanelMinHeight16 />}
                            />
                        }
                        inputMaxH={
                            <MinMaxSizeInput
                                hoverId={Wukong.DocumentProto.SizeHoverMenuItem.SIZE_HOVER_MENU_ITEM_HEIGHT}
                                tooltipTitle={translation('MaxHeightToolTip')}
                                placeholder={translation('MaxHeightPlaceHolder')}
                                propType={
                                    Wukong.DocumentProto.UpdateMinSizeAndMaxSizeCommandEnum
                                        .UPDATE_MIN_SIZE_AND_MAX_SIZE_COMMAND_ENUM_MAX_HEIGHT
                                }
                                propValue={state.maxH}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: () => new Map(Object.entries(state.maxH.values)),
                                    onScrubChangeAllMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMaxHeight()
                                        onScrubChangeAllMixed('maxH')(...args)
                                    },
                                    onChangeMixed(...args) {
                                        minMaxSizeSelectInputProps.markForceShowMaxHeight()
                                        onChangeMixed('maxH')(...args)
                                    },
                                }}
                                onChange={(...args) => {
                                    minMaxSizeSelectInputProps.markForceShowMaxHeight()
                                    onChangeNew('maxH')(...args)
                                }}
                                labelTestId={EditorDataTestId.BaseAttribute.MaxHInputLabel}
                                icon={<MonoIconPanelMaxHeight16 />}
                            />
                        }
                        showMinWidth={minMaxSizeSelectInputProps.showMinWidth}
                        showMaxWidth={minMaxSizeSelectInputProps.showMaxWidth}
                        showMinHeight={minMaxSizeSelectInputProps.showMinHeight}
                        showMaxHeight={minMaxSizeSelectInputProps.showMaxHeight}
                    />
                </>
            )}
            {!!widthType && !!heightType && (
                <SingleGrid>
                    <Tooltip title={translation('HorizontalResizing')}>
                        <SingleGrid.Item start={5} span={22}>
                            {widthType}
                        </SingleGrid.Item>
                    </Tooltip>
                    <Tooltip title={translation('VerticalResizing')}>
                        <SingleGrid.Item start={29} span={22}>
                            {heightType}
                        </SingleGrid.Item>
                    </Tooltip>
                </SingleGrid>
            )}
            <SingleGrid>
                <Tooltip title={translation('Rotation')} canMouseDownClose>
                    <SingleGrid.Item start={5} span={22}>
                        <ScrubbableInputDegree
                            disabled={
                                state.r?.editType ===
                                Wukong.DocumentProto.BaseAttributePropEditType.BASE_ATTRIBUTE_PROP_EDIT_TYPE_DISABLE
                            }
                            labelTestId="coordinate-rotation-label"
                            icon={<IconAngle />}
                            isMixed={
                                rotation?.valueType ===
                                Wukong.DocumentProto.BaseAttributePropValueType.BASE_ATTRIBUTE_PROP_VALUE_TYPE_MIXED
                            }
                            value={rotation?.value}
                            onChange={onChangeNew('rotation')}
                            mixedMathHandler={{
                                getScrubStartValueMixed: () => new Map(Object.entries(state.r.values)),
                                onScrubChangeAllMixed: (values) => {
                                    const obj = Object.fromEntries(values) as Record<string, number>
                                    batchUpdateBaseAttribute('r', obj)
                                },
                                onChangeMixed: onChangeMixed('rotation'),
                            }}
                            notUseUserConfig
                        />
                    </SingleGrid.Item>
                </Tooltip>
                {flag ? (
                    <CornerRadius />
                ) : (
                    <>
                        {showCornerRadius ? (
                            <Tooltip title={translation('CornerRadius')} canMouseDownClose>
                                <SingleGrid.Item start={29} span={22}>
                                    <ScrubbableInputNumber
                                        disabled={expandIndependentCornerRadius}
                                        labelTestId="coordinate-radius-label"
                                        testId="corner-radius-input"
                                        icon={<IconCorners2 />}
                                        isMixed={cornerRadius?.mixed}
                                        value={typeof cornerRadius?.value === 'number' ? cornerRadius.value : undefined}
                                        onChange={onChangeNew('cornerRadius')}
                                        min={0}
                                        scrubbingDisabled={cornerRadius?.mixed}
                                    />
                                </SingleGrid.Item>
                            </Tooltip>
                        ) : null}
                        {showIndependentCornerRadius ? (
                            <SingleGrid.Item start={51} span={12} horizontalCenter>
                                <Tooltip title={translation('IndependentCorners')}>
                                    <IconButton
                                        onChange={onChange('independentCorners')}
                                        selected={expandIndependentCornerRadius}
                                        icon={<IconCorners />}
                                        dataTestId="independentCorners-icon"
                                        tabTestId="icon-corners-focus"
                                    />
                                </Tooltip>
                            </SingleGrid.Item>
                        ) : null}
                        {showCornerSmoothing && !showIndependentCornerRadius ? (
                            <CornerSmoothing onChange={onChange} />
                        ) : null}
                    </>
                )}
            </SingleGrid>
            {flag ? (
                <IndependentCornerRadius />
            ) : expandIndependentCornerRadius ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={46} className={style.corner}>
                        <div className={classnames(style.expandIndependentCornerRadiusIcon)}>
                            <IconCorners2 transform={'rotate(' + forceIndex * 90 + ', 8, 8)'} />
                        </div>
                        <Tooltip title={translation('TopLeft')}>
                            <div className={style.cell}>
                                <ScrubbableInputNumber
                                    isMixed={topLeftRadius?.mixed}
                                    labelTestId="coordinate-top-left-radius-label"
                                    value={typeof topLeftRadius?.value === 'number' ? topLeftRadius.value : undefined}
                                    onChange={onChangeNew('topLeftRadius')}
                                    min={0}
                                    scrubbingDisabled={topLeftRadius?.mixed}
                                    rightScrubbable={false}
                                    onFocus={() => updateForceIndex(0)}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={translation('TopRight')}>
                            <div className={style.cell}>
                                <ScrubbableInputNumber
                                    isMixed={topRightRadius?.mixed}
                                    labelTestId="coordinate-top-right-radius-label"
                                    value={typeof topRightRadius?.value === 'number' ? topRightRadius.value : undefined}
                                    onChange={onChangeNew('topRightRadius')}
                                    min={0}
                                    scrubbingDisabled={topRightRadius?.mixed}
                                    rightScrubbable={false}
                                    onFocus={() => updateForceIndex(1)}
                                    onBlur={() => updateForceIndex(0)}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={translation('BottomRight')}>
                            <div className={style.cell}>
                                <ScrubbableInputNumber
                                    isMixed={bottomRightRadius?.mixed}
                                    labelTestId="coordinate-bottom-right-radius-label"
                                    value={
                                        typeof bottomRightRadius?.value === 'number'
                                            ? bottomRightRadius.value
                                            : undefined
                                    }
                                    onChange={onChangeNew('bottomRightRadius')}
                                    min={0}
                                    scrubbingDisabled={bottomRightRadius?.mixed}
                                    rightScrubbable={false}
                                    onFocus={() => updateForceIndex(2)}
                                    onBlur={() => updateForceIndex(0)}
                                />
                            </div>
                        </Tooltip>
                        <Tooltip title={translation('BottomLeft')}>
                            <div className={style.cell}>
                                <ScrubbableInputNumber
                                    isMixed={bottomLeftRadius?.mixed}
                                    labelTestId="coordinate-bottom-left-radius-label"
                                    value={
                                        typeof bottomLeftRadius?.value === 'number' ? bottomLeftRadius.value : undefined
                                    }
                                    onChange={onChangeNew('bottomLeftRadius')}
                                    min={0}
                                    scrubbingDisabled={bottomLeftRadius?.mixed}
                                    onFocus={() => updateForceIndex(3)}
                                    onBlur={() => updateForceIndex(0)}
                                />
                            </div>
                        </Tooltip>
                    </SingleGrid.Item>
                    {showCornerSmoothing && <CornerSmoothing onChange={onChange} />}
                </SingleGrid>
            ) : null}
            {showPointCount ? (
                <SingleGrid>
                    <Tooltip title={tooltipTitle} canMouseDownClose>
                        <SingleGrid.Item start={5} span={22}>
                            <ScrubbableInputInteger
                                icon={polygonStarIcon}
                                isMixed={pointCountMixed}
                                labelTestId="coordinate-point-count-label"
                                value={pointCountValue}
                                onChange={onChangeNew('pointCount')}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: getScrubStartValueMixed('pointCount'),
                                    onScrubChangeAllMixed: onScrubChangeAllMixed('pointCount'),
                                    onChangeMixed: onChangeMixed('pointCount'),
                                }}
                                min={3}
                                max={60}
                                testId="point-count-input"
                                notUseUserConfig
                            />
                        </SingleGrid.Item>
                    </Tooltip>
                    {!showStarInnerRadius ? null : (
                        <Tooltip title={translation('Radio')} canMouseDownClose>
                            <SingleGrid.Item start={29} span={22}>
                                <ScrubbableInputPercent
                                    icon={<IconRatio />}
                                    isMixed={starInnerRadiusMixed}
                                    labelTestId="coordinate-inner-radius-label"
                                    value={starInnerRadiusValue}
                                    onChange={onChangeNew('innerRadius')}
                                    mixedMathHandler={{
                                        getScrubStartValueMixed: getScrubStartValueMixed('innerRadius'),
                                        onScrubChangeAllMixed: onScrubChangeAllMixed('innerRadius'),
                                        onChangeMixed: onChangeMixed('innerRadius'),
                                    }}
                                    min={0.1}
                                    max={100}
                                    testId="inner-radius-input"
                                />
                            </SingleGrid.Item>
                        </Tooltip>
                    )}
                </SingleGrid>
            ) : null}
            {!hiddenArc ? (
                <SingleGrid>
                    <SingleGrid.Item start={5} span={46} className={style.arc}>
                        <Tooltip title={translation('Start')} addContainer={{ style: { height: '100%' } }}>
                            <ScrubbableInputDegree
                                icon={<IconOvalEdit />}
                                isMixed={arcDataStartingAngleMixed}
                                labelTestId="coordinate-arc-starting-angle-label"
                                value={arcDataStartingAngleValue}
                                onChange={onChangeNew('arcStartingAngle')}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: getScrubStartValueMixed('arcStartingAngle'),
                                    onScrubChangeAllMixed: onScrubChangeAllMixed('arcStartingAngle'),
                                    onChangeMixed: onChangeMixed('arcStartingAngle'),
                                }}
                                className={style.arc_cell1}
                                rightScrubbable={false}
                                testId="arc-starting-angle-input"
                            />
                        </Tooltip>
                        <Tooltip title={translation('Sweep')} addContainer={{ style: { height: '100%' } }}>
                            <ScrubbableInputPercent
                                isMixed={sweepMixed}
                                labelTestId="coordinate-sweep-label"
                                value={sweepValue}
                                onChange={onChangeNew('sweep')}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: getScrubStartValueMixed('sweep'),
                                    onScrubChangeAllMixed: onScrubChangeAllMixed('sweep'),
                                    onChangeMixed: onChangeMixed('sweep'),
                                }}
                                min={-100}
                                max={100}
                                className={style.arc_cell2}
                                rightScrubbable={false}
                                testId="sweep-input"
                            />
                        </Tooltip>
                        <Tooltip title={translation('Radio')} addContainer={{ style: { height: '100%' } }}>
                            <ScrubbableInputPercent
                                isMixed={innerRadiusMixed}
                                labelTestId="coordinate-arc-inner-radius-label"
                                value={innerRadiusValue}
                                onChange={onChangeNew('arcInnerRadius')}
                                mixedMathHandler={{
                                    getScrubStartValueMixed: getScrubStartValueMixed('arcInnerRadius'),
                                    onScrubChangeAllMixed: onScrubChangeAllMixed('arcInnerRadius'),
                                    onChangeMixed: onChangeMixed('arcInnerRadius'),
                                }}
                                min={0}
                                max={100}
                                className={style.arc_cell3}
                                testId="arc-inner-radius-input"
                            />
                        </Tooltip>
                    </SingleGrid.Item>
                </SingleGrid>
            ) : null}
            {clipsContent?.show && (
                <SingleGrid>
                    <SingleGrid.Item start={1} span={59}>
                        <Checkbox
                            className="ml-4"
                            checked={clipsContent.value}
                            indeterminate={clipsContent.mixed}
                            label={translation('ClipContent')}
                            onChange={onChange('clipsContent')}
                            testid="clipsContent"
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {smartSelectionState?.enabled && (
                <SingleGrid>
                    {smartSelectionState.showHorizontal && (
                        <SingleGrid.Item start={5} span={22}>
                            <ScrubbableInputNumber
                                labelTestId="coordinate-group-horizontal-label"
                                icon={<IconSmartSelectionHorizontal />}
                                value={smartSelectionState.horizontal}
                                onChange={(value, options) => {
                                    if (typeof value === 'number') {
                                        command.DEPRECATED_invokeBridge(ChangeSmartSelectionHorizontalCommand, {
                                            value: value,
                                        })
                                        command.commitUndo(options?.commitType)
                                    }
                                }}
                            />
                        </SingleGrid.Item>
                    )}
                    {smartSelectionState.showVertical && (
                        <SingleGrid.Item start={!smartSelectionState.showHorizontal ? 5 : 29} span={22}>
                            <ScrubbableInputNumber
                                labelTestId="coordinate-group-vertical-label"
                                icon={<IconSmartSelectionVertical />}
                                value={smartSelectionState.vertical}
                                onChange={(value, options) => {
                                    if (typeof value === 'number') {
                                        command.DEPRECATED_invokeBridge(ChangeSmartSelectionVerticalCommand, {
                                            value: value,
                                        })
                                        command.commitUndo(options?.commitType)
                                    }
                                }}
                            />
                        </SingleGrid.Item>
                    )}
                </SingleGrid>
            )}
        </div>
    )
}
