import { CommitUndo, CommitUndoOrSquash, MethodSignature } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'
import { EmBridge } from '../../kernel/bridge/em-bridge'
import { IDocumentRoot } from '../document-bridge/document-root'
import { CommitType } from './commit-type'

export interface CommandContext {
    /**
     * @deprecated
     */
    readonly documentRoot: IDocumentRoot
    readonly bridge: Bridge
    readonly commandInvoker: CommandInvoker
}

export class CommandInvoker {
    private readonly context!: CommandContext

    constructor(private readonly documentRoot: IDocumentRoot, private readonly bridge: EmBridge) {
        this.context = {
            documentRoot: this.documentRoot,
            bridge: this.bridge,
            commandInvoker: this,
        }
    }

    invoke<T extends any[] = any[], R = void>(command: BridgeCommand<T, R>, ...args: T) {
        const ret = command(this.context, ...args)
        return ret
    }

    /**
     * @deprecated 请使用 invokeBridge
     */

    DEPRECATED_invokeBridge<RET, ARG>(method: MethodSignature<RET, ARG>, arg?: ARG): RET {
        const ret = this.bridge.call(method, arg)
        return ret
    }

    /**
     *
     * @param commitType
     * Noop: 本次操作之后不需要进入 undo 栈；
     * CommitUndo：将当前 undo buffer 内容压入到 undo 栈，形成 undo 记录；
     * CommitSquash: 间隔 <500ms 时，将当前 undo buffer 内容和 undo 栈顶合并；否则效果与 CommitUndo 相同。
     * 对于输入框，如无特殊 undo 需求， 请透传 onchange 参数 option 中的 commitType
     */
    invokeBridge<RET, ARG>(commitType: CommitType, method: MethodSignature<RET, ARG>, arg?: ARG): RET {
        const ret = this.bridge.call(method, arg)
        this.commitUndo(commitType)
        return ret
    }

    commitUndo(commitType?: CommitType) {
        const commitType_ = commitType ?? CommitType.CommitUndo
        switch (commitType_) {
            case CommitType.CommitSquash:
                this.bridge.call(CommitUndoOrSquash)
                break
            case CommitType.CommitUndo:
                this.bridge.call(CommitUndo)
                break
            case CommitType.Noop:
                break
        }
    }

    destroy() {}
}

export type BridgeCommand<T extends any[] = any[], R = void> = (documentRoot: CommandContext, ...args: T) => R
