/* eslint-disable no-restricted-imports */
import { UpdateTextSettingTabKeyCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback, useMemo } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { cmdChangePopupState } from '../../../../document/command/document-command'
import { FontInfo, FontName, PopupStateType } from '../../../../document/node/node'
import { useViewState } from '../../../../view-state-bridge'
import { useCommand, useFontManagerService } from '../../../context/document-context'
import { useRecentFonts } from '../../../context/recent-fonts-context'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { TextValueUnit, Value } from '../../atom/inputs/utils/type'
import { useUserFontNameFirst } from '../common/use-user-font-name-first'
import { FontStyleSelectInfo } from './font/font-style-select/font-style-select'
import { useTextCommand } from './hooks/use-text-command'
import { useTextState } from './hooks/use-text-state'
import { CustomAxesStyleInfo } from './use-font-style-option-list'
import { getStyleGroup } from './utils'

function useTextPopup() {
    const command = useCommand()
    const popupState = useViewState('popupState')
    const isOpen = useMemo(() => {
        return popupState && popupState.type === PopupStateType.POPUP_STATE_TYPE_TEXT
    }, [popupState])

    const onClickMote = () => {
        if (isOpen) {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_NONE,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        } else {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_TEXT,
                reciprocalIndex: -1,
                multiPopup: [],
            })
        }
    }
    return { isOpen, onClickMote }
}

export function useText() {
    const { isOpen, onClickMote } = useTextPopup()

    const textCommand = useTextCommand()
    const selectionTextState = useTextState()
    const {
        hasTextInSelection,
        paragraphSpacing,
        textAlignHorizontal,
        textAlignVertical,
        textAutoResize,
        fontSize,
        lineHeight,
        lineHeightAutoValue,
        letterSpacing,
        selectedFamily,
        selectedLocalizedFamily,
        selectedFontCustomAxes,
        selectedFontName: selectedNodeFontName,
        selectedFontNames,
        isFamilyMixed,
        isFontNameMixed,
        variationInfo,
        customStyleState,
        missFontInfo,
    } = selectionTextState

    const fontManagerService = useFontManagerService()
    const availableFonts = fontManagerService.states.use.allFontInfosState()

    const availableFontInfo = useMemo(() => {
        const emptyFontInfo = { family: '', localizedFamily: '', styles: [] }

        let fontInfo: FontInfo | undefined
        if (isFamilyMixed) {
            fontInfo = emptyFontInfo
        } else {
            fontInfo = availableFonts.find((_fontInfo) => _fontInfo.family === selectedFamily) ?? emptyFontInfo
        }
        return fontInfo
    }, [availableFonts, selectedFamily, isFamilyMixed])

    const availableStyle = useMemo(() => {
        return getStyleGroup(availableFontInfo)
    }, [availableFontInfo])

    const { isMissFamily, isMissStyle } = missFontInfo
    const missFontFamily = isMissFamily
    const missFontStyle = isMissStyle

    // NOTE: figma导入文档的FontName信息与用户字体列表可能不一致，统一尝试映射为用户字体列表中的FontName
    const selectedFontName = useUserFontNameFirst(selectedNodeFontName)

    const { recentFonts, updateRecentFont } = useRecentFonts()

    const onChangeFontFamily = (fontInfo: FontInfo) => {
        const targetFamilyInfo = availableFonts.find((item) => item.family === fontInfo.family)
        if (!targetFamilyInfo) {
            return
        }

        textCommand.changeFontFamily(targetFamilyInfo.family)
        updateRecentFont(fontInfo)
    }

    const onPresetFontFamily = (fontInfo: FontInfo) => {
        const targetFamilyInfo = availableFonts.find((item) => item.family === fontInfo.family)
        if (!targetFamilyInfo) {
            return
        }

        textCommand.presetFontFamily(targetFamilyInfo.family)
    }

    const command = useCommand()
    const onChangeFontStyle = (info: string | CustomAxesStyleInfo) => {
        if (info === 'variable-setting') {
            command.invoke(cmdChangePopupState, {
                type: PopupStateType.POPUP_STATE_TYPE_TEXT,
                reciprocalIndex: -1,
                multiPopup: [],
            })
            command.DEPRECATED_invokeBridge(
                UpdateTextSettingTabKeyCommand,
                Wukong.DocumentProto.UpdateTextSettingTabKeyCommandParam.create({
                    tabKey: Wukong.DocumentProto.TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE,
                })
            )
            return
        }
        const style = typeof info === 'string' ? info : info.style
        const customAxesStyle = typeof info === 'string' ? '' : info.customAxesStyle.name
        const isMissFontCustomStyle = typeof info === 'string' ? false : info.isMissFontCustomStyle
        const fontName = availableFontInfo.styles.find((item) => item.style === style)
        if (fontName) {
            textCommand.changeFontName(fontName, customAxesStyle, isMissFontCustomStyle)
            updateRecentFont(fontManagerService.getFontInfo(fontName.family))
        } else if (isMissFontCustomStyle) {
            textCommand.changeFontName(
                { family: selectedFamily, style } as FontName,
                customAxesStyle,
                isMissFontCustomStyle
            )
        }
    }

    const onSelectVariableSetting = () => {
        command.invoke(cmdChangePopupState, {
            type: PopupStateType.POPUP_STATE_TYPE_TEXT,
            reciprocalIndex: -1,
            multiPopup: [],
        })
        command.invokeBridge(
            CommitType.Noop,
            UpdateTextSettingTabKeyCommand,
            Wukong.DocumentProto.UpdateTextSettingTabKeyCommandParam.create({
                tabKey: Wukong.DocumentProto.TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE,
            })
        )
    }

    const onChangeFontStyleV2 = (info: FontStyleSelectInfo) => {
        textCommand.changeFontName(info.fontName, info.customAxesStyle, info.isMissFontCustomStyle)
        if (!info.isMissFontCustomStyle) {
            updateRecentFont(fontManagerService.getFontInfo(info.fontName.family))
        }
    }

    const onPresetFontStyle = (info: FontStyleSelectInfo) => {
        textCommand.presetFontName(info.fontName, info.customAxesStyle, info.isMissFontCustomStyle)
    }

    const onChangeLetterSpacing = useCallback(
        (value: TextValueUnit, options?: InputOptionsForUndoSquash) => {
            textCommand.changeLetterSpacing(value, options)
        },
        [textCommand]
    )

    const onChangeLineHeight = useCallback(
        (value: TextValueUnit, options?: InputOptionsForUndoSquash) => {
            textCommand.changeLineHeight(value, options)
        },
        [textCommand]
    )

    const onChangeParagraphSpacing = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                textCommand.changeParagraphSpacing(value, options)
            }
        },
        [textCommand]
    )

    const onChangeFontSize = (v: string | number, options?: InputOptionsForUndoSquash) => {
        textCommand.changeFontSize(Number(v), options)
    }

    return {
        onChangeLetterSpacing,
        onChangeLineHeight,
        onChangeParagraphSpacing,
        onChangeFontFamily,
        onPresetFontFamily,
        onChangeFontStyle,
        onChangeFontStyleV2,
        onPresetFontStyle,
        onChangeFontSize,
        onChangeAutoResize: textCommand.changeAutoResize,
        onChangeTextAlignHorizontal: textCommand.changeTextAlignHorizontal,
        onChangeTextAlignVertical: textCommand.changeTextAlignVertical,
        onSelectVariableSetting,

        availableFonts,
        availableStyle,
        availableFontInfo,
        recentFonts,
        missFontFamily,
        missFontStyle,

        isOpen,

        selectionTextState,
        hasTextInSelection,
        textAlignHorizontal,
        textAlignVertical,
        textAutoResize,
        paragraphSpacing,
        selectedLocalizedFamily,
        selectedFontCustomAxes,
        selectedFamily,
        selectedFontName,
        isFamilyMixed,
        isFontNameMixed,
        fontSize,
        lineHeight,
        lineHeightAutoValue,
        letterSpacing,
        onClickMote,
        variationInfo,
        customStyleState,
    }
}
