import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    CreateInstanceSwapProp: '创建实例替换属性',
    ApplyInstanceSwapProp: '应用实例替换属性',
    EditInstanceSwapProp: '',
    CreateProp: '创建属性...',
    ComponentCanNotContainItself: '组件不能包含它自身的实例',
} as const

export const enTranslation = {
    CreateInstanceSwapProp: 'Create instance swap property',
    ApplyInstanceSwapProp: 'Apply instance swap property',
    EditInstanceSwapProp: 'Edit instance swap property',
    CreateProp: 'Create property...',
    ComponentCanNotContainItself: `Components can't contain instances of themselves`,
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
