import { ExitAILayoutCommand, SaveExitAILayoutCommand, ToggleAiLayoutEntryMenuCommand } from '@wukong/bridge-proto'
import { useMemo } from 'react'
import { DropdownV2 } from '../../../../../../ui-lib/src'
import { useCommand } from '../../../../main/app-context'
import { useViewState } from '../../../../view-state-bridge'
import { translation } from './ai-layout-entry-menu.translation'
import { useCanvas } from '../../../../external-store/atoms/create-canvas'
import { ShortcutKey, shortcutLabelMap } from '../../../../kernel/interface/shortcut-key'

interface MenuItem {
    value: string
    title: string
    shortcut?: string
    handler: () => void
}

type MenuItems = Record<string, MenuItem>

export function AiLayoutEntryMenu() {
    const menuState = useViewState('aiLayoutEntryMenuState')
    const command = useCommand()
    const canvas = useCanvas()

    const position = useMemo(() => {
        if (menuState?.opened) {
            const rawPosition = menuState?.position ?? { x: 0, y: 0 }
            const boundingRect = canvas.getBoundingClientRect()

            return {
                x: rawPosition.x + 12 + boundingRect.x,
                y: rawPosition.y + 24 + boundingRect.y,
            }
        } else {
            return {
                x: 0,
                y: 0,
            }
        }
    }, [canvas, menuState?.opened, menuState?.position])

    const menuItems: MenuItems = useMemo(() => {
        return {
            '1': {
                value: '1',
                title: translation('Exit'),
                shortcut: shortcutLabelMap[ShortcutKey.EnterAiLayout],
                handler: () => {
                    command.DEPRECATED_invokeBridge(ExitAILayoutCommand)
                },
            },
            '2': {
                value: '2',
                title: translation('SaveAutoLayout'),
                handler: () => {
                    command.DEPRECATED_invokeBridge(SaveExitAILayoutCommand)
                },
            },
        }
    }, [command])

    const onChange = (value: string) => {
        menuItems[value].handler()
    }

    return (
        <>
            {menuState?.opened && (
                <DropdownV2.NoTriggerSingleLevel
                    triggerRect={{
                        left: position.x,
                        right: position.x,
                        top: position.y,
                        bottom: position.y,
                    }}
                    isOpenState={true}
                    onClose={() => {
                        command.DEPRECATED_invokeBridge(ToggleAiLayoutEntryMenuCommand, {
                            opened: false,
                            position: { x: 0, y: 0 },
                        })
                    }}
                    onChange={onChange}
                    placement="bottom center"
                >
                    <DropdownV2.NoTriggerSingleLevel.Option
                        key={'1'}
                        value={menuItems['1'].value}
                        backwardIcon={menuItems['1'].shortcut}
                    >
                        {menuItems['1'].title}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                    <DropdownV2.NoTriggerSingleLevel.Option key={'2'} value={menuItems['2'].value}>
                        {menuItems['2'].title}
                    </DropdownV2.NoTriggerSingleLevel.Option>
                </DropdownV2.NoTriggerSingleLevel>
            )}
        </>
    )
}
