import { CanvasState, CanvasStateType } from '../../kernel/service/canvas-types'
import { GlobalCanvas, GlobalWebGPUCanvas } from './global-canvas'

export interface ReadMagnifierBufferParams {
    width: number
    height: number
    dataOffset: number
    bufferHandle: number
}

export interface ReadSyncWGPUParams {
    x: number
    y: number
    width: number
    height: number
    dataOffset: number
    textureHandle: number
}

interface CanvasInspectorDependecies {
    getCanvasState: () => CanvasState | null
    startSetTimeout: (f: () => void, ms: number) => void
    getHeap: () => Uint8Array
    log: (desc: string, arg: any) => void
}

function clearUint8Array(bytes: Uint8Array) {
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = 0
    }
}

export class CanvasInspector {
    private isDestroy = false

    constructor(private deps: CanvasInspectorDependecies) {}

    public destroy() {
        this.isDestroy = true
    }

    public readMagnifierBuffer(arg: ReadMagnifierBufferParams, onFinish: () => void) {
        const state = this.deps.getCanvasState()
        if (state?.type == CanvasStateType.WebGL) {
            // 6ms 后执行
            this.deps.startSetTimeout(() => {
                if (this.isDestroy) {
                    return
                }
                const s = this.deps.getCanvasState()
                if (s?.type != CanvasStateType.WebGL) {
                    return
                }
                if (typeof WebGL2RenderingContext === 'undefined' || !(s.context instanceof WebGL2RenderingContext)) {
                    return
                }
                const buffer = s.findBuffer(arg.bufferHandle)
                if (!buffer) {
                    return
                }

                const gl = s.context
                const oldBuffer = gl.getParameter(gl.PIXEL_PACK_BUFFER_BINDING)
                gl.bindBuffer(gl.PIXEL_PACK_BUFFER, buffer)
                gl.getBufferSubData(
                    gl.PIXEL_PACK_BUFFER,
                    0,
                    this.deps.getHeap().subarray(arg.dataOffset, arg.dataOffset + arg.width * arg.height * 4)
                )
                gl.bindBuffer(gl.PIXEL_PACK_BUFFER, oldBuffer)

                onFinish()
            }, 6)
        } else if (state?.type == CanvasStateType.WebGPU) {
            const buffer = state.findBuffer(arg.bufferHandle)
            if (!buffer) {
                return
            }

            const BytesPreRow = 256
            const size = BytesPreRow * arg.height
            buffer.mapAsync(GPUMapMode.READ, 0, size).then((_) => {
                if (this.isDestroy) {
                    return
                }

                const src = buffer.getMappedRange(0, size)
                const dst = this.deps.getHeap().subarray(arg.dataOffset, arg.dataOffset + arg.width * arg.height * 4)
                const rowValidBytes = arg.width * 4
                let srcOffset = 0
                let dstOffset = 0
                for (let y = 0; y < arg.height; ++y) {
                    dst.set(new Uint8Array(src, srcOffset, rowValidBytes), dstOffset)
                    srcOffset += BytesPreRow
                    dstOffset += rowValidBytes
                }
                buffer.unmap()

                onFinish()
            })
        }
    }

    public readSyncWGPU(arg: ReadSyncWGPUParams) {
        const state = this.deps.getCanvasState()
        if (state?.type != CanvasStateType.WebGPU || !state.device) {
            this.deps.log('WK_READ_SYNC_WGPU_ERROR', {
                reason: '[GL] type is not webgpu or device is null',
            })
            return
        }
        const texture = state.findTexture(arg.textureHandle)
        if (!texture) {
            this.deps.log('WK_READ_SYNC_WGPU_ERROR', {
                reason: '[GL] texture is not found',
            })
            return
        }

        const webgpuContext = GlobalWebGPUCanvas.context(arg.width, arg.height)
        if (!webgpuContext) {
            this.deps.log('WK_READ_SYNC_WGPU_ERROR', {
                reason: '[GL] webgpuContext not found',
            })
            return
        }
        webgpuContext.configure({
            device: state.device,
            format: 'rgba8unorm',
            usage: GPUTextureUsage.COPY_DST,
            alphaMode: 'premultiplied',
        })
        const encoder = state.device.createCommandEncoder()
        encoder.copyTextureToTexture(
            { texture, origin: { x: arg.x, y: arg.y } },
            { texture: webgpuContext.getCurrentTexture() },
            { width: arg.width, height: arg.height }
        )
        state.device.queue.submit([encoder.finish()])

        const webgl = GlobalCanvas.webgl()
        if (!webgl) {
            this.deps.log('WK_READ_SYNC_WGPU_ERROR', {
                reason: '[GL] webgl not found',
            })
            return
        }
        const gl = webgl.context
        const glTexture = gl.createTexture()
        gl.bindTexture(gl.TEXTURE_2D, glTexture)
        // 必须设置预乘 alpha，WebGL 并不会根据 canvas 的 alphaMode 来上传数据
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true)
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, webgpuContext.canvas)
        // 恢复到默认设置
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, false)
        const glFramebuffer = gl.createFramebuffer()
        gl.bindFramebuffer(gl.FRAMEBUFFER, glFramebuffer)
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, glTexture, 0)
        const dst = this.deps.getHeap().subarray(arg.dataOffset, arg.dataOffset + arg.width * arg.height * 4)
        gl.readPixels(arg.x, arg.y, arg.width, arg.height, gl.RGBA, gl.UNSIGNED_BYTE, dst)
        gl.deleteTexture(glTexture)
        gl.deleteFramebuffer(glFramebuffer)
    }
}
