import { featureSwitchManager } from '../switch'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { IDBPDatabase, IDBPObjectStore, StoreNames } from 'idb'
import { gzip, ungzip } from 'pako'
import { domLocation, downloadBlob, isCypress } from '../../../../util/src'
import { HttpPrefixKey, environment } from '../../environment'
import { formalAbroadTestingEnvironment } from '../../environment/formal-abroad.ts/testing'
import { testingEnvironment } from '../../environment/inland/testing'
import { IndexedDBName } from '../../web-storage/indexed-db/config'
import { RecordReplaySchema } from '../../web-storage/indexed-db/config/schema'
import { openEnhancedIndexedDB } from '../../web-storage/indexed-db/storage'
import { getAllDatabaseNames } from '../../web-storage/indexed-db/utils'
import { CrashData } from '../bridge/bridge'
import { WkCLog } from '../clog/wukong/instance'
import { ReplayDBPrefix, getReplayDownloadUrl, getReplayId } from '../interface/replay'
import { Sentry, disableSentry } from '../sentry'
import { CrashType, CrashType2Label } from '../util/crash'
import { openRecordingDb } from './record'

const KEEP_RECENT_RECORDINGS_COUNT = 20
const LOCK_KEY = 'UPLOADING'
let currentUserEmail = ''
let currentSessionId = 0

export const BRIDGE_CALL_RECORDING_VERSION = 3
export const RECORD_UPLOADED_FONTS_DB_VERSION = 1

export enum JiraIssueType {
    Bug = 10009,
    Crash = 10078,
}

export interface UploadAuthorizationVO {
    resourceId: string
    resourceUrl: string
    ossUrl: string
    contentType: string
    method: string
}

export interface UserReport {
    email: string
    sessionId: number
    fileUrl: string
    timestamp: number
    description: string
    sentryEventId: string
    release: string
    crashType: string
}

export function setCurrentUserEmail(email: string) {
    currentUserEmail = email
}

export function setCurrentSessionId(sessionId: number) {
    currentSessionId = sessionId
}

export async function uploadCrashedRecordings() {
    if (!indexedDB.databases) {
        return
    }
    const dbNames = await getAllDatabaseNames()
    let recordingTimestamps = []
    for (const name of dbNames) {
        if (name.startsWith(ReplayDBPrefix)) {
            recordingTimestamps.push(getReplayId(name))
        }
    }
    recordingTimestamps = recordingTimestamps.sort().reverse()
    const expiredRecordings = new Set<number>(recordingTimestamps.slice(KEEP_RECENT_RECORDINGS_COUNT))

    for (const recordingTimestamp of recordingTimestamps) {
        const dbName = `${ReplayDBPrefix}${recordingTimestamp}`
        const db = await openRecordingDb(dbName)
        const userReport = await getRecordingMark<UserReport>(db, 'USER_REPORTED')
        if (userReport) {
            const locked = await tryLock(db)
            if (!locked) {
                return
            }
            const [uploadTo, uploadErrorMessage] = await uploadBridgeRecordingTwice(dbName, db)
            const lines = []
            if (userReport.description) {
                lines.push(`「问题描述」\n${userReport.description}`)
            }
            lines.push(`「用户账号」\n${userReport.email}`)
            lines.push(`「sessoinId」\n${userReport.sessionId}`)
            lines.push(`「故障时间」\n${new Date(userReport.timestamp).toLocaleString()}`)
            lines.push(`「故障文件」\n${userReport.fileUrl}`)
            lines.push(`「本地下载」\n ${getReplayDownloadUrl(db.name!)}`)
            lines.push(
                `「错误现场」\n${
                    uploadTo
                        ? await getReplayUrl(uploadTo, userReport.release)
                        : `上传失败 ${uploadErrorMessage}， 请联系用户本地下载 ${getReplayDownloadUrl(
                              db.name!
                          )} 然后发给你`
                }`
            )
            lines.push(`「错误堆栈」\nhttps://wukong-1o.sentry.io/issues/?query=id:${userReport.sentryEventId}`)
            lines.push(
                `「clog 日志查询链接」\n${clogQueryUrl(String(userReport.sessionId), docIdFromUrl(userReport.fileUrl))}`
            )
            const labels = uploadTo ? ['包含回放'] : ['无回放']
            labels.push(environment.isProduction ? 'production' : 'testing')
            let sendWechat = environment.isProduction
            if (userReport.crashType) {
                labels.push(userReport.crashType)
            }
            if (featureSwitchManager.isCurrentUserMaintainer()) {
                labels.push('维护者创建')
                sendWechat = false
            }
            const jiraIssueLink = await createJiraIssue({
                issueTypeId: JiraIssueType.Crash,
                projectId: '10002',
                summary: `${uploadTo ? '[包含回放]' : '[无回放]'} Bridge 连接因遇到异常状态关闭 - ${userReport.email}`,
                components: ['未分类'],
                labels,
                priorityId: 2,
                description: lines.join('\n\n'),
                reporterEmail: userReport.email,
                sentryEventId: userReport.sentryEventId,
                sendWechat,
            })
            console.warn('created jira issue', jiraIssueLink)
            await updateRecordingMark(db, 'USER_REPORTED', '')
            // 删除上传成功的 DB
            if (uploadTo) {
                indexedDB.deleteDatabase(dbName)
            }
        } else if (expiredRecordings.has(recordingTimestamp)) {
            indexedDB.deleteDatabase(dbName)
        }
    }
}

export async function uploadDataBase(dbName: string): Promise<[UploadAuthorizationVO | undefined, string | undefined]> {
    const db = await openRecordingDb(dbName)
    const locked = await tryLock(db)
    if (!locked) {
        return [undefined, 'locked']
    }
    return await uploadBridgeRecordingTwice(dbName, db)
}

async function uploadBridgeRecordingTwice(
    dbName: string,
    db: IDBPDatabase<RecordReplaySchema>
): Promise<[UploadAuthorizationVO | undefined, string | undefined]> {
    if ((await countBridgeCallRecording(dbName)) > 500000) {
        WkCLog.log('recording is too large to upload', {
            dbName,
        })
        return [undefined, '录制条数超过阈值']
    }
    try {
        return [await uploadBridgeRecording(dbName, db), undefined]
    } catch (e) {
        console.error('failed to upload crash', e)
        try {
            await sleep(1000)
            return [await uploadBridgeRecording(dbName, db), undefined]
        } catch (e2) {
            console.error('failed to upload crash again', e2)
            return [undefined, `${e2}`]
        }
    }
}

export async function downloadBridgeRecording(dbName: string, downloadAs?: string) {
    const recording = await openBridgeCallRecording(dbName)
    await uploadFonts(recording)
    const encoded = Wukong.DocumentProto.BridgeCallRecording.encode(recording).finish()
    const gzipped = gzip(encoded)
    const decoded = Wukong.DocumentProto.BridgeCallRecording.decode(ungzip(gzipped))
    if (decoded.recordingVersion !== BRIDGE_CALL_RECORDING_VERSION) {
        throw new Error('录制文件的版本过旧，无法查看')
    }
    downloadBlob(new Blob([gzipped]), downloadAs || `${dbName}.bin`)
}

export async function countBridgeCallRecording(recordingName: string) {
    const db = await openRecordingDb(recordingName)

    try {
        const tx = db.transaction(['BridgeCall'], 'readonly')
        const store = tx.objectStore('BridgeCall')
        const count = await store.count()
        return count
    } finally {
        db.close()
    }
}
export async function openBridgeCallRecording(dbName: string) {
    const db = await openRecordingDb(dbName)

    try {
        const tx = db.transaction(['BridgeCall'], 'readonly')
        const store = tx.objectStore('BridgeCall')
        const recording: Wukong.DocumentProto.IBridgeCallRecording = { bridgeCalls: [] }
        let cursor = await store.openCursor()

        while (cursor) {
            if (cursor.value?.action === Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_OPEN_DOC) {
                recording.docId = cursor.value.docId
                recording.timestamp = cursor.value.timestamp
                recording.url = cursor.value.url
                recording.recordingVersion = BRIDGE_CALL_RECORDING_VERSION
                recording.perfNow = cursor.value.perfNow
                recording.release = cursor.value.release
            } else if (typeof cursor.key === 'number') {
                if (recording.bridgeCalls!.length <= cursor.key) {
                    recording.bridgeCalls!.length = cursor.key + 1
                }
                recording.bridgeCalls![cursor.key] = cursor.value
            }
            cursor = await cursor.continue()
        }

        // indexed db 的序号不能是 0，所以是从 1 开始的
        // 1 是 BRIDGE_ACTION_OPEN_DOC 不进入回放
        // 所以删掉前两个空位子
        recording.bridgeCalls!.splice(0, 2)

        for (let i = 0; i < recording.bridgeCalls!.length; i++) {
            if (!recording.bridgeCalls![i]) {
                recording.bridgeCalls![i] = { action: Wukong.DocumentProto.BridgeAction.BRIDGE_ACTION_UNKNOWN }
            }
        }

        return recording
    } finally {
        db.close()
    }
}

export async function uploadBridgeRecording(dbName: string, db: IDBPDatabase<RecordReplaySchema>) {
    WkCLog.log('start upload bridge recording', {
        dbName,
    })
    const recording = await openBridgeCallRecording(dbName)
    await uploadFonts(recording)
    const encoded = Wukong.DocumentProto.BridgeCallRecording.encode(recording).finish()
    const blob = new Blob([gzip(encoded)])
    const uploadTo = await getUploadAuthorization()
    const ossRes = await doUpload(dbName, db, blob, uploadTo)
    if (!ossRes.ok) {
        throw new Error('failed to upload ' + ossRes.status + ': ' + (await ossRes.text()))
    }
    WkCLog.log('uploaded bridge recording', {
        dbName,
        uploadTo: uploadTo.resourceUrl,
    })
    return uploadTo
}

async function doUpload(
    dbName: string,
    db: IDBPDatabase<RecordReplaySchema>,
    blob: Blob,
    uploadTo: UploadAuthorizationVO
) {
    const uploaded = fetch(uploadTo.ossUrl, {
        method: uploadTo.method,
        headers: {
            'Content-Type': uploadTo.contentType,
        },
        body: blob,
    })
    let counter = 1
    while ((await Promise.race([sleep(5000), uploaded])) === 'sleep') {
        WkCLog.log('still uploading bridge recording', {
            counter: counter++,
            dbName,
            uploadTo: uploadTo.resourceUrl,
            fileSize: blob.size,
        })
        await refreshLock(db)
    }
    return uploaded
}

async function uploadFonts(recording: Wukong.DocumentProto.IBridgeCallRecording) {
    for (const bridgeCall of recording.bridgeCalls!) {
        if (bridgeCall?.code === Wukong.DocumentProto.WasmCallCode.WCC_loadFontInJs) {
            const argLoadFont = Wukong.DocumentProto.Arg_loadFontInJs.decodeDelimited(bridgeCall.args!)
            if (argLoadFont.url) {
                argLoadFont.url = await uploadFont(argLoadFont.url)
                bridgeCall.args = Wukong.DocumentProto.Arg_loadFontInJs.encodeDelimited(argLoadFont).finish()
            }
        }
    }
}

function isFontPluginUrl(url: string) {
    return url.startsWith(environment.fontPluginLocalHost) || url.startsWith(environment.fontPluginDaemonHost)
}

async function uploadFont(url: string) {
    if (!isFontPluginUrl(url)) {
        return url
    }
    const uploadedTo = await getUploadedFont(url)
    if (uploadedTo) {
        return uploadedTo
    }
    WkCLog.log('start upload font', {
        url,
    })
    const fontData = await downloadFont(url)
    const uploadTo = await getUploadAuthorization()
    const ossRes = await fetch(uploadTo.ossUrl, {
        method: uploadTo.method,
        headers: {
            'Content-Type': uploadTo.contentType,
        },
        body: new Blob([fontData]),
    })
    if (!ossRes.ok) {
        throw new Error('failed to upload font ' + ossRes.status + ': ' + (await ossRes.text()))
    }
    await cacheUploadedFont(url, uploadTo.resourceUrl)
    WkCLog.log('uploaded font', {
        url,
        uploadTo: uploadTo.resourceUrl,
    })
    return uploadTo.resourceUrl
}

export async function openUploadedFontDb() {
    const db = await openEnhancedIndexedDB({
        name: IndexedDBName.UploadedFonts,
        version: RECORD_UPLOADED_FONTS_DB_VERSION,
        callback: {
            upgrade: (database, oldVersion) => {
                if (oldVersion === 0) {
                    database.createObjectStore('UploadedFonts')
                } else {
                    throw new Error('version mismatch: ' + oldVersion)
                }
            },

            blocked: () => {
                console.warn('open UploadedFonts database blocked')
            },
        },
    })
    return db
}

async function getUploadedFont(fontUrl: string): Promise<string> {
    const db = await openUploadedFontDb()
    const store = db.transaction(['UploadedFonts'], 'readwrite').objectStore('UploadedFonts')
    try {
        const url = await store.get(fontUrl)
        return url ?? ''
    } catch (e) {
        return ''
    }
}

async function cacheUploadedFont(fontUrl: string, uploadedUrl: string) {
    const db = await openUploadedFontDb()
    const store = db.transaction(['UploadedFonts'], 'readwrite').objectStore('UploadedFonts')
    await store.put(uploadedUrl, fontUrl)
}

export async function getReplayUrl(uploadTo: Promise<UploadAuthorizationVO> | UploadAuthorizationVO, release?: string) {
    const resourceUrl = (await uploadTo).resourceUrl
    if (!release) {
        // eslint-disable-next-line no-process-env
        release = process.env.BUILD_ID || ''
    }
    const host = environment.isAbroad ? formalAbroadTestingEnvironment.host : testingEnvironment.host
    if (release) {
        return `${host}/commit/${release}/app/workbench/recording-loader?url=${resourceUrl}`
    } else {
        return `${host}/workbench/recording-loader?url=${resourceUrl}`
    }
}

export class GetUploadAuthorizationError extends Error {
    constructor(message: string) {
        super(message)
        this.name = this.constructor.name
    }
}

export async function getUploadUpgradeOperationsAuthorization(
    docId: string,
    originSchemaVersion: number,
    targetSchemaVersion: number
): Promise<UploadAuthorizationVO> {
    try {
        const res = await fetch(
            environment.httpPrefixes[HttpPrefixKey.COMMON_API] +
                `/admin/file/cos/private/upgrade-operations/uploadAuthorization?docId=${docId}&originSchemaVersion=${originSchemaVersion}&targetSchemaVersion=${targetSchemaVersion}`,
            {
                credentials: 'include',
            }
        )
        if (!res.ok) {
            throw new Error(
                'failed to get upgradeOperations upload authorization ' + res.status + ': ' + (await res.text())
            )
        }
        return await res.json()
    } catch (e: any) {
        throw new GetUploadAuthorizationError(e.message)
    }
}

export async function getUploadAuthorization(): Promise<UploadAuthorizationVO> {
    try {
        const res = await fetch(
            environment.httpPrefixes[HttpPrefixKey.COMMON_API] +
                '/file/cos/public/report/uploadAuthorization?format=bin',
            {
                credentials: 'include',
            }
        )
        if (!res.ok) {
            throw new Error('failed to get upload authorization ' + res.status + ': ' + (await res.text()))
        }
        return await res.json()
    } catch (e: any) {
        throw new GetUploadAuthorizationError(e.message)
    }
}

export async function reportMirrorCrash(crashData: CrashData, docId: string, crashType: string) {
    try {
        const isMaintainer = featureSwitchManager.isCurrentUserMaintainer()

        const sentryEventId = Sentry.captureException(crashData.error, {
            tags: {
                wasmCrash: true,
                crashType,
                isMaintainer,
            },
        })

        // crash 后不再上报 sentry
        disableSentry(0)

        const lines = [
            `「用户账号」\n${currentUserEmail}`,
            `「故障时间」\n${new Date().toLocaleString()})`,
            `「故障文件」\n${docId}`,
            `「错误堆栈」\nhttps://wukong-1o.sentry.io/issues/?query=id:${sentryEventId}`,
            `「clog 日志查询链接」\n${clogQueryUrl(String(currentSessionId), docId)}`,
        ]

        if (isCypress()) {
            // cypress不上报jira
            return
        }

        if (!environment.isProduction) {
            // 非线上环境不上报jira
            return
        }

        const labels = ['无回放', crashType]
        let sendWechat = true

        if (isMaintainer) {
            labels.push('维护者创建')
            sendWechat = false
        }

        if (crashType === CrashType2Label[CrashType.WEBGL_CONTEXT_LOST]) {
            // 目前没什么处理手段，不发送消息
            sendWechat = false
        }

        return await createJiraIssue({
            issueTypeId: JiraIssueType.Crash,
            projectId: '10002',
            summary: `Bridge 连接因遇到异常状态关闭 - ${currentUserEmail}`,
            components: ['移动端预览'],
            labels,
            priorityId: 2,
            description: lines.join('\n\n'),
            reporterEmail: currentUserEmail,
            sentryEventId: sentryEventId,
            sendWechat,
        })
    } catch (error) {
        console.error('reportMirrorCrash failed', error)
    }
}

export async function createJiraIssue(options: {
    issueTypeId?: JiraIssueType
    projectId?: string
    summary?: string
    components?: string[]
    labels?: string[]
    priorityId?: number
    description?: string
    reporterEmail?: string
    fieldId2Value?: { [fieldId: string]: string }
    sentryEventId?: string
    sendWechat?: boolean
}) {
    const res = await fetch(environment.httpPrefixes[HttpPrefixKey.COMMON_API] + '/jira/issue', {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(options),
    })
    if (!res.ok) {
        throw new Error('failed to create jira issue' + res.status + ': ' + (await res.text()))
    }
    return await res.text()
}

async function downloadFont(url: string) {
    const res = await fetch(url)
    if (!res.ok) {
        throw new Error('failed to fetch font ' + res.status)
    }
    return new Uint8Array(await res.arrayBuffer())
}

async function tryLock(db: IDBPDatabase<RecordReplaySchema>) {
    const store = db.transaction(['BridgeCall'], 'readwrite').objectStore('BridgeCall')
    const timestamp = await getUploading(store)
    if (timestamp === undefined) {
        await store.add(new Date().getTime(), LOCK_KEY)
        return true
    } else {
        const now = new Date().getTime()
        if (now - timestamp > 10000) {
            return true
        }
        return false
    }
}

async function refreshLock(db: IDBPDatabase<RecordReplaySchema>) {
    const tx = db.transaction(['BridgeCall'], 'readwrite')
    const store = tx.objectStore('BridgeCall')
    await store.put(new Date().getTime(), LOCK_KEY)
}

async function getUploading(
    store: IDBPObjectStore<
        RecordReplaySchema,
        ArrayLike<StoreNames<RecordReplaySchema>>,
        StoreNames<RecordReplaySchema>,
        'readwrite'
    >
) {
    return await store.get(LOCK_KEY)
}

async function updateRecordingMark(db: IDBPDatabase<RecordReplaySchema>, key: string, value: any) {
    const tx = db.transaction(['BridgeCall'], 'readwrite')
    const store = tx.objectStore('BridgeCall')
    await store.put(value, key).catch((error) => {
        console.error('failed to update mark', key, error)
    })
}

export async function markRecordingAsUserReported(
    recordingName: string | undefined,
    options: { description: string; sentryEventId: string; crashType: string }
) {
    if (!recordingName) {
        return
    }
    const fileUrl = domLocation().href
    const timestamp = new Date().getTime()
    const db = await openRecordingDb(recordingName)
    const userReport: UserReport = {
        fileUrl,
        timestamp,
        email: currentUserEmail,
        sessionId: currentSessionId,
        // eslint-disable-next-line no-process-env
        release: process.env.BUILD_ID || '',
        ...options,
    }
    await updateRecordingMark(db, 'USER_REPORTED', userReport)
}

export async function reportRecording(description: string, uploadTo: UploadAuthorizationVO) {
    const time = new Date()
    const replayUrl = await getReplayUrl(uploadTo)
    const issueUrl = await createJiraIssue({
        issueTypeId: JiraIssueType.Bug,
        projectId: '10002',
        summary: `用户错误报告 - ${currentUserEmail}`,
        components: ['未分类'],
        labels: ['用户主动上报'],
        priorityId: 3,
        description: [
            `「问题描述」\n${description}`,
            `「故障文件」\n${domLocation().href}`,
            `「错误现场」\n${replayUrl}`,
            `「故障时间」\n${time.getFullYear()}/${
                time.getMonth() + 1
            }/${time.getDate()} ${time.getHours()}:${time.getMinutes()}`,
            `「用户账号」\n${currentUserEmail}`,
            `「sessionId」\n${currentSessionId}`,
            `「clog 日志查询链接」\n${clogQueryUrl(String(currentSessionId), docIdFromUrl(domLocation().href))}`,
        ].join('\n\n'),
        reporterEmail: currentUserEmail,
        // customfield_10099 是 jira上自定义字段【Affected Users】对应的id
        fieldId2Value: { customfield_10099: currentUserEmail },
        sendWechat: false,
    })
    return { issueUrl }
}

async function getRecordingMark<T>(db: IDBPDatabase<RecordReplaySchema>, key: string) {
    const tx = db.transaction(['BridgeCall'], 'readwrite')
    const store = tx.objectStore('BridgeCall')
    return await store.get(key)
}

function sleep(ms: number) {
    return new Promise((resolve) =>
        setTimeout(() => {
            resolve('sleep')
        }, ms)
    )
}

function clogQueryUrl(sessionId: string, docId: string) {
    return `${environment.clogConfig.queryUrl}&ql=sessionId%20%3D%20${sessionId}%20AND%20documentId%20%3D%20%22${docId}%22`
}

function docIdFromUrl(url: string) {
    const match = url.match(/file\/(.*)\?/)
    return match ? match[1] : ''
}
