import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { RefObject, useRef } from 'react'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { useSelectedVariablesEditorContext } from '../selected-variables-editor-context'
import { ColorVariableValueItem } from './color-item'
import { FloatVariableValueItem } from './float-item'
import classes from './index-v2.module.less'
import { translation } from './index-v2.translation'

// 变量值编辑区域，包含标题和各个 mode 对应的行
export function VariableValue({
    variable,
    collection,
}: {
    variable: Wukong.DocumentProto.ILocalVariable
    collection: Wukong.DocumentProto.ILocalVariableSet | null
}) {
    return (
        <div className={classNames(classes.container, isEnglishLanguage() && classes.en)} data-testid="variable-value">
            <div className={classes.title}>{translation('Value')}</div>
            {collection?.modes.map((mode, index) => {
                if (!mode.modeId) {
                    return null
                }
                return <VariableValueItem key={index} mode={mode} variable={variable} />
            })}
        </div>
    )
}

// 每个 mode 对应的一行，包含 label 和 item 两个部分
function VariableValueItem({
    mode,
    variable,
}: {
    mode: Wukong.DocumentProto.IVariableSetMode
    variable: Wukong.DocumentProto.ILocalVariable
}) {
    const { modeId, modeName } = mode
    const { selectedModeId } = useSelectedVariablesEditorContext()
    const selected = selectedModeId === modeId
    // 由外部获得值编辑区域的 div ref 给到组件中，用于计算 popup 的位置（不同 type 打开位置不同）
    const containerRef = useRef<HTMLDivElement>(null)
    return (
        <div
            data-testid="variable-value-item"
            className={classNames(classes.line, selected && classes.selected)}
            onPointerDown={(e) => e.stopPropagation()}
        >
            <div className={classes.label}>{modeName}</div>
            <div className={classes.item} ref={containerRef}>
                <VariableValueItemByType mode={mode} variable={variable} containerRef={containerRef} />
            </div>
        </div>
    )
}

// 根据变量类型选择对应的 item
function VariableValueItemByType({
    mode,
    variable,
    containerRef,
}: {
    mode: Wukong.DocumentProto.IVariableSetMode
    variable: Wukong.DocumentProto.ILocalVariable
    containerRef?: RefObject<HTMLDivElement>
}) {
    switch (variable.dataType) {
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR:
            return <ColorVariableValueItem mode={mode} variable={variable} popupRef={containerRef} />
        case Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT:
            return <FloatVariableValueItem mode={mode} variable={variable} popupRef={containerRef} />
        default:
            return null
    }
}
