import { ScrubbableInput, ScrubbableInputProps } from './components/scrubbable-input'
import { formateToFixed2Percent, formateToIntegerPercent } from './utils/format'
import { valueFilter10 } from './utils/value-filter'

/**
 * @param props
 * @returns
 * @description 百分比专用
 */
export function ScrubbableInputPercent(props: ScrubbableInputProps & { isInteger?: boolean }) {
    const { isInteger, ...otherProps } = props
    return (
        <ScrubbableInput
            formatter={{ format: isInteger ? formateToIntegerPercent : formateToFixed2Percent }}
            valueFilter={valueFilter10}
            resolution={1}
            {...otherProps}
            value={Number(otherProps.value ?? 0) * 100}
            allowedUnits={'%'}
            notUseUserConfig
        />
    )
}
