import { Wukong } from '@wukong/bridge-proto'
import { StyleListItem, StyleListItemKeyboardProps, StyleListItemProps } from '../style-items-layout/style-list-item'
import style from './style-layout-grid-list-item.module.less'
import { LayoutGridStyleThumbnail } from './style-thumbnail'

export function StyleLayoutGridListItem(
    props: StyleListItemProps<{ layoutGrids: readonly Wukong.DocumentProto.ILayoutGrid[] }> & StyleListItemKeyboardProps
) {
    return (
        <StyleListItem {...props}>
            <LayoutGridStyleThumbnail
                className={style.thumbnail}
                layoutGrids={props.item.layoutGrids}
                reversed={true}
            />
            <div className={style.name}>{props.item.styleName}</div>
        </StyleListItem>
    )
}
