import { Wukong } from '@wukong/bridge-proto'
import { forwardRef } from 'react'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { BlendColor } from '../../blend/blend-color/blend-color'
import { ColorLibraries } from '../color-libraries/color-libraries'
import { ColorPanelProps } from '../type'
import { ColorPanelContainerV2 } from './color-panel-container-v2'
import { ColorPanelTitle } from './color-panel-title'
import { ColorPanelRef, useColorPanel } from './use-color-panel'

function _ColorPanelSubset(props: ColorPanelProps, ref: React.Ref<ColorPanelRef>) {
    const {
        originalProps,
        paint,
        activeTitle,
        colorSpace,
        colorPanelContainerRef,
        colorPanelContainerV2Ref,
        setActiveTitle,
        onChangeColor,
        onChangeOpacity,
        onFirstMove,
        onChangeColorVar,
        onChangeStyle,
    } = useColorPanel(props, ref)

    return (
        <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (originalProps.onCancel(), false)}>
            <ColorPanelContainerV2
                ref={colorPanelContainerV2Ref}
                testId="color-panel-subset"
                position={originalProps.position}
                header={
                    <ColorPanelTitle
                        from={originalProps.from}
                        activeKey={activeTitle}
                        onActiveTabChange={setActiveTitle}
                        onClickAddIcon={originalProps.onClickAddIcon}
                        onClickCloseIcon={originalProps.onCancel}
                    />
                }
                onFirstMove={onFirstMove}
            >
                {activeTitle === 'libraries' ? (
                    <ColorLibraries
                        from={originalProps.from}
                        selectStyleId={originalProps.styleId}
                        selectStyleNodeKey={originalProps.styleKey ?? ''}
                        selectColorVariableNodeId={paint.colorVar?.value.alias ?? undefined}
                        onChangeStyle={onChangeStyle}
                        onChangeColorVar={onChangeColorVar}
                        onClickEditStyle={originalProps.onClickEditStyle}
                    />
                ) : activeTitle === 'custom' && paint.type === Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT ? (
                    <BlendColor
                        color={paint.color!}
                        colorSpace={colorSpace}
                        opacity={paint.opacity ?? 1}
                        onChangeColor={onChangeColor}
                        onChangeOpacity={onChangeOpacity}
                    />
                ) : null}
            </ColorPanelContainerV2>
        </KeyboardReceiver>
    )
}

export const ColorPanelSubset = forwardRef<ColorPanelRef, ColorPanelProps>(_ColorPanelSubset)
