import { useEffect, useRef, useState } from 'react'
import { InputV2, WKButton } from '../../../../../../../../../ui-lib/src'
import { TextAreaOperations } from '../../../../../../../../../ui-lib/src/components/inputs/textarea/textarea'
import { PopupStateType } from '../../../../../../../document/node/node'
import { SingleGrid } from '../../../../../atom/grid/single-grid'
import { Arg_cmdEditComponentPropData, ComponentPropType, VComponentPropDef, VariableAnyValue } from '../../../types'
import { ICreateComponentProp } from '../../component-prop/hook'
import styles from './index.module.less'
import { translation } from './index.translation'

export function CreateTextPropPopupContent(props: {
    popupData: VComponentPropDef
    popupType: PopupStateType
    closePopup: () => void
    createProp: (propVal: ICreateComponentProp) => void
    createAndApplyProp: (propVal: ICreateComponentProp) => void
}) {
    const { closePopup, popupType, createProp, createAndApplyProp, popupData } = props

    const [textName, setTextName] = useState<string>(popupData.name)
    const [textVal, setTextVal] = useState<string>(popupData.varValue.value.textDataValue.characters)

    const confirm = () => {
        const propData = {
            name: textName,
            type: ComponentPropType.COMPONENT_PROP_TYPE_TEXT,
            value: {
                textDataValue: {
                    characters: textVal,
                },
            } as VariableAnyValue,
        }

        if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_PROP_AREA) {
            createProp(propData)
        } else if (popupType === PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_TEXT_PROP_OF_TEXT_AREA) {
            createAndApplyProp(propData)
        }
        closePopup()
    }

    return (
        <div className="pt-2">
            <SingleGrid>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyName')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2
                        dataTestIds={{ input: 'create-text-prop-name-input' }}
                        autoFocus
                        size="small"
                        width={144}
                        value={textName}
                        onChange={(e: any) => {
                            setTextName(e.target.value)
                        }}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={styles.textAreaRow}>
                <SingleGrid.Item start={5} span={16} className={styles.textPropLabel}>
                    {translation('PropertyValue')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2.Textarea
                        dataTestIds={{ textarea: 'create-text-prop-value-textarea' }}
                        autoHeight
                        minHeight={28}
                        maxHeight={108}
                        onChange={(e: any) => {
                            setTextVal(e.target.value)
                        }}
                        value={textVal}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <div className={styles.compPropPopupConfirm}>
                <WKButton
                    type="primary"
                    dataTestId="create-prop-confirm"
                    onClick={confirm}
                    disabled={!textName || !textVal}
                >
                    {translation('CreateProperty')}
                </WKButton>
            </div>
        </div>
    )
}

export function UpdateTextPropPopupContent(props: {
    popupData: VComponentPropDef
    updateProp: (propVal: Arg_cmdEditComponentPropData) => void
}) {
    const { updateProp, popupData } = props
    const [textVal, setTextVal] = useState<string>(popupData.varValue.value.textDataValue.characters)

    const ref = useRef<TextAreaOperations>(null)

    useEffect(() => {
        setTextVal(popupData.varValue.value.textDataValue.characters)
    }, [popupData.varValue.value.textDataValue.characters])

    const onNameInputBlur = (e: any) => {
        const value = e.target.value
        if (!value || value === popupData.name) {
            return
        }
        updateProp({
            propId: popupData.id,
            name: value,
        })
    }

    const onValueInputBlur = (e: any) => {
        const value = e.target.value
        if (value === popupData.varValue.value.textDataValue.characters) {
            return
        }
        setTextVal(value)
        updateProp({
            propId: popupData.id,
            value: {
                textDataValue: {
                    characters: value,
                },
            } as VariableAnyValue,
        })
    }

    return (
        <div className="pt-2 pb-3">
            <SingleGrid>
                <SingleGrid.Item start={5} span={16}>
                    {translation('PropertyName')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36}>
                    <InputV2
                        dataTestIds={{ input: 'update-text-prop-name-input' }}
                        autoFocus
                        size="small"
                        width={144}
                        value={popupData.name}
                        onBlur={onNameInputBlur}
                    />
                </SingleGrid.Item>
            </SingleGrid>
            <SingleGrid className={styles.textAreaRow}>
                <SingleGrid.Item start={5} span={16} className={styles.textPropLabel}>
                    {translation('PropertyValue')}
                </SingleGrid.Item>
                <SingleGrid.Item start={21} span={36} onKeyDown={(e) => e.stopPropagation()}>
                    <InputV2.Textarea
                        ref={ref}
                        onFocus={() => {
                            ref.current?.selectAll()
                        }}
                        onInput={(e) => setTextVal(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault() // 阻止 textArea 回车换行的默认行为
                                const inputElement = e.target as HTMLTextAreaElement
                                inputElement.blur() // 触发失焦事件
                            }
                        }}
                        dataTestIds={{ textarea: 'update-text-prop-value-textarea' }}
                        autoHeight
                        minHeight={28}
                        maxHeight={108}
                        value={textVal}
                        onBlur={onValueInputBlur}
                    />
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    )
}
