import { OnboardingScene2Status, OnboardingStatus } from './onboarding'

import { LoginPolicy, PayPlanType, ResourceType, UserBriefVO, UserID } from './type'

export enum UserVOV2Status {
    NORMAL = 'NORMAL',
    DEREGISTERED = 'DEREGISTERED',
    INIT = 'INIT',
}

export enum PreferenceComment {
    None = 'none',
    Relative = 'onlyMentionAndReply',
    All = 'all',
}

export enum PreferenceInviteRequest {
    None = 'none',
    Approval = 'onlyRequestMessage',
    All = 'all',
}
export interface EmailWithOptRequest {
    email: string
    otp: string
    otpToken: string
    password: string
    verifiedOtpKey?: string
    utmInfo: string
}

// [how to add a new feature guide 1] 新增一个key，同时需要同步服务端，让接口先支持
export type FeatureGuideScene = 'mask' | 'aiDuplicate' | 'spaceFavorites' | 'aiGenDesignSystem'
export interface UserVOV2 {
    userId: UserID
    hasPassword: boolean
    hasWechat: boolean
    status: UserVOV2Status
    createdTime: number
    updatedTime: number
    userBrief: UserBriefVO
    preference?: string
    onBoardingScene2Status?: OnboardingScene2Status
    // 功能指引只有开始和结束状态
    featureGuideScene2Status: Record<FeatureGuideScene, OnboardingStatus.Init | OnboardingStatus.Finished>
    featureFlagKeys: string[]
    googleManaged?: boolean
    googleName?: string
    enableEmailNotification?: boolean
    commentPreference?: PreferenceComment
    inviteAndRequestPreference?: PreferenceInviteRequest
    orgLoginPolicyEnum?: LoginPolicy
}

export interface AuthSession {
    authSessionId: string
    expireTimeMillis: number
}

export interface AuthGrant {
    grantCode: string
    expireTimeMillis: number
}

export interface PreferenceEmail {
    enableEmailNotification: boolean
    commentPreference: PreferenceComment
    inviteAndRequestPreference: PreferenceInviteRequest
}

export interface GoogleAuthResponse {
    register: boolean // 是否是新注册账号
}

export interface FulfillmentBriefVO {
    resourceType: ResourceType
    resourceId: string
    planType: PayPlanType
    name: string
    icon: string
    iconBackgroundColor: string
    memberCount: number
    orgId: string
}
