/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { useHistoryService } from '../../../../main/app-context'
import { useLeaveHistoryMode, useSwitchHistoryVersion } from '../../../../main/history-mode'
import { useMenuContextState } from '../../top-area/menu/context/menu-context'
import { AutoItem, CurrentItem, ItemType, UserItem } from '../history-list/type'
import { MessageModalProps } from '../message-modal/message-modal'
import { useDocReadonly } from '../../../../external-store/context/doc-info'

export function useHistoryPanel() {
    const historyService = useHistoryService()

    const items = historyService.useZustandStore.use.items()
    const currentItem = historyService.useZustandStore.use.currentItem()
    const currentHistoryVersionId = historyService.useZustandStore.use.currentHistoryVersionId()
    const isDifferentSelectSort = historyService.useZustandStore.use.isDifferentSelectSort()
    const selectSortFilter = historyService.useZustandStore.use.selectSortFilter()
    const isFinish = historyService.useZustandStore.use.isFinish()
    const existsBeyondThresholdVersion = historyService.useZustandStore.use.existsBeyondThresholdVersion()
    const isLoadMoreLoading = historyService.useZustandStore.use.isLoadMoreLoading()

    const isReadonly = useDocReadonly()
    const { setMessageModalProps } = useMenuContextState()
    const leaveHistoryMode = useLeaveHistoryMode()
    const { switchHistoryVersion, loading } = useSwitchHistoryVersion()

    const editVersionInfo = useCallback(
        (value: UserItem | AutoItem) => {
            const nextState: MessageModalProps = {
                visible: true,
                type: 'edit',
                onOK: (v) => {
                    historyService.requestEditHistoryVersionInfo(value.origin.id, v.name, v.description)
                },
            }
            if (value.type === ItemType.UserItem) {
                nextState.defaultName = value.name
                nextState.defaultDescription = value.description
            } else {
                nextState.defaultName = ''
                nextState.defaultDescription = ''
            }
            setMessageModalProps(nextState)
        },
        [historyService, setMessageModalProps]
    )

    const addNewVersion = useCallback(() => {
        const nextState: MessageModalProps = {
            visible: true,
            type: 'add',
            defaultName: '',
            defaultDescription: '',
            onOK: (v) => {
                historyService.requestAddVersion(v.name, v.description)
            },
        }
        setMessageModalProps(nextState)
    }, [historyService, setMessageModalProps])

    const clickVersion = useCallback(
        async (value: UserItem | AutoItem | CurrentItem) => {
            if (value.origin.id === currentHistoryVersionId) {
                return
            }
            await switchHistoryVersion(value.origin.id, currentHistoryVersionId)
        },
        [currentHistoryVersionId, switchHistoryVersion]
    )

    return {
        currentHistoryVersionId,
        isFinish,
        existsBeyondThresholdVersion,
        isDifferentSelectSort,
        selectSortFilter,
        items,
        currentItem,
        loading,
        isLoadMoreLoading,
        isReadonly,
        addNewVersion,
        closeHistoryPanel: leaveHistoryMode,
        onChangeSort: historyService.updateSelectSortFilter,
        clickVersion,
        editVersionInfo,
        clearVersionInfo: historyService.clearVersionInfo,
        applyVersion: historyService.applyVersion,
        copyVersion: historyService.copyVersion,
        copyVersionLink: historyService.copyVersionLink,
        onClickLoadMore: historyService.loadMore,
    }
}
