import { sleep } from '../../../../util/src'

import { WkCLog } from '../../kernel/clog/wukong/instance'
import { GlobalCanvas } from './global-canvas'

export async function detectCompressedTextureSupported() {
    await sleep(0)

    try {
        const webgl = GlobalCanvas.webgl()
        if (webgl) {
            const astcExt = webgl.context.getExtension('WEBGL_compressed_texture_astc')
            const etcExt = webgl.context.getExtension('WEBGL_compressed_texture_etc')
            const bptcExt = webgl.context.getExtension('EXT_texture_compression_bptc')
            const astc = Boolean(astcExt?.COMPRESSED_RGBA_ASTC_4x4_KHR)
            const etc = Boolean(etcExt?.COMPRESSED_RGB8_ETC2)
            const bptc = Boolean(bptcExt?.COMPRESSED_RGBA_BPTC_UNORM_EXT)

            WkCLog.throttleLog('WK_GPU_COMPRESSED_TEXTURE', {
                astc,
                etc,
                bptc,
            })
        }
    } catch (e) {}
}
