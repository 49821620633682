/**
 * 只针对提交失败，下次进来可以记住答案场景，简单场景
 */

const store = self.localStorage || {}

export default {
    set(key: string, value: string | { [key: string]: any }) {
        try {
            if (store.setItem) {
                store.setItem(key, JSON.stringify(value))
            } else {
                console.warn('store.setItem is undefined')
            }
        } catch (e) {
            console.error(e)
        }
    },
    remove(key: string) {
        try {
            if (store.removeItem) {
                store.removeItem(key)
            } else {
                console.warn('store.removeItem is undefined')
            }
        } catch (e) {
            console.error(e)
        }
    },
    get(key: string) {
        try {
            if (store.getItem) {
                const value = store.getItem(key)
                if (value) {
                    return JSON.parse(value)
                }
            } else {
                console.warn('store.removeItem is undefined')
            }
        } catch (e) {
            console.error(e)
            return null
        }
    },
}
