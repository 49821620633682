/* eslint-disable no-restricted-imports */
import { WKUserAvatar, WKUserAvatarProps } from '../../../../../../ui-lib/src'
import classNames from 'classnames'
import classes from './comment-avatar.module.less'

export interface CommentAvatarProps extends Pick<WKUserAvatarProps, 'userInfo'> {
    className?: string
    pending?: boolean
}
export function CommentAvatar(props: CommentAvatarProps) {
    const { className, userInfo, pending } = props

    return (
        <div
            className={classNames(
                classes.commentAvatar,
                { [className ?? '']: className },
                { [classes.loading]: pending }
            )}
        >
            <WKUserAvatar size={24} userInfo={userInfo} />
        </div>
    )
}
