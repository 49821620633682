/* eslint-disable no-restricted-imports */
import { DetachSelectionTextStyleIdCommand, UpdateSelectionTextStyleIdCommand, Wukong } from '@wukong/bridge-proto'
import { FontName, TextDecoration, TextStyleNode } from '../../../../../document/node/node'
import { DeepRequired, useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useTextState } from './use-text-state'

export const useTextStyleModel = () => {
    return {
        modelState: {
            textStyleState: useTextStyleState(),
            selectionAddStyleInfo: useSelectionAddStyleInfo(),
        },
        modelCommand: useTextStyleCommand(),
    } as const
}

function useTextStyleCommand() {
    const command = useCommand()

    return {
        changeStyleId: (textStyleId: string) => {
            if (!textStyleId) {
                return
            }

            command.DEPRECATED_invokeBridge(
                UpdateSelectionTextStyleIdCommand,
                Wukong.DocumentProto.UpdateSelectionTextStyleIdParam.create({
                    textStyleId,
                })
            )
            command.commitUndo()
        },
        detachStyleId: (textStyleId: string) => {
            command.DEPRECATED_invokeBridge(
                DetachSelectionTextStyleIdCommand,
                Wukong.DocumentProto.DetachSelectionTextStyleIdParam.create({
                    textStyleId,
                })
            )
            command.commitUndo()
        },
    }
}

const useTextStyleState = () => {
    const textState = useViewState('selectionText')

    if (!textState?.hasTextSelection) {
        return { type: 'none' } as const
    }

    if (textState.textStyleType == Wukong.DocumentProto.VTextStyleStateType.V_TEXT_STYLE_STATE_TYPE_STYLE) {
        return {
            type: 'style',
            mixed: false,
            textStyleId: textState.textStyleId,
            textStyleNode: textState.textStyleNode as unknown as TextStyleNode | null,
        } as const
    }

    if (textState.textStyleType == Wukong.DocumentProto.VTextStyleStateType.V_TEXT_STYLE_STATE_TYPE_MIXED) {
        return {
            type: 'mixed',
            mixed: true,
            textStyleId: textState.textStyleId,
            textStyleNode: textState.textStyleNode as unknown as TextStyleNode | null,
        } as const
    }

    return { type: 'none' } as const
}

function useSelectionAddStyleInfo() {
    const selectionTextState = useTextState()
    const selectionTextStyleState = useTextStyleState()

    if (!selectionTextState.hasTextInSelection) {
        return { canAdd: false } as const
    }

    if (selectionTextState.textStyleType == Wukong.DocumentProto.VTextStyleStateType.V_TEXT_STYLE_STATE_TYPE_NORMAL) {
        const {
            fontSize, //
            isFontNameMixed,
            selectedFontName,
            letterSpacing,
            lineHeight,
            textDecoration,
            paragraphSpacing,
            paragraphIndent,
            variationInfo,
            selectedFontCustomAxes,
        } = selectionTextState

        if (
            fontSize.mixed ||
            isFontNameMixed ||
            !selectedFontName ||
            letterSpacing.mixed ||
            lineHeight.mixed ||
            textDecoration.mixed
        ) {
            return { canAdd: false } as const
        }

        const fontVariations: DeepRequired<Wukong.DocumentProto.IFontVariation>[] = []
        let detachOpticalSizeFromFontSize = false
        if (selectedFontCustomAxes?.customAxesStyle !== '' && variationInfo.hasValue) {
            variationInfo.variationAxisValue.map((axis) => {
                fontVariations.push({ axisTag: axis.axisTag, axisName: axis.name, value: axis.value })
            })
            detachOpticalSizeFromFontSize =
                variationInfo.detachOpticalSizeFromFontSize ==
                Wukong.DocumentProto.VDetachOpticalSizeFromFontSize.V_DETACH_OPTICAL_SIZE_FROM_FONT_SIZE_DETACH
        }

        return {
            canAdd: true,
            style: {
                fontSize: fontSize.value,
                fontName: selectedFontName as FontName,
                lineHeight: lineHeight.value,
                letterSpacing: letterSpacing.value,
                textDecoration: textDecoration.value as unknown as TextDecoration,
                fontVariations: fontVariations,
                paragraphSpacing: paragraphSpacing.value ?? 0,
                paragraphIndent: paragraphIndent.value ?? 0,
                detachOpticalSizeFromFontSize: detachOpticalSizeFromFontSize,
            },
        } as const
    }

    if (!selectionTextStyleState.mixed && selectionTextStyleState.textStyleNode) {
        return {
            canAdd: true,
            styleNode: selectionTextStyleState.textStyleNode,
        } as const
    }

    return { canAdd: false } as const
}
