import { ClassWithEffect, EffectController } from '../../../../../util/src/effect-controller'
import { PluginHostService } from '../../../document/plugin-ui/plugin-host-service'
import { debugLog } from '../../../kernel/debug'
import { ClientService } from '../../../main/service/client-service'
import { getLocalPluginData } from './plugin-development/use-plugin-development'
import { fileSep } from './plugin-development/util'

export class PluginHotReloadService extends ClassWithEffect {
    private pluginFileChangeCallback: (() => void) | undefined
    private registeredFilePaths: string[] = []

    constructor(
        controller: EffectController,
        private clientService: ClientService,
        private pluginHostService: PluginHostService
    ) {
        super(controller)
        pluginHostService.bindClosePluginCallback(this.clearBindHotReloadPlugin)
    }

    destroy = () => {
        this.clearBindHotReloadPlugin()
    }

    public bindHotReloadPluginOnce = async (pluginPath: string, callback: () => void) => {
        this.clearBindHotReloadPlugin()

        const data = await getLocalPluginData(pluginPath)
        if (!data) {
            throw new Error()
        }

        const { manifest } = data
        this.registeredFilePaths = [
            `${pluginPath}${fileSep()}manifest.json`,
            `${pluginPath}${fileSep()}${manifest.ui}`,
            `${pluginPath}${fileSep()}${manifest.main}`,
        ]

        this.registeredFilePaths.forEach((p) => {
            this.clientService.bindClientLocalFileChangeListener(p, this.onPluginFileChange)
        })
        this.pluginFileChangeCallback = callback
    }

    public clearBindHotReloadPlugin = () => {
        this.unregisterAllFileChanges()
        this.pluginFileChangeCallback = undefined
    }

    private unregisterAllFileChanges = () => {
        this.registeredFilePaths.forEach((path) => {
            this.clientService.unbindClientLocalFileChangeListener(path, this.onPluginFileChange)
        })
        this.registeredFilePaths = []
    }

    private onPluginFileChange = (path: string) => {
        debugLog('onPluginFileChange', path)
        this.pluginFileChangeCallback?.()
        this.clearBindHotReloadPlugin()
    }
}
