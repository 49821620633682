import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { cloneDeep } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { MonoIconPanelEffect16 } from '../../../../../../../ui-lib/src'
import { DeepRequired } from '../../../../../../../util/src'
import { SolidPaint } from '../../../../../document/node/node'
import { useRightPanelWidth } from '../../../../../main/layout/layout-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useRenderColorSpace } from '../../../color-profile'
import { ColorSpace } from '../../../design/blend/color-picker/utils/color-translate'
import { EffectStyleIcon, getEffectStyleIcon } from '../../../design/effects/effect-style/effect-style-icon'
import { SolidPaintRender } from '../../../inspect/comp/paint-render-v2'
import { ViewAllV2 } from '../../../inspect/comp/view-all-v2'
import { CopyableRow } from '../common/copyable-row'
import { effectTypeToShow } from '../common/inspect-type'
import { ColorModePicker } from '../dev-mode-inspect-fill/color-mode-picker'
import style from '../inspect.module.less'
import { translation } from './index.translation'
import EffectType = Wukong.DocumentProto.EffectType

export function DevModeInspectEffect() {
    const effectViewState = useViewState('devModeInspectEffectViewState')
    const colorSpace = useRenderColorSpace()

    const [limit, setLimit] = useState<number>(2)
    const limitChange = useCallback((_limit: number) => setLimit(_limit), [])

    const effectWrappersValue = useMemo(
        () => cloneDeep(effectViewState?.effectWrappers ?? []).reverse(),
        [effectViewState?.effectWrappers]
    )
    const styleName = effectViewState?.styleName
    const variables = effectViewState?.variables

    if (!effectViewState?.show) {
        return null
    }

    return (
        <div className={style.panel} data-testid="dev-mode-inspect-effect">
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('BAfTrg')}</div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>

            {!!styleName?.length && (
                <EffectStyle
                    styleName={styleName}
                    styleDescription={effectViewState?.styleDescription}
                    limit={limit}
                    effectWrappersValue={effectWrappersValue}
                    colorSpace={colorSpace}
                    variables={variables}
                />
            )}

            {!styleName?.length && (
                <EffectList
                    effectWrappersValue={effectWrappersValue}
                    limit={limit}
                    colorSpace={colorSpace}
                    variables={variables}
                />
            )}
            <ViewAllV2
                limit={limit}
                className="!ml-4"
                count={effectWrappersValue.length}
                onChange={limitChange}
                dataTestId={'dev-mode-effect-view-all'}
            />
        </div>
    )
}

function EffectList({
    effectWrappersValue,
    limit,
    colorSpace,
    variables,
}: {
    effectWrappersValue: DeepRequired<Wukong.DocumentProto.IEffectWrapper>[]
    limit: number
    colorSpace: ColorSpace
    variables?: Wukong.DocumentProto.ISingleVariable[]
}) {
    return (
        <div className="px-4" data-testid={'normal-effect-items'}>
            {effectWrappersValue.slice(0, limit).map((item, index) => {
                const variable = variables?.find((v) => v.id === item.effect.colorVar?.value.alias)
                return (
                    <NormalEffectItem
                        effectWrapper={item}
                        key={index}
                        isStyle={false}
                        colorSpace={colorSpace}
                        variable={variable}
                    />
                )
            })}
        </div>
    )
}

function EffectStyle({
    styleName,
    styleDescription,
    effectWrappersValue,
    limit,
    colorSpace,
    variables,
}: {
    styleName: string
    styleDescription?: string
    effectWrappersValue: DeepRequired<Wukong.DocumentProto.IEffectWrapper>[]
    limit: number
    colorSpace: ColorSpace
    variables?: Wukong.DocumentProto.ISingleVariable[]
}) {
    const effectsValue = useMemo(() => {
        return effectWrappersValue.map((item) => item.effect)
    }, [effectWrappersValue])

    return (
        <div className="px-4 mt-2">
            <div className="flex items-center wk-font-medium">
                <EffectStyleIcon className="shrink-0" iconType={getEffectStyleIcon(effectsValue)} />
                <div className={style.effectStyleName}>
                    <CopyableRow copyValue={styleName} style={{ padding: '2px 4px' }}>
                        <span className="wk-text-12 wk-font-medium">{styleName}</span>
                    </CopyableRow>
                </div>
            </div>
            <div className={style.styleItemBodyWithDivider}>
                <div>
                    {styleDescription && (
                        <div className={style.effectStyleDescription} tabIndex={-1}>
                            <span className="wk-text-12">{styleDescription}</span>
                        </div>
                    )}

                    {effectWrappersValue.slice(0, limit).map((item, index) => {
                        const variable = variables?.find((v) => v.id === item.effect.colorVar?.value.alias)
                        return (
                            <NormalEffectItem
                                effectWrapper={item}
                                key={index}
                                isStyle={true}
                                colorSpace={colorSpace}
                                variable={variable}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function NormalEffectItem({
    effectWrapper,
    isStyle,
    colorSpace,
    variable,
}: {
    effectWrapper: DeepRequired<Wukong.DocumentProto.IEffectWrapper>
    isStyle: boolean
    colorSpace: ColorSpace
    variable?: Wukong.DocumentProto.ISingleVariable
}) {
    const rightPanelWidth = useRightPanelWidth()
    const isShadow = useCallback((type: EffectType) => {
        return type == EffectType.EFFECT_TYPE_DROP_SHADOW || type == EffectType.EFFECT_TYPE_INNER_SHADOW
    }, [])
    const effect = effectWrapper.effect
    const solidPaint: SolidPaint | null = effect.color
        ? {
              type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
              color: { r: effect.color.r, g: effect.color.g, b: effect.color.b },
              opacity: effect.color.a / 255,
              visible: true,
              blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
          }
        : null

    return (
        <div className={isStyle ? 'ml-1 mt-2' : 'mt-3'}>
            <div className={style.effectBaseItem}>
                {/* 使用样式的情况 不展示具体的icon */}
                {!isStyle && <span className={style.paintIconIndependent}>{<MonoIconPanelEffect16 />}</span>}
                <span className={style.weightMedium}>
                    <CopyableRow copyValue={effectTypeToShow[effect.type]}>{effectTypeToShow[effect.type]}</CopyableRow>
                </span>
            </div>
            <div className={classNames([style.effectShadowPairsContainer, 'mt-1', 'ml-1', isStyle ? '' : ' ml-8'])}>
                {/* 当{translation('BAfTrg')}为背景、图层模糊时 只展示 blur 值 */}
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>X</span>
                        <CopyableRow copyValue={`${effectWrapper.offsetX}`}>
                            <span className={style.shadowPairValue}>{effectWrapper.offsetX}</span>
                        </CopyableRow>
                    </div>
                )}
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>Y</span>
                        <CopyableRow copyValue={effectWrapper.offsetY}>
                            <span className={style.shadowPairValue}>{effectWrapper.offsetY}</span>
                        </CopyableRow>{' '}
                    </div>
                )}

                <div>
                    <span className={style.shadowPairName}>B</span>
                    <CopyableRow copyValue={effectWrapper.radius}>
                        <span className={style.shadowPairValue}>{effectWrapper.radius}</span>
                    </CopyableRow>{' '}
                </div>
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>S</span>
                        <CopyableRow copyValue={effectWrapper.spread}>
                            <span className={style.shadowPairValue}>{effectWrapper.spread}</span>
                        </CopyableRow>{' '}
                    </div>
                )}
            </div>
            {isShadow(effect.type) && solidPaint && (
                <div className={classNames([style.effectBaseItem, ' mt-1', isStyle ? 'ml-1' : ' ml-8'])}>
                    <SolidPaintRender
                        paint={solidPaint}
                        colorSpace={colorSpace}
                        variable={variable}
                        maxWidth={rightPanelWidth - 64}
                    />
                </div>
            )}
        </div>
    )
}
