export const svgArrowRight = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 3L11 8L6 13" stroke="#1B1F26" />
    </svg>
)

export const svgCheckboxMixed = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="4" y1="8" x2="12" y2="8" stroke="#1B1F26" strokeWidth="2" />
    </svg>
)

export const svgClose = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29287 8.00004L2.64642 12.6465L3.35353 13.3536L7.99998 8.70715L12.6464 13.3536L13.3535 12.6465L8.70708 8.00004L13.3535 3.35359L12.6464 2.64648L7.99998 7.29293L3.35353 2.64648L2.64642 3.35359L7.29287 8.00004Z"
            fill="#1B1F26"
        />
    </svg>
)

export const svgSlash = (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.615 2L5 13.96H5.936L10.538 2H9.615Z" fill="#1B1F26" />
    </svg>
)
