import { WKTextButton } from '../../../../../../../ui-lib/src'
import { translation } from './view-all.translation'

interface PropType {
    limit: number
    count: number
    onChange: (limit: number) => void
    className?: string
    dataTestId?: string
}

export function ViewAll(props: PropType) {
    return (
        <>
            {props.count > props.limit && (
                <WKTextButton
                    className={`${props.className} !mt-3`}
                    type={'primary'}
                    size={12}
                    onClick={() => props.onChange(props.count)}
                    data-testid={props.dataTestId}
                >
                    {translation('SeeAll', { count: props.count })}
                </WKTextButton>
            )}
        </>
    )
}
