import { DevModeSelectColorsPanelEnterHoverCommand, Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { useRightPanelWidth } from '../../../../../main/layout/layout-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useCommand } from '../../../../context/document-context'
import { useSelectionState } from '../../../../document/selection/use-selection-state'
import { useRenderColorSpace } from '../../../color-profile'
import { NormalPaintRender, StylePaintRender } from '../../../inspect/comp/paint-render-v2'
import { ViewAllV2 } from '../../../inspect/comp/view-all-v2'
import { ColorModePicker } from '../dev-mode-inspect-fill/color-mode-picker'
import style from '../inspect.module.less'
import { translation } from './dev-mode-select-colors.translation'

const ColorItemContainer: FC<PropsWithChildren & { usingInfos: Wukong.DocumentProto.IPaintStyleUsingInfo[] }> = (
    props
) => {
    const { usingInfos, children } = props
    const command = useCommand()

    const mouseEnter = () => {
        command.invokeBridge(
            CommitType.Noop,
            DevModeSelectColorsPanelEnterHoverCommand,
            Wukong.DocumentProto.ApiIds.create({
                ids: usingInfos.map((o) => o.nodeId!),
            })
        )
    }
    const mouseLeave = () => {
        command.invokeBridge(
            CommitType.Noop,
            DevModeSelectColorsPanelEnterHoverCommand,
            Wukong.DocumentProto.ApiIds.create({
                ids: [],
            })
        )
    }
    return (
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            {children}
        </div>
    )
}

const _DevModeSelectColors: FC = () => {
    const state = useViewState('selectionPaint')!
    const { changeSelectionInfo } = useSelectionState()
    const { paintInfos, styleInfos } = state
    const colorSpace = useRenderColorSpace()
    const [close, setClose] = useState(true)
    const rightPanelWidth = useRightPanelWidth()

    const _list = useMemo(() => {
        const newPaintVarInfos = paintInfos
            .filter((o) => o.variable)
            .map(
                (o) =>
                    ({
                        isStyle: false,
                        usingInfos: o.usingInfos,
                        paint: o.paint,
                        variable: o.variable,
                    } as const)
            )
        const newPaintInfos = paintInfos
            .filter((o) => !o.variable)
            .map(
                (o) =>
                    ({
                        isStyle: false,
                        usingInfos: o.usingInfos,
                        paint: o.paint,
                        variable: o.variable,
                    } as const)
            )
        const newStyleInfos = styleInfos.map(
            (o) =>
                ({
                    data: {
                        paints: o.style.paints,
                        styleDescription: o.style.description,
                        styleName: o.style.name,
                        variables: o.style.variables,
                    },
                    originStyleId: o.originStyleId,
                    usingInfos: o.usingInfos,
                    isStyle: true,
                } as const)
        )
        return [...newPaintVarInfos, ...newStyleInfos, ...newPaintInfos]
    }, [paintInfos, styleInfos])
    const list = _list.slice(0, close ? 5 : _list.length)
    const isSimplePaints = useMemo(() => {
        return paintInfos.every((o) => !o.variable) && styleInfos.every((o) => !o.style.variables.length)
    }, [paintInfos, styleInfos])

    useEffect(() => {
        // 选区改变重置展开状态
        return () => {
            setClose(true)
        }
    }, [changeSelectionInfo.sessionId])
    if (!list.length) {
        return <></>
    }
    return (
        <div className={style.panel} data-testid="dev-mode-select-colors">
            <div className={classNames(style.panelTitle, '!mb-2')}>
                <div className={style.title}>{translation('title')}</div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>
            <div className="px-4">
                <div className="flex flex-col" style={{ gap: isSimplePaints ? '8px' : '16px' }}>
                    {list.map((item) => {
                        if (item.isStyle) {
                            const { data, usingInfos, originStyleId } = item
                            return (
                                <ColorItemContainer usingInfos={usingInfos} key={originStyleId}>
                                    <StylePaintRender
                                        data={data}
                                        colorSpace={colorSpace}
                                        renderCount={data.paints.length}
                                        maxWidth={rightPanelWidth - 32}
                                    />
                                </ColorItemContainer>
                            )
                        } else {
                            const { paint, usingInfos, variable } = item
                            return (
                                <ColorItemContainer usingInfos={usingInfos} key={JSON.stringify(paint)}>
                                    <NormalPaintRender
                                        paint={paint}
                                        colorSpace={colorSpace}
                                        variable={variable}
                                        maxWidth={rightPanelWidth - 32}
                                    />
                                </ColorItemContainer>
                            )
                        }
                    })}
                </div>
                <ViewAllV2
                    limit={close ? 5 : _list.length}
                    count={_list.length}
                    onChange={() => setClose(false)}
                    dataTestId="style-color-view-all"
                />
            </div>
        </div>
    )
}

export const DevModeSelectColors = () => {
    const state = useViewState('selectionPaint')
    if (!state?.show || state.emptyDueToLimitExceeded) {
        return <></>
    }
    return <_DevModeSelectColors />
}
