import { computed } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { Ticket } from '../../document/synergy/synergy-ticket'
import { isFileCreated$ } from '../../main/create-file-manager'
import { LocalStorageKey } from '../../web-storage/local-storage/config'
import { enhancedLocalStorage } from '../../web-storage/local-storage/storage'
import { SessionStorageKey } from '../../web-storage/session-storage/config'
import { enhancedSessionStorage } from '../../web-storage/session-storage/storage'
import { canOpenDesktop$ } from './desktop-open'
import { editorLoaderPathname$ } from './editor-context'
import { ticket$ } from './ticket'

type DocAuthStatus =
    | {
          status: 'open-in-desktop'
          ticket: Ticket | undefined
      }
    | {
          status: 'auth-failed'
          ticket: undefined
      }
    | {
          status: 'auth-successed'
          ticket: Ticket
      }

// 获取文档的鉴权状态
// 结果可能是: 在桌面端打开/鉴权失败/鉴权成功
export const docAuthStatus$ = traceable(
    'hulh01@kanyun.com',
    computed<Promise<DocAuthStatus | undefined>>(
        async (get) => {
            const pathname = get(editorLoaderPathname$)

            if (!pathname) {
                return undefined
            }

            // 新建文档时，直接进入
            if (get(isFileCreated$)) {
                return {
                    status: 'auth-successed',
                    ticket: (await get(ticket$))!,
                }
            }

            const shouldOpenInDesktop =
                !window.localBridge &&
                enhancedLocalStorage.getSerializedItem(LocalStorageKey.AlwaysOpenInApp) &&
                enhancedSessionStorage.getItem(SessionStorageKey.FirstPath) === pathname

            const ticket = await get(ticket$)

            // 非新建文档时，先判断是否需要在桌面端打开
            if (shouldOpenInDesktop && (await get(canOpenDesktop$))) {
                return { status: 'open-in-desktop', ticket }
            }

            // 在 web 侧打开，此时需要判断是否有权限，不进行 web 侧的 lifecycle
            if (!ticket || ('needPassword' in ticket && ticket.needPassword)) {
                return { status: 'auth-failed' }
            }

            return { status: 'auth-successed', ticket }
        },
        { debugLabel: 'docAuthStatus$' }
    )
)
