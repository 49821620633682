import { translation } from './layout-grid-style-list-internal.translation'
import { useMemo, useRef } from 'react'
import { DragContextProvider } from '../drag/drag-context'
import { useStyleFolderCollapseModel } from '../hooks/use-doc-style-folder-collapse'
import { LocalStyleModel } from '../hooks/use-local-style-model-v2'
import { StyleTitle, StyleTitleProps } from '../style-title'
import { LayoutGridStyleListItem } from './layout-grid-style-list-item'

export interface LayoutGridStyleListInternalProps {
    model: LocalStyleModel
    hover: string | undefined
    setHover: (value: string) => void
    isReadonly?: boolean
    onChickAddIcon: StyleTitleProps['onChickAddIcon']
}

export const LayoutGridStyleListInternal = ({
    model,
    hover,
    setHover,
    isReadonly,
    onChickAddIcon,
}: LayoutGridStyleListInternalProps) => {
    const { modelState, selection } = model

    const containerRef = useRef<HTMLDivElement>(null)

    const layoutGridStyleItems = useMemo(
        () => modelState?.layoutGridStyleItems ?? [],
        [modelState?.layoutGridStyleItems]
    )
    const folderCollapseModel = useStyleFolderCollapseModel('layoutgrid', layoutGridStyleItems)
    const renderItemList = useMemo(() => {
        return layoutGridStyleItems.filter((item) => {
            return folderCollapseModel.isItemVisible(item)
        })
    }, [folderCollapseModel, layoutGridStyleItems])

    const list = renderItemList.map((item, index) => {
        return (
            <LayoutGridStyleListItem
                key={index}
                item={item}
                itemIndex={index}
                model={model}
                hover={hover}
                setHover={setHover}
                isReadonly={isReadonly}
                isFolderCollapse={item.isFolder && folderCollapseModel.isFolderCollapse(item.folderName)}
                toggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
            ></LayoutGridStyleListItem>
        )
    })

    if (!modelState) {
        return null
    }

    return (
        <>
            {modelState.layoutGridStyleItems.length > 0 ? (
                <StyleTitle
                    title={translation('GridStyles')}
                    tooltipTitle={translation('CreateNewGrid')}
                    onChickAddIcon={onChickAddIcon}
                    isReadonly={!!isReadonly}
                ></StyleTitle>
            ) : null}
            <div ref={containerRef} data-testid="grid-style-container">
                <DragContextProvider
                    containerRef={containerRef}
                    renderList={renderItemList}
                    selection={selection}
                    handleToggleFolderCollapse={folderCollapseModel.toggleFolderCollapse}
                >
                    {list}
                </DragContextProvider>
            </div>
        </>
    )
}
