import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { cloneDeep } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { MonoIconPanelEffect16 } from '../../../../../../ui-lib/src'
import { SolidPaint } from '../../../../document/node/node'
import { DeepRequired, useViewState } from '../../../../view-state-bridge'
import { toFixed } from '../../../utils/to-fixed'
import { useRenderColorSpace } from '../../color-profile'
import { ColorSpace } from '../../design/blend/color-picker/utils/color-translate'
import { EffectStyleIcon, getEffectStyleIcon } from '../../design/effects/effect-style/effect-style-icon'
import { ColorModePicker } from '../../dev-mode/inspect/dev-mode-inspect-fill/color-mode-picker'
import { CopyableRow } from '../comp/copyable-row'
import { SolidPaintRender } from '../comp/paint-render-v2'
import { ViewAllV2 } from '../comp/view-all-v2'
import { effectTypeToShow } from '../inspect-type'
import style from '../inspect.module.less'
import { translation } from './index.translation'
import EffectType = Wukong.DocumentProto.EffectType

export function InspectEffect() {
    const [limit, setLimit] = useState<number>(2)
    const limitChange = useCallback((_limit: number) => setLimit(_limit), [])
    const colorSpace = useRenderColorSpace()

    const effectViewState = useViewState('inspectEffectViewState')
    const effectsValue = useMemo(() => cloneDeep(effectViewState?.effects ?? []).reverse(), [effectViewState?.effects])
    const styleName = effectViewState?.styleName
    const variables = effectViewState?.variables ?? []

    if (!effectViewState?.display) {
        return null
    }

    return (
        <div className={style.panel} data-testid="design-mode-inspect-effect">
            <div className={style.panelTitle}>
                <div className={style.title} data-testid="inspect-effect">
                    {translation('Effect')}
                </div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>
            {!!styleName?.length && (
                <EffectStyle
                    styleName={styleName}
                    styleDescription={effectViewState?.styleDescription}
                    effectsValue={effectsValue}
                    limit={limit}
                    colorSpace={colorSpace}
                    variables={variables}
                />
            )}
            {!styleName?.length && (
                <EffectList effectsValue={effectsValue} limit={limit} colorSpace={colorSpace} variables={variables} />
            )}
            <ViewAllV2 className="px-4" limit={limit} count={effectsValue.length} onChange={limitChange} />
        </div>
    )
}

function EffectList({
    effectsValue,
    limit,
    colorSpace,
    variables,
}: {
    effectsValue: DeepRequired<Wukong.DocumentProto.IEffect>[]
    limit: number
    colorSpace: ColorSpace
    variables: Wukong.DocumentProto.ISingleVariable[]
}) {
    return (
        <div className={style.effectUnStyleBody} data-testid={'normal-effect-items'}>
            {effectsValue.slice(0, limit).map((item, index) => {
                const variable = variables.find((v) => v.id === item.colorVar?.value.alias)
                return (
                    <NormalEffectItem
                        effect={item}
                        key={index}
                        isStyle={false}
                        colorSpace={colorSpace}
                        variable={variable}
                    />
                )
            })}
        </div>
    )
}

function EffectStyle({
    styleName,
    styleDescription,
    effectsValue,
    limit,
    colorSpace,
    variables,
}: {
    styleName: string
    styleDescription?: string
    effectsValue: DeepRequired<Wukong.DocumentProto.IEffect>[]
    limit: number
    colorSpace: ColorSpace
    variables: Wukong.DocumentProto.ISingleVariable[]
}) {
    return (
        <div className="px-4 mt-2">
            <div className="flex items-center wk-font-medium">
                <EffectStyleIcon className="shrink-0" iconType={getEffectStyleIcon(effectsValue)} />
                <div className={style.effectStyleName}>
                    <CopyableRow copyValue={styleName} style={{ padding: '2px 4px' }}>
                        <span className="wk-text-12 wk-font-medium">{styleName}</span>
                    </CopyableRow>
                </div>
            </div>
            <div className={style.styleItemBodyWithDivider}>
                <div>
                    {styleDescription && (
                        <div className={style.effectStyleDescription} tabIndex={-1}>
                            <span className="wk-text-12">{styleDescription}</span>
                        </div>
                    )}

                    {effectsValue.slice(0, limit).map((item, index) => {
                        const variable = variables.find((v) => v.id === item.colorVar?.value.alias)
                        return (
                            <NormalEffectItem
                                effect={item}
                                key={index}
                                isStyle={true}
                                colorSpace={colorSpace}
                                variable={variable}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function NormalEffectItem({
    effect,
    isStyle,
    colorSpace,
    variable,
}: {
    effect: DeepRequired<Wukong.DocumentProto.IEffect>
    isStyle: boolean
    colorSpace: ColorSpace
    variable?: Wukong.DocumentProto.ISingleVariable
}) {
    const isShadow = useCallback((type: EffectType) => {
        return type == EffectType.EFFECT_TYPE_DROP_SHADOW || type == EffectType.EFFECT_TYPE_INNER_SHADOW
    }, [])
    const solidPaint: SolidPaint | null = effect.color
        ? {
              type: Wukong.DocumentProto.PaintType.PAINT_TYPE_SOLID_PAINT,
              color: { r: effect.color.r, g: effect.color.g, b: effect.color.b },
              opacity: effect.color.a / 255,
              visible: true,
              blendMode: Wukong.DocumentProto.BlendMode.BLEND_MODE_NORMAL,
          }
        : null

    return (
        <div className={isStyle ? 'ml-1 mt-2' : 'mt-3'}>
            <div className={style.effectBaseItem}>
                {/* 使用样式的情况 不展示具体的icon */}
                {!isStyle && <span className={style.paintIconIndependent}>{<MonoIconPanelEffect16 />}</span>}
                <span className={style.weightMedium}>
                    <CopyableRow copyValue={effectTypeToShow[effect.type]}>{effectTypeToShow[effect.type]}</CopyableRow>
                </span>
            </div>
            <div className={classNames([style.effectShadowPairsContainer, 'mt-1', 'ml-1', isStyle ? '' : ' ml-8'])}>
                {/* 当{translation('BAfTrg')}为背景、图层模糊时 只展示 blur 值 */}
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>X</span>
                        <CopyableRow copyValue={`${toFixed(effect.offset.x, 2)}`}>
                            <span className={style.shadowPairValue}>{toFixed(effect.offset.x, 2)}</span>
                        </CopyableRow>
                    </div>
                )}
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>Y</span>
                        <CopyableRow copyValue={`${toFixed(effect.offset.y, 2)}`}>
                            <span className={style.shadowPairValue}>{toFixed(effect.offset.y, 2)}</span>
                        </CopyableRow>{' '}
                    </div>
                )}

                <div>
                    <span className={style.shadowPairName}>B</span>
                    <CopyableRow copyValue={`${toFixed(effect.radius, 2)}`}>
                        <span className={style.shadowPairValue}>{toFixed(effect.radius, 2)}</span>
                    </CopyableRow>{' '}
                </div>
                {isShadow(effect.type) && (
                    <div>
                        <span className={style.shadowPairName}>S</span>
                        <CopyableRow copyValue={`${toFixed(effect.spread, 2)}`}>
                            <span className={style.shadowPairValue}>{toFixed(effect.spread, 2)}</span>
                        </CopyableRow>{' '}
                    </div>
                )}
            </div>
            {isShadow(effect.type) && solidPaint && (
                <div className={classNames([style.effectBaseItem, 'mt-1', isStyle ? 'ml-1' : ' ml-8'])}>
                    <SolidPaintRender paint={solidPaint} colorSpace={colorSpace} variable={variable} maxWidth={184} />
                </div>
            )}
        </div>
    )
}
