import { Position } from '../../../../../../ui-lib/src'
import { useEditingTextStyle } from '../styles/hooks/use-editing-style'
import { TextStyleEditor } from '../styles/style-panel/text-style-editor/text-style-editor'

export interface StyleViewerProps {
    open?: boolean
    position?: Position
    onClose?: () => void
}

export const TextStyleViewer = (props: StyleViewerProps) => {
    const { open, position, onClose } = props
    const style = useEditingTextStyle()?.textStyleNode
    if (!style) {
        return null
    }

    return (
        <TextStyleEditor
            visible={open}
            position={position}
            isRemoteStyle={true}
            onCancel={onClose}
            styleId={style.id}
            hideCommonFooter
        />
    )
}
