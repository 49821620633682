import { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { WKDialog, WKDialogProps } from '.'

export type ResolveFn<K> = (value: K) => void
export type AsyncModalParams<T = undefined, S = undefined> = Omit<
    WKDialogProps,
    'onOk' | 'onCancel' | 'onCloseIconCancel' | 'visible' | 'children'
> & {
    onOk?: (resolve: ResolveFn<T>, destroy: () => void) => void
    onCancel?: (
        resolve: ResolveFn<T>,
        destroy: () => void,
        update: (p: Partial<AsyncModalParams<T, S>>) => void
    ) => void
    onCloseIconCancel?: (resolve: ResolveFn<T>, destroy: () => void) => void
    childrenFn: (
        resolve: ResolveFn<T>,
        update: (p: Partial<AsyncModalParams<T, S>>) => void,
        destroy: () => void,
        state?: S
    ) => ReactNode
    state?: S
    /**
     * 自动关闭弹窗
     */
    disableAutoDestroy?: boolean
}
export const asyncModal = <T, S = undefined>(params: AsyncModalParams<T, S>) => {
    const { childrenFn, onOk, onCancel, onCloseIconCancel, disableAutoDestroy, ...rest } = params
    return new Promise<T>((resolve) => {
        const div = document.createElement('div')
        document.body.append(div)
        const root = createRoot(div)

        const update = (p: Partial<AsyncModalParams<T, S>>) => {
            const state = p.state
            root.render(
                <WKDialog
                    {...rest}
                    {...p}
                    onOk={_onOk}
                    onCloseIconCancel={_onCloseIconCancel}
                    onCancel={_onCancel}
                    visible
                >
                    {childrenFn(childrenResolve, update, destroy, state)}
                </WKDialog>
            )
        }

        const destroy = () => {
            root.unmount()
            div.remove()
        }
        const _onOk = () => {
            if (!disableAutoDestroy) {
                destroy()
            }
            onOk?.(resolve, destroy)
        }
        const _onCancel = () => {
            if (!disableAutoDestroy) {
                destroy()
            }
            onCancel?.(resolve, destroy, update)
        }
        const _onCloseIconCancel = onCloseIconCancel
            ? () => {
                  if (!disableAutoDestroy) {
                      destroy()
                  }
                  onCloseIconCancel?.(resolve, destroy)
              }
            : onCloseIconCancel
        const childrenResolve = (param: T) => {
            resolve(param)
            if (!disableAutoDestroy) {
                destroy()
            }
        }

        root.render(
            <WKDialog onOk={_onOk} onCancel={_onCancel} onCloseIconCancel={_onCloseIconCancel} {...rest} visible>
                {childrenFn(childrenResolve, update, destroy, params.state)}
            </WKDialog>
        )
    })
}
