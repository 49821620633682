import { Wukong } from '@wukong/bridge-proto'
import { toFixed } from '../../../../../utils/to-fixed'
import { CubicBezierNumber } from '../action-animation-preview'

export const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max)
}

function clampSpringHandleOffset(offset: { x: number; y: number }) {
    return {
        x: clamp(offset.x, 0, 1),
        y: clamp(offset.y, 0, 2),
    }
}

export class Transformation {
    constructor(public scaleFactor = 1, public translation = 0) {
        this.scaleFactor = scaleFactor
        this.translation = translation
    }

    translate(offset: number) {
        this.translation += offset
        return this
    }

    scale(factor: number) {
        this.scaleFactor *= factor
        this.translation *= factor
        return this
    }

    transformValue(value: number) {
        return value * this.scaleFactor + this.translation
    }

    transformDirection(value: number) {
        return value * this.scaleFactor
    }

    static fromRangeMapping(inputStart: number, inputEnd: number, outputStart: number, outputEnd: number) {
        const scaleFactor = (outputEnd - outputStart) / (inputEnd - inputStart)
        return new Transformation().translate(-inputStart).scale(scaleFactor).translate(outputStart)
    }

    static fromConstant(value: number) {
        return new Transformation(0, value)
    }

    static invert(transformation: Transformation) {
        const invScaleFactor = 1 / transformation.scaleFactor
        const invTranslation = -transformation.translation / transformation.scaleFactor
        return new Transformation(invScaleFactor, invTranslation)
    }
}

/**
 * @see https://github.com/WebKit/webkit/blob/main/Source/WebCore/platform/graphics/SpringSolver.h
 */
export class SpringSolver {
    private w0: number
    private zeta: number
    private wd: number
    private A: number
    private B: number

    constructor(mass: number, stiffness: number, damping: number, initialVelocity: number) {
        this.w0 = Math.sqrt(stiffness / mass)
        this.zeta = damping / (2 * Math.sqrt(stiffness * mass))

        if (this.zeta < 1) {
            this.wd = this.w0 * Math.sqrt(1 - this.zeta * this.zeta)
            this.A = 1
            this.B = (this.zeta * this.w0 - initialVelocity) / this.wd
        } else {
            this.wd = 0
            this.A = 1
            this.B = -initialVelocity + this.w0
        }
    }

    solve(t: number): number {
        let value: number

        if (this.zeta < 1) {
            value =
                Math.exp(-t * this.zeta * this.w0) * (this.A * Math.cos(this.wd * t) + this.B * Math.sin(this.wd * t))
        } else {
            value = (this.A + this.B * t) * Math.exp(-t * this.w0)
        }

        // Map range from [1..0] to [0..1]
        return 1 - value
    }
}

export const getSpringDurationHandleOffset = (func: CubicBezierNumber): number => {
    const [mass, stiffness, damping, _] = func
    const frequency = Math.sqrt(stiffness / mass)
    const zeta = damping / (2 * Math.sqrt(stiffness * mass))

    const E = 6
    return E / (frequency * zeta)
}

export const getSpringHandleOffset = (func: CubicBezierNumber): { x: number; y: number } => {
    const [mass, stiffness, damping, _] = func
    const frequency = Math.sqrt(stiffness / mass)
    const zeta = damping / (2 * Math.sqrt(stiffness * mass))

    return clampSpringHandleOffset({
        x: Math.max(Math.PI / frequency, 0),
        y: 2 - 2 * Math.pow(clamp(zeta, 0, 1), 0.87),
    })
}

const MAX_FREQUENCY = 1e3
const MIN_FREQUENCY = 0.1
const MAX_ZETA = 1
const MIN_ZETA = 1e-4
const MIN_MASS = 0.01
const MAX_MASS = 100
const O = Math.PI / MAX_FREQUENCY

function getSpringFuncByFrequencyAndZeta(
    { frequency, zeta }: { frequency: number; zeta: number },
    originFunc: CubicBezierNumber
): CubicBezierNumber {
    const mass = originFunc[0] > 0 ? clamp(originFunc[0], MIN_MASS, MAX_MASS) : 1
    const stiffness = frequency * frequency * mass
    const damping = 2 * zeta * Math.sqrt(stiffness * mass)
    return [mass, toFixed(stiffness, 2), toFixed(damping), originFunc[3]]
}

export function getSpringFuncByHandleOffset(offset: { x: number; y: number }, originFunc: CubicBezierNumber) {
    const clampOffset = clampSpringHandleOffset(offset)
    return getSpringFuncByFrequencyAndZeta(
        {
            frequency: clamp(Math.PI / Math.max(O, clampOffset.x), MIN_FREQUENCY, MAX_FREQUENCY),
            zeta: clamp(Math.pow((2 - clampOffset.y) / 2, 1 / 0.87), MIN_ZETA, MAX_ZETA),
        },
        originFunc
    )
}

export interface ITransform {
    transformValue: (value: number) => number
}
export class TransformCompose implements ITransform {
    constructor(private a: ITransform, private b: ITransform) {}

    transformValue(value: number): number {
        return this.b.transformValue(this.a.transformValue(value))
    }
}

export function slowFunction(x: number): number {
    return x >= 0 ? x : 1 / (1 - x) - 1
}

export function slowFunctionInverse(x: number): number {
    return x >= 0 ? x : 1 - 1 / (x + 1)
}
export class TransformationLimiter implements ITransform {
    private slowFunction: (x: number) => number
    private slowFunctionInverse: (x: number) => number
    private mapToSlowSpace: Transformation
    private mapToSlowSpaceInverse: Transformation
    private initialValueInSlowSpace: number

    constructor(props: {
        hardLimit: number
        threshold: number
        initialValue: number
        slowFunction: (x: number) => number
        slowFunctionInverse: (x: number) => number
    }) {
        this.slowFunction = props.slowFunction
        this.slowFunctionInverse = props.slowFunctionInverse

        this.mapToSlowSpace = Transformation.fromRangeMapping(props.threshold, props.hardLimit, 0, -1)
        this.mapToSlowSpaceInverse = Transformation.invert(this.mapToSlowSpace)

        this.initialValueInSlowSpace = this.mapToSlowSpace.transformValue(props.initialValue)
    }

    transformValue(value: number): number {
        const transformedValue = this.mapToSlowSpace.transformValue(value)

        if (transformedValue > this.initialValueInSlowSpace) {
            this.initialValueInSlowSpace = transformedValue
        }

        const offset = this.slowFunctionInverse(this.initialValueInSlowSpace) - this.initialValueInSlowSpace

        return this.mapToSlowSpaceInverse.transformValue(this.slowFunction(transformedValue + offset))
    }
}

export function getSpringStableDurationByFunc(func: CubicBezierNumber): number {
    const [mass, stiffness, damping, initialVelocity] = func
    const frequency = Math.sqrt(stiffness / mass)
    const zeta = Math.min(0.9999, damping / (2 * Math.sqrt(stiffness * mass)))
    const dampedFrequency = frequency * Math.sqrt(1 - zeta * zeta)
    return (
        (-Math.log(0.001) + Math.log(1 + (zeta * frequency + Math.abs(initialVelocity)) / dampedFrequency)) /
        (zeta * frequency)
    )
}

export function getSpringFunctionByDurationOffset(func: CubicBezierNumber, duration: number): CubicBezierNumber {
    const [mass, stiffness, damping, _] = func
    const zeta = damping / (2 * Math.sqrt(stiffness * mass))
    const frequency = 6 / zeta / duration

    return getSpringFuncByFrequencyAndZeta({ frequency, zeta }, func)
}

export function isSpringEasingType(easingType: Wukong.DocumentProto.EasingType) {
    return [
        Wukong.DocumentProto.EasingType.EASING_TYPE_GENTLE_SPRING,
        Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_ONE,
        Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_TWO,
        Wukong.DocumentProto.EasingType.EASING_TYPE_SPRING_PRESET_THREE,
        Wukong.DocumentProto.EasingType.EASING_TYPE_CUSTOM_SPRING,
    ].includes(easingType)
}
