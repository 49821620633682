import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    MainComponentNot: '组件母版未找到',
    ReturnToInstance: '返回实例',
} as const

export const enTranslation = {
    MainComponentNot: 'Main component not found',
    ReturnToInstance: 'Return to instance',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
