import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    '400_80001': '当前申请不存在',
    '400_80004': '当前申请已被处理',
    '403': '你没有权限处理该席位申请',
    '404_95000': '工作区不存在，请修改工作区。',
} as const

export const enTranslation = {
    '400_80001': 'This request does not exist.',
    '400_80004': 'This request has already been handled.',
    '403': 'You do not have permission to  approve this request.',
    '404_95000': 'The workspace does not exist. Change the workspace.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
