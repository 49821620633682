/* eslint-disable no-restricted-imports */
import { type UserVOV2 } from '../kernel/interface/account'
import { SandboxEvent, SandboxWindow } from './types'
import { injectConsole, isSandboxPath, SANDBOX_PATH } from './utils'

declare global {
    interface Window {
        __shareWithSandboxInfo: {
            userInfo?: UserVOV2
        }
    }
}

export type MotiffSandbox = Awaited<ReturnType<typeof createSandBoxInstance>>

const SHARED_SANDBOX_ID = 'shared-sandbox'
const DEFAULT_SANDBOX_CONFIG = {
    // 当不指定 id 时，使用全局单例，注意：在大多数情况下，都不需要指定 id，除非你明确知晓需要创建一个单独的沙盒
    id: SHARED_SANDBOX_ID,

    width: 200,
    height: 200,

    visible: false, // 如果是为了调试，可以尝试开启此选项
}

export type MotiffSandboxConfig = typeof DEFAULT_SANDBOX_CONFIG

export const SandBoxManager = {
    instances: new Map<string, MotiffSandbox>(),
    timerIds: new Map<string, ReturnType<typeof setTimeout>>(),
    pendingWorks: new Map<string, Array<(instance: MotiffSandbox) => void>>(),

    MAX_COUNT: 5, // 为了防止意外内存泄露，限制沙盒的总数量
    DESTROY_TIME_OUT_MS: 60 * 1000, // 沙盒销毁延迟

    /**
     * 提前初始化共享 sandbox
     * */
    initSharedSandBox() {
        createSandbox()
    },

    reset() {
        SandBoxManager.instances.forEach((instance) => instance.destroy())
        SandBoxManager.timerIds.forEach((id) => clearTimeout(id))
        SandBoxManager.pendingWorks.clear()
    },

    get sharedInstance() {
        return createSandbox()
    },

    /**
     * 删除沙盒，有两种情况：
     * 1. 对于共享的全局沙盒，并不会真的删除沙盒，而是触发一次 reload
     * 2. 对于用户指定 id 创建的沙盒，并不会立即删除沙盒，而是延迟处理，防止由于误操作而频繁删除和创建沙盒
     *
     * @param id - 沙盒 id
     */
    destroy(id: string = SHARED_SANDBOX_ID) {
        if (this.timerIds.has(id)) return

        // 延迟调用沙盒销毁函数
        this.timerIds.set(
            id,
            setTimeout(() => this.instantDestory(id), this.DESTROY_TIME_OUT_MS)
        )
    },

    /**
     * 立即删除沙盒，大多数情况下都不会使用此函数，请优先使用 destroy
     * @param id - 沙盒 id
     */
    instantDestory(id: string = SHARED_SANDBOX_ID) {
        const sandbox = this.instances.get(id)
        const isSharedInstance = id === SHARED_SANDBOX_ID

        if (isSharedInstance) {
            sandbox?.iframe.contentWindow?.location.reload()
        } else if (sandbox) {
            sandbox.motiff = undefined as any
            sandbox.iframe.parentNode?.removeChild(sandbox.iframe)
            sandbox.iframe = undefined as any
        }

        if (!isSharedInstance) {
            this.instances.delete(id)
        }
        this.timerIds.delete(id)
    },

    /**
     * 更新沙盒实例状态
     * @param instance
     */
    upsert(instance: MotiffSandbox) {
        clearTimeout(this.timerIds.get(instance.id))
        this.instances.set(instance.id, instance)
    },

    getInstance(id: string) {
        return this.instances.get(id)
    },

    resolvePendingWorks(instance: MotiffSandbox) {
        SandBoxManager.pendingWorks.get(instance.id)?.forEach((func) => func(instance))
    },
}

/**
 * 启用一个沙盒实例，此函数需要在主环境中调用
 */
export function createSandbox(options: Partial<MotiffSandboxConfig> = {}) {
    if (isSandboxPath()) {
        throw Error('不允许嵌套创建沙盒')
    }

    const config = { ...DEFAULT_SANDBOX_CONFIG, ...options }

    return new Promise<MotiffSandbox>((resolve) => {
        const instance = SandBoxManager.instances.get(config.id)

        // 如果实例已经被创建过，则直接返回
        if (instance) return resolve(instance)

        if (SandBoxManager.pendingWorks.has(config.id)) {
            // 如果实例正在创建中，则加入回调队列，等待实例创建完成后，再触发回调
            SandBoxManager.pendingWorks.get(config.id)!.push((_instance) => {
                resolve(_instance)
                SandBoxManager.upsert(_instance)
            })
        } else {
            // 如果实例尚未创建，则直接开始创建，并且清空回调队列
            SandBoxManager.pendingWorks.set(config.id, [])
            createSandBoxInstance(config).then((_instance) => {
                resolve(_instance)
                SandBoxManager.upsert(_instance)

                SandBoxManager.pendingWorks.get(config.id)?.forEach((func) => func(_instance))
                SandBoxManager.pendingWorks.delete(config.id)
            })
        }
    })
}

function createSandBoxInstance(config: MotiffSandboxConfig) {
    const iframe = document.createElement('iframe')
    if (!iframe) {
        throw Error('创建沙盒 iframe 失败')
    }

    iframe.src = SANDBOX_PATH
    iframe.width = config.width + 'px'
    iframe.height = config.height + 'px'
    iframe.style.position = 'absolute'
    iframe.style.top = '0px'
    iframe.style.zIndex = '99999'
    iframe.style.border = '0'

    const show = () => {
        iframe.style.pointerEvents = 'all'
        iframe.style.opacity = '1'
    }
    const hide = () => {
        iframe.style.pointerEvents = 'none'
        iframe.style.opacity = '0'
    }

    if (config.visible) {
        show()
    } else {
        hide()
    }

    document.body.appendChild(iframe)
    const sandboxWindow = iframe.contentWindow as SandboxWindow
    injectConsole((sandboxWindow as any).console, '[sandbox]')

    function collectSandbox() {
        return {
            id: config.id,
            iframe,

            ...sandboxWindow.sandbox,
            motiff: sandboxWindow.motiff,

            resize(w: number, h: number) {
                iframe.width = w + 'px'
                iframe.height = h + 'px'
            },

            destroy() {
                SandBoxManager.destroy(config.id)
            },

            get visible() {
                return Number(getComputedStyle(iframe).opacity) === 1
            },

            set visible(value: boolean) {
                value ? show() : hide()
            },
        }
    }

    return new Promise<ReturnType<typeof collectSandbox>>((resolve) => {
        function onSandboxReady() {
            sandboxWindow.removeEventListener(SandboxEvent.Ready, onSandboxReady)
            const instance = collectSandbox()
            resolve(instance)
        }

        sandboxWindow.addEventListener(SandboxEvent.Ready, onSandboxReady)
    })
}
