/* eslint-disable no-restricted-imports */
import { ResolveSeatApplication } from '../../../kernel/request/seat'
import { ResolveJoinTeam } from '../../../kernel/request/user-roles'
import {
    DeleteMessageReadAll,
    DeleteMessageReadSome,
    GetMessageBeforeId,
    GetMessageBeforeNow,
    GetMessageGetLast,
    PostMessageViewMark,
} from '../../../kernel/request/wukong-message-center'

export const fetchGetMessageBeforeNow = (...args: ConstructorParameters<typeof GetMessageBeforeNow>) => {
    return new GetMessageBeforeNow(...args).start()
}

export const fetchGetMessageGetLast = (...args: ConstructorParameters<typeof GetMessageGetLast>) => {
    return new GetMessageGetLast(...args).start()
}

export const fetchGetMessageBeforeId = (...args: ConstructorParameters<typeof GetMessageBeforeId>) => {
    return new GetMessageBeforeId(...args).start()
}

export const fetchPostMessageViewMark = (...args: ConstructorParameters<typeof PostMessageViewMark>) => {
    return new PostMessageViewMark(...args).start()
}

export const fetchDeleteMessageReadAll = (...args: ConstructorParameters<typeof DeleteMessageReadAll>) => {
    return new DeleteMessageReadAll(...args).start()
}

export const fetchDeleteMessageReadSome = (...args: ConstructorParameters<typeof DeleteMessageReadSome>) => {
    return new DeleteMessageReadSome(...args).start()
}

export const fetchResolveJoinTeam = (...args: ConstructorParameters<typeof ResolveJoinTeam>) => {
    return new ResolveJoinTeam(...args).start()
}

export const fetchResolveSeatApplication = (...args: ConstructorParameters<typeof ResolveSeatApplication>) => {
    return new ResolveSeatApplication(...args).start()
}
