import { HighlightNodesByIdsWasmCall, Wukong } from '@wukong/bridge-proto'
import { CommitType } from '../../../../../../../document/command/commit-type'
import { useCommand } from '../../../../../../context/document-context'
import { SinglePublicPropInstanceChain } from '../../component-prop-defs-item/components/public-prop'
import styles from './index.module.less'
import { useState } from 'react'

export function CreatePublicPropPopupContent(props: {
    publicPropChains: Wukong.DocumentProto.IPublicPropInstanceChain[]
}) {
    const command = useCommand()
    const { publicPropChains } = props
    const [hoverIndex, setHoverIndex] = useState<number>(-1)

    const onInstanceHovered = (nodeIds: string[], index: number) => {
        setHoverIndex(index)
        command.invokeBridge(CommitType.Noop, HighlightNodesByIdsWasmCall, {
            nodeIds: nodeIds,
        })
    }

    return (
        <div
            className={styles.publicPropBody}
            onMouseLeave={() => {
                onInstanceHovered([], -1)
            }}
        >
            {publicPropChains
                .filter((chain) => chain.instances.length != 0)
                .map((chain, index) => (
                    <SinglePublicPropInstanceChain
                        key={index}
                        index={index}
                        chain={chain}
                        onInstanceHovered={onInstanceHovered}
                        hovered={index === hoverIndex}
                    />
                ))}
        </div>
    )
}
