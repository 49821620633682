import {
    FeatureSwitchConfig,
    SwitchEnvironmentScopeType,
    SwitchStrategyType,
    UserGroupType,
} from '../feature-switch-config'
const Config: FeatureSwitchConfig = {
    name: 'ai-align',
    owner: '',
    description: '',
    strategies: [
        {
            env: SwitchEnvironmentScopeType.TESTING,
            config: { type: SwitchStrategyType.ALL_USER },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: { type: SwitchStrategyType.SPECIFIC_USER_LIST, group: UserGroupType.PM },
        },
        {
            env: SwitchEnvironmentScopeType.PRODUCTION,
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: [
                    '124f62c04631f675e093dd633a32e3df36781b93',
                    '15d648ab2d0a3c81da4bd2f00c4341bad0e9d06c',
                    '578a3747618eb16654d52b14dfe2b0b61267c446',
                    'd9ea8e20290f4d276893decb64f8ab767a1554a5',
                    '7248e3813a25f9687a36eab25554d963218c4497',
                ],
            },
        },
    ],
    alwaysDisableInTests: false,
}
export default Config
