import { Wukong } from '@wukong/bridge-proto'
import classNames from 'classnames'
import { useMemo } from 'react'
import { getIconStyle } from '../render-item-icon'
import styles from './index.module.less'
import VLayerPanelVisibleStatus = Wukong.DocumentProto.VLayerPanelVisibleStatus

const IconThumbnail = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 4C2.5 3.17157 3.17157 2.5 4 2.5H12C12.8284 2.5 13.5 3.17157 13.5 4V12C13.5 12.8284 12.8284 13.5 12 13.5H4C3.17157 13.5 2.5 12.8284 2.5 12V4ZM4 3.5C3.72386 3.5 3.5 3.72386 3.5 4V12C3.5 12.2761 3.72386 12.5 4 12.5H12C12.2761 12.5 12.5 12.2761 12.5 12V4C12.5 3.72386 12.2761 3.5 12 3.5H4Z"
        />
        <rect x="3" y="9" width="10" height="1" />
    </svg>
)

export const LayerPanelThumbnailIcon = ({
    isThumbnail,
    isSelected,
    isFontBold,
    isPurple,
    visibleStatus,
}: {
    isThumbnail?: boolean
    isSelected: boolean
    isPurple: boolean
    isFontBold: boolean
    visibleStatus: VLayerPanelVisibleStatus
}) => {
    const iconStyle = useMemo(
        () =>
            getIconStyle({
                isSelected,
                isPurple,
                isFontBold,
                visibleStatus,
            }),
        [isSelected, isPurple, isFontBold, visibleStatus]
    )
    if (isThumbnail) {
        return (
            <div className={classNames(styles[iconStyle], styles.thumbnailIcon, 'flex items-center pl-2')}>
                <IconThumbnail />
            </div>
        )
    }
    return <></>
}
