import { useCallback, useState } from 'react'
import { SingleLevelRef } from '../picks/single-level/single-level'
import { isPickComponent } from '../picks/type'
import { TriggerNormalRef } from '../triggers/trigger-normal'
import { Rect } from '../type'

export interface UseCommentSelectProps {
    triggerRect?: Rect | (() => Rect)
    triggerRef: React.RefObject<TriggerNormalRef>
    pickRef: React.RefObject<SingleLevelRef>
    onOpen?: () => void
    onClose?: () => void
}
export function useCommentSelect(props: UseCommentSelectProps) {
    const { triggerRect, triggerRef, pickRef, onOpen, onClose } = props
    const [isOpenPick, setIsOpenPick] = useState<boolean>(false)

    const onSelectOpen = useCallback(() => {
        setIsOpenPick(true)
        onOpen?.()
    }, [onOpen])

    const onSelectClose = useCallback(() => {
        setIsOpenPick(false)
        onClose?.()
    }, [onClose])

    const openAction = useCallback(() => {
        pickRef.current?.open()
    }, [pickRef])

    const closeAction = useCallback(() => {
        pickRef.current?.close()
    }, [pickRef])

    const closeByESC = useCallback(() => {
        triggerRef.current?.focus()
    }, [triggerRef])

    const _triggerRect = useCallback(() => {
        if (triggerRect) {
            return typeof triggerRect === 'function' ? triggerRect() : triggerRect
        }
        return triggerRef.current?.triggerRect?.()!
    }, [triggerRect, triggerRef])

    const getPortalElement = useCallback(() => {
        const triggerContainer = triggerRef.current?.getContainer()
        let element = triggerContainer?.parentElement
        while (element) {
            if (isPickComponent(element.dataset.componentName)) {
                return triggerContainer
            }
            element = element.parentElement
        }
    }, [triggerRef])

    return {
        isOpenPick,
        onSelectOpen,
        onSelectClose,
        openAction,
        closeAction,
        closeByESC,
        triggerRect: _triggerRect,
        getPortalElement,
    }
}
