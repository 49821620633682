import { domLocation } from '../../../../util/src/dom-env'
import { generateRouterPath } from '../../../../util/src/route'
import { RouteToken } from '../../../../util/src/routes'
import { openIndependentWindow } from '../../kernel/util/open-window'
import { CreatePlaygroundRequest, PlaygroundType } from '../ai-service/requests'

const PlaygroundRegex = /^https:\/\/motiff-create-playground\/([^/]+)$/

let pendingCreatePlayground = false // 是否正在等待创建文件，期间不会重复请求

/**
 * 是否是 playground 链接，是则打开并返回 true，否则返回 false
 * @param url
 * @returns
 */
export function tryOpenPlaygroundLink(url: string, orgId: string): boolean {
    const playgroundMatch = url.match(PlaygroundRegex)
    if (playgroundMatch?.[1]) {
        requestCreatingFileThenOpen(orgId, playgroundMatch[1] as any)
        return true
    }
    return false
}

async function requestCreatingFileThenOpen(orgId: string, playgroundType: PlaygroundType) {
    if (!Object.values(PlaygroundType).includes(playgroundType)) {
        console.warn('unknown playground type', playgroundType)
        return
    }
    if (pendingCreatePlayground) {
        return
    }
    pendingCreatePlayground = true
    try {
        const newDoc = await new CreatePlaygroundRequest(orgId, playgroundType).start()
        openIndependentWindow(`${domLocation().origin}/${generateRouterPath(RouteToken.File)}/${newDoc.id}`)
    } catch (_) {
    } finally {
        pendingCreatePlayground = false
    }
}
