/* eslint-disable no-restricted-imports */
import classnames from 'classnames'
import React, { HTMLAttributes, isValidElement } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { safeCall } from '../../utils/safe-call'
import classes from './tooltip.module.less'
import { Placement, Point, Rect, Size } from './type'
import { createArrowStyle, getFrontEndOfLineInLimited, isPointInRect } from './utils'

type PlacementFn = () => {
    res: {
        value: number
        placement: Placement
    }
    success: boolean
}
interface TooltipExtendProps {
    triggerRect?: Rect | (() => Rect)
    worldRect?: Rect | (() => Rect)
    overlay?: React.ReactNode | (() => React.ReactNode)
    // 拖拽组件一般需要设置这个以避免在拖拽中一直出现tooltip
    canMouseDownClose?: boolean
    // 一些组件不接收tooltip需要的事件或者接受但处理的方式和tooltip期待的不一样,可通过这个属性快速添加个容器解决这个问题,如果样式不符合建议直接修改组件...
    addContainer?: Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'style' | 'onMouseEnter' | 'onMouseLeave'>
    // 设置这个值后tooltip失活即不会通过事件打开tooltip
    inactivation?: boolean
    // children 的 ref 的可能并不是HTMLElement,而是一些自定义函数调用，因此需要通过这个值定义获取到对应的dom。不支持多层级 key.key.key
    triggerRefKey?: string
}
export interface TooltipProps extends TooltipExtendProps {
    title?: string
    shortcut?: string
    placement?: Placement
    autoAdjustOverflow?: boolean
    children?: React.ReactNode // ReactFragment 无法添加事件监听所以传入<></>的children不能显示tooltip
    dataTestIds?: {
        tooltip?: string
        arrow?: string
    }
    firstDelay?: 100 // 传入 100 表明立即出 toast
    ref?: React.Ref<any>
    toolTipClassName?: string
    maxWidth?: number
}

interface TooltipState {
    visible: boolean
}

interface TooltipEventDetail {
    openTooltipId: string | undefined
}

function findDOMNode<T = Element | Text>(node: React.ReactInstance | HTMLElement): T {
    if (node instanceof HTMLElement) {
        return node as unknown as T
    }
    // eslint-disable-next-line
    return ReactDOM.findDOMNode(node) as unknown as T
}

function generateTooltip() {
    const tipContainerId = 'tip-container-id-' + Date.now()
    const getTipContainer = () => {
        let tipContainer = document.getElementById(tipContainerId)
        if (!tipContainer) {
            const div = document.createElement('div')
            document.body.appendChild(div)
            div.id = tipContainerId
            tipContainer = div
            window.addEventListener('scroll', () => requestCloseTooltip(true), true)
            window.addEventListener('keydown', () => requestCloseTooltip(true), true)
        }
        return tipContainer
    }

    const tooltipEventListener: Set<(_e: TooltipEventDetail) => void> = new Set()
    const addTooltipEventListener = (callback: (e: TooltipEventDetail) => void) => {
        tooltipEventListener.add(callback)
    }
    const removeTooltipEventListener = (callback: (e: TooltipEventDetail) => void) => {
        tooltipEventListener.delete(callback)
    }
    let lastTooltipEventDetail: TooltipEventDetail = { openTooltipId: undefined }
    const dispatchTooltipEvent = (detail: TooltipEventDetail) => {
        lastTooltipEventDetail = detail
        for (const listener of tooltipEventListener) {
            safeCall(listener, detail)
        }
    }

    const setTimeoutTaskTimer: Set<NodeJS.Timeout> = new Set()
    const recordSetTimeoutTimer = (timer: NodeJS.Timeout) => {
        setTimeoutTaskTimer.add(timer)
    }
    const clearSetTimeoutTimer = () => {
        for (const timer of setTimeoutTaskTimer) {
            clearTimeout(timer)
        }
        setTimeoutTaskTimer.clear()
    }

    const requestOpenTooltip = (tooltipId: string, delay = 1000) => {
        clearSetTimeoutTimer()
        const detail = { openTooltipId: tooltipId }
        if (lastTooltipEventDetail.openTooltipId !== undefined) {
            dispatchTooltipEvent(detail)
        } else {
            recordSetTimeoutTimer(setTimeout(dispatchTooltipEvent, delay, detail))
        }
    }
    const requestCloseTooltip = (needImmediateClose = false, delay = 300) => {
        clearSetTimeoutTimer()
        const detail = { openTooltipId: undefined }
        if (needImmediateClose) {
            dispatchTooltipEvent(detail)
        } else {
            recordSetTimeoutTimer(setTimeout(dispatchTooltipEvent, delay, detail))
        }
    }

    class Tooltip extends React.Component<TooltipProps, TooltipState> {
        override state = { visible: false }
        triggerRef = React.createRef<any>() // 用于获取触发器容器节点的dom。类型不可知。可能是HTMLElement，也可能一些方法
        shadowRef = React.createRef<HTMLSpanElement>() // 此dom是为了作为底层阴影。用于和箭头叠加实现阴影效果
        arrowRef = React.createRef<HTMLSpanElement>()
        contentRef = React.createRef<HTMLDivElement>()
        observer = new ResizeObserver(() => this.tryUpdatePosition())
        mousePositionInTooltip: Point | null = null
        tooltipId: string

        mergeMouseEvent = (innerEvent: (e: React.MouseEvent) => void, outerEvent: unknown) => {
            return (e: React.MouseEvent) => {
                innerEvent(e)
                if (typeof outerEvent === 'function') {
                    outerEvent(e)
                }
            }
        }
        mergeFocusEvent = (innerEvent: (e: React.FocusEvent) => void, outerEvent: unknown) => {
            return (e: React.FocusEvent) => {
                innerEvent(e)
                if (typeof outerEvent === 'function') {
                    outerEvent(e)
                }
            }
        }
        tooltipEventListener = (e: TooltipEventDetail) => {
            const nextVisible = e.openTooltipId === this.tooltipId
            if (this.state.visible !== nextVisible) {
                this.setState({ visible: nextVisible })
            }
        }
        needSilenceEvent = () => {
            return this.props.inactivation
        }
        openTooltip = () => {
            if (this.needSilenceEvent()) {
                return
            }
            requestOpenTooltip(this.tooltipId, this.props.firstDelay)
        }
        closeTooltip = (needImmediateClose = false) => {
            requestCloseTooltip(needImmediateClose)
        }

        onmousemove = (e: React.MouseEvent) => {
            this.mousePositionInTooltip = { x: e.clientX, y: e.clientY }
        }
        onmouseenter = (e: React.MouseEvent) => {
            this.mousePositionInTooltip = { x: e.clientX, y: e.clientY }
            // 键盘可以打开一些弹框， 这些弹出往往有全局遮罩，这时鼠标并没有移动，因此这个判断是去除一些让人疑惑的行为
            if (isPointInRect(this.mousePositionInTooltip, e.currentTarget.getBoundingClientRect())) {
                this.openTooltip()
            }
        }
        onmouseleave = () => {
            this.mousePositionInTooltip = null
            this.closeTooltip()
        }
        // 组件内部往往会阻止事件的按下抬起的传播，这里选择在捕获阶段关闭
        onpointerdownCapture = () => {
            this.closeTooltip(true)
        }
        onclickCapture = () => {
            this.closeTooltip(true)
        }
        onfocusinCapture = () => {
            this.closeTooltip(true)
        }
        oncontextmenuCapture = () => {
            this.closeTooltip(true)
        }

        loadTriggerElement = () => {
            const child = React.Children.only(
                this.props.addContainer ? (
                    <div {...this.props.addContainer}>{this.props.children}</div>
                ) : isValidElement(this.props.children) ? (
                    this.props.children
                ) : (
                    <span>{this.props.children}</span>
                )
            )

            const childProps: HTMLAttributes<HTMLElement> & { key: string; ref?: React.Ref<any> } = {
                key: 'tooltip',
            }
            childProps.onMouseMove = this.mergeMouseEvent(this.onmousemove, child.props.onMouseMove)
            childProps.onMouseEnter = this.mergeMouseEvent(this.onmouseenter, child.props.onMouseEnter)
            childProps.onMouseLeave = this.mergeMouseEvent(this.onmouseleave, child.props.onMouseLeave)
            childProps.onFocusCapture = this.mergeFocusEvent(this.onfocusinCapture, child.props.onFocusCapture)
            childProps.onContextMenuCapture = this.mergeMouseEvent(
                this.oncontextmenuCapture,
                child.props.onContextMenuCapture
            )
            if (this.props.canMouseDownClose) {
                childProps.onPointerDownCapture = this.mergeMouseEvent(
                    this.onpointerdownCapture,
                    child.props.onPointerDownCapture
                )
            } else {
                childProps.onClickCapture = this.mergeMouseEvent(this.onclickCapture, child.props.onClickCapture)
            }
            childProps.ref = (childRefValue: any) => {
                const childWithRef = child as any
                if (childWithRef?.ref) {
                    if (typeof childWithRef.ref === 'function') {
                        childWithRef.ref(childRefValue)
                    } else {
                        childWithRef.ref.current = childRefValue
                    }
                } else if (childWithRef?.props?.ref) {
                    if (typeof childWithRef.props.ref === 'function') {
                        childWithRef.props.ref(childRefValue)
                    } else {
                        childWithRef.props.ref.current = childRefValue
                    }
                }
                Object.assign(this.triggerRef, { current: childRefValue })
            }

            return React.cloneElement(child, { ...childProps })
        }

        loadTipElement = () => {
            const customContent = this.props.overlay
            const content = customContent ? (
                typeof customContent === 'function' ? (
                    customContent()
                ) : (
                    customContent
                )
            ) : (
                <>
                    <span className={classes.title}>{this.props.title ?? ''}</span>
                    {this.props.shortcut ? <span className={classes.shortcut}>{this.props.shortcut}</span> : null}
                </>
            )
            const contentRefCallback = (e: HTMLDivElement | null) => {
                if (this.contentRef.current) {
                    this.observer.unobserve(this.contentRef.current)
                }
                Object.assign(this.contentRef, { current: e })
                if (this.contentRef.current) {
                    this.observer.observe(this.contentRef.current)
                }
            }
            const tipContent = (
                <div
                    className={classnames(classes.tooltip, this.props.toolTipClassName ?? '')}
                    data-testid={this.props.dataTestIds?.tooltip}
                >
                    <span ref={this.shadowRef} className={classes.shadow}></span>
                    <span
                        ref={this.arrowRef}
                        className={classes.arrow}
                        data-testid={this.props.dataTestIds?.arrow}
                    ></span>
                    <div ref={contentRefCallback} className={classes.content} style={{ maxWidth: this.props.maxWidth }}>
                        {content}
                    </div>
                </div>
            )
            return this.state.visible ? createPortal(tipContent, getTipContainer()) : null
        }

        findDOMNodeFromRef = () => {
            const refValue = this.triggerRef.current
            if (!refValue) {
                return
            }
            if (
                refValue instanceof HTMLElement ||
                refValue instanceof SVGElement ||
                refValue instanceof MathMLElement
            ) {
                return refValue
            }
            const fnKey = this.props.triggerRefKey
            if (fnKey && typeof refValue[fnKey] === 'function') {
                const value = refValue[fnKey]()
                if (value instanceof HTMLElement) {
                    return value
                }
            }
        }

        getWorldRect = () => {
            const worldRect = this.props.worldRect
            if (worldRect) {
                if (typeof worldRect === 'function') {
                    return worldRect()
                } else {
                    return worldRect
                }
            }
            return {
                left: 0,
                top: 0,
                right: document.documentElement.clientWidth,
                bottom: document.documentElement.clientHeight,
            }
        }

        getThisDOMRect = () => {
            try {
                // eslint-disable-next-line
                const dom = this.findDOMNodeFromRef() ?? findDOMNode(this)
                const rect = (dom as Element).getBoundingClientRect()
                // 一些元素会被动态设为display: none;这个时候获取到的坐标全是 0，导致某一瞬间tooltip在左上角展示。也就是rect其实是不对的，因此这里把这个错误的位置丢弃
                const isHideElement =
                    !rect.top && !rect.left && !rect.right && !rect.bottom && !rect.width && !rect.height
                return isHideElement ? null : (rect as Rect)
            } catch (e) {
                return null
            }
        }

        getTriggerRect = () => {
            const customTriggerRect = this.props.triggerRect
            if (customTriggerRect) {
                if (typeof customTriggerRect === 'function') {
                    return customTriggerRect()
                } else {
                    return customTriggerRect
                }
            }
            return this.getThisDOMRect()
        }

        placementFnPick = (majorPlacementFn: PlacementFn, minorPlacementFn: PlacementFn) => {
            const majorPlacement = majorPlacementFn()
            if (majorPlacement.success || !this.props.autoAdjustOverflow) {
                return majorPlacement.res
            } else {
                const minorPlacement = minorPlacementFn()
                return minorPlacement.success ? minorPlacement.res : majorPlacement.res
            }
        }

        getTopLeftPlacement = (
            worldRect: Rect,
            triggerRect: Rect,
            contentSize: Size,
            worldEdge: number,
            nearOffset: number,
            farOffset: number,
            minOffsetFromContentToArrow: number
        ) => {
            const minWorldTop = Math.min(worldRect.top + worldEdge, worldRect.bottom)
            const maxWorldBottom = Math.max(worldRect.bottom - worldEdge, worldRect.top)
            const minWorldLeft = Math.min(worldRect.left + worldEdge, worldRect.right)
            const maxWorldRight = Math.max(worldRect.right - worldEdge, worldRect.left)
            const placementRight: PlacementFn = () => {
                const canPlaceRight = triggerRect.right + nearOffset + contentSize.width + farOffset <= maxWorldRight
                return {
                    res: { value: triggerRect.right + nearOffset, placement: 'right' },
                    success: canPlaceRight,
                }
            }
            const placementLeft: PlacementFn = () => {
                const canPlaceLeft = triggerRect.left - nearOffset - contentSize.width - farOffset >= minWorldLeft
                return {
                    res: { value: triggerRect.left - nearOffset - contentSize.width, placement: 'left' },
                    success: canPlaceLeft,
                }
            }
            const placementBottom: PlacementFn = () => {
                const canPlaceBottom =
                    triggerRect.bottom + nearOffset + contentSize.height + farOffset <= maxWorldBottom
                return {
                    res: { value: triggerRect.bottom + nearOffset, placement: 'bottom' },
                    success: canPlaceBottom,
                }
            }
            const placementTop: PlacementFn = () => {
                const canPlaceTop = triggerRect.top - nearOffset - contentSize.height - farOffset >= minWorldTop
                return {
                    res: { value: triggerRect.top - nearOffset - contentSize.height, placement: 'top' },
                    success: canPlaceTop,
                }
            }
            const placement = this.props.placement ?? 'bottom'
            const isPlaceHorizontal = placement === 'left' || placement === 'right'
            if (isPlaceHorizontal) {
                const majorPlacementFn = placement === 'left' ? placementLeft : placementRight
                const minorPlacementFn = placement === 'left' ? placementRight : placementLeft
                const result = this.placementFnPick(majorPlacementFn, minorPlacementFn)
                const top = getFrontEndOfLineInLimited(
                    minWorldTop,
                    triggerRect.top,
                    contentSize.height,
                    triggerRect.bottom,
                    maxWorldBottom,
                    minOffsetFromContentToArrow
                )
                return { top, left: result.value, placement: result.placement }
            } else {
                const majorPlacementFn = placement === 'top' ? placementTop : placementBottom
                const minorPlacementFn = placement === 'top' ? placementBottom : placementTop
                const result = this.placementFnPick(majorPlacementFn, minorPlacementFn)
                const left = getFrontEndOfLineInLimited(
                    minWorldLeft,
                    triggerRect.left,
                    contentSize.width,
                    triggerRect.right,
                    maxWorldRight,
                    minOffsetFromContentToArrow
                )
                return { top: result.value, left, placement: result.placement }
            }
        }

        updateArrowCSS = (triggerRect: Rect, arrowElement: HTMLElement, arrowHeight: number, placement: Placement) => {
            const style = createArrowStyle(triggerRect, arrowHeight, placement)
            arrowElement.setAttribute('style', style)
            arrowElement.dataset.placement = placement
        }

        setTooltipPosition = (
            worldRect: Rect,
            triggerRect: Rect,
            arrowElement: HTMLElement,
            contentElement: HTMLElement
        ) => {
            // worldEdge、arrowHeight、minOffsetFromContentToArrow 是 设计定义的值
            const worldEdge = 8
            const arrowHeight = 6
            const minOffsetFromContentToArrow = arrowHeight + 8
            const contentRect = contentElement.getBoundingClientRect()

            const contentStyle = this.getTopLeftPlacement(
                worldRect,
                triggerRect,
                contentRect,
                worldEdge,
                arrowHeight,
                0,
                minOffsetFromContentToArrow
            )

            this.updateArrowCSS(triggerRect, arrowElement, arrowHeight, contentStyle.placement)
            // 这里不使用top/left而使用translate是因为如果topleft的值使文本被浏览器右侧遮挡，那么浏览器会换行文本
            // 换行文本有特定的规则就是200px内不换行，超出换行（指定最大宽度和word-break: break-all后自动实现）
            contentElement.style.setProperty('transform', `translate(${contentStyle.left}px, ${contentStyle.top}px)`)
            this.shadowRef.current?.setAttribute(
                'style',
                `translate: ${contentStyle.left}px ${contentStyle.top}px;width: ${contentRect.width}px;height: ${contentRect.height}px;`
            )
        }

        tryUpdatePosition = () => {
            if (!this.state.visible) {
                return
            }
            const worldRect = this.getWorldRect()
            const triggerRect = this.getTriggerRect()
            const arrowElement = this.arrowRef.current
            const contentElement = this.contentRef.current
            if (triggerRect && arrowElement && contentElement) {
                this.setTooltipPosition(worldRect, triggerRect, arrowElement, contentElement)
            }
        }
        tryActiveTooltip = () => {
            const position = this.mousePositionInTooltip
            const rect = this.getThisDOMRect()
            if (position && rect && isPointInRect(position, rect)) {
                this.openTooltip()
            }
        }

        static defaultProps: TooltipProps = {
            placement: 'bottom',
            autoAdjustOverflow: true,
        }

        private constructor(props: TooltipProps) {
            super(props)
            this.tooltipId = `tooltip-id:${Date.now()}_${Math.random()}`
            addTooltipEventListener(this.tooltipEventListener)
        }

        public override componentDidUpdate(prevProps: TooltipProps) {
            this.tryUpdatePosition()
            if (prevProps.inactivation && !this.props.inactivation) {
                this.tryActiveTooltip()
            }
        }

        public override componentWillUnmount() {
            this.closeTooltip(true)
            removeTooltipEventListener(this.tooltipEventListener)
            this.observer.disconnect()
        }

        public override render(): React.ReactNode {
            return (
                <>
                    {this.loadTriggerElement()}
                    {this.loadTipElement()}
                </>
            )
        }
    }

    const TooltipManager = {
        close: () => requestCloseTooltip(true),
    }
    return { Tooltip, TooltipManager }
}

const { Tooltip, TooltipManager } = generateTooltip()

export { TooltipManager }
export default Tooltip as unknown as React.FC<TooltipProps>
