export enum ClogDomain {
    Biz = 'biz',
    Com = 'com',
    Ws = 'ws',
}

export interface ClogConfig {
    site: string // 站点域名
    domain: ClogDomain // 顶级域
    queryUrl: string // 查询日志 url
}

export interface Environment {
    isAbroad: boolean
    isDev: boolean
    isProduction: boolean
    googleClientId: string
    host: string
    wssPrefix: string // wss 链接的 hosts
    ossCdnPath: string // ossCdnPath
    wasmDownloadPrefix?: string // 下载二进制文件的 cdn 前缀，会比普通的 cdn 前缀多一个 /app/
    publicResourcePrefix: string // oss 存储的客户端访问资源的前缀
    sentryDsn: string // sentry dsn
    clogConfig: ClogConfig // 用于 clog 的配置
    featureSwitchEnv: 'testing' | 'production'
    httpPrefixes: Record<HttpPrefixKey, string> // httpApiPrefix
    availableHostsForEmailLoginApi: string[] // 支持邮箱登录接口的域名, !!!增加新域名前需要服务端配置nginx
    uiPrefixCls: string // ui-lib & antd 统一 class 前缀
    figmaClientId: string // figma oauth client id
    figmaCallbackUrl: string // figma oauth callback url
    mirrorBasePath: string // 移动端预览base路径
    notifyWssPrefix: string // notify wss链接的域名
    mirrorWssPrefix: string // 稳定预览 wss链接前缀
    fontPluginDaemonHost: string // 字体助手https域名
    fontPluginLocalHost: string // 字体助手http域名
    onboardingHelpDocUrl: Record<
        | 'frame'
        | 'vector'
        | 'comment'
        | 'share'
        | 'coator'
        | 'library'
        | 'prototype'
        | 'device'
        | 'devModeStart'
        | 'devmodeInspect'
        | 'devmodeExport'
        | 'variable',
        string
    >
    helpDocsUrl: Record<
        | 'home'
        | 'color'
        | 'image'
        | 'component'
        | 'webgl'
        | 'aiCredit'
        | 'quota'
        | 'ssoPath'
        | 'stateManagement'
        | 'motiffAPI'
        | 'variable'
        | 'organizationGuest',
        string
    >
}

export enum HttpPrefixKey {
    COMMON_API,
    NOTIFY_API,
    SYNERGY_CONNECTOR_API,
    SYNERGY_ADMIN_API,
    ADMIN_API,
    INNER_API,
    BOLT_API,
    AI_GEN_API,
}
