import { translation } from './style-title.translation'
/* eslint-disable no-restricted-imports */
import { IconAdd16, IconComponentLibrary16, IconGrid, IconList, Tooltip } from '../../../../../../../ui-lib/src'
import { useCallback } from 'react'
import { WKFrogTask } from '../../../../../share/wk-frog-task'
import { IconButton } from '../../../atom/button/icon-button'
import style from './style-title.module.less'

export enum StyleType {
    PaintStyle,
    TextStyle,
    EffectStyle,
    LayoutGridStyle,
}

export interface StyleTitleProps {
    styleType: StyleType
    isList?: boolean
    hiddenLibraryIcon?: boolean
    disabledAddIcon?: boolean
    onClickLibraryIcon?: () => void
    onClickAddIcon?: () => void
    onClickLayoutIcon?: () => void
}

export function StyleTitle(props: StyleTitleProps): JSX.Element {
    const {
        onClickAddIcon,
        onClickLibraryIcon,
        onClickLayoutIcon,
        disabledAddIcon,
        hiddenLibraryIcon,
        styleType,
        isList,
    } = props

    const onClick = useCallback(() => {
        styleType === StyleType.TextStyle && WKFrogTask.textStyle.addTextStyle()
        onClickAddIcon?.()
    }, [onClickAddIcon, styleType])

    return (
        <div className={style.title}>
            <div className={style.title_text} data-testid="title">
                {getStyleTitle(styleType)}
            </div>
            <div className={style.title_icon}>
                {hiddenLibraryIcon ? null : (
                    <Tooltip title={contextText.library}>
                        <IconButton
                            data-testid="library"
                            icon={<IconComponentLibrary16 />}
                            selected={false}
                            onClick={onClickLibraryIcon}
                        />
                    </Tooltip>
                )}
                {styleType === StyleType.PaintStyle ? (
                    <Tooltip title={isList ? contextText.list : contextText.grid}>
                        <IconButton
                            data-testid="layout"
                            icon={isList ? <IconList /> : <IconGrid />}
                            selected={false}
                            onClick={onClickLayoutIcon}
                        />
                    </Tooltip>
                ) : null}
                <Tooltip title={contextText.add}>
                    <IconButton
                        icon={<IconAdd16 />}
                        selected={false}
                        onClick={onClick}
                        disabled={disabledAddIcon}
                        data-testid="create-style"
                    />
                </Tooltip>
            </div>
        </div>
    )
}

export const contextText = {
    paintStyle: translation('ColorStyles'),
    textStyle: translation('TextStyles'),
    effectStyle: translation('EffectStyles'),
    LayoutGridStyle: translation('GridStyles'),
    add: translation('CreateStyle'),
    library: translation('TeamLibrary'),
    list: translation('ShowAsList'),
    grid: translation('ShowAsGrid'),
}

export function getStyleTitle(styleType: StyleType): string {
    switch (styleType) {
        case StyleType.PaintStyle:
            return contextText.paintStyle
        case StyleType.TextStyle:
            return contextText.textStyle
        case StyleType.EffectStyle:
            return contextText.effectStyle
        case StyleType.LayoutGridStyle:
            return contextText.LayoutGridStyle
        default:
            return ''
    }
}
