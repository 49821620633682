import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    CreatePlugin: '新建插件',
    CreateToLocal: '创建到本地',
    PleaseInputPluginName: '请输入插件名称',
    PluginNameTooLong: '插件名称请不要超过 50 个字符',
    CreatePluginFailed: '创建插件失败',
} as const

export const enTranslation = {
    CreatePlugin: 'Create new plugin',
    CreateToLocal: 'Create',
    PleaseInputPluginName: 'Plugin name',
    PluginNameTooLong: 'Name is too long (Maximum is 50 characters)',
    CreatePluginFailed: 'An error occurred while creating plugin.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
