import { MotiffApi } from '@motiffcom/plugin-api-types'
import { Wukong } from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'

export type DeepRequired<T> = T extends (...args: any[]) => any
    ? T
    : T extends object
    ? {
          [K in keyof T]-?: NonNullable<DeepRequired<T[K]>>
      }
    : T extends any[]
    ? Array<DeepRequired<T[number]>>
    : NonNullable<T>

import P = Wukong.DocumentProto

interface Vector {
    x: number
    y: number
}

interface NodeWithId {
    id: string
}

export function createMapperFromMotiffEnum<T extends object, U = string>(enumObject: T, prefix: string) {
    const motiffToFigmaMapper: Record<number, string> = {}
    const figmaToMotiffMapper: Record<string, number> = {}
    const prefixCount = prefix.length

    Object.entries(enumObject).forEach(([k, v]) => {
        const key = typeof k === 'string' && k.startsWith(prefix) ? k.slice(prefixCount) : k
        figmaToMotiffMapper[key] = v
        motiffToFigmaMapper[v] = key
    })

    return {
        fromMotiff(motiffEnum: keyof T): U {
            return motiffToFigmaMapper[motiffEnum as number] as unknown as U
        },
        fromFigma(figmaEnum: string): T {
            return figmaToMotiffMapper[figmaEnum as string] as unknown as T
        },
    }
}

export function createMapperFromFigmaEnum<F extends string, M extends string | number>(figma2Motiff: Record<F, M>) {
    return {
        figmaEnum: Object.keys(figma2Motiff).reduce((res, val) => ({ ...res, [val]: val }), {} as Record<F, F>),
        motiffEnum: figma2Motiff,
        fromMotiff(m: M): F {
            const entry = Object.entries(figma2Motiff).find(([_, value]) => value === m) as [F, M] | undefined
            if (!entry) {
                throw new Error('未定义的 motiff -> figma 转换值')
            }
            return entry[0]
        },
        fromFigma(f: F): M {
            return figma2Motiff[f]
        },
    }
}

export type NodePropMapperKeys = keyof typeof PrototypePropMapper
export type NodePropMapperRawType = typeof PrototypePropMapper
export type NodePropMapperType = {
    [k in keyof NodePropMapperRawType]: {
        motiff: ReturnType<NodePropMapperRawType[k]['fromFigma']>
        figma: ReturnType<NodePropMapperRawType[k]['fromMotiff']>
    }
}

export namespace Motiff {
    export type NodeType = typeof P.NodeType

    // 属性类型
    export type Transform = DeepRequired<P.ITransform>

    // 字体相关
    export type FontName = DeepRequired<P.IFontName>
    export type TextAutoResize = typeof P.TextAutoResize
    export type TextAlignHorizontal = typeof P.TextAlignHorizontal
    export type TextAlignVertical = typeof P.TextAlignVertical

    // 自动布局
    export type StackMode = P.StackMode
    export type StackWrap = P.StackWrap
    export type StackSize = P.StackSize
    export type StackAlign = P.StackAlign
    export type StackJustify = P.StackJustify
    export type StackCounterAlign = P.StackCounterAlign
    export type StackPositioning = P.StackPositioning
    export type StackCounterAlignContent = P.StackCounterAlignContent

    // [how_to_add_plugin_api][5 of n][可选] 导出 js motiff 类型，如果是基础类型，可忽略此处

    export type ExportSettings = DeepRequired<P.IExportSettings>[]

    export type ExportSettingsFormatType = P.ExportFormatType

    // 约束
    export type ConstraintType = P.ConstraintType
    export type Constraints = P.Constraints

    // 辅助线
    export type GuideAxis = P.GuideAxis
    export type Guide = readonly P.Guide[]

    // 颜色
    export type BlendMode = P.BlendMode
    export type ScaleMode = P.ScaleMode
    export type PaintType = P.PaintType
    export type RGB = DeepRequired<P.IRGB>
    export type RGBA = DeepRequired<P.IRGBA>
    export type ImageFilters = DeepRequired<P.IImageFilters>
    export interface SolidPaint {
        type: P.PaintType.PAINT_TYPE_SOLID_PAINT
        color: RGB
        colorVar: P.IVariableAliasData | null
        visible: boolean
        opacity: number
        blendMode: BlendMode
    }
    export interface ColorStop {
        position: number
        color: RGBA
    }

    export interface GradientPaint {
        type:
            | P.PaintType.PAINT_TYPE_GRADIENT_ANGULAR
            | P.PaintType.PAINT_TYPE_GRADIENT_DIAMOND
            | P.PaintType.PAINT_TYPE_GRADIENT_LINEAR
            | P.PaintType.PAINT_TYPE_GRADIENT_RADIAL
        gradientTransform: Transform
        gradientStops: ColorStop[]
        visible: boolean
        opacity: number
        blendMode: BlendMode
    }

    export interface ImagePaint {
        type: P.PaintType.PAINT_TYPE_IMAGE_PAINT
        scaleMode: ScaleMode
        imageHash: string
        imageName: string
        imageTransform: Transform
        scalingFactor: number
        rotation: number
        filters: ImageFilters
        visible: boolean
        opacity: number
        blendMode: BlendMode
        previewHash: string
    }

    export type Paint = SolidPaint | GradientPaint | ImagePaint

    export interface DropShadowEffect {
        type: P.EffectType.EFFECT_TYPE_DROP_SHADOW
        color: RGBA
        offset: Vector
        radius: number
        spread: number
        visible: boolean
        blendMode: BlendMode
        showShadowBehindNode?: boolean
    }

    export interface InnerShadowEffect {
        type: P.EffectType.EFFECT_TYPE_INNER_SHADOW
        color: RGBA
        offset: Vector
        radius: number
        spread: number
        visible: boolean
        blendMode: BlendMode
    }
    export interface BlurEffect {
        type: P.EffectType.EFFECT_TYPE_BACKGROUND_BLUR | P.EffectType.EFFECT_TYPE_LAYER_BLUR
        radius: number
        visible: boolean
    }
    export type Effect = DropShadowEffect | InnerShadowEffect | BlurEffect

    // 布局网格
    export interface LayoutGrid {
        pattern: P.LayoutGridPattern
        align: P.RowsColsLayoutGridAlign
        count: number
        gutterSize: number
        offset: number
        sectionSize: number
        visible: boolean
        paints: Paint
    }

    export interface VectorVertex {
        x: number
        y: number
        strokeCap?: P.StrokeCap
        strokeJoin?: P.StrokeJoin
        cornerRadius?: number
        handleMirroring?: P.VectorHandleMirror
    }

    export interface VectorSegment {
        start: number
        end: number
        tangentStart?: Vector
        tangentEnd?: Vector
    }

    export interface VectorRegion {
        windingRule: P.WindingRule
        loops: P.VectorNetworkLoop[]
        fills?: Paint[]
        fillStyleId?: string
    }

    export interface VectorNetwork {
        vertices: VectorVertex[]
        segments: VectorSegment[]
        regions?: VectorRegion[]
    }

    export interface ApiVectorPath {
        windingRule?: P.WindingRule
        data: string
    }

    export interface LetterSpacing {
        value: number
        unit: P.NumberUnit
    }

    export interface LineHeight {
        value: number
        unit: P.NumberUnit
    }

    export interface HyperlinkTarget {
        type: P.HyperlinkTargetType
        value: string
    }

    export interface TextListOptions {
        type: P.TextListOptionsType
    }

    // 位置
    export type PosType = P.PosType
    export type TextRange = DeepRequired<P.TextRange>
    export type Rect = DeepRequired<P.IRect>

    export type FontWeight = P.FontWeight
    export type FontWidth = P.FontWidth
    export type FontSlant = P.FontSlant
    export type AIPoweredReason = P.AIPoweredReason
    export type ActiveUserRole = P.ActiveUserRole
    export type AiAlignType = P.AiAlignType
    export type AlignType = P.AlignType
    export type AutoLayoutDisplayType = P.AutoLayoutDisplayType
    export type AutoLayoutEditType = P.AutoLayoutEditType
    export type AutoLayoutMoveChildrenType = P.AutoLayoutMoveChildrenType
    export type AutoLayoutWHType = P.AutoLayoutWHType
    export type AutoLayoutWHValue = P.AutoLayoutWHValue
    export type MetricImageType = P.MetricImageType
    export type BooleanOperation = P.BooleanOperation
    export type BridgeAction = P.BridgeAction
    export type CanvasSearchMode = P.CanvasSearchMode
    export type CanvasSearchNodeTypeFilter = P.CanvasSearchNodeTypeFilter
    export type CanvasSearchScope = P.CanvasSearchScope
    export type ClipboardCopyType = P.ClipboardCopyType
    export type ClipboardNodeTreeContentType = P.ClipboardNodeTreeContentType
    export type ClipboardPasteType = P.ClipboardPasteType
    export type CodeType = P.CodeType
    export type ColorMode = P.ColorMode
    export type ColorPickCommandType = P.ColorPickCommandType
    export type CompSetPropConflictSelectionType = P.CompSetPropConflictSelectionType
    export type CompSetPropConflictType = P.CompSetPropConflictType
    export type CompressType = P.CompressType
    export type ContextMenuAutoLayoutType = P.ContextMenuAutoLayoutType
    export type CursorMode = P.CursorMode
    export type DebouncedMovingBoundsPositionHold = P.DebouncedMovingBoundsPositionHold
    export type DeviceSupportDisplayP3Level = P.DeviceSupportDisplayP3Level
    export type Directionality = P.Directionality
    export type DirectionalityIntent = P.DirectionalityIntent
    export type DisplayPanelType = P.DisplayPanelType
    export type DistributeType = P.DistributeType
    export type DocMode = P.DocMode
    export type DocumentColorProfile = P.DocumentColorProfile
    export type DownloadErrorType = P.DownloadErrorType
    export type DragMoveMode = P.DragMoveMode
    export type EditingPaintType = P.EditingPaintType
    export type EditorMode = P.EditorMode
    export type EditorStateFromModule = P.EditorStateFromModule
    export type EffectStyleIconType = P.EffectStyleIconType
    export type EffectType = P.EffectType
    export type ExportConstraintType = P.ExportConstraintType
    export type ExportFormatType = P.ExportFormatType
    export type ExportOperationTarget = P.ExportOperationTarget
    export type FocusView = P.FocusView
    export type FontStyle = P.FontStyle
    export type HyperlinkEditingMode = P.HyperlinkEditingMode
    export type ImageFormat = P.ImageFormat
    export type ImageState = P.ImageState
    export type IndependentStrokeType = P.IndependentStrokeType
    export type InputMode = P.InputMode
    export type InstanceResetType = P.InstanceResetType
    export type KeyMouseState = P.KeyMouseState
    export type LayoutGridPattern = P.LayoutGridPattern
    export type LayoutGridStyleIconType = P.LayoutGridStyleIconType
    export type LayoutOrigin = P.LayoutOrigin
    export type LineType = P.LineType
    export type LocalStyleSelectionType = P.LocalStyleSelectionType
    export type LooperRotateMode = P.LooperRotateMode
    export type MixedCheckState = P.MixedCheckState
    export type NumberUnit = P.NumberUnit
    export type OperateSystemType = P.OperateSystemType
    export type PackType = P.PackType
    export type PaintPositionType = P.PaintPositionType
    export type PaintUsingType = P.PaintUsingType
    export type ProtoValueType = P.ProtoValueType
    export type RotateCopyEndState = P.RotateCopyEndState
    export type RowsColsLayoutGridAlign = P.RowsColsLayoutGridAlign
    export type SelectedReversedIndexesType = P.SelectedReversedIndexesType
    export type SelectionFillType = P.SelectionFillType
    export type SelectionHyperlinkType = P.SelectionHyperlinkType
    export type SelectionStrokeEndPointShowType = P.SelectionStrokeEndPointShowType
    export type SelectionStrokeType = P.SelectionStrokeType
    export type SelectionTextLineType = P.SelectionTextLineType
    export type SelectionType = P.SelectionType
    export type SidebarPanelType = P.SidebarPanelType
    export type SizeOverride = P.SizeOverride
    export type StrokeAlign = P.StrokeAlign
    export type StrokeCap = P.StrokeCap
    export type StrokeJoin = P.StrokeJoin
    export type StrokeModeType = P.StrokeModeType
    export type StyledTextSegment = DeepRequired<P.StyledTextSegmentExt>
    export type SwitchGroupFrameNameTo = P.SwitchGroupFrameNameTo
    export type TestEnum = P.TestEnum
    export type TextCase = P.TextCase
    export type TextDecoration = P.TextDecoration
    export type TextMatchType = P.TextMatchType
    export type TextTruncation = P.TextTruncation
    export type MaxLines = number | null
    export type TidyType = P.TidyType
    export type ToggleDocumentStateCommandEnum = P.ToggleDocumentStateCommandEnum
    export type UpdateSelectionFrameDirectionCommandEnum = P.UpdateSelectionFrameDirectionCommandEnum
    export type UpdateSelectionPaintType = P.UpdateSelectionPaintType
    export type UpdateVectorNetworkRegionType = P.UpdateVectorNetworkRegionType
    export type VLayerPanelMaskType = P.VLayerPanelMaskType
    export type VSelectionEffectType = P.VSelectionEffectType
    export type VSelectionLayoutGridType = P.VSelectionLayoutGridType
    export type VTextStateType = P.VTextStateType
    export type VTextStyleStateType = P.VTextStyleStateType
    export type VectorHandleMirror = P.VectorHandleMirror
    export type ViewportAnimationKeyframeType = P.ViewportAnimationKeyframeType
    export type WindingRule = P.WindingRule
    export type ZoomToolState = P.ZoomToolState
    export type InteractionType = P.InteractionType
    export type PrototypeInteraction = P.IPrototypeInteraction
    export type PrototypeAction = P.IPrototypeAction
    export type PrototypeEvent = P.IPrototypeEvent
    export type NavigationType = P.NavigationType
    export type ScrollDirection = P.ScrollDirection
}

export namespace FigmaToMotiff {
    export const NodeType: Record<MotiffApi.BaseNode['type'], P.NodeType> = {
        VECTOR: P.NodeType.NODE_TYPE_VECTOR,
        DOCUMENT: P.NodeType.NODE_TYPE_DOCUMENT,
        RECTANGLE: P.NodeType.NODE_TYPE_RECTANGLE,
        STAR: P.NodeType.NODE_TYPE_STAR,
        POLYGON: P.NodeType.NODE_TYPE_POLYGON,
        LINE: P.NodeType.NODE_TYPE_LINE,
        ELLIPSE: P.NodeType.NODE_TYPE_ELLIPSE,
        PAGE: P.NodeType.NODE_TYPE_PAGE,
        FRAME: P.NodeType.NODE_TYPE_FRAME,
        GROUP: P.NodeType.NODE_TYPE_GROUP,
        BOOLEAN_OPERATION: P.NodeType.NODE_TYPE_BOOL_OPERATION,
        TEXT: P.NodeType.NODE_TYPE_TEXT,
        COMPONENT_SET: P.NodeType.NODE_TYPE_COMPONENT_SET,
        COMPONENT: P.NodeType.NODE_TYPE_COMPONENT,
        INSTANCE: P.NodeType.NODE_TYPE_INSTANCE,
        SLICE: P.NodeType.NODE_TYPE_SLICE,
    } as const

    // [how_to_add_plugin_api][6 of n][可选] 导出 js figma -> motiff 类型映射表，如果是基础类型，可忽略此步

    export const StyleNodeType: Record<MotiffApi.StyleType, P.NodeType> = {
        PAINT: P.NodeType.NODE_TYPE_PAINT_STYLE,
        TEXT: P.NodeType.NODE_TYPE_TEXT_STYLE,
        EFFECT: P.NodeType.NODE_TYPE_EFFECT_STYLE,
        GRID: P.NodeType.NODE_TYPE_LAYOUT_GRID_STYLE,
    } as const

    export const TextAutoResize: Record<MotiffApi.TextNode['textAutoResize'], P.TextAutoResize> = {
        NONE: P.TextAutoResize.TEXT_AUTO_RESIZE_NONE,
        WIDTH_AND_HEIGHT: P.TextAutoResize.TEXT_AUTO_RESIZE_WIDTH_AND_HEIGHT,
        HEIGHT: P.TextAutoResize.TEXT_AUTO_RESIZE_HEIGHT,
        TRUNCATE: P.TextAutoResize.TEXT_AUTO_RESIZE_NONE, // fallback 到 none
    } as const

    export const TextAlignHorizontal: Record<MotiffApi.TextNode['textAlignHorizontal'], P.TextAlignHorizontal> = {
        LEFT: P.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_LEFT,
        CENTER: P.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_CENTER,
        RIGHT: P.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_RIGHT,
        JUSTIFIED: P.TextAlignHorizontal.TEXT_ALIGN_HORIZONTAL_JUSTIFIED,
    } as const

    export const TextAlignVertical: Record<MotiffApi.TextNode['textAlignVertical'], P.TextAlignVertical> = {
        TOP: P.TextAlignVertical.TEXT_ALIGN_VERTICAL_TOP,
        CENTER: P.TextAlignVertical.TEXT_ALIGN_VERTICAL_CENTER,
        BOTTOM: P.TextAlignVertical.TEXT_ALIGN_VERTICAL_BOTTOM,
    } as const

    export const TextTruncation: Record<MotiffApi.TextNode['textTruncation'], P.TextTruncation> = {
        DISABLED: P.TextTruncation.TEXT_TRUNCATION_DISABLED,
        ENDING: P.TextTruncation.TEXT_TRUNCATION_ENDING,
    } as const

    export const TextCase: Record<MotiffApi.TextStyle['textCase'], P.TextCase> = {
        ORIGINAL: P.TextCase.TEXT_CASE_ORIGINAL,
        UPPER: P.TextCase.TEXT_CASE_UPPER,
        LOWER: P.TextCase.TEXT_CASE_LOWER,
        TITLE: P.TextCase.TEXT_CASE_TITLE,
        // 以下 motiff 暂不支持
        SMALL_CAPS: P.TextCase.TEXT_CASE_ORIGINAL,
        SMALL_CAPS_FORCED: P.TextCase.TEXT_CASE_ORIGINAL,
    } as const

    export const TextDecoration: Record<MotiffApi.TextDecoration, P.TextDecoration> = {
        NONE: P.TextDecoration.TEXT_DECORATION_NONE,
        UNDERLINE: P.TextDecoration.TEXT_DECORATION_UNDERLINE,
        STRIKETHROUGH: P.TextDecoration.TEXT_DECORATION_STRIKETHROUGH,
    } as const

    export const NumberUnit: Record<'PIXELS' | 'PERCENT', P.NumberUnit> = {
        PIXELS: P.NumberUnit.NUMBER_UNIT_PIXELS,
        PERCENT: P.NumberUnit.NUMBER_UNIT_PERCENT,
    } as const

    export const LineHeightUnit: Record<MotiffApi.LineHeight['unit'], P.NumberUnit> = {
        AUTO: P.NumberUnit.NUMBER_UNIT_AUTO,
        PIXELS: P.NumberUnit.NUMBER_UNIT_PIXELS,
        PERCENT: P.NumberUnit.NUMBER_UNIT_PERCENT,
    } as const

    export const HyperlinkTargetType: Record<MotiffApi.HyperlinkTarget['type'], P.HyperlinkTargetType> = {
        URL: P.HyperlinkTargetType.HYPERLINK_TARGET_TYPE_URL,
        NODE: P.HyperlinkTargetType.HYPERLINK_TARGET_TYPE_NODE,
    } as const

    export const TextListOptionsType: Record<MotiffApi.TextListOptions['type'], P.TextListOptionsType> = {
        ORDERED: P.TextListOptionsType.TEXT_LIST_OPTIONS_TYPE_ORDERED,
        UNORDERED: P.TextListOptionsType.TEXT_LIST_OPTIONS_TYPE_UNORDERED,
        NONE: P.TextListOptionsType.TEXT_LIST_OPTIONS_TYPE_NONE,
    } as const

    export const LayoutMode: Record<MotiffApi.AutoLayoutMixin['layoutMode'], P.StackMode> = {
        NONE: P.StackMode.STACK_MODE_NONE,
        VERTICAL: P.StackMode.STACK_MODE_VERTICAL,
        HORIZONTAL: P.StackMode.STACK_MODE_HORIZONTAL,
    } as const

    export const StackSize: Record<MotiffApi.AutoLayoutMixin['primaryAxisSizingMode'], P.StackSize> = {
        AUTO: P.StackSize.STACK_SIZE_STACK_SIZE_RESIZE_TO_FIT_WITH_IMPLICIT_SIZE,
        FIXED: P.StackSize.STACK_SIZE_STACK_SIZE_FIXED,
    } as const

    export const StackAlign: Record<MotiffApi.AutoLayoutMixin['counterAxisAlignItems'], P.StackAlign> = {
        MIN: P.StackAlign.STACK_ALIGN_STACK_ALIGN_MIN,
        MAX: P.StackAlign.STACK_ALIGN_STACK_ALIGN_MAX,
        CENTER: P.StackAlign.STACK_ALIGN_STACK_ALIGN_CENTER,
        BASELINE: P.StackAlign.STACK_ALIGN_STACK_ALIGN_BASE_LINE,
    } as const

    export const StackJustify: Record<MotiffApi.AutoLayoutMixin['primaryAxisAlignItems'], P.StackJustify> = {
        MIN: P.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MIN,
        MAX: P.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_MAX,
        CENTER: P.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_CENTER,
        SPACE_BETWEEN: P.StackJustify.STACK_JUSTIFY_STACK_JUSTIFY_SPACE_EVENTLY,
    } as const

    export const StackCounterAlign: Record<MotiffApi.AutoLayoutChildrenMixin['layoutAlign'], P.StackCounterAlign> = {
        INHERIT: P.StackCounterAlign.STACK_COUNTER_ALIGN_AUTO,
        MIN: P.StackCounterAlign.STACK_COUNTER_ALIGN_MIN,
        CENTER: P.StackCounterAlign.STACK_COUNTER_ALIGN_CENTER,
        MAX: P.StackCounterAlign.STACK_COUNTER_ALIGN_MAX,
        STRETCH: P.StackCounterAlign.STACK_COUNTER_ALIGN_STRETCH,
    } as const

    export const StackPositioning: Record<MotiffApi.AutoLayoutChildrenMixin['layoutPositioning'], P.StackPositioning> =
        {
            ABSOLUTE: P.StackPositioning.STACK_POSITIONING_ABSOLUTE,
            AUTO: P.StackPositioning.STACK_POSITIONING_AUTO,
        } as const

    export const ConstraintType: Record<MotiffApi.ConstraintType, P.ConstraintType> = {
        MIN: P.ConstraintType.CONSTRAINT_TYPE_MIN,
        CENTER: P.ConstraintType.CONSTRAINT_TYPE_CENTER,
        MAX: P.ConstraintType.CONSTRAINT_TYPE_MAX,
        STRETCH: P.ConstraintType.CONSTRAINT_TYPE_STRETCH,
        SCALE: P.ConstraintType.CONSTRAINT_TYPE_SCALE,
    } as const

    export const GuideAxis: Record<MotiffApi.Guide['axis'], P.GuideAxis> = {
        X: P.GuideAxis.GUIDE_AXIS_X,
        Y: P.GuideAxis.GUIDE_AXIS_Y,
    } as const

    export const BlendMode: Record<MotiffApi.BlendMode, P.BlendMode> = {
        PASS_THROUGH: P.BlendMode.BLEND_MODE_PASS_THROUGH,
        NORMAL: P.BlendMode.BLEND_MODE_NORMAL,
        DARKEN: P.BlendMode.BLEND_MODE_DARKEN,
        MULTIPLY: P.BlendMode.BLEND_MODE_MULTIPLY,
        LINEAR_BURN: P.BlendMode.BLEND_MODE_LINEAR_BURN,
        COLOR_BURN: P.BlendMode.BLEND_MODE_COLOR_BURN,
        LIGHTEN: P.BlendMode.BLEND_MODE_LIGHTEN,
        SCREEN: P.BlendMode.BLEND_MODE_SCREEN,
        LINEAR_DODGE: P.BlendMode.BLEND_MODE_LINEAR_DODGE,
        COLOR_DODGE: P.BlendMode.BLEND_MODE_COLOR_DODGE,
        OVERLAY: P.BlendMode.BLEND_MODE_OVERLAY,
        SOFT_LIGHT: P.BlendMode.BLEND_MODE_SOFT_LIGHT,
        HARD_LIGHT: P.BlendMode.BLEND_MODE_HARD_LIGHT,
        DIFFERENCE: P.BlendMode.BLEND_MODE_DIFFERENCE,
        EXCLUSION: P.BlendMode.BLEND_MODE_EXCLUSION,
        HUE: P.BlendMode.BLEND_MODE_HUE,
        SATURATION: P.BlendMode.BLEND_MODE_SATURATION,
        COLOR: P.BlendMode.BLEND_MODE_COLOR,
        LUMINOSITY: P.BlendMode.BLEND_MODE_LUMINOSITY,
    } as const

    // NOTE: figma 的 VideoPaint 与 motiff 的 EmojiPaint 无法对应
    export const PaintType: Record<
        (MotiffApi.SolidPaint | MotiffApi.GradientPaint | MotiffApi.ImagePaint)['type'],
        P.PaintType
    > = {
        SOLID: P.PaintType.PAINT_TYPE_SOLID_PAINT,
        GRADIENT_LINEAR: P.PaintType.PAINT_TYPE_GRADIENT_LINEAR,
        GRADIENT_RADIAL: P.PaintType.PAINT_TYPE_GRADIENT_RADIAL,
        GRADIENT_ANGULAR: P.PaintType.PAINT_TYPE_GRADIENT_ANGULAR,
        GRADIENT_DIAMOND: P.PaintType.PAINT_TYPE_GRADIENT_DIAMOND,
        IMAGE: P.PaintType.PAINT_TYPE_IMAGE_PAINT,
    } as const

    export const EffectType: Record<MotiffApi.Effect['type'], P.EffectType> = {
        DROP_SHADOW: P.EffectType.EFFECT_TYPE_DROP_SHADOW,
        INNER_SHADOW: P.EffectType.EFFECT_TYPE_INNER_SHADOW,
        LAYER_BLUR: P.EffectType.EFFECT_TYPE_LAYER_BLUR,
        BACKGROUND_BLUR: P.EffectType.EFFECT_TYPE_BACKGROUND_BLUR,
    } as const

    export const ScaleMode: Record<MotiffApi.ImagePaint['scaleMode'], P.ScaleMode> = {
        FILL: P.ScaleMode.SCALE_MODE_FILL,
        FIT: P.ScaleMode.SCALE_MODE_FIT,
        CROP: P.ScaleMode.SCALE_MODE_CROP,
        TILE: P.ScaleMode.SCALE_MODE_TILE,
    } as const

    export const InsertCharactersUseStyleType: Record<
        NonNullable<Parameters<MotiffApi.NonResizableTextMixin['insertCharacters']>[2]>,
        P.PosType
    > = {
        BEFORE: P.PosType.POS_TYPE_AFTER_CHAR,
        AFTER: P.PosType.POS_TYPE_BEFORE_CHAR,
    } as const

    export const StrokeCap: Record<MotiffApi.StrokeCap, P.StrokeCap> = {
        NONE: P.StrokeCap.STROKE_CAP_NONE,
        ROUND: P.StrokeCap.STROKE_CAP_ROUND,
        SQUARE: P.StrokeCap.STROKE_CAP_SQUARE,
        ARROW_LINES: P.StrokeCap.STROKE_CAP_ARROW_LINES,
        ARROW_EQUILATERAL: P.StrokeCap.STROKE_CAP_ARROW_EQUILATERAL,
    } as const

    export const StrokeJoin: Record<MotiffApi.StrokeJoin, P.StrokeJoin> = {
        MITER: P.StrokeJoin.STROKE_JOIN_MITER,
        BEVEL: P.StrokeJoin.STROKE_JOIN_BEVEL,
        ROUND: P.StrokeJoin.STROKE_JOIN_ROUND,
    } as const

    export const StrokeAlign: Record<MotiffApi.MinimalStrokesMixin['strokeAlign'], P.StrokeAlign> = {
        CENTER: P.StrokeAlign.STROKE_ALIGN_CENTER,
        INSIDE: P.StrokeAlign.STROKE_ALIGN_INSIDE,
        OUTSIDE: P.StrokeAlign.STROKE_ALIGN_OUTSIDE,
    } as const

    export const LayoutGridPattern: Record<MotiffApi.LayoutGrid['pattern'], P.LayoutGridPattern> = {
        GRID: P.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID,
        ROWS: P.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS,
        COLUMNS: P.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID,
    }

    export const LayoutGridAlignment: Record<MotiffApi.RowsColsLayoutGrid['alignment'], P.RowsColsLayoutGridAlign> = {
        MIN: P.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MIN,
        MAX: P.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_MAX,
        CENTER: P.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_CENTER,
        STRETCH: P.RowsColsLayoutGridAlign.ROWS_COLS_LAYOUT_GRID_ALIGN_STRETCH,
    }

    export const HandleMirroring: Record<MotiffApi.HandleMirroring, P.VectorHandleMirror> = {
        NONE: P.VectorHandleMirror.VECTOR_HANDLE_MIRROR_NONE,
        ANGLE: P.VectorHandleMirror.VECTOR_HANDLE_MIRROR_ANGLE,
        ANGLE_AND_LENGTH: P.VectorHandleMirror.VECTOR_HANDLE_MIRROR_ANGLE_AND_LENGTH,
    }

    export const WindingRule: Record<MotiffApi.VectorRegion['windingRule'], P.WindingRule> = {
        NONZERO: P.WindingRule.WINDING_RULE_NONZERO,
        EVENODD: P.WindingRule.WINDING_RULE_EVENODD,
    }

    export const CounterAxisAlignContent: Record<
        MotiffApi.AutoLayoutMixin['counterAxisAlignContent'],
        P.StackCounterAlignContent
    > = {
        AUTO: P.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_AUTO,
        SPACE_BETWEEN: P.StackCounterAlignContent.STACK_COUNTER_ALIGN_CONTENT_SPACE_BETWEEN,
    }

    export const LayoutWrap: Record<MotiffApi.AutoLayoutMixin['layoutWrap'], P.StackWrap> = {
        NO_WRAP: P.StackWrap.STACK_WRAP_NO_WRAP,
        WRAP: P.StackWrap.STACK_WRAP_WRAP,
    }

    export const BooleanOperation: Record<MotiffApi.BooleanOperationNode['booleanOperation'], P.BooleanOperation> = {
        UNION: P.BooleanOperation.BOOLEAN_OPERATION_UNION,
        INTERSECT: P.BooleanOperation.BOOLEAN_OPERATION_INTERSECT,
        SUBTRACT: P.BooleanOperation.BOOLEAN_OPERATION_SUBTRACT,
        EXCLUDE: P.BooleanOperation.BOOLEAN_OPERATION_EXCLUDE,
    }
    export const DocumentColorProfile: Record<MotiffApi.DocumentNode['documentColorProfile'], P.DocumentColorProfile> =
        {
            SRGB: P.DocumentColorProfile.DOCUMENT_COLOR_PROFILES_R_G_B,
            DISPLAY_P3: P.DocumentColorProfile.DOCUMENT_COLOR_PROFILE_DISPLAY_P3,
        }

    export const TriggerType: Record<MotiffApi.Trigger['type'], P.InteractionType> = {
        ON_CLICK: P.InteractionType.INTERACTION_TYPE_ON_CLICK,
        ON_HOVER: P.InteractionType.INTERACTION_TYPE_ON_HOVER,
        ON_PRESS: P.InteractionType.INTERACTION_TYPE_ON_PRESS,
        ON_DRAG: P.InteractionType.INTERACTION_TYPE_DRAG,
        AFTER_TIMEOUT: P.InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT,
        MOUSE_ENTER: P.InteractionType.INTERACTION_TYPE_MOUSE_ENTER,
        MOUSE_LEAVE: P.InteractionType.INTERACTION_TYPE_MOUSE_LEAVE,
        MOUSE_UP: P.InteractionType.INTERACTION_TYPE_MOUSE_UP,
        MOUSE_DOWN: P.InteractionType.INTERACTION_TYPE_MOUSE_DOWN,
        ON_KEY_DOWN: P.InteractionType.INTERACTION_TYPE_ON_KEY_DOWN,
        ON_MEDIA_HIT: P.InteractionType.INTERACTION_TYPE_ON_MEDIA_HIT,
        ON_MEDIA_END: P.InteractionType.INTERACTION_TYPE_ON_MEDIA_END,
    }

    export const NavigationType: Record<MotiffApi.Navigation, P.NavigationType> = {
        NAVIGATE: P.NavigationType.NAVIGATION_TYPE_NAVIGATE,
        SWAP: P.NavigationType.NAVIGATION_TYPE_SWAP,
        OVERLAY: P.NavigationType.NAVIGATION_TYPE_OVERLAY,
        SCROLL_TO: P.NavigationType.NAVIGATION_TYPE_SCROLL_TO,
    }

    export const EasingType: Record<MotiffApi.Easing['type'], P.EasingType> = {
        EASE_IN: P.EasingType.EASING_TYPE_IN_CUBIC,
        EASE_OUT: P.EasingType.EASING_TYPE_OUT_CUBIC,
        EASE_IN_AND_OUT: P.EasingType.EASING_TYPE_INOUT_CUBIC,
        LINEAR: P.EasingType.EASING_TYPE_LINEAR,
        EASE_IN_BACK: P.EasingType.EASING_TYPE_IN_BACK_CUBIC,
        EASE_OUT_BACK: P.EasingType.EASING_TYPE_OUT_BACK_CUBIC,
        EASE_IN_AND_OUT_BACK: P.EasingType.EASING_TYPE_INOUT_BACK_CUBIC,
        CUSTOM_CUBIC_BEZIER: P.EasingType.EASING_TYPE_CUSTOM_CUBIC,
        GENTLE: P.EasingType.EASING_TYPE_GENTLE_SPRING,
        QUICK: P.EasingType.EASING_TYPE_SPRING_PRESET_ONE,
        BOUNCY: P.EasingType.EASING_TYPE_SPRING_PRESET_TWO,
        SLOW: P.EasingType.EASING_TYPE_SPRING_PRESET_THREE,
        CUSTOM_SPRING: P.EasingType.EASING_TYPE_CUSTOM_SPRING,
    }

    export const ScrollDirection: Record<MotiffApi.OverflowDirection, P.ScrollDirection> = {
        NONE: P.ScrollDirection.SCROLL_DIRECTION_NONE,
        HORIZONTAL: P.ScrollDirection.SCROLL_DIRECTION_HORIZONTAL,
        VERTICAL: P.ScrollDirection.SCROLL_DIRECTION_VERTICAL,
        BOTH: P.ScrollDirection.SCROLL_DIRECTION_BOTH,
    }
}

export const PrototypePropMapper = {
    RGB: {
        fromMotiff(t: Motiff.RGB): MotiffApi.RGB {
            return {
                r: t.r / 255,
                g: t.g / 255,
                b: t.b / 255,
            }
        },
        fromFigma(t: MotiffApi.RGB): Motiff.RGB {
            return {
                r: t.r * 255,
                g: t.g * 255,
                b: t.b * 255,
            }
        },
    },
    RGBA: {
        fromMotiff(t: Motiff.RGBA): MotiffApi.RGBA {
            return {
                r: t.r / 255,
                g: t.g / 255,
                b: t.b / 255,
                a: t.a / 255,
            }
        },
        fromFigma(t: MotiffApi.RGBA): Motiff.RGBA {
            return {
                r: t.r * 255,
                g: t.g * 255,
                b: t.b * 255,
                a: t.a * 255,
            }
        },
    },
    Transform: {
        fromMotiff(t: Motiff.Transform): MotiffApi.Transform {
            return [
                [t.scaleX, t.skewX, t.translateX],
                [t.skewY, t.scaleY, t.translateY],
            ]
        },
        fromFigma(t: MotiffApi.Transform): Motiff.Transform {
            return {
                scaleX: t[0][0],
                scaleY: t[1][1],
                skewX: t[0][1],
                skewY: t[1][0],
                translateX: t[0][2],
                translateY: t[1][2],
            }
        },
    },
    Paint: {
        fromMotiff(t: Motiff.Paint): MotiffApi.Paint {
            const paint = {
                type: PrototypePropMapper.PaintType.fromMotiff(t.type!)!,
                blendMode: PrototypePropMapper.BlendMode.fromMotiff(t.blendMode!),
                opacity: t.opacity!,
                visible: t.visible!,
            }

            switch (t.type) {
                case P.PaintType.PAINT_TYPE_SOLID_PAINT:
                    return PrototypePropMapper.SolidPaint.fromMotiff(t as Motiff.SolidPaint)
                case P.PaintType.PAINT_TYPE_GRADIENT_LINEAR:
                case P.PaintType.PAINT_TYPE_GRADIENT_RADIAL:
                case P.PaintType.PAINT_TYPE_GRADIENT_ANGULAR:
                case P.PaintType.PAINT_TYPE_GRADIENT_DIAMOND: {
                    return {
                        ...paint,
                        gradientTransform: PrototypePropMapper.Transform.fromMotiff(
                            (t as Motiff.GradientPaint).gradientTransform
                        ),
                        gradientStops: (t as Motiff.GradientPaint).gradientStops.map((colorStops) => {
                            return {
                                position: colorStops.position,
                                color: PrototypePropMapper.RGBA.fromMotiff(colorStops.color),
                            }
                        }),
                    } as MotiffApi.GradientPaint
                }
                case P.PaintType.PAINT_TYPE_IMAGE_PAINT: {
                    const imageT = t as Motiff.ImagePaint
                    return {
                        ...paint,
                        scaleMode: PrototypePropMapper.ScaleMode.fromMotiff(imageT.scaleMode!),
                        imageHash: imageT.imageHash!,
                        imageTransform: PrototypePropMapper.Transform.fromMotiff(imageT.imageTransform),
                        scalingFactor: imageT.scalingFactor!,
                        rotation: imageT.rotation!,
                        filters: imageT.filters!,
                    } as MotiffApi.ImagePaint
                }
            }
        },
        fromFigma(t: MotiffApi.Paint): Motiff.Paint {
            const paint = {
                blendMode: PrototypePropMapper.BlendMode.fromFigma(t.blendMode! || 'NORMAL'),
                opacity: t.opacity ?? 1,
                visible: t.visible ?? true,
                type: PrototypePropMapper.PaintType.fromFigma(t.type),
            }
            switch (t.type) {
                case 'SOLID': {
                    const solidT = t as MotiffApi.SolidPaint
                    return { ...paint, color: PrototypePropMapper.RGB.fromFigma(solidT.color) } as Motiff.SolidPaint
                }
                case 'GRADIENT_LINEAR':
                case 'GRADIENT_RADIAL':
                case 'GRADIENT_ANGULAR':
                case 'GRADIENT_DIAMOND':
                    return {
                        ...paint,
                        gradientTransform: PrototypePropMapper.Transform.fromFigma(
                            (t as MotiffApi.GradientPaint).gradientTransform
                        ),
                        gradientStops: (t as MotiffApi.GradientPaint).gradientStops.map((colorStop) => {
                            return {
                                position: colorStop.position,
                                color: PrototypePropMapper.RGBA.fromFigma(colorStop.color),
                            }
                        }),
                    } as Motiff.GradientPaint
                case 'IMAGE': {
                    const imageT = t as MotiffApi.ImagePaint
                    return {
                        ...paint,
                        scaleMode: PrototypePropMapper.ScaleMode.fromFigma(imageT.scaleMode || 'FILL'),
                        imageHash: imageT.imageHash!,
                        imageTransform: PrototypePropMapper.Transform.fromFigma(
                            imageT.imageTransform! || [
                                [1, 0, 0],
                                [0, 1, 0],
                            ]
                        ),
                        scalingFactor: imageT.scalingFactor! || 1,
                        rotation: imageT.rotation!,
                        filters: imageT.filters!,
                    } as Motiff.ImagePaint
                }
            }
        },
    },
    Paints: {
        fromMotiff(t: Motiff.Paint[]): MotiffApi.Paint[] {
            return t.map((i) => PrototypePropMapper.Paint.fromMotiff(i))
        },
        fromFigma(t: MotiffApi.Paint[]): Motiff.Paint[] {
            return t.map((i) => PrototypePropMapper.Paint.fromFigma(i))
        },
    },
    Effect: {
        fromMotiff(t: Motiff.Effect): MotiffApi.Effect {
            const effect = {
                type: PrototypePropMapper.EffectType.fromMotiff(t.type!)!,
                radius: t.radius,
                visible: t.visible,
            }

            switch (t.type) {
                case P.EffectType.EFFECT_TYPE_DROP_SHADOW:
                    return {
                        ...effect,
                        color: PrototypePropMapper.RGBA.fromMotiff(t.color),
                        offset: t.offset,
                        spread: t.spread,
                        blendMode: PrototypePropMapper.BlendMode.fromMotiff(t.blendMode!),
                        showShadowBehindNode: t.showShadowBehindNode,
                    } as MotiffApi.DropShadowEffect

                case P.EffectType.EFFECT_TYPE_INNER_SHADOW:
                    return {
                        ...effect,
                        color: PrototypePropMapper.RGBA.fromMotiff(t.color),
                        offset: t.offset as MotiffApi.Vector,
                        spread: t.spread,
                        blendMode: PrototypePropMapper.BlendMode.fromMotiff(t.blendMode!),
                    } as MotiffApi.InnerShadowEffect

                case P.EffectType.EFFECT_TYPE_LAYER_BLUR:
                case P.EffectType.EFFECT_TYPE_BACKGROUND_BLUR:
                    return {
                        ...effect,
                    } as MotiffApi.BlurEffect
            }
        },

        fromFigma(t: MotiffApi.Effect): Motiff.Effect {
            const effect = {
                type: PrototypePropMapper.EffectType.fromFigma(t.type!)!,
                radius: t.radius,
                visible: t.visible,
            }
            switch (t.type) {
                case 'DROP_SHADOW':
                    return {
                        ...effect,
                        color: PrototypePropMapper.RGBA.fromFigma(t.color),
                        offset: t.offset,
                        spread: t.spread,
                        blendMode: PrototypePropMapper.BlendMode.fromFigma(t.blendMode!),
                        showShadowBehindNode: t.showShadowBehindNode,
                    } as Motiff.DropShadowEffect

                case 'INNER_SHADOW': {
                    return {
                        ...effect,
                        color: PrototypePropMapper.RGBA.fromFigma(t.color),
                        offset: t.offset,
                        spread: t.spread,
                        blendMode: PrototypePropMapper.BlendMode.fromFigma(t.blendMode!),
                    } as Motiff.InnerShadowEffect
                }
                case 'LAYER_BLUR':
                case 'BACKGROUND_BLUR':
                    return {
                        ...effect,
                    } as Motiff.BlurEffect
            }
        },
    },

    Effects: {
        fromMotiff(t: Motiff.Effect[]): MotiffApi.Effect[] {
            return t.map((i) => PrototypePropMapper.Effect.fromMotiff(i))
        },
        fromFigma(t: MotiffApi.Effect[]): Motiff.Effect[] {
            return t.map((i) => PrototypePropMapper.Effect.fromFigma(i))
        },
    },
    // [how_to_add_plugin_api][7 of n][可选] 生成双向映射函数，以供后续调用
    NodeType: createMapperFromFigmaEnum(FigmaToMotiff.NodeType),
    StyleNodeType: createMapperFromFigmaEnum(FigmaToMotiff.StyleNodeType),
    TextAutoResize: createMapperFromFigmaEnum(FigmaToMotiff.TextAutoResize),
    TextAlignHorizontal: createMapperFromFigmaEnum(FigmaToMotiff.TextAlignHorizontal),
    TextAlignVertical: createMapperFromFigmaEnum(FigmaToMotiff.TextAlignVertical),
    TextTruncation: createMapperFromFigmaEnum(FigmaToMotiff.TextTruncation),
    TextCase: createMapperFromFigmaEnum(FigmaToMotiff.TextCase),
    TextDecoration: createMapperFromFigmaEnum(FigmaToMotiff.TextDecoration),
    NumberUnit: createMapperFromFigmaEnum(FigmaToMotiff.NumberUnit),
    LineHeightUnit: createMapperFromFigmaEnum(FigmaToMotiff.LineHeightUnit),
    LetterSpacing: {
        fromMotiff(t: Motiff.LetterSpacing): MotiffApi.LetterSpacing {
            return {
                value: t.value,
                unit: PrototypePropMapper.NumberUnit.fromMotiff(t.unit),
            }
        },
        fromFigma(t: MotiffApi.LetterSpacing): Motiff.LetterSpacing {
            return {
                value: t.value,
                unit: PrototypePropMapper.NumberUnit.fromFigma(t.unit),
            }
        },
    },

    ExportSettingConstraint: {
        fromMotiff(t: P.IExportConstraint): MotiffApi.ExportSettingsConstraints {
            t.value = t.value! / 1000

            switch (t.type) {
                case P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE:
                    return { type: 'SCALE', value: t.value }
                case P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH:
                    return { type: 'WIDTH', value: t.value }
                case P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT:
                    return { type: 'HEIGHT', value: t.value }
                default:
                    throw new Error(`not supported export constraint type: ${t.type}`)
            }
        },
        fromFigma(t: MotiffApi.ExportSettingsConstraints): P.ExportConstraint {
            switch (t.type) {
                case 'SCALE':
                    return {
                        type: P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_SCALE,
                        value: t.value * 1000,
                    } as P.ExportConstraint
                case 'WIDTH':
                    return {
                        type: P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_WIDTH,
                        value: t.value * 1000,
                    } as P.ExportConstraint
                case 'HEIGHT':
                    return {
                        type: P.ExportConstraintType.EXPORT_CONSTRAINT_TYPE_HEIGHT,
                        value: t.value * 1000,
                    } as P.ExportConstraint
                default:
                    throw new Error(`not supported export constraint type: ${t.type}`)
            }
        },
    },

    ExportSettingsFormat: {
        fromMotiff(t: P.ExportFormatType): 'JPG' | 'PNG' | 'AVIF' | 'WEBP' | 'SVG' | 'PDF' | 'NOT_SUPPORTED' {
            switch (t) {
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_WEBP:
                    return 'WEBP'
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_AVIF:
                    return 'AVIF'
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_JPG:
                    return 'JPG'
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_PNG:
                    return 'PNG'
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_SVG:
                    return 'SVG'
                case P.ExportFormatType.EXPORT_FORMAT_TYPE_PDF:
                    return 'PDF'
                default:
                    throw new Error(`Unknown export format type: ${t}`)
            }
        },
        fromFigma(t: string): P.ExportFormatType {
            switch (t) {
                case 'WEBP':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_WEBP
                case 'AVIF':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_AVIF
                case 'JPG':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_JPG
                case 'PNG':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_PNG
                case 'SVG':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_SVG
                case 'PDF':
                    return P.ExportFormatType.EXPORT_FORMAT_TYPE_PDF
                default:
                    throw new Error(`Unknown export format type: ${t}`)
            }
        },
    },

    LineHeight: {
        fromMotiff(t: Motiff.LineHeight): MotiffApi.LineHeight {
            return t.unit === P.NumberUnit.NUMBER_UNIT_AUTO
                ? { unit: 'AUTO' }
                : {
                      value: t.value,
                      unit: PrototypePropMapper.NumberUnit.fromMotiff(t.unit),
                  }
        },
        fromFigma(t: MotiffApi.LineHeight): Motiff.LineHeight {
            return t.unit === 'AUTO'
                ? { value: 0, unit: P.NumberUnit.NUMBER_UNIT_AUTO }
                : {
                      value: t.value,
                      unit: PrototypePropMapper.NumberUnit.fromFigma(t.unit),
                  }
        },
    },
    HyperlinkTargetType: createMapperFromFigmaEnum(FigmaToMotiff.HyperlinkTargetType),
    HyperlinkTarget: {
        fromMotiff(t: Motiff.HyperlinkTarget): MotiffApi.HyperlinkTarget {
            return {
                type: PrototypePropMapper.HyperlinkTargetType.fromMotiff(t.type),
                value: t.value,
            }
        },
        fromFigma(t: MotiffApi.HyperlinkTarget): Motiff.HyperlinkTarget {
            return {
                type: PrototypePropMapper.HyperlinkTargetType.fromFigma(t.type),
                value: t.value,
            }
        },
    },
    TextListOptionsType: createMapperFromFigmaEnum(FigmaToMotiff.TextListOptionsType),
    TextListOptions: {
        fromMotiffLineType(t: Motiff.LineType): MotiffApi.TextListOptions {
            const type =
                t === P.LineType.LINE_TYPE_ORDERED_LIST
                    ? 'ORDERED'
                    : t === P.LineType.LINE_TYPE_UNORDERED_LIST
                    ? 'UNORDERED'
                    : 'NONE'
            return { type }
        },
        fromMotiff(t: Motiff.TextListOptions): MotiffApi.TextListOptions {
            return { type: PrototypePropMapper.TextListOptionsType.fromMotiff(t.type) }
        },
        fromFigma(t: MotiffApi.TextListOptions): Motiff.TextListOptions {
            return { type: PrototypePropMapper.TextListOptionsType.fromFigma(t.type) }
        },
        fromFigmaOptions(t: MotiffApi.TextListOptions): Motiff.LineType {
            return t.type === 'ORDERED'
                ? P.LineType.LINE_TYPE_ORDERED_LIST
                : t.type === 'UNORDERED'
                ? P.LineType.LINE_TYPE_UNORDERED_LIST
                : P.LineType.LINE_TYPE_PLAIN
        },
    },

    LayoutMode: createMapperFromFigmaEnum(FigmaToMotiff.LayoutMode),
    CounterAxisAlignContent: createMapperFromFigmaEnum(FigmaToMotiff.CounterAxisAlignContent),
    LayoutWrap: createMapperFromFigmaEnum(FigmaToMotiff.LayoutWrap),
    StackSize: createMapperFromFigmaEnum(FigmaToMotiff.StackSize),
    StackAlign: createMapperFromFigmaEnum(FigmaToMotiff.StackAlign),
    StackJustify: createMapperFromFigmaEnum(FigmaToMotiff.StackJustify),
    StackCounterAlign: createMapperFromFigmaEnum(FigmaToMotiff.StackCounterAlign),
    StackPositioning: createMapperFromFigmaEnum(FigmaToMotiff.StackPositioning),
    ConstraintType: createMapperFromFigmaEnum(FigmaToMotiff.ConstraintType),
    GuideAxis: createMapperFromFigmaEnum(FigmaToMotiff.GuideAxis),
    BlendMode: createMapperFromFigmaEnum(FigmaToMotiff.BlendMode),
    ScaleMode: createMapperFromFigmaEnum(FigmaToMotiff.ScaleMode),
    PaintType: createMapperFromFigmaEnum(FigmaToMotiff.PaintType),
    EffectType: createMapperFromFigmaEnum(FigmaToMotiff.EffectType),
    InsertCharactersUseStyleType: createMapperFromFigmaEnum(FigmaToMotiff.InsertCharactersUseStyleType),
    StrokeCap: createMapperFromFigmaEnum(FigmaToMotiff.StrokeCap),
    StrokeAlign: createMapperFromFigmaEnum(FigmaToMotiff.StrokeAlign),
    StrokeJoin: createMapperFromFigmaEnum(FigmaToMotiff.StrokeJoin),
    HandleMirroring: createMapperFromFigmaEnum(FigmaToMotiff.HandleMirroring),
    WindingRule: createMapperFromFigmaEnum(FigmaToMotiff.WindingRule),
    LayoutGridPattern: createMapperFromFigmaEnum(FigmaToMotiff.LayoutGridPattern),
    LayoutGridAlignment: createMapperFromFigmaEnum(FigmaToMotiff.LayoutGridAlignment),
    LayoutGrids: {
        fromMotiff(ts: Motiff.LayoutGrid[]): MotiffApi.LayoutGrid[] {
            return ts.map((t) => {
                const color = { ...(t.paints as Motiff.SolidPaint).color, a: t.paints.opacity } as MotiffApi.RGBA
                switch (t.pattern) {
                    case P.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID:
                        return {
                            pattern: 'GRID',
                            sectionSize: t.sectionSize,
                            visible: t.visible,
                            color: color,
                        } as MotiffApi.GridLayoutGrid
                    case P.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS:
                    case P.LayoutGridPattern.LAYOUT_GRID_PATTERN_COLUMNS:
                        return {
                            pattern: t.pattern === P.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS ? 'ROWS' : 'COLUMNS',
                            alignment: PrototypePropMapper.LayoutGridAlignment.fromMotiff(t.align),
                            gutterSize: t.gutterSize,
                            count: t.count,
                            sectionSize: t.sectionSize,
                            offset: t.offset,
                            visible: t.visible,
                            color: color,
                        } as MotiffApi.RowsColsLayoutGrid
                }
            }) as MotiffApi.LayoutGrid[]
        },
        fromFigma(ts: MotiffApi.LayoutGrid[]): Motiff.LayoutGrid[] {
            return ts.map((t) => {
                const paints = {
                    color: { r: t.color?.r!, g: t.color?.g!, b: t.color?.b! },
                    opacity: t.color?.a!,
                    type: P.PaintType.PAINT_TYPE_SOLID_PAINT,
                    visible: true,
                    blendMode: P.BlendMode.BLEND_MODE_NORMAL,
                }
                switch (t.pattern) {
                    case 'GRID':
                        return {
                            pattern: P.LayoutGridPattern.LAYOUT_GRID_PATTERN_GRID,
                            sectionSize: t.sectionSize,
                            visible: t.visible ?? true,
                            paints: paints,
                        } as unknown as Motiff.LayoutGrid
                    case 'ROWS':
                    case 'COLUMNS':
                        return {
                            pattern:
                                t.pattern === 'ROWS'
                                    ? P.LayoutGridPattern.LAYOUT_GRID_PATTERN_ROWS
                                    : P.LayoutGridPattern.LAYOUT_GRID_PATTERN_COLUMNS,
                            align: PrototypePropMapper.LayoutGridAlignment.fromFigma(t.alignment),
                            gutterSize: t.gutterSize,
                            count: t.count,
                            sectionSize: t.sectionSize ?? 10,
                            offset: t.offset ?? 0,
                            visible: t.visible ?? true,
                            paints: paints,
                        } as unknown as Motiff.LayoutGrid
                }
            }) as Motiff.LayoutGrid[]
        },
    },
    Vertices: {
        fromMotiff(t: Motiff.VectorVertex[]): MotiffApi.VectorVertex[] {
            return t.map(
                (i) =>
                    ({
                        x: i.x,
                        y: i.y,
                        strokeCap: PrototypePropMapper.StrokeCap.fromMotiff(i.strokeCap ?? P.StrokeCap.STROKE_CAP_NONE),
                        strokeJoin: PrototypePropMapper.StrokeJoin.fromMotiff(
                            i.strokeJoin ?? P.StrokeJoin.STROKE_JOIN_MITER
                        ),
                        cornerRadius: i.cornerRadius,
                        handleMirroring: PrototypePropMapper.HandleMirroring.fromMotiff(
                            i.handleMirroring ?? P.VectorHandleMirror.VECTOR_HANDLE_MIRROR_NONE
                        ),
                    } as MotiffApi.VectorVertex)
            )
        },
        fromFigma(t: MotiffApi.VectorVertex[]): Motiff.VectorVertex[] {
            return t.map(
                (i) =>
                    ({
                        x: i.x,
                        y: i.y,
                        strokeCap: PrototypePropMapper.StrokeCap.fromFigma(i.strokeCap ?? 'NONE'),
                        strokeJoin: PrototypePropMapper.StrokeJoin.fromFigma(i.strokeJoin ?? 'MITER'),
                        cornerRadius: i.cornerRadius,
                        handleMirroring: PrototypePropMapper.HandleMirroring.fromFigma(i.handleMirroring ?? 'NONE'),
                    } as Motiff.VectorVertex)
            )
        },
    },

    Segments: {
        fromMotiff(t: Motiff.VectorSegment[]): MotiffApi.VectorSegment[] {
            return t.map((i) => ({
                start: i.start,
                end: i.end,
                tangentStart: i.tangentStart ?? { x: 0, y: 0 },
                tangentEnd: i.tangentEnd ?? { x: 0, y: 0 },
            }))
        },
        fromFigma(t: MotiffApi.VectorSegment[]): Motiff.VectorSegment[] {
            return t.map((i) => ({
                start: i.start,
                end: i.end,
                tangentStart: i.tangentStart ?? { x: 0, y: 0 },
                tangentEnd: i.tangentEnd ?? { x: 0, y: 0 },
            }))
        },
    },

    Regions: {
        fromMotiff(t: Motiff.VectorRegion[]): MotiffApi.VectorRegion[] {
            return t.map(
                (i) =>
                    ({
                        windingRule: PrototypePropMapper.WindingRule.fromMotiff(i.windingRule),
                        loops: i.loops.map((j) => [...j.segmentIndices]),
                        fills: i.fills ? PrototypePropMapper.Paints.fromMotiff(i.fills) : [],
                        fillStyleId: i.fillStyleId ?? '',
                    } as MotiffApi.VectorRegion)
            )
        },
        fromFigma(t: MotiffApi.VectorRegion[]): Motiff.VectorRegion[] {
            return t.map(
                (i) =>
                    ({
                        windingRule: PrototypePropMapper.WindingRule.fromFigma(i.windingRule),
                        loops: i.loops.map((j) => ({ segmentIndices: [...j] } as P.VectorNetworkLoop)),
                        // @zangbingjie, 暂不支持对每个region设置颜色，此处暂时不转换 fills、fillStyleId 字段
                    } as Motiff.VectorRegion)
            )
        },
    },
    VectorNetwork: {
        fromMotiff(t: Motiff.VectorNetwork): MotiffApi.VectorNetwork {
            return {
                vertices: PrototypePropMapper.Vertices.fromMotiff(t.vertices),
                segments: PrototypePropMapper.Segments.fromMotiff(t.segments),
                regions: t.regions ? PrototypePropMapper.Regions.fromMotiff(t.regions) : undefined,
            }
        },
        fromFigma(t: MotiffApi.VectorNetwork): Motiff.VectorNetwork {
            return {
                vertices: PrototypePropMapper.Vertices.fromFigma(t.vertices),
                segments: PrototypePropMapper.Segments.fromFigma(t.segments),
                regions: t.regions ? PrototypePropMapper.Regions.fromFigma(t.regions) : undefined,
            }
        },
    },
    VectorPaths: {
        fromMotiff(t: Motiff.ApiVectorPath[]): MotiffApi.VectorPaths {
            return t.map((i) => ({
                windingRule: isNil(i.windingRule) ? 'NONE' : PrototypePropMapper.WindingRule.fromMotiff(i.windingRule),
                data: i.data,
            }))
        },
        fromFigma(t: MotiffApi.VectorPaths): Motiff.ApiVectorPath[] {
            return t.map((i) => ({
                windingRule:
                    i.windingRule === 'NONE' ? undefined : PrototypePropMapper.WindingRule.fromFigma(i.windingRule),
                data: i.data,
            }))
        },
    },
    SelectedTextRange: {
        fromMotiff(t: Motiff.TextRange | null): { node: MotiffApi.TextNode; start: number; end: number } | null {
            if (isNil(t)) {
                return null
            }
            return {
                node: motiff.getNodeById(t.nodeId) as MotiffApi.TextNode,
                start: t.start,
                end: t.end,
            }
        },
        fromFigma(t: { node: NodeWithId; start: number; end: number } | null): Motiff.TextRange | null {
            if (isNil(t)) {
                return null
            }
            return {
                nodeId: t.node.id,
                start: t.start,
                end: t.end,
            } as Motiff.TextRange
        },
    },
    BooleanOperation: createMapperFromFigmaEnum(FigmaToMotiff.BooleanOperation),
    DocumentColorProfile: createMapperFromFigmaEnum(FigmaToMotiff.DocumentColorProfile),
    StyledTextSegmentExt: {
        fromMotiff(t: Motiff.StyledTextSegment): MotiffApi.StyledTextSegment {
            return {
                characters: t.characters,
                start: t.start,
                end: t.end,
                fontSize: t.fontSize,
                fontName: t.fontName,
                fontWeight: t.fontWeight,
                textDecoration: PrototypePropMapper.TextDecoration.fromMotiff(t.textDecoration),
                textCase: PrototypePropMapper.TextCase.fromMotiff(t.textCase),
                letterSpacing: PrototypePropMapper.LetterSpacing.fromMotiff(t.letterSpacing),
                lineHeight: PrototypePropMapper.LineHeight.fromMotiff(t.lineHeight),
                fillStyleId: t.fillStyleId,
                fills: PrototypePropMapper.Paints.fromMotiff(t.fills as Motiff.Paint[]),
                textStyleId: t.textStyleId,
                listOptions: PrototypePropMapper.TextListOptions.fromMotiff(t.listOptions),
                hyperlink: t.hyperlink ? PrototypePropMapper.HyperlinkTarget.fromMotiff(t.hyperlink) : null,
                indentation: t.indentation,
                openTypeFeatures: {} as { [_feature in MotiffApi.OpenTypeFeature]: boolean },
            }
        },
        fromFigma(_t: MotiffApi.StyledTextSegment): Motiff.StyledTextSegment {
            throw new Error('Not implemented')
        },
    },
    TriggerType: createMapperFromFigmaEnum(FigmaToMotiff.TriggerType),
    NavigationType: createMapperFromFigmaEnum(FigmaToMotiff.NavigationType),
    EasingType: createMapperFromFigmaEnum(FigmaToMotiff.EasingType),
    Reactions: {
        fromMotiff(t: Motiff.PrototypeInteraction[]): ReadonlyArray<MotiffApi.Reaction> {
            return t.map((i) => PrototypePropMapper.Reaction.fromMotiff(i))
        },
        fromFigma(t: ReadonlyArray<MotiffApi.Reaction>): Motiff.PrototypeInteraction[] {
            return t.map((i) => PrototypePropMapper.Reaction.fromFigma(i))
        },
    },

    Easing: {
        fromMotiff(action: Motiff.PrototypeAction): MotiffApi.Easing {
            const ret: {
                type: MotiffApi.Easing['type']
                easingFunctionCubicBezier?: MotiffApi.EasingFunctionBezier
                easingFunctionSpring?: MotiffApi.EasingFunctionSpring
            } = {
                type: PrototypePropMapper.EasingType.fromMotiff(action.easingType),
            }

            if (action.easingType === P.EasingType.EASING_TYPE_CUSTOM_CUBIC) {
                ret.easingFunctionCubicBezier = {
                    x1: action.easingFunction[0] ?? 0,
                    y1: action.easingFunction[1] ?? 0,
                    x2: action.easingFunction[2] ?? 0,
                    y2: action.easingFunction[3] ?? 0,
                }
            }

            if (action.easingType === P.EasingType.EASING_TYPE_CUSTOM_SPRING) {
                ret.easingFunctionSpring = {
                    mass: action.easingFunction[0] ?? 0,
                    stiffness: action.easingFunction[1] ?? 0,
                    damping: action.easingFunction[2] ?? 0,
                    initialVelocity: action.easingFunction[3] ?? 0,
                }
            }
            return ret
        },
        fromFigma(action: MotiffApi.Easing): Pick<Motiff.PrototypeAction, 'easingType' | 'easingFunction'> {
            const easingFunction: number[] = []
            if (action.type === 'CUSTOM_CUBIC_BEZIER') {
                easingFunction.push(action.easingFunctionCubicBezier?.x1 ?? 0)
                easingFunction.push(action.easingFunctionCubicBezier?.y1 ?? 0)
                easingFunction.push(action.easingFunctionCubicBezier?.x2 ?? 0)
                easingFunction.push(action.easingFunctionCubicBezier?.y2 ?? 0)
            } else if (action.type === 'CUSTOM_SPRING') {
                easingFunction.push(action.easingFunctionSpring?.mass ?? 0)
                easingFunction.push(action.easingFunctionSpring?.stiffness ?? 0)
                easingFunction.push(action.easingFunctionSpring?.damping ?? 0)
                easingFunction.push(action.easingFunctionSpring?.initialVelocity ?? 0)
            }
            return {
                easingType: PrototypePropMapper.EasingType.fromFigma(action.type),
                easingFunction,
            }
        },
    },
    Transition: {
        fromMotiff(action: Motiff.PrototypeAction): MotiffApi.Transition | null {
            const getSimpleTransition = (type: MotiffApi.SimpleTransition['type']) => {
                return {
                    type,
                    easing: PrototypePropMapper.Easing.fromMotiff(action),
                    duration: action.transitionDuration,
                }
            }

            const getDirectionalTransition = (
                type: MotiffApi.DirectionalTransition['type'],
                direction: MotiffApi.DirectionalTransition['direction']
            ) => {
                return {
                    type,
                    direction,
                    matchLayers: action.transitionShouldSmartAnimate,
                    easing: PrototypePropMapper.Easing.fromMotiff(action),
                    duration: action.transitionDuration,
                }
            }

            switch (action.transitionType) {
                case P.TransitionType.TRANSITION_TYPE_DISSOLVE:
                    return getSimpleTransition('DISSOLVE')
                case P.TransitionType.TRANSITION_TYPE_SMART_ANIMATE:
                    return getSimpleTransition('SMART_ANIMATE')
                case P.TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE:
                    return getSimpleTransition('SCROLL_ANIMATE')
                case P.TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT:
                    return getDirectionalTransition('MOVE_IN', 'LEFT')
                case P.TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT:
                    return getDirectionalTransition('MOVE_IN', 'RIGHT')
                case P.TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP:
                    return getDirectionalTransition('MOVE_IN', 'TOP')
                case P.TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM:
                    return getDirectionalTransition('MOVE_IN', 'BOTTOM')
                case P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT:
                    return getDirectionalTransition('MOVE_OUT', 'LEFT')
                case P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT:
                    return getDirectionalTransition('MOVE_OUT', 'RIGHT')
                case P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP:
                    return getDirectionalTransition('MOVE_OUT', 'TOP')
                case P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM:
                    return getDirectionalTransition('MOVE_OUT', 'BOTTOM')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT:
                    return getDirectionalTransition('SLIDE_IN', 'LEFT')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT:
                    return getDirectionalTransition('SLIDE_IN', 'RIGHT')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP:
                    return getDirectionalTransition('SLIDE_IN', 'TOP')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM:
                    return getDirectionalTransition('SLIDE_IN', 'BOTTOM')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT:
                    return getDirectionalTransition('SLIDE_OUT', 'LEFT')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT:
                    return getDirectionalTransition('SLIDE_OUT', 'RIGHT')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP:
                    return getDirectionalTransition('SLIDE_OUT', 'TOP')
                case P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM:
                    return getDirectionalTransition('SLIDE_OUT', 'BOTTOM')
                case P.TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT:
                    return getDirectionalTransition('PUSH', 'LEFT')
                case P.TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT:
                    return getDirectionalTransition('PUSH', 'RIGHT')
                case P.TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP:
                    return getDirectionalTransition('PUSH', 'TOP')
                case P.TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM:
                    return getDirectionalTransition('PUSH', 'BOTTOM')
                case P.TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION:
                case P.TransitionType.TRANSITION_TYPE_FADE:
                case P.TransitionType.TRANSITION_TYPE_MAGIC_MOVE:
                    return null
            }
        },

        fromFigma(
            transition: MotiffApi.Transition
        ): Partial<
            Pick<
                Motiff.PrototypeAction,
                | 'transitionType'
                | 'transitionDuration'
                | 'transitionShouldSmartAnimate'
                | 'easingType'
                | 'easingFunction'
            >
        > {
            const getFromSimpleTransition = (transitionType: P.TransitionType, simple: MotiffApi.SimpleTransition) => {
                return {
                    transitionType,
                    ...PrototypePropMapper.Easing.fromFigma(simple.easing),
                    transitionDuration: simple.duration,
                }
            }
            const getDirectionalTransition = (
                transitionType: P.TransitionType,
                directional: MotiffApi.DirectionalTransition
            ) => {
                return {
                    transitionType,
                    ...PrototypePropMapper.Easing.fromFigma(directional.easing),
                    transitionShouldSmartAnimate: directional.matchLayers,
                    transitionDuration: directional.duration,
                }
            }

            switch (transition.type) {
                case 'DISSOLVE':
                    return getFromSimpleTransition(P.TransitionType.TRANSITION_TYPE_DISSOLVE, transition)
                case 'SMART_ANIMATE':
                    return getFromSimpleTransition(P.TransitionType.TRANSITION_TYPE_SMART_ANIMATE, transition)
                case 'SCROLL_ANIMATE':
                    return getFromSimpleTransition(P.TransitionType.TRANSITION_TYPE_SCROLL_ANIMATE, transition)
                case 'MOVE_IN':
                    return getDirectionalTransition(TRANSITION_TYPE_MAP.MOVE_IN[transition.direction], transition)
                case 'MOVE_OUT':
                    return getDirectionalTransition(TRANSITION_TYPE_MAP.MOVE_OUT[transition.direction], transition)
                case 'PUSH':
                    return getDirectionalTransition(TRANSITION_TYPE_MAP.PUSH[transition.direction], transition)
                case 'SLIDE_IN':
                    return getDirectionalTransition(TRANSITION_TYPE_MAP.SLIDE_IN[transition.direction], transition)
                case 'SLIDE_OUT':
                    return getDirectionalTransition(TRANSITION_TYPE_MAP.SLIDE_OUT[transition.direction], transition)
            }

            return {} as any
        },
    },
    Action: {
        fromMotiff(action: Motiff.PrototypeAction): MotiffApi.Action | null {
            switch (action.connectionType) {
                case P.ConnectionType.CONNECTION_TYPE_NONE:
                    return null
                case P.ConnectionType.CONNECTION_TYPE_INTERNAL_NODE:
                    return {
                        type: 'NODE',
                        destinationId: action.transitionNodeID ?? '',
                        navigation: PrototypePropMapper.NavigationType.fromMotiff(action.navigationType),
                        transition: PrototypePropMapper.Transition.fromMotiff(action),
                        overlayRelativePosition: {
                            x: action.overlayRelativePosition.x ?? 0,
                            y: action.overlayRelativePosition.y ?? 0,
                        },
                        resetScrollPosition: action.transitionResetScrollPosition,
                        resetVideoPosition: action.transitionResetVideoPosition,
                        resetInteractiveComponents: action.transitionResetInteractiveComponents,
                    }
                case P.ConnectionType.CONNECTION_TYPE_URL:
                    return {
                        type: 'URL',
                        url: action.connectionURL,
                    }
                case P.ConnectionType.CONNECTION_TYPE_BACK:
                    return {
                        type: 'BACK',
                    }
                case P.ConnectionType.CONNECTION_TYPE_CLOSE:
                    return {
                        type: 'CLOSE',
                    }
                case P.ConnectionType.CONNECTION_TYPE_SET_VARIABLE:
                case P.ConnectionType.CONNECTION_TYPE_UPDATE_MEDIA_RUNTIME:
                case P.ConnectionType.CONNECTION_TYPE_CONDITIONAL:
                    // 目前不支持
                    return null
            }
        },
        fromFigma(action: MotiffApi.Action): Motiff.PrototypeAction {
            const result = getDefaultPrototypeAction()
            switch (action.type) {
                case 'URL':
                    result.connectionType = P.ConnectionType.CONNECTION_TYPE_URL
                    result.connectionURL = action.url
                    return result
                case 'NODE': {
                    result.connectionType = P.ConnectionType.CONNECTION_TYPE_INTERNAL_NODE
                    result.transitionNodeID = action.destinationId ?? ''
                    result.navigationType = PrototypePropMapper.NavigationType.fromFigma(action.navigation)
                    if (action.transition) {
                        Object.assign(result, PrototypePropMapper.Transition.fromFigma(action.transition))
                    }
                    result.overlayRelativePosition = action.overlayRelativePosition ?? { x: 0, y: 0 }
                    result.transitionResetScrollPosition = action.resetScrollPosition ?? false
                    result.transitionResetVideoPosition = action.resetVideoPosition ?? false
                    result.transitionResetInteractiveComponents = action.resetInteractiveComponents ?? false
                    return result
                }
                case 'BACK':
                    result.connectionType = P.ConnectionType.CONNECTION_TYPE_BACK
                    return result
                case 'CLOSE':
                    result.connectionType = P.ConnectionType.CONNECTION_TYPE_CLOSE
                    return result
                case 'UPDATE_MEDIA_RUNTIME':
                case 'SET_VARIABLE':
                case 'CONDITIONAL':
                    // 目前不支持
                    result.connectionType = P.ConnectionType.CONNECTION_TYPE_NONE
                    return result
            }
        },
    },
    Trigger: {
        fromMotiff(event: Motiff.PrototypeEvent): MotiffApi.Trigger | null {
            switch (event.interactionType) {
                case P.InteractionType.INTERACTION_TYPE_ON_CLICK:
                    return { type: 'ON_CLICK' }
                case P.InteractionType.INTERACTION_TYPE_ON_HOVER:
                    return { type: 'ON_HOVER' }
                case P.InteractionType.INTERACTION_TYPE_ON_PRESS:
                    return { type: 'ON_PRESS' }
                case P.InteractionType.INTERACTION_TYPE_DRAG:
                    return { type: 'ON_DRAG' }
                case P.InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT:
                    return {
                        type: 'AFTER_TIMEOUT',
                        timeout: event.transitionTimeout,
                    }
                case P.InteractionType.INTERACTION_TYPE_MOUSE_ENTER:
                    return {
                        type: 'MOUSE_ENTER',
                        delay: event.interactionDuration,
                    }
                case P.InteractionType.INTERACTION_TYPE_MOUSE_LEAVE:
                    return {
                        type: 'MOUSE_LEAVE',
                        delay: event.interactionDuration,
                    }
                case P.InteractionType.INTERACTION_TYPE_MOUSE_UP:
                    return {
                        type: 'MOUSE_UP',
                        delay: event.interactionDuration,
                    }
                case P.InteractionType.INTERACTION_TYPE_MOUSE_DOWN:
                    return {
                        type: 'MOUSE_ENTER',
                        delay: event.interactionDuration,
                    }
                case P.InteractionType.INTERACTION_TYPE_NONE:
                    return null
                case P.InteractionType.INTERACTION_TYPE_ON_KEY_DOWN:
                case P.InteractionType.INTERACTION_TYPE_ON_VOICE:
                case P.InteractionType.INTERACTION_TYPE_MOUSE_IN:
                case P.InteractionType.INTERACTION_TYPE_MOUSE_OUT:
                case P.InteractionType.INTERACTION_TYPE_ON_MEDIA_HIT:
                case P.InteractionType.INTERACTION_TYPE_ON_MEDIA_END:
                    // 目前不支持
                    return null
            }
        },
        fromFigma(trigger: MotiffApi.Trigger | null): Motiff.PrototypeEvent {
            const event = {
                interactionType: P.InteractionType.INTERACTION_TYPE_NONE,
                interactionMaintained: false,
                interactionDuration: 0,
                transitionTimeout: 0,
                keyTrigger: {
                    keyCodes: [],
                    triggerDevice: 0,
                },
                voiceEventPhrase: '',
                mediaHitTime: 0,
            }

            if (trigger !== null) {
                switch (trigger.type) {
                    case 'ON_CLICK':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_ON_CLICK
                        break
                    case 'ON_HOVER':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_ON_HOVER
                        break
                    case 'ON_PRESS':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_ON_PRESS
                        break
                    case 'ON_DRAG':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_DRAG
                        break
                    case 'AFTER_TIMEOUT':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_AFTER_TIMEOUT
                        event.transitionTimeout = trigger.timeout
                        break
                    case 'MOUSE_ENTER':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_MOUSE_ENTER
                        event.interactionDuration = trigger.delay
                        break
                    case 'MOUSE_LEAVE':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_MOUSE_LEAVE
                        event.interactionDuration = trigger.delay
                        break
                    case 'MOUSE_UP':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_MOUSE_UP
                        event.interactionDuration = trigger.delay
                        break
                    case 'MOUSE_DOWN':
                        event.interactionType = P.InteractionType.INTERACTION_TYPE_MOUSE_DOWN
                        event.interactionDuration = trigger.delay
                        break
                    case 'ON_KEY_DOWN':
                    case 'ON_MEDIA_HIT':
                    case 'ON_MEDIA_END':
                        // 不支持
                        break
                }
            }
            return event
        },
    },
    Reaction: {
        fromMotiff(interaction: Motiff.PrototypeInteraction): MotiffApi.Reaction {
            const actions = interaction.actions
                .map((action) => PrototypePropMapper.Action.fromMotiff(action))
                .filter((action): action is NonNullable<typeof action> => action !== null && action !== undefined)

            const trigger = PrototypePropMapper.Trigger.fromMotiff(interaction.event)

            return actions.length === 0 ? { trigger } : { trigger, action: actions[0], actions: actions }
        },

        fromFigma(reaction: MotiffApi.Reaction): Motiff.PrototypeInteraction {
            const actions = (reaction.actions ?? (reaction.action ? [reaction.action] : [])).map((action) =>
                PrototypePropMapper.Action.fromFigma(action)
            )

            return {
                event: PrototypePropMapper.Trigger.fromFigma(reaction.trigger),
                actions: actions.length === 0 ? [getDefaultPrototypeAction()] : actions,
                stateManagementVersion: 0,
                isDeleted: false,
            }
        },
    },
    ScrollDirection: createMapperFromFigmaEnum(FigmaToMotiff.ScrollDirection),
    SolidPaint: {
        fromMotiff(t: Motiff.SolidPaint): MotiffApi.SolidPaint {
            const paint = {
                type: PrototypePropMapper.PaintType.fromMotiff(t.type!)!,
                blendMode: PrototypePropMapper.BlendMode.fromMotiff(t.blendMode!),
                opacity: t.opacity!,
                visible: t.visible!,
                color: PrototypePropMapper.RGB.fromMotiff((t as Motiff.SolidPaint).color!),
                boundVariables: {},
            } as MotiffApi.SolidPaint
            if (t.colorVar) {
                paint.boundVariables!.color = {
                    type: 'VARIABLE_ALIAS',
                    id: t.colorVar.value.alias!,
                }
            }
            return paint
        },
        fromFigma(t: MotiffApi.SolidPaint): Motiff.SolidPaint {
            const paint = {
                blendMode: PrototypePropMapper.BlendMode.fromFigma(t.blendMode! || 'NORMAL'),
                opacity: t.opacity ?? 1,
                visible: t.visible ?? true,
                type: PrototypePropMapper.PaintType.fromFigma(t.type),
                color: PrototypePropMapper.RGB.fromFigma(t.color),
            } as Motiff.SolidPaint
            if (t.boundVariables?.color) {
                paint.colorVar = {
                    dataType: P.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
                    resolvedDataType: P.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_COLOR,
                    value: {
                        alias: t.boundVariables.color.id,
                    },
                }
            }
            return paint
        },
    },
}

function getDefaultPrototypeAction(): Motiff.PrototypeAction {
    return {
        easingFunction: [],
        transitionType: P.TransitionType.TRANSITION_TYPE_INSTANT_TRANSITION,
        transitionDuration: 300,
        easingType: P.EasingType.EASING_TYPE_OUT_CUBIC,
        transitionTimeout: 0,
        transitionShouldSmartAnimate: false,
        connectionType: P.ConnectionType.CONNECTION_TYPE_NONE,
        connectionURL: '',
        overlayRelativePosition: {
            x: 0,
            y: 0,
        },
        navigationType: P.NavigationType.NAVIGATION_TYPE_NAVIGATE,
        transitionPreserveScroll: false,
        extraScrollOffset: {
            x: 0,
            y: 0,
        },
        mediaAction: 0,
        transitionResetVideoPosition: false,
        openUrlInNewTab: true,
        mediaSkipToTime: 0,
        mediaSkipByAmount: 0,
        transitionResetScrollPosition: false,
        transitionResetInteractiveComponents: false,
        transitionNodeID: '',
    }
}

const TRANSITION_TYPE_MAP = {
    MOVE_IN: {
        LEFT: P.TransitionType.TRANSITION_TYPE_MOVE_FROM_LEFT,
        RIGHT: P.TransitionType.TRANSITION_TYPE_MOVE_FROM_RIGHT,
        TOP: P.TransitionType.TRANSITION_TYPE_MOVE_FROM_TOP,
        BOTTOM: P.TransitionType.TRANSITION_TYPE_MOVE_FROM_BOTTOM,
    },
    MOVE_OUT: {
        LEFT: P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_LEFT,
        RIGHT: P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_RIGHT,
        TOP: P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_TOP,
        BOTTOM: P.TransitionType.TRANSITION_TYPE_MOVE_OUT_TO_BOTTOM,
    },
    PUSH: {
        LEFT: P.TransitionType.TRANSITION_TYPE_PUSH_FROM_LEFT,
        RIGHT: P.TransitionType.TRANSITION_TYPE_PUSH_FROM_RIGHT,
        TOP: P.TransitionType.TRANSITION_TYPE_PUSH_FROM_TOP,
        BOTTOM: P.TransitionType.TRANSITION_TYPE_PUSH_FROM_BOTTOM,
    },
    SLIDE_IN: {
        LEFT: P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_LEFT,
        RIGHT: P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_RIGHT,
        TOP: P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_TOP,
        BOTTOM: P.TransitionType.TRANSITION_TYPE_SLIDE_FROM_BOTTOM,
    },
    SLIDE_OUT: {
        LEFT: P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_LEFT,
        RIGHT: P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_RIGHT,
        TOP: P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_TOP,
        BOTTOM: P.TransitionType.TRANSITION_TYPE_SLIDE_OUT_TO_BOTTOM,
    },
}
