import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    'Re-center': '返回选中',
} as const

export const enTranslation = {
    'Re-center': 'Re-center',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
