import { WKMenuConfig } from '../../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../../kernel/switch/hooks'
import { translation } from './debugger.translation'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { useCommand } from '../../../../context/document-context'
import { UpdateMotiffScopeSettingsWasmCall } from '@wukong/bridge-proto'
import { CommitType } from '../../../../../document/command/commit-type'
import { useMenuContextState } from '../context/menu-context'

export const useMotiffDebugger = (): WKMenuConfig => {
    const motiffDebuggerEnabled = useFeatureSwitch('motiff-debugger')
    const { showMotiffScope, showWasmCallMonitor, setShowWasmCallMonitor } = useMenuContextState()
    const command = useCommand()

    return [
        {
            name: translation('Debugger'),
            position: 'right top',
            testId: 'motiff-debugger',
            hidden: !motiffDebuggerEnabled,
            children: [
                {
                    name: translation('MotiffScope'),
                    shortCut: shortcutLabelMap[ShortcutKey.ShowMotiffScope],
                    activity: showMotiffScope,
                    testId: 'motiff-scope',
                    disable: false,
                    handler: () => {
                        command.invokeBridge(CommitType.Noop, UpdateMotiffScopeSettingsWasmCall, {
                            updatedShowMainPanel: !showMotiffScope,
                        })
                    },
                },
                {
                    name: translation('WasmCallMonitor'),
                    activity: showWasmCallMonitor,
                    testId: 'motiff-scope',
                    disable: false,
                    handler: () => {
                        setShowWasmCallMonitor(!showWasmCallMonitor)
                    },
                },
            ],
        },
    ]
}
