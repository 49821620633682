import { translation } from './effect-edit-v2.translation'
/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Checkbox, MonoIconPanelCoordinateX16, MonoIconPanelCoordinateY16 } from '../../../../../../ui-lib/src'
import { RGB, SolidPaint } from '../../../../document/node/node'
import { ToKeyCode } from '../../../../document/util/keycode'
import { featureSwitchManager } from '../../../../kernel/switch'
import { KeyboardReceiver } from '../../../../main/keyboard-receiver/component'
import { PureBlock } from '../../atom/color-block/pure'
import { SingleGrid } from '../../atom/grid/single-grid'
import { InputOptionsForUndoSquash } from '../../atom/inputs/components/formatted-input'
import { HexInput } from '../../atom/inputs/hex-input'
import { ScrubbableInputNumber } from '../../atom/inputs/scrubbable-input-number'
import { ScrubbableInputPercent } from '../../atom/inputs/scrubbable-input-percent'
import { Value } from '../../atom/inputs/utils/type'
import { ColorVarItem } from '../../color-var-item'
import { ColorSpace, hex2rgb, rgb2hex } from '../blend/color-picker/utils/color-translate'
import { ColorInteraction } from '../color-interaction/color-interaction'
import { ColorInteractionFrom } from '../color-interaction/type'
import { useFloatVariablePanel } from '../primitive-variable/use-float-variable-panel'
import style from './effect-edit-v2.module.less'

import PaintType = Wukong.DocumentProto.PaintType
import EffectType = Wukong.DocumentProto.EffectType

export interface EffectEditV2Props {
    data: Wukong.DocumentProto.IEffect
    colorSpace: ColorSpace
    enableSpread?: boolean
    variables?: Wukong.DocumentProto.ISingleVariable[]
    floatVariables?: Wukong.DocumentProto.ISingleFloatVariable[]
    onChangeEffect?: (obj: { [key: string]: any }, options?: InputOptionsForUndoSquash) => void
    onDetachColorVar?: () => void
}

export function EffectEditV2(props: EffectEditV2Props) {
    return featureSwitchManager.isEnabled('float-variable') ? (
        <EffectEditNew {...props} />
    ) : (
        <EffectEditOld {...props} />
    )
}

function EffectEditOld(props: EffectEditV2Props) {
    const { onChangeEffect, onDetachColorVar, data, enableSpread: _enableSpread, variables } = props
    const enableSpread = _enableSpread ?? true

    const [openColorPopup, setOpenColorPopup] = useState<boolean>(false)
    const isDestroy = useRef<boolean>(false)
    const [blurIconWidth, setBlurIconWidth] = useState<number>(0)
    const [spreadIconWidth, setSpreadIconWidth] = useState<number>(0)

    const blurSpreadStyle = useMemo((): React.CSSProperties => {
        return {
            minWidth: Math.max(blurIconWidth, spreadIconWidth),
        }
    }, [blurIconWidth, spreadIconWidth])

    const blurIconRef = useCallback((el: HTMLSpanElement | null) => {
        setBlurIconWidth(el?.clientWidth ?? 0)
    }, [])

    const spreadIconRef = useCallback((el: HTMLSpanElement | null) => {
        setSpreadIconWidth(el?.clientWidth ?? 0)
    }, [])

    const isShadowEffect = useMemo(() => {
        return data.type === EffectType.EFFECT_TYPE_INNER_SHADOW || data.type === EffectType.EFFECT_TYPE_DROP_SHADOW
    }, [data])

    const colorContainerRef = useRef<HTMLDivElement>(null)
    const colorData = useMemo(() => {
        if (isShadowEffect) {
            return {
                type: PaintType.PAINT_TYPE_SOLID_PAINT,
                color: data.color ?? { r: 255, g: 255, b: 255 },
                visible: true,
                opacity: data.color ? data.color.a / 255 : 1,
                colorVar: data.colorVar,
            } as SolidPaint
        }
        return null
    }, [isShadowEffect, data.color, data.colorVar])
    const colorPopupPosition = useMemo(() => {
        if (openColorPopup && colorContainerRef.current) {
            const { left, top } = colorContainerRef.current.getBoundingClientRect()
            return { left, top }
        }
    }, [openColorPopup])
    const colorVar = useMemo(() => {
        if (isShadowEffect && data.color && data.colorVar && variables?.length) {
            return variables.find((v) => v.id === data.colorVar?.value.alias)
        }
    }, [variables, isShadowEffect, data.color, data.colorVar])

    const onChangeX = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const x = Number(value)
                onChangeEffect?.({ offset: Object.assign({}, data.offset, { x }) }, options)
            }
        },
        [data, isShadowEffect, onChangeEffect]
    )

    const onChangeY = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const y = Number(value)
                onChangeEffect?.({ offset: Object.assign({}, data.offset, { y }) }, options)
            }
        },
        [data, isShadowEffect, onChangeEffect]
    )

    const onChangeRadius = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeEffect?.({ radius: value }, options)
            }
        },
        [onChangeEffect]
    )

    const onChangeSpread = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const spread = Number(value)
                onChangeEffect?.({ spread }, options)
            }
        },
        [isShadowEffect, onChangeEffect]
    )

    const onChangeColorRGB = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            if (isShadowEffect) {
                onChangeEffect?.({ color: Object.assign({}, data.color, value), colorVar: null }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeColorHex = useCallback(
        (value: string | number, options?: InputOptionsForUndoSquash) => {
            if (isShadowEffect) {
                const rgb = hex2rgb(String(value))
                onChangeEffect?.({ color: Object.assign({}, data.color, rgb) }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const opacity = (value / 100) * 255
                onChangeEffect?.({ color: Object.assign({}, data.color, { a: opacity }), colorVar: null }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeBlendOpacity = useCallback(
        (value: string | number, options?: InputOptionsForUndoSquash) => {
            onChangeOpacity(Number(value) * 100, options)
        },
        [onChangeOpacity]
    )

    const onChangeColorVar = useCallback(
        (v: Wukong.DocumentProto.IVariableAliasData) => {
            onChangeEffect?.({ colorVar: v })
        },
        [onChangeEffect]
    )

    const onChangeShowShadowBehindNode = useCallback(
        (value: boolean) => {
            if (data.type === EffectType.EFFECT_TYPE_DROP_SHADOW) {
                onChangeEffect?.({ showShadowBehindNode: value })
            }
        },
        [data.type, onChangeEffect]
    )

    const onClickSetColorPopup = useCallback(() => {
        if (isDestroy.current) {
            return
        }
        setOpenColorPopup(!openColorPopup)
    }, [openColorPopup])

    const onClickDetachColorVar = useCallback(() => {
        onDetachColorVar?.()
        // 分离变量时，关闭颜色弹窗
        if (openColorPopup) {
            setOpenColorPopup(false)
        }
    }, [openColorPopup, onDetachColorVar])

    useEffect(() => {
        isDestroy.current = false
        return () => {
            isDestroy.current = true
        }
    }, [])

    return (
        <div className={style.content}>
            {data.type === EffectType.EFFECT_TYPE_LAYER_BLUR || data.type === EffectType.EFFECT_TYPE_BACKGROUND_BLUR ? (
                <SingleGrid className={style.grid60}>
                    <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-blur'}>
                        <ScrubbableInputNumber
                            icon={translation('Blur')}
                            value={data.radius ?? undefined}
                            onChange={onChangeRadius}
                            min={0}
                            autoFocus
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            ) : isShadowEffect ? (
                <>
                    <SingleGrid className={style.grid60}>
                        <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-x'}>
                            <ScrubbableInputNumber
                                icon={<MonoIconPanelCoordinateX16 />}
                                value={data.offset?.x ?? undefined}
                                onChange={onChangeX}
                                autoFocus
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={32} span={25} data-testid={'effect-modal-blur'}>
                            <ScrubbableInputNumber
                                icon={
                                    <span style={blurSpreadStyle} ref={blurIconRef}>
                                        {translation('Blur')}
                                    </span>
                                }
                                value={data.radius ?? undefined}
                                onChange={onChangeRadius}
                                min={0}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                    <SingleGrid className={style.grid60}>
                        <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-y'}>
                            <ScrubbableInputNumber
                                icon={<MonoIconPanelCoordinateY16 />}
                                value={data.offset?.y ?? undefined}
                                onChange={onChangeY}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={32} span={25} data-testid={'effect-modal-expend'}>
                            <ScrubbableInputNumber
                                disabled={!enableSpread}
                                icon={
                                    <span ref={spreadIconRef} style={blurSpreadStyle}>
                                        {translation('Spread')}
                                    </span>
                                }
                                value={data.spread ?? undefined}
                                onChange={onChangeSpread}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </>
            ) : null}
            {isShadowEffect && data.color ? (
                <div ref={colorContainerRef} data-testid="effect-modal-color">
                    {colorVar ? (
                        <SingleGrid className={style.grid60}>
                            <SingleGrid.Item start={5} span={52} className={style.backgroundGrid}>
                                <ColorVarItem
                                    paint={colorData as Wukong.DocumentProto.IPaint}
                                    colorSpace={props.colorSpace}
                                    variable={colorVar}
                                    width={208}
                                    onMouseDownItem={onClickSetColorPopup}
                                    onClickDetach={onClickDetachColorVar}
                                />
                            </SingleGrid.Item>
                        </SingleGrid>
                    ) : (
                        <SingleGrid className={style.grid60}>
                            <SingleGrid.Item start={5} span={52} className={style.backgroundGrid}>
                                <SingleGrid className={style.grid52}>
                                    <SingleGrid.Item start={1} span={8} className={style.colorIcon}>
                                        <PureBlock
                                            rgb={{ ...data.color }}
                                            colorSpace={props.colorSpace}
                                            opacity={data.color.a / 255}
                                            onClick={onClickSetColorPopup}
                                            className={style.focusWithinOutlineBorder}
                                        />
                                    </SingleGrid.Item>
                                    <SingleGrid.Item start={9} span={22} data-testid={'effect-modal-color-input'}>
                                        <HexInput
                                            value={rgb2hex(data.color.r, data.color.g, data.color.b)}
                                            onChange={onChangeColorHex}
                                        />
                                    </SingleGrid.Item>
                                    <SingleGrid.Item start={31} span={22} data-testid={'effect-modal-opacity-input'}>
                                        <ScrubbableInputPercent
                                            value={data.color.a / 255}
                                            onChange={onChangeOpacity}
                                            min={0}
                                            max={100}
                                        />
                                    </SingleGrid.Item>
                                </SingleGrid>
                            </SingleGrid.Item>
                        </SingleGrid>
                    )}
                </div>
            ) : null}
            {data.type === EffectType.EFFECT_TYPE_DROP_SHADOW && (
                <SingleGrid className={style.grid60}>
                    <SingleGrid.Item start={1} span={60}>
                        <Checkbox
                            className="ml-4"
                            checked={data.showShadowBehindNode ?? undefined}
                            label={translation('ShowBehind')}
                            onChange={onChangeShowShadowBehindNode}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {!colorData
                ? null
                : openColorPopup &&
                  colorPopupPosition && (
                      <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClickSetColorPopup(), false)}>
                          <ColorInteraction
                              from={ColorInteractionFrom.EFFECT}
                              position={colorPopupPosition}
                              paint={colorData}
                              onCancel={onClickSetColorPopup}
                              onChangeColor={onChangeColorRGB}
                              onChangeOpacity={onChangeBlendOpacity}
                              onChangeColorVar={onChangeColorVar}
                              enterClose={() => {}}
                              styleId={undefined}
                              styleKey={undefined}
                              onChangePaintType={() => {}}
                              onChangeBlendMode={() => {}}
                              onChangeImagePaint={() => {}}
                              onChangeColorStops={() => {}}
                              onChangeTransform={() => {}}
                              onChangeStyle={() => {}}
                              onChangePaints={() => {}}
                              onClickCreateStyleButton={undefined}
                          />
                      </KeyboardReceiver>
                  )}
        </div>
    )
}

function EffectEditNew(props: EffectEditV2Props) {
    const { onChangeEffect, onDetachColorVar, data, enableSpread: _enableSpread, variables, floatVariables } = props
    const enableSpread = _enableSpread ?? true

    const [openColorPopup, setOpenColorPopup] = useState<boolean>(false)
    const isDestroy = useRef<boolean>(false)
    const [blurIconWidth, setBlurIconWidth] = useState<number>(0)
    const [spreadIconWidth, setSpreadIconWidth] = useState<number>(0)

    const blurSpreadStyle = useMemo((): React.CSSProperties => {
        return {
            minWidth: Math.max(blurIconWidth, spreadIconWidth),
        }
    }, [blurIconWidth, spreadIconWidth])

    const blurIconRef = useCallback((el: HTMLSpanElement | null) => {
        setBlurIconWidth(el?.clientWidth ?? 0)
    }, [])

    const spreadIconRef = useCallback((el: HTMLSpanElement | null) => {
        setSpreadIconWidth(el?.clientWidth ?? 0)
    }, [])

    const isShadowEffect = useMemo(() => {
        return data.type === EffectType.EFFECT_TYPE_INNER_SHADOW || data.type === EffectType.EFFECT_TYPE_DROP_SHADOW
    }, [data])

    const colorContainerRef = useRef<HTMLDivElement>(null)
    const colorData = useMemo(() => {
        if (isShadowEffect) {
            return {
                type: PaintType.PAINT_TYPE_SOLID_PAINT,
                color: data.color ?? { r: 255, g: 255, b: 255 },
                visible: true,
                opacity: data.color ? data.color.a / 255 : 1,
                colorVar: data.colorVar,
            } as SolidPaint
        }
        return null
    }, [isShadowEffect, data.color, data.colorVar])
    const colorPopupPosition = useMemo(() => {
        if (openColorPopup && colorContainerRef.current) {
            const { left, top } = colorContainerRef.current.getBoundingClientRect()
            return { left, top }
        }
    }, [openColorPopup])
    const colorVar = useMemo(() => {
        if (isShadowEffect && data.color && data.colorVar && variables?.length) {
            return variables.find((v) => v.id === data.colorVar?.value.alias)
        }
    }, [variables, isShadowEffect, data.color, data.colorVar])

    const xVar = useMemo(() => {
        if (data.xVar) {
            return floatVariables?.find((v) => v.id === data.xVar?.value.alias)
        }
    }, [floatVariables, data.xVar])

    const yVar = useMemo(() => {
        if (data.yVar) {
            return floatVariables?.find((v) => v.id === data.yVar?.value.alias)
        }
    }, [floatVariables, data.yVar])

    const spreadVar = useMemo(() => {
        if (data.spreadVar) {
            return floatVariables?.find((v) => v.id === data.spreadVar?.value.alias)
        }
    }, [floatVariables, data.spreadVar])

    const radiusVar = useMemo(() => {
        if (data.radiusVar) {
            return floatVariables?.find((v) => v.id === data.radiusVar?.value.alias)
        }
    }, [floatVariables, data.radiusVar])

    const onChangeX = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const x = Number(value)
                onChangeEffect?.({ offset: Object.assign({}, data.offset, { x }), xVar: null }, options)
            }
        },
        [data, isShadowEffect, onChangeEffect]
    )

    const onChangeY = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const y = Number(value)
                onChangeEffect?.({ offset: Object.assign({}, data.offset, { y }), yVar: null }, options)
            }
        },
        [data, isShadowEffect, onChangeEffect]
    )

    const onChangeRadius = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number') {
                onChangeEffect?.({ radius: value, radiusVar: null }, options)
            }
        },
        [onChangeEffect]
    )

    const onChangeSpread = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const spread = Number(value)
                onChangeEffect?.({ spread, spreadVar: null }, options)
            }
        },
        [isShadowEffect, onChangeEffect]
    )

    const onChangeColorRGB = useCallback(
        (value: RGB, options?: InputOptionsForUndoSquash) => {
            if (isShadowEffect) {
                onChangeEffect?.({ color: Object.assign({}, data.color, value), colorVar: null }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeColorHex = useCallback(
        (value: string | number, options?: InputOptionsForUndoSquash) => {
            if (isShadowEffect) {
                const rgb = hex2rgb(String(value))
                onChangeEffect?.({ color: Object.assign({}, data.color, rgb) }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeOpacity = useCallback(
        (value: Value, options?: InputOptionsForUndoSquash) => {
            if (typeof value === 'number' && isShadowEffect) {
                const opacity = (value / 100) * 255
                onChangeEffect?.({ color: Object.assign({}, data.color, { a: opacity }), colorVar: null }, options)
            }
        },
        [data.color, isShadowEffect, onChangeEffect]
    )

    const onChangeBlendOpacity = useCallback(
        (value: string | number, options?: InputOptionsForUndoSquash) => {
            onChangeOpacity(Number(value) * 100, options)
        },
        [onChangeOpacity]
    )

    const onChangeColorVar = useCallback(
        (v: Wukong.DocumentProto.IVariableAliasData) => {
            onChangeEffect?.({ colorVar: v })
        },
        [onChangeEffect]
    )

    const onChangeShowShadowBehindNode = useCallback(
        (value: boolean) => {
            if (data.type === EffectType.EFFECT_TYPE_DROP_SHADOW) {
                onChangeEffect?.({ showShadowBehindNode: value })
            }
        },
        [data.type, onChangeEffect]
    )

    const onClickSetColorPopup = useCallback(() => {
        if (isDestroy.current) {
            return
        }
        setOpenColorPopup(!openColorPopup)
    }, [openColorPopup])

    const onClickDetachColorVar = useCallback(() => {
        onDetachColorVar?.()
        // 分离变量时，关闭颜色弹窗
        if (openColorPopup) {
            setOpenColorPopup(false)
        }
    }, [openColorPopup, onDetachColorVar])

    useEffect(() => {
        isDestroy.current = false
        return () => {
            isDestroy.current = true
        }
    }, [])

    const genarateFloatIVariableAliasData = useCallback((id: string) => {
        const floatVar: Wukong.DocumentProto.IVariableAliasData = {
            dataType: Wukong.DocumentProto.VariableDataType.VARIABLE_DATA_TYPE_ALIAS,
            resolvedDataType: Wukong.DocumentProto.VariableResolvedDataType.VARIABLE_RESOLVED_DATA_TYPE_FLOAT,
            value: { alias: id },
        }
        return floatVar
    }, [])

    const xFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.EFFECT_FLOAT,
        ],
        selectedVariable: xVar,
        selectedVariableFallbackFloatValue: data.offset?.x,
        createEnable: true,
        defaultCreateValue: data.offset?.x,
        onVariableSelected: (id) => onChangeEffect?.({ xVar: genarateFloatIVariableAliasData(id) }),
        onVariableDetach: () => onChangeEffect?.({ xVar: null }),
    })

    const yFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.EFFECT_FLOAT,
        ],
        selectedVariable: yVar,
        selectedVariableFallbackFloatValue: data.offset?.y,
        createEnable: true,
        defaultCreateValue: data.offset?.y,
        onVariableSelected: (id) => onChangeEffect?.({ yVar: genarateFloatIVariableAliasData(id) }),
        onVariableDetach: () => onChangeEffect?.({ yVar: null }),
    })

    const radiusFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.EFFECT_FLOAT,
        ],
        selectedVariable: radiusVar,
        selectedVariableFallbackFloatValue: data.radius,
        createEnable: true,
        defaultCreateValue: data.radius,
        onVariableSelected: (id) => onChangeEffect?.({ radiusVar: genarateFloatIVariableAliasData(id) }),
        onVariableDetach: () => onChangeEffect?.({ radiusVar: null }),
    })

    const spreadFloatVariablePicker = useFloatVariablePanel({
        requiredScopes: [
            Wukong.DocumentProto.VariableScope.ALL_SCOPES,
            Wukong.DocumentProto.VariableScope.EFFECT_FLOAT,
        ],
        selectedVariable: spreadVar,
        selectedVariableFallbackFloatValue: data.spread,
        createEnable: true,
        defaultCreateValue: data.spread,
        onVariableSelected: (id) => onChangeEffect?.({ spreadVar: genarateFloatIVariableAliasData(id) }),
        onVariableDetach: () => onChangeEffect?.({ spreadVar: null }),
    })

    return (
        <div className={style.content}>
            {data.type === EffectType.EFFECT_TYPE_LAYER_BLUR || data.type === EffectType.EFFECT_TYPE_BACKGROUND_BLUR ? (
                <SingleGrid className={style.grid60}>
                    <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-blur'}>
                        <ScrubbableInputNumber
                            icon={translation('Blur')}
                            value={data.radius ?? undefined}
                            onChange={onChangeRadius}
                            min={0}
                            autoFocus
                            useVariable={radiusFloatVariablePicker.useVariable}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            ) : isShadowEffect ? (
                <>
                    <SingleGrid className={style.grid60}>
                        <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-x'}>
                            <ScrubbableInputNumber
                                icon={<MonoIconPanelCoordinateX16 />}
                                value={data.offset?.x ?? undefined}
                                onChange={onChangeX}
                                autoFocus
                                useVariable={xFloatVariablePicker.useVariable}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={32} span={25} data-testid={'effect-modal-blur'}>
                            <ScrubbableInputNumber
                                icon={
                                    <span style={blurSpreadStyle} ref={blurIconRef}>
                                        {translation('Blur')}
                                    </span>
                                }
                                value={data.radius ?? undefined}
                                onChange={onChangeRadius}
                                min={0}
                                useVariable={radiusFloatVariablePicker.useVariable}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                    <SingleGrid className={style.grid60}>
                        <SingleGrid.Item start={5} span={25} data-testid={'effect-modal-y'}>
                            <ScrubbableInputNumber
                                icon={<MonoIconPanelCoordinateY16 />}
                                value={data.offset?.y ?? undefined}
                                onChange={onChangeY}
                                useVariable={yFloatVariablePicker.useVariable}
                            />
                        </SingleGrid.Item>
                        <SingleGrid.Item start={32} span={25} data-testid={'effect-modal-expend'}>
                            <ScrubbableInputNumber
                                disabled={!enableSpread}
                                icon={
                                    <span ref={spreadIconRef} style={blurSpreadStyle}>
                                        {translation('Spread')}
                                    </span>
                                }
                                value={data.spread ?? undefined}
                                onChange={onChangeSpread}
                                useVariable={spreadFloatVariablePicker.useVariable}
                            />
                        </SingleGrid.Item>
                    </SingleGrid>
                </>
            ) : null}
            {isShadowEffect && data.color ? (
                <div ref={colorContainerRef} data-testid="effect-modal-color">
                    {colorVar ? (
                        <SingleGrid className={style.grid60}>
                            <SingleGrid.Item start={5} span={52} className={style.backgroundGrid}>
                                <ColorVarItem
                                    paint={colorData as Wukong.DocumentProto.IPaint}
                                    colorSpace={props.colorSpace}
                                    variable={colorVar}
                                    width={208}
                                    onMouseDownItem={onClickSetColorPopup}
                                    onClickDetach={onClickDetachColorVar}
                                />
                            </SingleGrid.Item>
                        </SingleGrid>
                    ) : (
                        <SingleGrid className={style.grid60}>
                            <SingleGrid.Item start={5} span={52} className={style.backgroundGrid}>
                                <SingleGrid className={style.grid52}>
                                    <SingleGrid.Item start={1} span={8} className={style.colorIcon}>
                                        <PureBlock
                                            rgb={{ ...data.color }}
                                            colorSpace={props.colorSpace}
                                            opacity={data.color.a / 255}
                                            onClick={onClickSetColorPopup}
                                            className={style.focusWithinOutlineBorder}
                                        />
                                    </SingleGrid.Item>
                                    <SingleGrid.Item start={9} span={22} data-testid={'effect-modal-color-input'}>
                                        <HexInput
                                            value={rgb2hex(data.color.r, data.color.g, data.color.b)}
                                            onChange={onChangeColorHex}
                                        />
                                    </SingleGrid.Item>
                                    <SingleGrid.Item start={31} span={22} data-testid={'effect-modal-opacity-input'}>
                                        <ScrubbableInputPercent
                                            value={data.color.a / 255}
                                            onChange={onChangeOpacity}
                                            min={0}
                                            max={100}
                                        />
                                    </SingleGrid.Item>
                                </SingleGrid>
                            </SingleGrid.Item>
                        </SingleGrid>
                    )}
                </div>
            ) : null}
            {data.type === EffectType.EFFECT_TYPE_DROP_SHADOW && (
                <SingleGrid className={style.grid60}>
                    <SingleGrid.Item start={1} span={60}>
                        <Checkbox
                            className="ml-4"
                            checked={data.showShadowBehindNode ?? undefined}
                            label={translation('ShowBehind')}
                            onChange={onChangeShowShadowBehindNode}
                        />
                    </SingleGrid.Item>
                </SingleGrid>
            )}
            {!colorData
                ? null
                : openColorPopup &&
                  colorPopupPosition && (
                      <KeyboardReceiver keyCode={ToKeyCode.Esc} onKeydown={() => (onClickSetColorPopup(), false)}>
                          <ColorInteraction
                              from={ColorInteractionFrom.EFFECT}
                              position={colorPopupPosition}
                              paint={colorData}
                              onCancel={onClickSetColorPopup}
                              onChangeColor={onChangeColorRGB}
                              onChangeOpacity={onChangeBlendOpacity}
                              onChangeColorVar={onChangeColorVar}
                              enterClose={() => {}}
                              styleId={undefined}
                              styleKey={undefined}
                              onChangePaintType={() => {}}
                              onChangeBlendMode={() => {}}
                              onChangeImagePaint={() => {}}
                              onChangeColorStops={() => {}}
                              onChangeTransform={() => {}}
                              onChangeStyle={() => {}}
                              onChangePaints={() => {}}
                              onClickCreateStyleButton={undefined}
                          />
                      </KeyboardReceiver>
                  )}
            {xFloatVariablePicker.renderPanel()}
            {yFloatVariablePicker.renderPanel()}
            {radiusFloatVariablePicker.renderPanel()}
            {spreadFloatVariablePicker.renderPanel()}
        </div>
    )
}
