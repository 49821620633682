import { GetValidTransitionTypesCommand, OpenPresentInEditor } from '@wukong/bridge-proto'
import { Bridge } from '../../kernel/bridge/bridge'
import { ServiceClass } from '../../kernel/util/service-class'
import { openPresentUrl } from '../../prototype/context/present-url'
import { getValidTransitionTypes } from '../../ui/component/prototype/prototype-interaction-popup/interaction-action-animation/animation-transition-type/animation-transition-type'

export class PrototypeService extends ServiceClass {
    constructor(protected override readonly bridge: Bridge) {
        super(bridge)
        this.bindJsCall(GetValidTransitionTypesCommand, (args) => ({ transitionTypes: getValidTransitionTypes(args) }))
        this.bindJsCall(OpenPresentInEditor, () => openPresentUrl(bridge))
    }
}
