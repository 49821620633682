export const Hint = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            transform="matrix(1 0 0 1 2.99993 2.99951)"
            d="M18 9C18 4.02954 13.9706 0 9 0C4.02942 0 0 4.02954 0 9C0 13.9705 4.02942 18 9 18C13.9706 18 18 13.9705 18 9ZM8.5 3.99951L9.5 3.99951L9.5 10.9995L8.5 10.9995L8.5 3.99951ZM9 14C9.41418 14 9.75 13.6643 9.75 13.25C9.75 12.8357 9.41418 12.5 9 12.5C8.58582 12.5 8.25 12.8357 8.25 13.25C8.25 13.6643 8.58582 14 9 14Z"
            fillRule="evenodd"
            fill="rgb(235, 69, 28)"
        />
    </svg>
)
