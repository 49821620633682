import { translation } from './global-editor-action-config.translation'
/* eslint-disable no-restricted-imports */
import {
    AddAutoLayoutCommand,
    AiCreateAutoLayoutCommand,
    BoolSelectionNodesCommand,
    ComponentSelectionCommand,
    DetachSelectedInstanceWasmCall,
    DuplicateSelection as DuplicateSelectionCommand,
    EnterAILayoutCommand,
    ExitAILayoutCommand,
    FlattenCommand,
    FlipHorizontalCommand,
    FlipVerticalCommand,
    FrameNodesCommand,
    GoToSelectedInstanceMainComponentCommand,
    GroupSelectionWasmCall,
    HideOtherLayersCommand,
    MoveSelectionForwardOrBackwardWasmCall,
    MoveSelectionNodesFrontOrBackWasmCall,
    OutlineStrokeWasmCall,
    RemoveAllAutoLayoutWasmCall,
    RemoveAutoLayoutWasmCall,
    RemoveFillForSelectedNodesWasmCall,
    RemoveSelectedNodesCommand,
    RemoveStrokeForSelectedNodesWasmCall,
    RenameWasmCall,
    ResetSelectionNodeInInstanceCommand,
    SelectInverseCommand,
    SwapFillStrokeForSelectedNodes,
    ToggleLockedForSelectedNodesCommand,
    ToggleMaskSelectionCommand,
    ToggleShowCommentCommand,
    ToggleShownForSelectedNodesWasmCall,
    UndoRedoCommand,
    UngroupSelectionForJSCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { CommitType } from '../../../document/command/commit-type'
import { cmdChangeColorPick } from '../../../document/command/document-command'
import { cmdToggleFullscreen } from '../../../document/command/fullscreen-command'
import { cmdClearSelectNodes, cmdSelectAll } from '../../../document/command/selection-command'
import { ColorPickCommandType } from '../../../document/node/node'
import { ShortcutToken } from './shortcut-token'
import { getViewCommandByMethodSignature } from './util'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
import { ShortcutKey, shortcutLabelMap } from '../../../kernel/interface/shortcut-key'

export interface GlobalEditorActionConfig {
    displayName: string
    keyboardShortcut?: {
        token: ShortcutToken
        shortcutLabel: string
    }
    viewCommand: (command: CommandInvoker) => void
    autoCommit?: boolean
}

export const shortcutKeysMap: Partial<Record<ActionEnabledType, ShortcutKey>> = {
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN]: ShortcutKey.ToggleShowSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT]: ShortcutKey.ShowComment,
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED]: ShortcutKey.ToggleLockSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE]: ShortcutKey.OutlineStroke,
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN]: ShortcutKey.FlattenSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_FILL]: ShortcutKey.RemoveFill,
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_STROKE]: ShortcutKey.RemoveStroke,
    [ActionEnabledType.ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE]: ShortcutKey.SwapFillAndStroke,
    [ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK]: ShortcutKey.UseAsMask,
    [ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK]: ShortcutKey.UseAsMask,
    [ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION]: ShortcutKey.GroupSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION]: ShortcutKey.FrameSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION]: ShortcutKey.UngroupSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT]: ShortcutKey.BringToFront,
    [ActionEnabledType.ACTION_ENABLED_TYPE_BRING_FORWARD]: ShortcutKey.BringForward,
    [ActionEnabledType.ACTION_ENABLED_TYPE_SEND_BACKWARD]: ShortcutKey.SendBackward,
    [ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK]: ShortcutKey.SendToBack,
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL]: ShortcutKey.FlipHorizontal,
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL]: ShortcutKey.FlipVertical,
    [ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT]: ShortcutKey.AddAutoLayout,
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT]: ShortcutKey.RemoveAutoLayout,
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN]: ShortcutKey.ToggleFullScreen,
    [ActionEnabledType.ACTION_ENABLED_TYPE_COMPONENT_SELECTION]: ShortcutKey.ComponentSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_DETACH_INSTANCE]: ShortcutKey.DetachInstance,
    [ActionEnabledType.ACTION_ENABLED_TYPE_UNDOABLE]: ShortcutKey.Undo,
    [ActionEnabledType.ACTION_ENABLED_TYPE_REDOABLE]: ShortcutKey.Redo,
    [ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_ALL]: ShortcutKey.SelectAll,
    [ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_INVERSE]: ShortcutKey.SelectInverse,
    [ActionEnabledType.ACTION_ENABLED_TYPE_RENAME]: ShortcutKey.RenameSelection,
    [ActionEnabledType.ACTION_ENABLED_TYPE_ENTER_AI_LAYOUT]: ShortcutKey.ToggleAILayout,
    [ActionEnabledType.ACTION_ENABLED_TYPE_EXIT_AI_LAYOUT]: ShortcutKey.ToggleAILayout,
    [ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT]: ShortcutKey.AiCreateAutoLayout,
}

function getShortcutLabel(type: ActionEnabledType) {
    const key = shortcutKeysMap[type]
    return key ? shortcutLabelMap[key] : ''
}

// 全局菜单项/快捷键配置
export const GlobalEditorActionConfigMap: Partial<Record<ActionEnabledType, GlobalEditorActionConfig>> = {
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN]: {
        displayName: `${translation('Show')}/${translation('Hide')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleShownForSelectedNodes,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN),
        },
        viewCommand: getViewCommandByMethodSignature(ToggleShownForSelectedNodesWasmCall, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT]: {
        displayName: `${translation('Show')}/${translation('HideComments')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleCommentVisiable,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT),
        },
        viewCommand: getViewCommandByMethodSignature(ToggleShowCommentCommand, undefined),
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED]: {
        displayName: `${translation('Lock')}/${translation('Unlock')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleLockedForSelectedNodes,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED),
        },
        viewCommand: getViewCommandByMethodSignature(ToggleLockedForSelectedNodesCommand, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_UNION]: {
        displayName: translation('UnionSelection'),
        viewCommand: getViewCommandByMethodSignature(BoolSelectionNodesCommand, {
            op: Wukong.DocumentProto.BooleanOperation.BOOLEAN_OPERATION_UNION,
        }),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SUBTRACT]: {
        displayName: translation('SubstrateSelection'),
        viewCommand: getViewCommandByMethodSignature(BoolSelectionNodesCommand, {
            op: Wukong.DocumentProto.BooleanOperation.BOOLEAN_OPERATION_SUBTRACT,
        }),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_INTERSECT]: {
        displayName: translation('IntersectSelection'),
        viewCommand: getViewCommandByMethodSignature(BoolSelectionNodesCommand, {
            op: Wukong.DocumentProto.BooleanOperation.BOOLEAN_OPERATION_INTERSECT,
        }),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_EXCLUDE]: {
        displayName: translation('ExcludeSelection'),
        viewCommand: getViewCommandByMethodSignature(BoolSelectionNodesCommand, {
            op: Wukong.DocumentProto.BooleanOperation.BOOLEAN_OPERATION_EXCLUDE,
        }),
        autoCommit: true,
    },

    [ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE]: {
        displayName: translation('OutlineStroke'),
        keyboardShortcut: {
            token: ShortcutToken.OutlineStroke,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE),
        },
        viewCommand: getViewCommandByMethodSignature(OutlineStrokeWasmCall, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN]: {
        displayName: translation('Flatten'),
        keyboardShortcut: {
            token: ShortcutToken.Flatten,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN),
        },
        viewCommand: getViewCommandByMethodSignature(FlattenCommand, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_FILL]: {
        displayName: translation('RemoveFill'),
        keyboardShortcut: {
            token: ShortcutToken.DeleteFills,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_FILL),
        },
        viewCommand: getViewCommandByMethodSignature(RemoveFillForSelectedNodesWasmCall, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_STROKE]: {
        displayName: translation('RemoveStroke'),
        keyboardShortcut: {
            token: ShortcutToken.DeleteStrokes,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_STROKE),
        },
        viewCommand: getViewCommandByMethodSignature(RemoveStrokeForSelectedNodesWasmCall, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE]: {
        displayName: translation('SwapFillAnd'),
        keyboardShortcut: {
            token: ShortcutToken.SwitchFillsStrokes,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_SWAP_FILL_AND_STROKE),
        },
        viewCommand: getViewCommandByMethodSignature(SwapFillStrokeForSelectedNodes, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK]: {
        displayName: translation('UseAsMask'),
        keyboardShortcut: {
            token: ShortcutToken.ToggleMaskSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK),
        },
        viewCommand: (command) => getViewCommandByMethodSignature(ToggleMaskSelectionCommand, undefined)(command),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK]: {
        displayName: translation('RemoveMask'),
        keyboardShortcut: {
            token: ShortcutToken.ToggleMaskSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK),
        },
        viewCommand: (command) => getViewCommandByMethodSignature(ToggleMaskSelectionCommand, undefined)(command),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION]: {
        displayName: translation('GroupSelection'),
        keyboardShortcut: {
            token: ShortcutToken.GroupSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION),
        },
        viewCommand: getViewCommandByMethodSignature(GroupSelectionWasmCall, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION]: {
        displayName: translation('FrameSelection'),
        keyboardShortcut: {
            token: ShortcutToken.FrameSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION),
        },
        viewCommand: getViewCommandByMethodSignature(FrameNodesCommand, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION]: {
        displayName: translation('UngroupSelection'),
        keyboardShortcut: {
            token: ShortcutToken.UngroupSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION),
        },
        viewCommand: getViewCommandByMethodSignature(UngroupSelectionForJSCommand, undefined),
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT]: {
        displayName: translation('BringToFront'),
        keyboardShortcut: {
            token: ShortcutToken.BringToFront,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(MoveSelectionNodesFrontOrBackWasmCall, { value: false })
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_BRING_FORWARD]: {
        displayName: translation('BringForward'),
        keyboardShortcut: {
            token: ShortcutToken.BringForward,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_BRING_FORWARD),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(MoveSelectionForwardOrBackwardWasmCall, { value: true })
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SEND_BACKWARD]: {
        displayName: translation('BringBackward'),
        keyboardShortcut: {
            token: ShortcutToken.SendBackward,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_SEND_BACKWARD),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(MoveSelectionForwardOrBackwardWasmCall, { value: false })
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK]: {
        displayName: translation('SendToBack'),
        keyboardShortcut: {
            token: ShortcutToken.SendToBack,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(MoveSelectionNodesFrontOrBackWasmCall, { value: true })
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL]: {
        displayName: translation('FlipHorizontal'),
        keyboardShortcut: {
            token: ShortcutToken.FlipHorizontal,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(FlipHorizontalCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL]: {
        displayName: translation('FlipVertical'),
        keyboardShortcut: {
            token: ShortcutToken.FlipVertical,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(FlipVerticalCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT]: {
        displayName: translation('AddAutoLayout'),
        keyboardShortcut: {
            token: ShortcutToken.AutoLayoutAdd,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(AddAutoLayoutCommand, {
                origin: Wukong.DocumentProto.AddAutoLayoutOrigin.ADD_AUTO_LAYOUT_ORIGIN_CONTEXT_MENU,
            })
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT]: {
        displayName: translation('RemoveAutoLayout'),
        keyboardShortcut: {
            token: ShortcutToken.AutoLayoutRemove,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(RemoveAutoLayoutWasmCall)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_ALL_AUTO_LAYOUT]: {
        displayName: translation('RemoveAllAutoLayout'),
        viewCommand: (command) => {
            command.invokeBridge(CommitType.Noop, RemoveAllAutoLayoutWasmCall)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_ENTER_AI_LAYOUT]: {
        displayName: `${translation('RxsUsj')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleAILayout,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_ENTER_AI_LAYOUT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(EnterAILayoutCommand)
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_EXIT_AI_LAYOUT]: {
        displayName: `${translation('HDyoMR')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleAILayout,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_EXIT_AI_LAYOUT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(ExitAILayoutCommand)
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT]: {
        displayName: `${translation('SmartAddAutoLayout')}`,
        keyboardShortcut: {
            token: ShortcutToken.AiCreateAutoLayout,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(AiCreateAutoLayoutCommand)
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_PUBLISH_SELECTED_NODES]: {
        displayName: translation('PublishSelectedComponents'),
        viewCommand: () => {},
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN]: {
        displayName: `${translation('Show')}/${translation('HideUI')}`,
        keyboardShortcut: {
            token: ShortcutToken.ToggleFullscreen,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN),
        },
        viewCommand: (command) => {
            command.invoke(cmdToggleFullscreen)
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_COMPONENT_SELECTION]: {
        displayName: translation('CreateComponent'),
        keyboardShortcut: {
            token: ShortcutToken.ComponentSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_COMPONENT_SELECTION),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(ComponentSelectionCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_RESET_PROPERTIES]: {
        displayName: translation('ResetAllChanges'),
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(ResetSelectionNodeInInstanceCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_DETACH_INSTANCE]: {
        displayName: translation('DetachInstance'),
        keyboardShortcut: {
            token: ShortcutToken.DetachInstanceSelection,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_DETACH_INSTANCE),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(DetachSelectedInstanceWasmCall)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_GO_TO_SELECTED_INSTANCE_MAIN_COMPONENT]: {
        displayName: translation('GoToMain'),
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(GoToSelectedInstanceMainComponentCommand)
        },
        autoCommit: true,
    },

    [ActionEnabledType.ACTION_ENABLED_TYPE_UNDOABLE]: {
        displayName: translation('Undo'),
        keyboardShortcut: {
            token: ShortcutToken.Undo,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_UNDOABLE),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(
                UndoRedoCommand,
                Wukong.DocumentProto.UndoRedoCommandParam.create({
                    metaKey: true,
                    ctrlKey: false,
                    shiftKey: false,
                    altKey: false,
                })
            )
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REDOABLE]: {
        displayName: translation('Redo'),
        keyboardShortcut: {
            token: ShortcutToken.Redo,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_REDOABLE),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(
                UndoRedoCommand,
                Wukong.DocumentProto.UndoRedoCommandParam.create({
                    metaKey: true,
                    ctrlKey: false,
                    shiftKey: true,
                    altKey: false,
                })
            )
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_DUPLICATE_SELECTION]: {
        displayName: translation('Duplicate'),
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(DuplicateSelectionCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_SELECT_NODES]: {
        displayName: translation('Delete'),
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(RemoveSelectedNodesCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_COLOR_PICK]: {
        displayName: translation('PickColor'),
        viewCommand: (command) => {
            command.invoke(cmdChangeColorPick, ColorPickCommandType.SwitchColorPickVisible)
        },
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_ALL]: {
        displayName: translation('SelectAll'),
        keyboardShortcut: {
            token: ShortcutToken.SelectAll,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_ALL),
        },
        viewCommand: (command) => {
            command.invoke(cmdSelectAll)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_CLEAR_SELECT_NODES]: {
        displayName: translation('SelectNone'),
        viewCommand: (command) => {
            command.invoke(cmdClearSelectNodes)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_INVERSE]: {
        displayName: translation('SelectInverse'),
        keyboardShortcut: {
            token: ShortcutToken.SelectInverse,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_SELECT_INVERSE),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(SelectInverseCommand)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_RENAME]: {
        displayName: translation('Rename'),
        keyboardShortcut: {
            token: ShortcutToken.Rename,
            shortcutLabel: getShortcutLabel(ActionEnabledType.ACTION_ENABLED_TYPE_RENAME),
        },
        viewCommand: (command) => {
            command.DEPRECATED_invokeBridge(RenameWasmCall)
        },
        autoCommit: true,
    },
    [ActionEnabledType.ACTION_ENABLED_TYPE_HIDE_OTHER_LAYERS]: {
        displayName: translation('HideOtherLayers'),
        viewCommand: getViewCommandByMethodSignature(HideOtherLayersCommand, undefined),
        autoCommit: true,
    },
}
