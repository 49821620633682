import { getTranslationValue } from '../../../../../../../../util/src/i18n'

export const zhTranslation = {
    UntitledStyle: '未命名样式',
    GoTo: '打开样式所在文件',
} as const

export const enTranslation = {
    UntitledStyle: 'Untitled style',
    GoTo: 'Go to style definition to edit',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
