import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'
import { IconStrokeWeight } from '../../../../../../../ui-lib/src'
import { StrokeAlign } from '../../../../../document/node/node'
import { useRightPanelWidth } from '../../../../../main/layout/layout-context'
import { useViewState } from '../../../../../view-state-bridge'
import { useRenderColorSpace } from '../../../color-profile'
import { StyleColorsV2 } from '../../../inspect/comp/style-colors-v2'
import { UnStyleColorsV2 } from '../../../inspect/comp/un-style-colors-v2'
import { CopyableRow } from '../common/copyable-row'
import { strokeAlignToShow } from '../common/inspect-type'
import { ColorModePicker } from '../dev-mode-inspect-fill/color-mode-picker'
import style from '../inspect.module.less'
import { translation } from './index.translation'

function isNear(x: number, y: number, eps = 1e-5) {
    return Math.abs(x - y) < eps
}

export function DevModeInspectStroke() {
    const selectionStroke = useViewState('devModeInspectStrokeViewState')
    const colorSpace = useRenderColorSpace()
    const alignState = selectionStroke?.alignState
    const weightStateWrapper = selectionStroke?.weightStateWrapper
    const weightState = weightStateWrapper?.weightState
    const hasSameWeight =
        !weightState?.typeState.value ||
        weightState?.typeState.value === Wukong.DocumentProto.IndependentStrokeType.INDEPENDENT_STROKE_TYPE_ALL
    const weightValueStr =
        (hasSameWeight
            ? weightStateWrapper?.allBorderStateValue
            : [
                  weightStateWrapper?.topBorderStateValue,
                  weightStateWrapper?.rightBorderStateValue,
                  weightStateWrapper?.bottomBorderStateValue,
                  weightStateWrapper?.leftBorderStateValue,
              ].join(', ')) ?? ''

    const weightIsZero = hasSameWeight
        ? isNear(weightState?.allBorderState.value ?? 0, 0)
        : isNear(weightState.topBorderState.value ?? 0, 0) &&
          isNear(weightState.rightBorderState.value ?? 0, 0) &&
          isNear(weightState.bottomBorderState.value ?? 0, 0) &&
          isNear(weightState.leftBorderState.value ?? 0, 0)

    const isStyle =
        selectionStroke?.paintState.type == Wukong.DocumentProto.SelectionStrokeType.SELECTION_STROKE_TYPE_STYLE
    const stylePaintsItem = useMemo(() => {
        if (isStyle) {
            const paints =
                (selectionStroke?.paintState.paintStyleNode?.paints ?? []).filter(
                    (p) => p.visible && !isNear(p.opacity, 0)
                ) ?? []

            const styleName = selectionStroke?.paintState.paintStyleNode?.name
            const styleDescription = selectionStroke?.paintState.paintStyleNode?.description
            const variables = selectionStroke?.paintState.paintStyleNode?.variables ?? []
            return { styleName, paints, styleDescription, variables }
        } else {
            return undefined
        }
    }, [
        isStyle,
        selectionStroke?.paintState.paintStyleNode?.description,
        selectionStroke?.paintState.paintStyleNode?.name,
        selectionStroke?.paintState.paintStyleNode?.paints,
        selectionStroke?.paintState.paintStyleNode?.variables,
    ])

    const paints = useMemo(() => {
        // 由于 reverse 原地翻转，需要额外拷贝一份
        return (selectionStroke?.paintState.strokes ?? []).filter((p) => p.visible && !isNear(p.opacity, 0))
    }, [selectionStroke?.paintState.strokes])
    const paintLength = stylePaintsItem?.paints?.length ?? paints.length ?? 0
    const rightPanelWidth = useRightPanelWidth()

    if (!selectionStroke || (!paintLength && !isStyle) || weightIsZero) {
        return null
    }
    return (
        <div className={style.panel} data-testid="dev-mode-inspect-stroke">
            <div className={style.panelTitle}>
                <div className={style.title}>{translation('Borders')}</div>
                <div className={style.panelCopyActionControls}>
                    <ColorModePicker></ColorModePicker>
                </div>
            </div>
            <div className={classnames(style.propertyColorRow, style.borderPropertyRow, style.basePropertyRow)}>
                <span className={style.borderIcon}>
                    <IconStrokeWeight />
                </span>
                <div className={classnames(style.properyPairsContainer)}>
                    <CopyableRow copyValue={weightValueStr} noPaddingLeft>
                        <span className={style.paintColor}>{weightValueStr}</span>
                    </CopyableRow>
                </div>
                <div className={style.properyPairsContainer + ' ml-3!'}>
                    <CopyableRow copyValue={strokeAlignToShow[alignState?.value as StrokeAlign]} noPaddingLeft>
                        <span>{!isNil(alignState?.value) && strokeAlignToShow[alignState?.value as StrokeAlign]}</span>
                    </CopyableRow>
                </div>
            </div>
            {!stylePaintsItem && (
                <div className="px-4">
                    <UnStyleColorsV2
                        isStroke
                        paints={paints}
                        colorSpace={colorSpace}
                        variables={selectionStroke?.paintState.variables ?? []}
                        dataTestId="dev-mode-inspect-stroke-unstyle-colors"
                        maxWidth={rightPanelWidth - 32}
                    />
                </div>
            )}
            {stylePaintsItem && (
                <div className="px-4">
                    <StyleColorsV2
                        isStroke
                        items={[stylePaintsItem]}
                        colorSpace={colorSpace}
                        dataTestId="dev-mode-inspect-stroke-style-colors"
                        maxWidth={rightPanelWidth - 32}
                    />
                </div>
            )}
        </div>
    )
}
