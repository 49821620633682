import { translation } from './right-click-menu-config.translation'
/* eslint-disable no-restricted-imports */
import {
    AddPrototypeStartingPointCommand,
    AddVariantForSelectedComponentWasmCall,
    DeletePrototypeStartingPointsCommand,
    DevModeEnterRightClickSelectionCommand,
    DevModeLayerPanelCollapseAllChildrenWasmCall,
    DevModeLayerPanelExpandAllChildrenWasmCall,
    DevModeLeaveRightClickSelectionCommand,
    GoToSelectedInstanceMainComponentCommand,
    LayerPanelCancelHoverCommandWasmCall,
    LayerPanelEnterHoverCommandWasmCall,
    LayerPanelLeftClickCommandWasmCall,
    MoveNodesToOtherPage,
    RemoveAllPrototypeInteractions,
    RemoveSelectionAllPrototypeInteractions,
    SearchLocateNodeWithinMotiffScopeWasmCall,
    SetThumbnailIdWasmCall,
    ToggleShowCommentCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { isNil } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { WKDividerMenuItem, WKItemType, WKMenuConfig, WKMenuItemType, WKToast } from '../../../../../../../ui-lib/src'
import { isEnglishLanguage } from '../../../../../../../util/src'
import { CommandInvoker } from '../../../../../document/command/command-invoker'
import { CommitType } from '../../../../../document/command/commit-type'
import { ShortcutKey, shortcutLabelMap } from '../../../../../kernel/interface/shortcut-key'
import { isFirefox } from '../../../../../kernel/util/ua'
import {
    useAppContext,
    useCommentService,
    usePagePanelService,
    usePlanAndUserStateService,
    usePluginService,
} from '../../../../../main/app-context'
import { PayUpgradeUpperPageNumLimit } from '../../../../../share/payment'
import { PayUpgradeDialogFrom } from '../../../../../share/payment/pay-upgrade-dialog/pay-upgrade-dialog-struct/type'
import { isStarterPlan } from '../../../../../utils/plan-status-checker'
import { useViewState } from '../../../../../view-state-bridge'
import { WK } from '../../../../../window'
import { useClipboardService, useCommand } from '../../../../context/document-context'
import { useDocInfoContext } from '../../../../context/top-area-context'
import { frogEventToolsMenusAndActionsBasicAction } from '../../../../utils/tools-actions-frog'
import { isAISearchEnabled } from '../../../ai/ai-search/ai-search-feature'
import { OpenLibraryPublishModalSource } from '../../../component-style-library-v2/library-service/library-modal-router-service'
import { useLibraryModalRouter } from '../../../component-style-library-v2/library-service/use-library-modal-router'
import { useLocalStyleModel } from '../../../design/style/hooks/use-local-style-model-v2'
import { getSVGIconByNodeIconType } from '../../../layer-panel/render-item-icon'
import { PAGE_LIMIT_NUM } from '../../../page-panel/components/page-limit-tip'
import { useLastUsedPlugin } from '../../../plugin/last-used-plugin'
import { useEditorActionStatusSubMenuItem } from '../hook/editor-action-status-menu'
import ActionEnabledType = Wukong.DocumentProto.ActionEnabledType
import SetThumbnailStatus = Wukong.DocumentProto.RightClickSetThumbnailStatus

export function useDevModeRightClickMenuConfig(): WKMenuConfig {
    const commandInvoker = useCommand()
    const clipboardService = useClipboardService()
    const layerSelection = useViewState('layerSelection')
    const enableCopyAsSvg = useViewState('actionEnabledCopyAsSVGStatus')
    const enableCopyAsPng = useViewState('actionEnabledCopyAsPNGStatus')
    const enableCopyAsText = useViewState('actionEnabledCopyAsTextStatus')
    const enableCopyAsLink = useViewState('actionEnabledCopyAsLinkStatus')
    const actionEnabledCopyStatus = useViewState('actionEnabledCopyStatus')
    const actionEnabledBringToFrontStatus = useViewState('actionEnabledBringToFrontStatus')
    const actionEnabledGoToSelectedInstanceMainComponentStatus = useViewState(
        'actionEnabledGoToSelectedInstanceMainComponentStatus'
    )
    const configs: WKMenuConfig = [
        {
            name: translation('Copy'),
            shortCut: shortcutLabelMap[ShortcutKey.Copy],
            forwardIcon: false,
            hidden: !actionEnabledCopyStatus || isFirefox(),
            handler: () => clipboardService.copy(),
            testId: 'copy',
        },
        {
            name: translation('CopyAsText'),
            hidden: !enableCopyAsText,
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT),
        },
        {
            name: `${translation('CopyAs')} SVG`,
            hidden: !enableCopyAsSvg || isFirefox(),
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G),
        },
        {
            name: `${translation('CopyAs')} PNG`,
            shortCut: shortcutLabelMap[ShortcutKey.CopyAsPNG],
            hidden: !enableCopyAsPng || isFirefox(),
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G),
            testId: 'right-menu-copy-as-png',
        },
        {
            name: translation('CopyLink'),
            hidden: !enableCopyAsLink,
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK),
        },
        {
            type: WKItemType.Divider,
            hidden: !actionEnabledBringToFrontStatus,
        },
        {
            name: translation('SelectLayer'),
            position: 'right top',
            hidden: !layerSelection?.isShowLayerSelection,
            children: devModeFormalRightClickLayerSelectionList(
                layerSelection?.rightClickLayerSelectionNodes,
                commandInvoker
            ),
            testId: 'layer-selection',
        },
        {
            type: WKItemType.Divider,
            hidden: !actionEnabledGoToSelectedInstanceMainComponentStatus,
        },
        {
            name: translation('GoToMain'),
            hidden: !actionEnabledGoToSelectedInstanceMainComponentStatus,
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(GoToSelectedInstanceMainComponentCommand)
            },
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN,
                hiddenWhenDisabled: true,
            }),
            testId: 'toggle-full-screen',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT,
                hiddenWhenDisabled: true,
                // hidden: !docReadonly
            }),
            testId: 'toggle-shown-comment',
        },
    ]

    return configs
}

function usePrototypeStartingPointMenuConfig(): WKMenuConfig {
    const command = useCommand()
    const addPrototypeStartingPoint = useViewState('actionEnabledAddPrototypeStartingPoint')
    const deletePrototypeStartingPoint = useViewState('actionEnabledDeletePrototypeStartingPoint')
    const removePrototypeInteraction = useViewState('actionEnabledRemovePrototypeInteraction')

    return [
        {
            type: WKItemType.Divider,
            hidden: !(addPrototypeStartingPoint || deletePrototypeStartingPoint || removePrototypeInteraction),
            testId: 'prototype-separator',
        },
        {
            name: translation('RemovePrototypeInteraction'),
            forwardIcon: false,
            hidden: !removePrototypeInteraction,
            handler: () => {
                command.DEPRECATED_invokeBridge(RemoveSelectionAllPrototypeInteractions)
            },
            testId: 'delete-prototype-interactions',
        },
        {
            name: translation('AddPrototypeStartingPoint'),
            forwardIcon: false,
            hidden: !addPrototypeStartingPoint,
            handler: () => {
                command.DEPRECATED_invokeBridge(AddPrototypeStartingPointCommand)
            },
            testId: 'add-prototype-starting-point',
        },

        {
            name: translation('DeletePrototypeStartingPoint'),
            forwardIcon: false,
            hidden: !deletePrototypeStartingPoint,
            handler: () => {
                command.DEPRECATED_invokeBridge(DeletePrototypeStartingPointsCommand)
            },
            testId: 'delete-prototype-starting-point',
        },
    ]
}

function ComponentRelatedMenu(): WKMenuConfig {
    const commandInvoker = useCommand()
    const enabledPublishSelectedNodes = useViewState('actionEnabledPublishSelectedNodesStatus')
    const enabledAddVariant = useViewState('actionEnabledAddVariant')
    const { openLibraryPublishModalIfPaid } = useLibraryModalRouter()
    const { docData } = useDocInfoContext()

    const allOpen = enabledPublishSelectedNodes && enabledAddVariant

    const publishComponentItem = {
        name: translation('PublishSelectedComponents'),
        handler: () => {
            openLibraryPublishModalIfPaid(OpenLibraryPublishModalSource.SelectionPublishButton, !!docData?.draft)
        },
        hidden: !enabledPublishSelectedNodes || allOpen,
        testId: 'right-click-single-publish',
    } as WKMenuItemType
    const addVariantItem = {
        name: translation('AddVariant'),
        handler: () => commandInvoker.DEPRECATED_invokeBridge(AddVariantForSelectedComponentWasmCall),
        hidden: !enabledAddVariant || allOpen,
        testId: 'right-click-single-add-variant',
    } as WKMenuItemType

    const ComponentSubMenu = {
        name: translation('MainComponent'),
        forwardIcon: false,
        position: 'right top',
        hidden: !allOpen,
        children: [
            {
                name: translation('PublishSelectedComponents'),
                handler: () => {
                    openLibraryPublishModalIfPaid(
                        OpenLibraryPublishModalSource.SelectionPublishButton,
                        !!docData?.draft
                    )
                },
                testId: 'right-click-components-menu-publish',
            },
            {
                name: translation('AddVariant'),
                handler: () => commandInvoker.DEPRECATED_invokeBridge(AddVariantForSelectedComponentWasmCall),
                testId: 'right-click-components-menu-add-variant',
            },
        ],
        testId: 'right-click-components-menu',
    } as WKMenuItemType

    return [publishComponentItem, addVariantItem, ComponentSubMenu]
}

function useAISearchMenuConfig(): WKMenuConfig {
    const docReadonly = useViewState('docReadonly', false)
    const aiService = useAppContext().aiService
    if (docReadonly) {
        return []
    }

    if (isAISearchEnabled()) {
        return [
            {
                name: translation('AISearch'),
                forwardIcon: false,
                handler: () => {
                    aiService.tryEnterAiSearch()
                },
                testId: 'right-click-ai-search',
            },
        ]
    }
    return []
}

export function useRightCLickMotiffScopeMenuConfig(): WKMenuConfig {
    const motiffScope = useViewState('motiffScope')
    const commandInvoker = useCommand()
    const { id, name, mainComponentId } = useMemo(() => {
        const node = WK.getNode(motiffScope?.selectedId)
        if (!node) {
            return { name: '', mainComponentId: '' }
        }
        const curId = node.id ?? ''
        const curName = node.id === '0:0' ? '文档' : node.name
        const curMainComponentId = node.mainComponentId ?? ''
        return { id: curId, name: curName, mainComponentId: curMainComponentId }
    }, [motiffScope?.selectedId])

    const onCopyId = useCallback(() => {
        if (!id) {
            WKToast.error('节点不存在')
            return
        }
        navigator.clipboard.writeText(id)
    }, [id])

    const onCopyName = useCallback(() => {
        if (!id) {
            WKToast.error('节点不存在')
            return
        }
        navigator.clipboard.writeText(name)
    }, [name, id])

    const onCopyIdAndName = useCallback(() => {
        if (!id) {
            WKToast.error('节点不存在')
            return
        }
        navigator.clipboard.writeText(`(${id}) ${name}`)
    }, [id, name])

    const onCopyMainComponentId = useCallback(() => {
        if (!mainComponentId) {
            WKToast.error('组件节点不存在')
            return
        }
        navigator.clipboard.writeText(mainComponentId)
    }, [mainComponentId])

    const onJumpToNode = useCallback(
        (target: string) => {
            if (!target) {
                WKToast.error('组件节点不存在')
                return
            }
            commandInvoker.invokeBridge(CommitType.CommitUndo, SearchLocateNodeWithinMotiffScopeWasmCall, {
                value: target,
            })
        },
        [commandInvoker]
    )

    const configs: WKMenuConfig = [
        {
            name: '复制ID',
            forwardIcon: false,
            handler: onCopyId,
            testId: 'right-click-motiff-scope-copy-id',
        },
        {
            name: '复制名称',
            forwardIcon: false,
            handler: onCopyName,
            testId: 'right-click-motiff-scope-copy-name',
        },
        {
            name: '复制ID与名称',
            forwardIcon: false,
            handler: onCopyIdAndName,
            testId: 'right-click-motiff-scope-copy-id-and-name',
        },
    ]

    const unwrappedInstIds = id.split(';')
    if (unwrappedInstIds.length >= 2) {
        const unwrappedLayerId = unwrappedInstIds.slice(1).join(';')
        const unwrappedRootLayerId = unwrappedInstIds[unwrappedInstIds.length - 1]

        if (unwrappedLayerId !== unwrappedRootLayerId) {
            configs.push({
                name: `跳转至外层内嵌图层(${unwrappedLayerId})`,
                forwardIcon: false,
                handler: () => onJumpToNode(unwrappedLayerId),
                testId: 'right-click-motiff-scope-jump-to-unwrappered-instance',
            })
        }
        configs.push({
            name: `跳转至最外层内嵌图层(${unwrappedRootLayerId})`,
            forwardIcon: false,
            handler: () => onJumpToNode(unwrappedRootLayerId),
            testId: 'right-click-motiff-scope-jump-to-unwrappered-root-instance',
        })
        const outerInstId = unwrappedInstIds.slice(0, -1).join(';')
        const outerMostInstId = unwrappedInstIds[0]

        if (outerInstId !== outerMostInstId) {
            configs.push({
                name: `跳转至外层实例(${outerInstId})`,
                forwardIcon: false,
                handler: () => onJumpToNode(outerInstId),
                testId: 'right-click-motiff-scope-jump-to-parent-instance',
            })
        }

        configs.push({
            name: `跳转至最外层实例(${outerMostInstId})`,
            forwardIcon: false,
            handler: () => onJumpToNode(outerMostInstId),
            testId: 'right-click-motiff-scope-jump-to-outer-most-instance',
        })
    }

    if (mainComponentId) {
        configs.push(
            {
                name: `复制所属组件ID`,
                forwardIcon: false,
                handler: onCopyMainComponentId,
                testId: 'right-click-motiff-scope-copy-main-component-id',
            },
            {
                name: `跳转至所属组件(${mainComponentId})`,
                forwardIcon: false,
                handler: () => onJumpToNode(mainComponentId),
                testId: 'right-click-motiff-scope-jump-to-main-component',
            }
        )
    }
    return configs
}
export function useRightClickMenuConfig(): WKMenuConfig {
    const commandInvoker = useCommand()
    const clipboardService = useClipboardService()
    const singleSelection = useViewState('singleSelection')
    const docReadonly = useViewState('docReadonly', false)
    const pageList = useViewState('documentPageList')?.pages!
    const currentPageId = useViewState('currentPageId')!
    const layerSelection = useViewState('layerSelection')

    const enableCopyAsSvg = useViewState('actionEnabledCopyAsSVGStatus')
    const enableCopyAsPng = useViewState('actionEnabledCopyAsPNGStatus')
    const enableCopyAsText = useViewState('actionEnabledCopyAsTextStatus')
    const enableCopyAsLink = useViewState('actionEnabledCopyAsLinkStatus')
    const enableRemoveAllInteractions = useViewState('actionEnabledRemoveAllPrototypeInteractions')
    const setThumbnailStatus = useViewState(
        'rightClickSetThumbnailStatus',
        SetThumbnailStatus.RIGHT_CLICK_SET_THUMBNAIL_STATUS_HIDDEN
    )
    const enableSetAsThumbnail = setThumbnailStatus === SetThumbnailStatus.RIGHT_CLICK_SET_THUMBNAIL_STATUS_SET_AS
    const enableResetDefaultThumbnail =
        setThumbnailStatus === SetThumbnailStatus.RIGHT_CLICK_SET_THUMBNAIL_STATUS_SET_AS_DEFAULT

    const showDividerInCopyPasteSection = enableCopyAsText || enableCopyAsSvg || enableCopyAsPng || enableCopyAsLink
    const startingPointMenuConfig = usePrototypeStartingPointMenuConfig()
    const aiSearchMenuConfig = useAISearchMenuConfig()

    const configs: WKMenuConfig = [
        {
            name: translation('Copy'),
            shortCut: shortcutLabelMap[ShortcutKey.Copy],
            forwardIcon: false,
            hidden: !useViewState('actionEnabledCopyStatus') || isFirefox(),
            handler: () => clipboardService.copy(),
            testId: 'copy',
        },
        {
            name: translation('PasteHere'),
            forwardIcon: false,
            hidden: !useViewState('actionEnabledPasteHereStatus'),
            handler: () =>
                clipboardService.paste(Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_HERE),
            autoCommit: true,
            testId: 'paste-here',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_FULL_SCREEN,
                hiddenWhenDisabled: true,
            }),
            testId: 'toggle-full-screen',
        },

        {
            name: translation('PasteToReplce'),
            shortCut: shortcutLabelMap[ShortcutKey.PasteToReplace],
            forwardIcon: false,
            hidden: !useViewState('actionEnabledPasteToReplaceStatus'),
            handler: () =>
                clipboardService.paste(Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PASTE_TO_REPLACE),
            autoCommit: true,
            testId: 'paste-to-replace',
        },
        {
            name: translation('Copy/PasteAs'),
            forwardIcon: false,
            position: 'right top',
            hidden: !useViewState('actionEnabledCopyPasteStatus'),
            children: [
                {
                    name: translation('CopyAsText'),
                    hidden: !enableCopyAsText,
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT),
                },
                {
                    name: `${translation('CopyAs')} SVG`,
                    hidden: !enableCopyAsSvg || isFirefox(),
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G),
                },
                {
                    name: `${translation('CopyAs')} PNG`,
                    shortCut: shortcutLabelMap[ShortcutKey.CopyAsPNG],
                    hidden: !enableCopyAsPng || isFirefox(),
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G),
                    testId: 'right-menu-copy-as-png',
                },
                {
                    name: translation('CopyLink'),
                    hidden: !enableCopyAsLink,
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK),
                },
                { type: WKItemType.Divider, hidden: !showDividerInCopyPasteSection },
                {
                    name: translation('CopyProperties'),
                    shortCut: shortcutLabelMap[ShortcutKey.CopyProperties],
                    hidden: !useViewState('actionEnabledCopyPropertiesStatus'),
                    handler: () =>
                        clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_PROPERTY),
                },
                {
                    name: translation('PasteProperties'),
                    shortCut: shortcutLabelMap[ShortcutKey.PasteProperties],
                    hidden: !useViewState('actionEnabledPastePropertiesStatus'),
                    handler: () =>
                        clipboardService.paste(Wukong.DocumentProto.ClipboardPasteType.CLIPBOARD_PASTE_TYPE_PROPERTY),
                    autoCommit: true,
                },
            ],
            testId: 'copy-as',
        },
        {
            type: WKItemType.Divider,
            hidden: !useViewState('actionEnabledBringToFrontStatus'),
        },
        {
            name: translation('SelectLayer'),
            position: 'right top',
            hidden: !layerSelection?.isShowLayerSelection,
            children: designModeFormalRightClickLayerSelectionList(
                layerSelection?.rightClickLayerSelectionNodes,
                commandInvoker
            ),
            testId: 'layer-selection',
        },
        {
            name: translation('MoveToPage'),
            position: 'right top',
            hidden: !useViewState('actionEnabledMoveNodesToOtherPage'),
            children: formalRightClickMoveNodePageList(pageList, currentPageId, commandInvoker),
            testId: 'move-nodes-to-other-page',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_BRING_TO_FRONT,
                hiddenWhenDisabled: true,
            }),
            testId: 'bring-to-front',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_SEND_TO_BACK,
                hiddenWhenDisabled: true,
            }),
            testId: 'send-to-back',
        },
        {
            type: WKItemType.Divider,
            hidden: !useViewState('actionEnabledGroupSelectionStatus'),
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_GROUP_SELECTION,
                hiddenWhenDisabled: true,
            }),
            testId: 'group-selection',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FRAME_SELECTION,
                hiddenWhenDisabled: true,
            }),
            testId: 'frame-selection',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_UNGROUP_SELECTION,
                hiddenWhenDisabled: true,
            }),
            testId: 'ungroup-selection',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_RENAME,
                hiddenWhenDisabled: true,
            }),
            testId: 'rename',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLATTEN,
                hiddenWhenDisabled: true,
                handlerCallback: () => {
                    frogEventToolsMenusAndActionsBasicAction({
                        action_name: '使用拼合',
                        operation_way: '右键菜单',
                    })
                },
            }),
            testId: 'flatten-tool',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_OUTLINE_STROKE,
                hiddenWhenDisabled: true,
            }),
            testId: 'outline-tool',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_USE_AS_MASK,
                hiddenWhenDisabled: true,
                handlerCallback: () => {
                    frogEventToolsMenusAndActionsBasicAction({
                        action_name: '创建蒙版',
                        operation_way: '右键菜单',
                    })
                },
            }),
            testId: 'mask-tool',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_CANCEL_MASK,
                hiddenWhenDisabled: true,
                hidden: false,
                handlerCallback: () => {
                    frogEventToolsMenusAndActionsBasicAction({
                        action_name: '取消蒙版',
                        operation_way: '右键菜单',
                    })
                },
            }),
            testId: 'cancel-mask-tool',
        },
        {
            name: translation('SetAs'),
            forwardIcon: false,
            hidden: () => !enableSetAsThumbnail,
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(SetThumbnailIdWasmCall, {
                    value: singleSelection?.id,
                })
                WKToast.show(translation('ThumbnailSet'))
            },
        },
        {
            name: translation('RestoreDefaultThumbnail'),
            forwardIcon: false,
            hidden: () => !enableResetDefaultThumbnail,
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(SetThumbnailIdWasmCall, {
                    value: undefined,
                })
                WKToast.show(translation('DefaultThumbnailRestored'))
            },
        },
        {
            type: WKItemType.Divider,
            hidden: docReadonly,
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_ADD_AUTO_LAYOUT,
                hiddenWhenDisabled: true,
            }),
            testId: 'add-auto-layout',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_AUTO_LAYOUT,
                hiddenWhenDisabled: true,
            }),
            testId: 'remove-auto-layout',
        },
        {
            name: translation('MoreLayoutOption'),
            position: 'right top',
            hidden: !useViewState('actionEnabledMoreLayoutOptionStatus'),
            children: [
                {
                    ...useEditorActionStatusSubMenuItem({
                        command: commandInvoker,
                        editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_AI_CREATE_AUTO_LAYOUT,
                        hiddenWhenDisabled: true,
                    }),
                    testId: 'ai-create-auto-layout',
                },
                {
                    ...useEditorActionStatusSubMenuItem({
                        command: commandInvoker,
                        editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_REMOVE_ALL_AUTO_LAYOUT,
                        hiddenWhenDisabled: true,
                    }),
                    testId: 'remove-all-auto-layout',
                },
            ],
        },
        ...ComponentRelatedMenu(),
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_COMPONENT_SELECTION,
                hiddenWhenDisabled: true,
                handlerCallback: () => {
                    frogEventToolsMenusAndActionsBasicAction({
                        action_name: '创建组件',
                        operation_way: '右键菜单',
                    })
                },
            }),
            testId: 'component-selection',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_RESET_PROPERTIES,
                hiddenWhenDisabled: true,
                handlerCallback: () => {
                    frogEventToolsMenusAndActionsBasicAction({
                        action_name: '重置所有修改',
                        operation_way: '右键菜单',
                    })
                },
            }),
            testId: 'reset-properties',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_DETACH_INSTANCE,
                hiddenWhenDisabled: true,
            }),
            testId: 'detach-instance',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_GO_TO_SELECTED_INSTANCE_MAIN_COMPONENT,
                hiddenWhenDisabled: true,
            }),
            testId: 'go-to-selected-instance-main-component',
        },
        ...aiSearchMenuConfig,
        ...usePluginMenuConfig(),
        {
            type: WKItemType.Divider,
            hidden: !useViewState('actionEnabledToggleShownStatus'),
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOWN,
                hiddenWhenDisabled: true,
                // hidden: !docReadonly
            }),
            testId: 'toggle-shown',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_SHOW_COMMENT,
                hiddenWhenDisabled: true,
                // hidden: !docReadonly
            }),
            testId: 'toggle-shown-comment',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_TOGGLE_LOCKED,
                hiddenWhenDisabled: true,
            }),
            testId: 'toggle-locked',
        },
        {
            type: WKItemType.Divider,
            hidden: !useViewState('actionEnabledFlipHorizontalStatus'),
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_HORIZONTAL,
                hiddenWhenDisabled: true,
            }),
            testId: 'flip-horizontal',
        },
        {
            ...useEditorActionStatusSubMenuItem({
                command: commandInvoker,
                editorActionType: ActionEnabledType.ACTION_ENABLED_TYPE_FLIP_VERTICAL,
                hiddenWhenDisabled: true,
            }),
            testId: 'flip-vertical',
        },
        ...startingPointMenuConfig,
        {
            type: WKItemType.Divider,
            testId: 'remove-all-interactions-separator',
        },
        {
            name: translation('RemoveAllInteractions'),
            forwardIcon: false,
            hidden: !enableRemoveAllInteractions,
            handler: () => commandInvoker.invokeBridge(CommitType.CommitUndo, RemoveAllPrototypeInteractions),
            testId: 'remove-all-interactions',
        },
    ]

    return configs
}

export function usePluginMenuConfig(): WKMenuConfig {
    const pluginService = usePluginService()
    const lastUsedPlugin = useLastUsedPlugin()

    // 只读模式不展示{translation('Plugins')}
    const docReadonly = useViewState('docReadonly', false)

    if (lastUsedPlugin.disabledContextMenu || docReadonly) {
        return []
    }

    return [
        {
            name: translation('Plugins'),
            forwardIcon: true,
            hidden: false,
            position: 'right top',
            children: [
                ...pluginService.officialPlugins.map((plugin) => ({
                    name: plugin.manifest.name,
                    handler: () => {
                        pluginService.showModal(plugin.manifest.key)
                        lastUsedPlugin.onUserClickPlugin(plugin.manifest.key)
                    },
                })),
                ...pluginService.getEnabledPluginsForEditor().map((plugin) => ({
                    name: plugin.name,
                    handler: () => {
                        pluginService.runPublishedPlugin(plugin.id)
                    },
                })),
                ...(lastUsedPlugin.disabledLastUsedPlugin
                    ? []
                    : [
                          {
                              type: WKItemType.Divider,
                              hidden: false,
                          } as WKDividerMenuItem,
                          {
                              name: translation('RunLastPlugin'),
                              shortCut: shortcutLabelMap[ShortcutKey.RunLastPlugin],
                              handler: () => {
                                  lastUsedPlugin.onClick()
                              },
                          },
                      ]),
            ],
        },
    ]
}

export function useDesignModePagePanelRightClickMenuConfig(): WKMenuConfig {
    const pagePanelService = usePagePanelService()
    const { pages } = useViewState('documentPageList', { pages: [] })
    const docReadonly = useViewState('docReadonly', false)

    const planAndUserStateService = usePlanAndUserStateService()
    const planInfo = planAndUserStateService.useZustandStore.use.planAndUserState()
    const isFreeTeam = planInfo && isStarterPlan(planInfo)

    const handleDuplicatePage = () => {
        if (isFreeTeam && pages.length >= PAGE_LIMIT_NUM) {
            return PayUpgradeUpperPageNumLimit({
                from: PayUpgradeDialogFrom.Editor,
                teamId: planInfo?.resourceId,
            })
        }
        pagePanelService.duplicateSelectedPage()
    }

    return [
        {
            name: translation('CopyLinkTo'),
            handler: pagePanelService.copyUrlOfSelectedPage,
            testId: 'design-mode-copy-page-url',
        },
        { type: WKItemType.Divider, hidden: docReadonly },
        {
            name: translation('RenamePage'),
            hidden: docReadonly,
            handler: pagePanelService.renameSelectedPage,
            autoCommit: true,
        },
        {
            name: translation('DuplicatePage'),
            hidden: docReadonly,
            handler: handleDuplicatePage,
            autoCommit: true,
        },
        { type: WKItemType.Divider, hidden: docReadonly },
        {
            name: translation('DeletePage'),
            hidden: docReadonly,
            handler: pagePanelService.deleteSelectedPage,
            autoCommit: true,
            disable: pages.length <= 1,
        },
    ]
}

export function useDevModePagePanelRightClickMenuConfig(): WKMenuConfig {
    const pagePanelService = usePagePanelService()

    return [
        {
            name: translation('CopyLinkTo'),
            handler: pagePanelService.copyUrlOfSelectedPage,
            testId: 'dev-mode-copy-page-url',
        },
    ]
}

export function useDevModeLayerPanelRightClickMenuConfig(): WKMenuConfig {
    const commandInvoker = useCommand()
    const clipboardService = useClipboardService()
    const enableCopyAsText = useViewState('actionEnabledCopyAsTextStatus')
    const enableCopyAsSvg = useViewState('actionEnabledCopyAsSVGStatus')
    const enableCopyAsPng = useViewState('actionEnabledCopyAsPNGStatus')
    const enableCopyAsLink = useViewState('actionEnabledCopyAsLinkStatus')
    const toggleChildAction = useViewState('devModeLayerPanelToggleChildAction')

    return [
        {
            name: translation('ExpandAllChildren'),
            hidden:
                toggleChildAction !=
                Wukong.DocumentProto.VDevModeLayerPanelToggleChildAction
                    .V_DEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION_EXPAND,
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(DevModeLayerPanelExpandAllChildrenWasmCall)
            },
            testId: 'dev-mode-layer-panel-expand-child',
        },
        {
            name: translation('CollapseAllChildren'),
            hidden:
                toggleChildAction !=
                Wukong.DocumentProto.VDevModeLayerPanelToggleChildAction
                    .V_DEV_MODE_LAYER_PANEL_TOGGLE_CHILD_ACTION_COLLAPSE,
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(DevModeLayerPanelCollapseAllChildrenWasmCall)
            },
            testId: 'dev-mode-layer-panel-collapse-child',
        },
        { type: WKItemType.Divider },
        {
            name: translation('Copy'),
            shortCut: shortcutLabelMap[ShortcutKey.Copy],
            hidden: !useViewState('actionEnabledCopyStatus') || isFirefox(),
            handler: () => clipboardService.copy(),
            testId: 'copy',
        },
        {
            name: translation('CopyAsText'),
            hidden: !enableCopyAsText,
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_TEXT),
        },
        {
            name: `${translation('CopyAs')} SVG`,
            hidden: !enableCopyAsSvg || isFirefox(),
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_S_V_G),
        },
        {
            name: `${translation('CopyAs')} PNG`,
            shortCut: shortcutLabelMap[ShortcutKey.CopyAsPNG],
            hidden: !enableCopyAsPng || isFirefox(),
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_P_N_G),
            testId: 'right-menu-copy-as-png',
        },
        {
            name: translation('CopyLink'),
            hidden: !enableCopyAsLink,
            handler: () => clipboardService.copy(Wukong.DocumentProto.ClipboardCopyType.CLIPBOARD_COPY_TYPE_AS_LINK),
        },
        { type: WKItemType.Divider },
        {
            name: translation('GoToMain'),
            hidden: !useViewState('actionEnabledGoToSelectedInstanceMainComponentStatus'),
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(GoToSelectedInstanceMainComponentCommand)
            },
        },
    ]
}

export function useDesignPanelRightClickMenuConfig(): WKMenuConfig {
    const rightClickMenuState = useViewState('rightClickMenuState')
    const { selection, modelCommand, clipboardStyleNodeCount, styleSelectionCount } = useLocalStyleModel({
        designPanelRightMenuOpen:
            rightClickMenuState?.opened &&
            rightClickMenuState.source === Wukong.DocumentProto.RightClickSource.RIGHT_CLICK_SOURCE_DESIGN_PANEL,
    })

    const isEnglish = isEnglishLanguage()

    return [
        {
            name: translation('EditStyle'),
            forwardIcon: false,
            hidden: () => !(selection.folderNames.length === 0 && selection.styleIds.length === 1),
            handler: () =>
                modelCommand.updateEditStyle({
                    type: selection.type,
                    editingStyleId: selection.styleIds[0],
                }),
        },
        {
            name: translation('AddNewFolder'),
            forwardIcon: false,
            hidden: () => !(selection.folderNames.length > 0 || selection.styleIds.length > 0),
            handler: () => modelCommand.groupSelection(),
        },
        {
            name: translation('Ungroup'),
            forwardIcon: false,
            hidden: () => !(selection.folderNames.length === 1 && selection.styleIds.length === 0),
            handler: () => {
                modelCommand.ungroupSelection()
            },
            autoCommit: true,
        },
        {
            name: isEnglish
                ? styleSelectionCount > 1
                    ? `Delete ${styleSelectionCount} styles`
                    : 'Delete style'
                : styleSelectionCount > 1
                ? `删除 ${styleSelectionCount} 个样式`
                : '删除样式',
            forwardIcon: false,
            hidden: () => !styleSelectionCount,
            handler: modelCommand.deleteSelection,
            autoCommit: true,
        },
        { type: WKItemType.Divider },
        {
            name: isEnglish
                ? styleSelectionCount > 1
                    ? `Cut ${styleSelectionCount} styles`
                    : 'Cut style'
                : styleSelectionCount > 1
                ? `剪切 ${styleSelectionCount} 个样式`
                : '剪切样式',
            forwardIcon: false,
            hidden: () => !styleSelectionCount,
            handler: modelCommand.cutSelection,
            autoCommit: true,
        },
        {
            name: isEnglish
                ? styleSelectionCount > 1
                    ? `Paste ${styleSelectionCount} styles`
                    : 'Paste style'
                : styleSelectionCount > 1
                ? `粘贴 ${styleSelectionCount} 个样式`
                : '粘贴样式',
            forwardIcon: false,
            hidden: !clipboardStyleNodeCount,
            handler: modelCommand.paste,
            autoCommit: true,
        },
    ]
}

export function useRightClickBubbbleConfig(): WKMenuConfig {
    const commandInvoker = useCommand()
    const commentService = useCommentService()

    return [
        {
            name: translation('MarkAsResolved'),
            forwardIcon: false,
            hidden: commentService.isHideRightClickCommentResolved,
            handler: commentService.rightClickResolve,
        },
        {
            name: translation('MarkAsUnresolved'),
            forwardIcon: false,
            hidden: commentService.isHideRightClickCommentUnResolved,
            handler: commentService.rightClickUnResolve,
        },
        {
            name: translation('MarkAsUnread'),
            forwardIcon: false,
            hidden: commentService.isHideRightClickCommentUnread,
            handler: commentService.rightClickUnread,
        },
        {
            name: translation('CopyLink'),
            forwardIcon: false,
            hidden: commentService.isHideRightClickCopyCommentLink,
            handler: commentService.rightClickCopyCommentLink,
        },
        { type: WKItemType.Divider },
        {
            name: translation('HideComments'),
            forwardIcon: false,
            shortCut: shortcutLabelMap[ShortcutKey.ShowComment],
            handler: () => {
                commandInvoker.DEPRECATED_invokeBridge(ToggleShowCommentCommand)
            },
        },
    ]
}

export function useAIComponentPublishPanelRightClickMenuConfig(): WKMenuConfig {
    const pagePanelService = usePagePanelService()

    return [
        {
            name: translation('GoToMain'),
            handler: pagePanelService.locateToComponent,
        },
    ]
}

export function useDesignModeRightClickCtrlOrCmdConfig() {
    const layerSelection = useViewState('layerSelection')
    const commandInvoker = useCommand()

    if (layerSelection?.isShowLayerSelection) {
        return designModeFormalRightClickLayerSelectionList(
            layerSelection.rightClickLayerSelectionNodes,
            commandInvoker
        )
    } else {
        return []
    }
}

export function designModeFormalRightClickLayerSelectionList(
    list: Wukong.DocumentProto.IRightClickLayerSelectionNode[] | undefined,
    command: CommandInvoker
): WKMenuConfig {
    if (isNil(list) || list.length === 0) {
        return []
    }
    return list.map((listNode) => {
        return {
            ...listNode,
            key: listNode.id,
            forwardIcon: getSVGIconByNodeIconType(listNode.forwardIcon as Wukong.DocumentProto.NodeIconData),
            itemForwardIconClassName: 'svg-fill-color',
            showCheckBoxAndForwardIcon: true,
            testId: `right-click-layer-selection-${listNode.id}`,
            handler: () => {
                command.DEPRECATED_invokeBridge(
                    LayerPanelLeftClickCommandWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
                command.commitUndo()
            },
            mouseEnterHandler: () => {
                command.DEPRECATED_invokeBridge(
                    LayerPanelEnterHoverCommandWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
            },
            mouseLevelHandler: () => {
                command.DEPRECATED_invokeBridge(
                    LayerPanelCancelHoverCommandWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
            },
        }
    }) as WKMenuConfig
}

export function useDevModeRightClickCtrlOrCmdConfig() {
    const layerSelection = useViewState('layerSelection')
    const commandInvoker = useCommand()

    if (layerSelection?.isShowLayerSelection) {
        return devModeFormalRightClickLayerSelectionList(layerSelection.rightClickLayerSelectionNodes, commandInvoker)
    } else {
        return []
    }
}

export function devModeFormalRightClickLayerSelectionList(
    list: Wukong.DocumentProto.IRightClickLayerSelectionNode[] | undefined,
    command: CommandInvoker
): WKMenuConfig {
    if (isNil(list) || list.length === 0) {
        return []
    }
    return list.map((listNode) => {
        return {
            ...listNode,
            key: listNode.id,
            forwardIcon: getSVGIconByNodeIconType(listNode.forwardIcon as Wukong.DocumentProto.NodeIconData),
            itemForwardIconClassName: 'svg-fill-color',
            showCheckBoxAndForwardIcon: true,
            testId: `right-click-layer-selection-${listNode.id}`,
            handler: () => {
                command.DEPRECATED_invokeBridge(
                    LayerPanelLeftClickCommandWasmCall,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
                command.commitUndo()
            },
            mouseEnterHandler: () => {
                command.DEPRECATED_invokeBridge(
                    DevModeEnterRightClickSelectionCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
            },
            mouseLevelHandler: () => {
                command.DEPRECATED_invokeBridge(
                    DevModeLeaveRightClickSelectionCommand,
                    Wukong.DocumentProto.BridgeProtoString.create({
                        value: listNode.id,
                    })
                )
            },
        }
    }) as WKMenuConfig
}

export function formalRightClickMoveNodePageList(
    pageList: Wukong.DocumentProto.IVPageItem[],
    currentPageId: string,
    command: CommandInvoker
) {
    const validPageList = pageList.filter((page) => page.id !== currentPageId)
    return validPageList.map((page) => {
        return {
            ...page,
            key: page.id,
            showCheckBoxAndForwardIcon: false,
            testId: `right-click-move-node-${page.id}`,
            handler: () => {
                command.DEPRECATED_invokeBridge(
                    MoveNodesToOtherPage,
                    Wukong.DocumentProto.BridgeProtoString.create({ value: page.id })
                )
                command.commitUndo()
            },
        }
    }) as WKMenuConfig
}
