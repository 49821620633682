/* eslint-disable no-restricted-imports */
import { Wukong } from '@wukong/bridge-proto'
import { HTMLAttributes, useMemo } from 'react'
import {
    IconBackgroundBlur,
    IconDropShadowLeft,
    IconDropShadowLower,
    IconDropShadowLowerLeft,
    IconDropShadowLowerRight,
    IconDropShadowRight,
    IconDropShadowUpper,
    IconDropShadowUpperLeft,
    IconDropShadowUpperRight,
    IconEffect,
    IconInnerShadowLeft,
    IconInnerShadowLower,
    IconInnerShadowLowerLeft,
    IconInnerShadowLowerRight,
    IconInnerShadowRight,
    IconInnerShadowUpper,
    IconInnerShadowUpperLeft,
    IconInnerShadowUpperRight,
    IconLayerBlur,
} from '../../../../../../../ui-lib/src'
import { EffectStyleIconType } from '../../../../../kernel/interface/component-style'

export interface EffectStyleIconProps extends HTMLAttributes<SVGElement> {
    iconType: EffectStyleIconType
}

export function EffectStyleIcon({ iconType, ...props }: EffectStyleIconProps) {
    const IconSvg = useMemo(() => {
        switch (iconType) {
            case EffectStyleIconType.Default:
                return IconEffect
            case EffectStyleIconType.BackgroundBlur:
                return IconBackgroundBlur
            case EffectStyleIconType.LayerBlur:
                return IconLayerBlur
            case EffectStyleIconType.DropButtom:
                return IconDropShadowLower
            case EffectStyleIconType.DropButtomLeft:
                return IconDropShadowLowerLeft
            case EffectStyleIconType.DropButtomRight:
                return IconDropShadowLowerRight
            case EffectStyleIconType.DropLeft:
                return IconDropShadowLeft
            case EffectStyleIconType.DropRight:
                return IconDropShadowRight
            case EffectStyleIconType.DropTop:
                return IconDropShadowUpper
            case EffectStyleIconType.DropTopLeft:
                return IconDropShadowUpperLeft
            case EffectStyleIconType.DropTopRight:
                return IconDropShadowUpperRight
            case EffectStyleIconType.InnerButtom:
                return IconInnerShadowLower
            case EffectStyleIconType.InnerButtomLeft:
                return IconInnerShadowLowerLeft
            case EffectStyleIconType.InnerButtomRight:
                return IconInnerShadowLowerRight
            case EffectStyleIconType.InnerLeft:
                return IconInnerShadowLeft
            case EffectStyleIconType.InnerRight:
                return IconInnerShadowRight
            case EffectStyleIconType.InnerTop:
                return IconInnerShadowUpper
            case EffectStyleIconType.InnerTopLeft:
                return IconInnerShadowUpperLeft
            case EffectStyleIconType.InnerTopRight:
                return IconInnerShadowUpperRight
        }
    }, [iconType])

    return <IconSvg {...props} />
}

export function getEffectStyleIcon(effects: ReadonlyArray<Wukong.DocumentProto.IEffect>): EffectStyleIconType {
    const effectsLength = effects.length
    if (!effectsLength) {
        return EffectStyleIconType.Default
    }
    const lastEffect = effects[effectsLength - 1]
    const lastEffectType = lastEffect.type
    if (lastEffectType === Wukong.DocumentProto.EffectType.EFFECT_TYPE_BACKGROUND_BLUR) {
        return EffectStyleIconType.BackgroundBlur
    }
    if (lastEffectType === Wukong.DocumentProto.EffectType.EFFECT_TYPE_LAYER_BLUR) {
        return EffectStyleIconType.LayerBlur
    }

    if (lastEffectType === Wukong.DocumentProto.EffectType.EFFECT_TYPE_DROP_SHADOW) {
        const lastEffectOffset = { x: lastEffect.offset?.x ?? 0, y: lastEffect.offset?.y ?? 0 }
        const lastEffectOffsetX = parseFloat(lastEffectOffset.x.toFixed(2))
        const lastEffectOffsetY = parseFloat(lastEffectOffset.y.toFixed(2))
        if (lastEffectOffsetX === 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.DropButtom
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.DropButtomRight
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.DropButtomLeft
        }
        if (lastEffectOffsetX === 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.DropTop
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.DropTopRight
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.DropTopLeft
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY === 0) {
            return EffectStyleIconType.DropRight
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY === 0) {
            return EffectStyleIconType.DropLeft
        }
    }
    if (lastEffectType === Wukong.DocumentProto.EffectType.EFFECT_TYPE_INNER_SHADOW) {
        const lastEffectOffset = { x: lastEffect.offset?.x ?? 0, y: lastEffect.offset?.y ?? 0 }
        const lastEffectOffsetX = parseFloat(lastEffectOffset.x.toFixed(2))
        const lastEffectOffsetY = parseFloat(lastEffectOffset.y.toFixed(2))
        if (lastEffectOffsetX === 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.InnerTop
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.InnerTopLeft
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY > 0) {
            return EffectStyleIconType.InnerTopRight
        }
        if (lastEffectOffsetX === 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.InnerButtom
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.InnerButtomLeft
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY < 0) {
            return EffectStyleIconType.InnerButtomRight
        }
        if (lastEffectOffsetX > 0 && lastEffectOffsetY === 0) {
            return EffectStyleIconType.InnerLeft
        }
        if (lastEffectOffsetX < 0 && lastEffectOffsetY === 0) {
            return EffectStyleIconType.InnerRight
        }
    }
    return EffectStyleIconType.Default
}
