import { useRef } from 'react'
import { WKPopover, WKPopoverRef } from '../../../../../../../ui-lib/src'
import { WKIconButton } from '../../../../../../../ui-lib/src/components/wk-button/icon-button'
import { useAppContext } from '../../../../../main/app-context'
import { useDebugSetting } from './debug-setting'

export const ChatbotDebugSettingPanel = () => {
    const chatbotService = useAppContext().chatbotService

    const { debugSetting, setDebugSetting } = useDebugSetting()

    const debugPopoverRef = useRef<WKPopoverRef>(null)

    return (
        <WKPopover
            placement="bottom-left"
            contentClassName="p-0"
            contents={
                <div className="py-2">
                    <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => chatbotService.exportCurrentDialogueAsFile()}
                    >
                        导出当前对话
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between"
                        onClick={() => setDebugSetting('enablePresetInput', !debugSetting.enablePresetInput)}
                    >
                        <span>使用预设输入 & 输入历史记录: </span>
                        <span>{debugSetting.enablePresetInput ? '开' : '关'}</span>
                    </div>
                    <div
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between"
                        onClick={() => setDebugSetting('focusV2', !debugSetting.focusV2)}
                    >
                        <span>使用 focusV2: </span>
                        <span>{debugSetting.focusV2 ? '开' : '关'}</span>
                    </div>
                </div>
            }
            ref={debugPopoverRef}
        >
            <WKIconButton
                icon={
                    <div className="flex items-center justify-center w-4 h-4">
                        <DebugIcon />
                    </div>
                }
                onClick={() => debugPopoverRef.current?.show()}
            />
        </WKPopover>
    )
}

const DebugIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <clipPath id="clipPath8000089982a">
                    <path transform="matrix(1 0 0 1 0 0)" d="M0 0L24 0L24 24L0 24L0 0Z" fillRule="nonzero" />
                </clipPath>
            </defs>
            <g clipPath="url(#clipPath8000089982)">
                <path
                    transform="matrix(1 0 0 1 5 7)"
                    d="M7 13Q10.3535 13 11.9131 10.615Q12.4925 9.72893 12.7807 8.59295Q13 7.72861 13 7L13 0L14 0L14 1L0 1L0 0L1 0L1 7Q1 7.71359 1.21914 8.57509Q1.50829 9.71185 2.08777 10.601Q3.65133 13 7 13ZM7 15Q4.64579 15 2.88891 14.0228Q1.38571 13.1867 0.412226 11.693Q-0.348264 10.5262 -0.719138 9.06813Q-1 7.96397 -1 7L-1 0Q-1 -0.0984914 -0.980785 -0.19509Q-0.96157 -0.291689 -0.923879 -0.382683Q-0.886188 -0.473678 -0.83147 -0.55557Q-0.776751 -0.637463 -0.707107 -0.707107Q-0.637463 -0.776751 -0.55557 -0.831469Q-0.473678 -0.886188 -0.382683 -0.923879Q-0.291689 -0.961571 -0.19509 -0.980785Q-0.0984914 -1 0 -1L14 -1Q14.0985 -1 14.1951 -0.980785Q14.2917 -0.96157 14.3827 -0.923879Q14.4737 -0.886188 14.5556 -0.83147Q14.6375 -0.776751 14.7071 -0.707107Q14.7767 -0.637463 14.8315 -0.55557Q14.8862 -0.473678 14.9239 -0.382683Q14.9616 -0.291689 14.9808 -0.19509Q15 -0.0984914 15 0L15 7Q15 7.97835 14.7193 9.08478Q14.349 10.5442 13.5869 11.7096Q12.6138 13.1978 11.1088 14.0298Q9.35379 15 7 15Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 2 4)"
                    d="M-0.707107 -0.707107C-1.09763 -0.316586 -1.09763 0.316586 -0.707107 0.707107L2.29289 3.70711C2.68341 4.09763 3.31659 4.09763 3.70711 3.70711C4.09763 3.31659 4.09763 2.68341 3.70711 2.29289L0.707107 -0.707107C0.316586 -1.09763 -0.316586 -1.09763 -0.707107 -0.707107Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 19 4)"
                    d="M3.70711 -0.707107C3.31659 -1.09763 2.68341 -1.09763 2.29289 -0.707107L-0.707107 2.29289C-1.09763 2.68341 -1.09763 3.31659 -0.707107 3.70711C-0.316586 4.09763 0.316586 4.09763 0.707107 3.70711L3.70711 0.707107C4.09763 0.316586 4.09763 -0.316586 3.70711 -0.707107Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 2 13.5)"
                    d="M-1 0C-1 0.55228 -0.55228 1 0 1L3 1C3.55228 1 4 0.55228 4 0C4 -0.55228 3.55228 -1 3 -1L0 -1C-0.55228 -1 -1 -0.55228 -1 0Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 19 13.5)"
                    d="M-1 0C-1 0.55228 -0.55228 1 0 1L3 1C3.55228 1 4 0.55228 4 0C4 -0.55228 3.55228 -1 3 -1L0 -1C-0.55228 -1 -1 -0.55228 -1 0Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 3.5 19)"
                    d="M3.70711 -0.707107C3.31659 -1.09763 2.68341 -1.09763 2.29289 -0.707107L-0.707107 2.29289C-1.09763 2.68341 -1.09763 3.31659 -0.707107 3.70711C-0.316586 4.09763 0.316586 4.09763 0.707107 3.70711L3.70711 0.707107C4.09763 0.316586 4.09763 -0.316586 3.70711 -0.707107Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 17.5 19)"
                    d="M-0.707107 -0.707107C-1.09763 -0.316586 -1.09763 0.316586 -0.707107 0.707107L2.29289 3.70711C2.68341 4.09763 3.31659 4.09763 3.70711 3.70711C4.09763 3.31659 4.09763 2.68341 3.70711 2.29289L0.707107 -0.707107C0.316586 -1.09763 -0.316586 -1.09763 -0.707107 -0.707107Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 12 7)"
                    d="M0 -1C-0.55228 -1 -1 -0.55228 -1 0L-1 14C-1 14.5523 -0.55228 15 0 15C0.55228 15 1 14.5523 1 14L1 0C1 -0.55228 0.55228 -1 0 -1Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 7.4602 19.5068)"
                    d="M9.87152 -0.64443C9.51561 -1.06674 8.88473 -1.12057 8.46242 -0.764663Q6.97005 0.493052 4.53975 0.493052Q2.13124 0.493052 0.64177 -0.753397C0.218229 -1.10783 -0.41246 -1.05181 -0.766897 -0.62827C-1.12133 -0.204728 -1.06531 0.42596 -0.64177 0.780397Q1.4048 2.49305 4.53975 2.49305Q7.70042 2.49305 9.75128 0.764663C10.1736 0.408757 10.2274 -0.222122 9.87152 -0.64443Z"
                    fillRule="evenodd"
                    fill="currentColor"
                />
                <path
                    transform="matrix(1 0 0 1 8 2)"
                    d="M7 4.16665Q7 3.51423 6.75913 2.92102Q6.52773 2.35113 6.10704 1.91292Q5.6905 1.47902 5.1538 1.24255Q4.60327 1 4 1Q3.39673 1 2.8462 1.24255Q2.3095 1.47902 1.89296 1.91292Q1.47227 2.35113 1.24087 2.92102Q1 3.51423 1 4.16665L1 5L0 5L0 4L8 4L8 5L7 5L7 4.16665ZM9 4.16665L9 5Q9 5.09849 8.98079 5.19509Q8.96157 5.29169 8.92388 5.38268Q8.88619 5.47368 8.83147 5.55557Q8.77675 5.63746 8.70711 5.70711Q8.63746 5.77675 8.55557 5.83147Q8.47368 5.88619 8.38268 5.92388Q8.29169 5.96157 8.19509 5.98078Q8.09849 6 8 6L0 6Q-0.0984914 6 -0.19509 5.98078Q-0.291689 5.96157 -0.382683 5.92388Q-0.473678 5.88619 -0.55557 5.83147Q-0.637463 5.77675 -0.707107 5.70711Q-0.776751 5.63746 -0.831469 5.55557Q-0.886188 5.47368 -0.923879 5.38268Q-0.961571 5.29169 -0.980785 5.19509Q-1 5.09849 -1 5L-1 4.16665Q-1 3.12367 -0.612194 2.16859Q-0.236365 1.24301 0.450182 0.527854Q1.14087 -0.191611 2.03983 -0.58768Q2.97567 -1 4 -1Q5.02433 -1 5.96017 -0.58768Q6.85913 -0.191612 7.54982 0.527854Q8.23636 1.24301 8.61219 2.16859Q9 3.12367 9 4.16665Z"
                    fillRule="nonzero"
                    fill="currentColor"
                />
            </g>
        </svg>
    )
}
