import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    RemoveFromLibrary: '确定取消发布组件库吗？',
    ThisActionWill: '取消发布组件库，团队成员将不可引用此组件库。',
    Remove: '取消发布',
    SuccessfullyUnpublishedLibrary: '已取消发布',
    FailedTo: '取消发布失败',
    VoUyYL: '确定取消识别组件吗？',
    DlBCCu: '取消识别',
    pwxJTX: '继续识别',
    UnknownLibrary: '未知组件库',
    XlvpuX: '维护',
    gHrUlZ: '本文件包含',
    ',': '和',
    'NoComponents,styles': '本文件内无组件和样式',
    'NoComponents,styles,variables': '本文件内无组件、样式或变量',
    LocalLibraryNot: '本地组件库未发布',
    Publish: '发布组件库',
    FBOUJC: '当前组件库有 {{count}} 处修改，请及时发布',
    Publish_synonyms1: '发布更新',
    Unpublish: '取消发布',
    ThreeTypes: '{{type1}}，{{type2}}和{{type3}}',
} as const

export const enTranslation = {
    RemoveFromLibrary: 'Remove from library?',
    ThisActionWill: 'This action will remove all of the components in this file from the library.',
    Remove: 'Remove',
    SuccessfullyUnpublishedLibrary: 'Successfully unpublished library',
    FailedTo: 'An error occurred while unpublishing library',
    VoUyYL: 'Are you sure you want to stop recognizing components?',
    DlBCCu: 'Stop recogniwzing',
    pwxJTX: 'Continue',
    UnknownLibrary: 'Unknown library',
    XlvpuX: '维护',
    gHrUlZ: '',
    ',': ',',
    'NoComponents,styles': 'No components or styles',
    'NoComponents,styles,variables': 'No components, styles or variables',
    LocalLibraryNot: 'Local library not published',
    Publish: 'Publish',
    FBOUJC: 'Current library has {{count}} {{change}}.',
    Publish_synonyms1: 'Publish',
    Unpublish: 'Unpublish',
    ThreeTypes: '{{type1}}, {{type2}}, {{type3}}',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
