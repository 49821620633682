import { useCallback, useMemo, useRef } from 'react'
import { Position } from '../../../../../../ui-lib/src'
import { useFeatureSwitch } from '../../../../kernel/switch/hooks'
import { useUserConfigStateV2 } from '../../../../main/user-config/user-config-hook'

export const useAiGenUiModalMaxHeight = (
    containsImage: boolean,
    failedType: false | 'one-failed' | 'either-failed'
) => {
    const enableCustomConfig = useFeatureSwitch('ai-gen-custom-config')
    // 屏幕高度 - 顶层tab_bar高度 - 标题栏 - 底部栏 - 上下边距 - 与tab_bar的距离 - 与底部的距离 - 因为是context-box所有还要去掉16
    const maxHeight = 540 - 54 - 64 - 16 - 16 - 8 - 8 - 16 - 5
    const afterConfigHeight = maxHeight - (enableCustomConfig ? 32 : 0)
    const afterCheckFailed =
        afterConfigHeight - (failedType === 'one-failed' ? 44 : failedType === 'either-failed' ? 64 : 0)
    if (containsImage) {
        return afterCheckFailed - 78
    }
    return afterCheckFailed
}

export const useAIGenUIModalPos = () => {
    const [posV2, storeCurrentPosition] = useUserConfigStateV2('AIGenUIModalPos')
    const inMemPosV2 = useRef(posV2)

    const initPos = useMemo((): Position | undefined => {
        return posV2
    }, [posV2])

    const storeCurrentPos = useCallback(() => {
        storeCurrentPosition(inMemPosV2.current)
    }, [storeCurrentPosition])

    const updateCurrentPos = useCallback((newPos: Position | undefined) => {
        inMemPosV2.current = newPos
    }, [])

    return {
        initPos,
        storeCurrentPos,
        updateCurrentPos,
    } as const
}
