import { translation } from './style-text.translation'
/* eslint-disable no-restricted-imports */
import { DeleteTextStyleNode, Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    DraggablePopupV2,
    DropdownDefaultCustomNode,
    DropdownV2,
    IconComponentLibrary16,
    InputV2,
    Position,
    RectContainer,
    ScrollView,
    WKButton,
} from '../../../../../../../ui-lib/src'
import { sortArrayByKey } from '../../../../../../../util/src'
import { cmdChangePopupState } from '../../../../../document/command/document-command'
import { cmdChangeAttrPanelStyleEditorState } from '../../../../../document/command/node-props-command'
import { ITextStyle, PopupStateType, TextStyleNode } from '../../../../../document/node/node'
import { ToKeyCode } from '../../../../../document/util/keycode'
import { StyleGetVO } from '../../../../../kernel/interface/component-style'
import { useComponentService, useLibraryComponentService } from '../../../../../main/app-context'
import { KeyboardReceiver } from '../../../../../main/keyboard-receiver/component'
import { WKFrogTask } from '../../../../../share/wk-frog-task'
import { useViewState } from '../../../../../view-state-bridge'
import { LibraryStyleTestId } from '../../../../../window'
import { useCommand } from '../../../../context/document-context'
import { isSearchMatched, searchMatchedFilter } from '../../../../utils/search-sort'
import { useTextStyleModel } from '../../text/hooks/use-text-style-model'
import { CommonStyleInfo, groupStyleVOByName, parseStyleName } from '../get-style-nodes-info-map'
import { useKeyboardStyleStyleList } from '../hooks/use-keyboard-style-list'
import { modifiedMultiPopup } from '../modified-multi-popup'
import style from '../style-color/style-color.module.less'
import { TextStyleEditor, TextStyleEditorProps } from '../style-panel/text-style-editor/text-style-editor'
import { StyleTitle, StyleType } from '../style-title/style-title'
import { RemoteStyleTextListItem } from './remote-style-text-list-item'
import { StyleTextListItem } from './style-text-list-item'
import { createITextStyle } from './style-text-utils'
import { UnknowStyleTextListItem } from './unknow-style-text-list-item'

interface StyleTextProps {
    open?: boolean
    position?: Position
    selectStyleId?: string
    selectTextStyleNode?: TextStyleNode
    isMissFamily?: boolean
    onChangeStyle?: (styleId: string, isCreateStyle: boolean) => void
}

interface UnknowStyleGroup {
    libraryId?: string
    libraryName?: string
    docId?: string
    value: TextStyleNode
    groups: Map<string, TextStyleNode[]>
}

export function StyleText(props: StyleTextProps): JSX.Element {
    const { open, position, selectStyleId, selectTextStyleNode, isMissFamily, onChangeStyle } = props

    const command = useCommand()
    const componentService = useComponentService()
    const containerRef = useRef<HTMLDivElement>(null)
    const hasMoved = useRef<boolean>(false)
    const [searchStr, setSearchStr] = useState<string>('')
    const [styleIdEditor, setStyleIdEditor] = useState<string>('')
    const [isRemoteStyle, setIsRemoteStyle] = useState<boolean>(false)
    const [createStyle, setCreateStyle] = useState<TextStyleEditorProps['createStyle']>({
        isCreate: false,
    })
    const [remoteStyleInfo, setRemoteStyleInfo] = useState<{
        styleId?: string
        docId?: string
        fromFig?: boolean
        name?: string
    }>()
    const [positionEditor, setPositionEditor] = useState<Position>()
    const attrPanelStyleEditorState = useViewState('attrPanelStyleEditorState')
    const popupState = useViewState('popupState')
    const [contextMenuInfo, setContextMenuInfo] = useState<{
        styleNodeId: string
        rectContainer: RectContainer
    } | null>(null)

    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryTextStyleState',
        {
            localStyleGroups: [],
            inUseUnknownStyleNodeItems: [],
            subscribedRemoteItems: [],
            inUseOtherRemoteItems: [],
            fakeFieldToForceUpdate: false,
        }
    )

    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()
    const titleContainerRef = useRef<HTMLDivElement>(null)
    const [isCreateStyle, setIsCreateStyle] = useState<boolean>(false)

    const {
        modelState: { selectionAddStyleInfo },
    } = useTextStyleModel()
    const disabledAddStyle = !selectionAddStyleInfo.canAdd || isMissFamily

    const localStyleMap = useMemo(() => {
        return localStyleGroups
            .filter((group) => group.items.length > 0)
            .map((group) => {
                return {
                    ...group,
                    items: group.items.map((item): CommonStyleInfo & ITextStyle & { contentHash: string } => {
                        const { styleName, groupName } = parseStyleName(item.name)
                        return {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            styleName,
                            groupName,
                            ...createITextStyle(item as unknown as TextStyleNode),
                            contentHash: item.contentHash,
                            missFontInfo: item.missFontInfo ?? { isMissStyle: false, isMissFamily: false },
                        }
                    }),
                }
            })
    }, [localStyleGroups])

    const showStyleMap = useMemo(() => {
        const infoMap: typeof localStyleMap = []
        if (!open) {
            return infoMap
        }

        return localStyleMap
            .map((group) => ({
                ...group,
                items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
            }))
            .filter((group) => group.items.length > 0)
    }, [localStyleMap, open, searchStr])

    const hasRemoteStyle = useMemo(() => {
        const selectStyleNodeKey = selectTextStyleNode?.key ?? ''
        return (
            subscribedRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) => group.items.length > 0)
            }) ||
            inUseOtherRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) =>
                    group.items.find((item) => selectStyleNodeKey && item.id === selectStyleNodeKey)
                )
            })
        )
    }, [inUseOtherRemoteItems, selectTextStyleNode?.key, subscribedRemoteItems])

    const isNoStyle = localStyleMap.length === 0 && !hasRemoteStyle && inUseUnknownStyleNodeItems.length === 0

    const remoteStyleList = useMemo(() => {
        const libs = subscribedRemoteItems.map((libraryContent) => ({
            libraryId: libraryContent.libraryId,
            libraryName: libraryContent.libraryName,
            docId: libraryContent.documentId,
            groups: (isSearchMatched(libraryContent.libraryName, searchStr)
                ? libraryContent.groups
                : libraryContent.groups.map((group) => ({
                      ...group,
                      items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
                  }))
            ).filter((group) => group.items.length) as unknown as Array<{ name: string; items: StyleGetVO[] }>,
        }))

        const selectTextStyleNodeKey = selectTextStyleNode?.key ?? ''
        inUseOtherRemoteItems.forEach((libraryContent) => {
            libs.push({
                libraryId: libraryContent.libraryId,
                libraryName: libraryContent.libraryName,
                docId: libraryContent.documentId,
                groups: libraryContent.groups
                    .map((group) => {
                        return {
                            ...group,
                            items: group.items.filter(
                                (item) =>
                                    selectTextStyleNodeKey &&
                                    item.id === selectTextStyleNodeKey &&
                                    isSearchMatched(item.name, searchStr)
                            ),
                        }
                    })
                    .filter((group) => group.items.length > 0) as unknown as Array<{
                    name: string
                    items: StyleGetVO[]
                }>,
            })
        })
        return sortArrayByKey(
            libs.filter(({ groups }) => groups.length),
            'libraryName'
        )
    }, [subscribedRemoteItems, selectTextStyleNode?.key, inUseOtherRemoteItems, searchStr])

    const unknownStyleGroup: UnknowStyleGroup | undefined = useMemo(() => {
        const unknowStyleNodes = inUseUnknownStyleNodeItems as unknown as TextStyleNode[]

        for (const value of unknowStyleNodes) {
            if (value.id !== selectStyleId) continue
            if (!isSearchMatched(value.name, searchStr)) continue

            return {
                libraryId: '',
                libraryName: translation('UnknownLibrary'),
                docId: value.publishFile,
                value,
                groups: groupStyleVOByName<TextStyleNode>([value]),
            }
        }
        return undefined
    }, [inUseUnknownStyleNodeItems, searchStr, selectStyleId])

    const openStyleEditor = useMemo(() => {
        const multiPopup = popupState?.multiPopup
        if (!multiPopup || multiPopup.length === 0) {
            return false
        }
        return multiPopup.some((v) => v.type === PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR)
    }, [popupState?.multiPopup])

    const onFirstMove = useCallback(() => {
        hasMoved.current = true
    }, [])

    const setDocumentPopupState = useCallback(
        (openPopup: boolean) => {
            const _popupState = modifiedMultiPopup(
                popupState,
                PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR,
                openPopup
                    ? {
                          type: PopupStateType.POPUP_STATE_TYPE_TEXT_STYLE_EDITOR,
                          reciprocalIndex: -1,
                          multiPopup: [],
                      }
                    : undefined
            )
            command.invoke(cmdChangePopupState, _popupState)
        },
        [command, popupState]
    )
    const onChangeSearchInput = useCallback((value: string) => {
        WKFrogTask.textStyle._recordSearchContent(value)
        setSearchStr(value)
    }, [])

    const onCloseEditor = useCallback(() => {
        setStyleIdEditor('')
        setDocumentPopupState(false)
        command.invoke(cmdChangeAttrPanelStyleEditorState, {
            editingStyleId: '',
            openFromModule: attrPanelStyleEditorState?.openFromModule,
        })
    }, [command, setDocumentPopupState, attrPanelStyleEditorState?.openFromModule])

    const onOpenEditor = useCallback(
        (styleId: string, isCreate?: boolean) => {
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                editingStyleId: styleId,
                isCreate,
            })
            setDocumentPopupState(true)
            setStyleIdEditor(styleId)
            setIsCreateStyle(false)
            setCreateStyle({ isCreate: false })
        },
        [command, setDocumentPopupState, attrPanelStyleEditorState?.openFromModule]
    )
    const effectCreateStyle = useCallback(
        (styleId: string) => {
            onChangeStyle?.(styleId, false)
            command.invoke(cmdChangeAttrPanelStyleEditorState, {
                openFromModule: attrPanelStyleEditorState?.openFromModule,
                editingStyleId: styleId,
                isCreate: true,
            })
            setStyleIdEditor(styleId)
        },
        [attrPanelStyleEditorState?.openFromModule, command, onChangeStyle]
    )

    const onClickAddStyle = useCallback(() => {
        if (!selectionAddStyleInfo.canAdd) {
            return
        }
        const rect = titleContainerRef.current?.getBoundingClientRect()
        if (!rect) {
            return
        }
        setDocumentPopupState(true)
        setCreateStyle({
            isCreate: true,
            createBase: selectionAddStyleInfo.styleNode ?? selectionAddStyleInfo.style,
        })

        setPositionEditor({ left: rect.left, top: rect.top })
        setIsRemoteStyle(false)
    }, [
        selectionAddStyleInfo.canAdd,
        selectionAddStyleInfo.style,
        selectionAddStyleInfo.styleNode,
        setDocumentPopupState,
    ])

    const isSearchState = useRef<() => boolean>()
    isSearchState.current = () => !!searchStr

    const onClickApplyStyle = useCallback(
        ({ id }: any) => {
            if (isSearchState.current?.()) {
                WKFrogTask.textStyle.searchResults()
            } else {
                WKFrogTask.textStyle.textStyleList()
            }
            onCloseEditor()
            onChangeStyle?.(id, !hasMoved.current)
        },
        [onChangeStyle, onCloseEditor]
    )

    const onClickHoverIcon = useCallback(
        (
            { id }: any,
            containerDomRect: DOMRect,
            remoteStyleId?: string,
            docId?: string,
            name?: string,
            fromFig?: boolean
        ) => {
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            styleIdEditor === id ? onCloseEditor() : onOpenEditor(id)
            setPositionEditor({ left: containerDomRect.left, top: containerDomRect.top })
        },
        [onCloseEditor, onOpenEditor, styleIdEditor]
    )

    const onContextMenu = useCallback(
        ({ id }: any, e: any, remoteStyleId?: string, docId?: string, name?: string, fromFig?: boolean) => {
            const { clientX, clientY } = e
            setIsRemoteStyle(!!remoteStyleId)
            setRemoteStyleInfo({ styleId: remoteStyleId, docId, name, fromFig })
            setContextMenuInfo({
                styleNodeId: id,
                rectContainer: {
                    top: clientY,
                    bottom: clientY,
                    right: clientX,
                    left: clientX,
                },
            })
        },
        []
    )

    const onCloseContextMenu = useCallback(() => {
        setContextMenuInfo(null)
    }, [])

    const editStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)
            const {
                rectContainer: { left, top },
                styleNodeId,
            } = contextMenuInfo
            onOpenEditor(styleNodeId)
            setPositionEditor({ left, top })
        },
        [contextMenuInfo, onOpenEditor]
    )

    const deleteStyle = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            if (!contextMenuInfo) {
                return
            }
            setContextMenuInfo(null)

            command.DEPRECATED_invokeBridge(
                DeleteTextStyleNode,
                Wukong.DocumentProto.BridgeProtoString.create({
                    value: contextMenuInfo.styleNodeId,
                })
            )
            command.commitUndo()
        },
        [contextMenuInfo, command]
    )

    const jumpToOrigin = useCallback(
        (e?: any) => {
            e?.stopPropagation()
            componentService.jumpToOriginDocument({
                docId: remoteStyleInfo?.docId,
                nodeId: remoteStyleInfo?.styleId,
                name: remoteStyleInfo?.name,
                fromFig: remoteStyleInfo?.fromFig,
            })
        },
        [
            componentService,
            remoteStyleInfo?.docId,
            remoteStyleInfo?.fromFig,
            remoteStyleInfo?.name,
            remoteStyleInfo?.styleId,
        ]
    )

    useEffect(() => {
        if (!open) {
            setSearchStr('')
            hasMoved.current = false
        }
    }, [open])

    const noSearchResult = useMemo(() => {
        return (
            showStyleMap.length === 0 &&
            !remoteStyleList?.filter((v) => v.groups.length > 0).length &&
            !unknownStyleGroup?.groups.size
        )
    }, [remoteStyleList, showStyleMap.length, unknownStyleGroup?.groups.size])

    const needSplitLineWithRemoteStyleListItem = (index: number) => {
        const hasItemBeforeRemoteStyle = showStyleMap.length > 0 || !!unknownStyleGroup?.groups.size
        if (hasItemBeforeRemoteStyle) {
            return true
        }
        const firstItemIndex = remoteStyleList?.findIndex((library) => library.groups.length > 0) ?? -1
        if (firstItemIndex !== -1 && index > firstItemIndex) {
            return true
        }
        return false
    }
    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        if (unknownStyleGroup) {
            for (const [_, styles] of unknownStyleGroup.groups) {
                optionSum += styles.length ?? 0
            }
        }
        if (remoteStyleList) {
            for (const { groups } of remoteStyleList) {
                for (const { items } of groups) {
                    optionSum += items.length ?? 0
                }
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [remoteStyleList, showStyleMap, unknownStyleGroup])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })

    const renderItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item) => (
                                <StyleTextListItem
                                    key={item.id}
                                    item={item}
                                    openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                    onContextMenu={onContextMenu}
                                    onSelectItem={onClickApplyStyle}
                                    onClickHoverIcon={onClickHoverIcon}
                                    dataTestId={`${LibraryStyleTestId.LocalTextStyleItem}-${item.id}`}
                                    index={optionIndex++}
                                    selectStyleId={_selectStyleId}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: !!showStyleMap.length,
                            })}
                        >
                            {unknownStyleGroup.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                {styles.map((item) => (
                                    <UnknowStyleTextListItem
                                        key={item.id}
                                        item={item}
                                        docId={unknownStyleGroup.docId || ''}
                                        openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                        onContextMenu={onContextMenu}
                                        onSelectItem={onClickApplyStyle}
                                        onClickHoverIcon={onClickHoverIcon}
                                        index={optionIndex++}
                                        selectStyleId={_selectStyleId}
                                        setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                        trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                        recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                    />
                                ))}
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}
                {remoteStyleList?.map(
                    (library, index) =>
                        library.groups.length > 0 && (
                            <div key={library.libraryId}>
                                <div
                                    className={classnames(style.h1, {
                                        [style.splitLine]: needSplitLineWithRemoteStyleListItem(index),
                                    })}
                                >
                                    {library?.libraryName}
                                </div>
                                {library.groups.map(({ name, items }, index2) => (
                                    <div key={index2}>
                                        {name ? <div className={style.h2}>{name}</div> : null}
                                        {items.map((item) => (
                                            <RemoteStyleTextListItem
                                                key={item.id}
                                                item={item}
                                                selectStyleKey={selectTextStyleNode?.key ?? ''}
                                                docId={library.docId || ''}
                                                openStyleId={open && openStyleEditor ? styleIdEditor : undefined}
                                                onContextMenu={onContextMenu}
                                                onSelectItem={onClickApplyStyle}
                                                onClickHoverIcon={onClickHoverIcon}
                                                index={optionIndex++}
                                                selectStyleId={_selectStyleId}
                                                setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                                trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                                recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                            ></RemoteStyleTextListItem>
                                        ))}
                                        <div className={style.paddingBottom8}></div>
                                    </div>
                                ))}
                            </div>
                        )
                )}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                closable={false}
                width={216}
                positionRightBase
                onFirstMove={onFirstMove}
                header={
                    <div className={style.titleContainer} ref={titleContainerRef}>
                        <StyleTitle
                            styleType={StyleType.TextStyle}
                            hiddenLibraryIcon={localStyleMap.length === 0 && !hasRemoteStyle}
                            onClickAddIcon={onClickAddStyle}
                            disabledAddIcon={disabledAddStyle}
                            onClickLibraryIcon={goToRemoteLibraryHome}
                        />
                    </div>
                }
                bodyClassName="p-0"
                footer={null}
                testId="style-text-popup"
            >
                <div className={style.contentContainer} ref={containerRef}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onChangeSearchInput}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                        data-testid={'text-style-search'}
                    />
                    {isNoStyle ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoTextStyles')}</div>
                            <div className={style.emptyStyle_button}>
                                <WKButton
                                    type="secondary"
                                    className={style.button}
                                    onClick={goToRemoteLibraryHome}
                                    icon={<IconComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : noSearchResult ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoStylesMatch')}</div>
                        </div>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key={`${open}`}
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    )}
                </div>
            </DraggablePopupV2>

            <TextStyleEditor
                styleId={styleIdEditor}
                visible={open && openStyleEditor}
                position={positionEditor}
                isRemoteStyle={isRemoteStyle}
                onCancel={onCloseEditor}
                onClickRemoteStyleLink={jumpToOrigin}
                createStyle={createStyle}
                onClickCreate={onCloseEditor}
                effectCreateStyle={effectCreateStyle}
            />
            <DropdownV2.NoTriggerMultiLevel<DropdownDefaultCustomNode & { handler?: () => void }>
                isOpenState={open && !!contextMenuInfo}
                triggerRect={() => contextMenuInfo?.rectContainer!}
                onClose={onCloseContextMenu}
                onChange={(v) => v.customNode?.handler?.()}
                pickRest={{ onMouseDown: (e) => e.stopPropagation() }}
                onKeyboard={(e) => e.code === 'Escape' && e.stopPropagation()}
                items={[
                    {
                        name: translation('GoToStyle'),
                        hidden: !isRemoteStyle,
                        customNode: {
                            handler: jumpToOrigin,
                        },
                    },
                    {
                        name: translation('EditStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: editStyle,
                        },
                    },
                    {
                        name: translation('DeleteStyle'),
                        hidden: isRemoteStyle,
                        customNode: {
                            handler: deleteStyle,
                        },
                    },
                ]}
            />
        </div>
    )
}

export function StyleTextViewOnly(props: StyleTextProps & { width?: number }): JSX.Element {
    const { open, position, selectStyleId, selectTextStyleNode, onChangeStyle, width } = props

    const containerRef = useRef<HTMLDivElement>(null)
    const hasMoved = useRef<boolean>(false)
    const [searchStr, setSearchStr] = useState<string>('')

    const { localStyleGroups, inUseUnknownStyleNodeItems, subscribedRemoteItems, inUseOtherRemoteItems } = useViewState(
        'libraryTextStyleState',
        {
            localStyleGroups: [],
            inUseUnknownStyleNodeItems: [],
            subscribedRemoteItems: [],
            inUseOtherRemoteItems: [],
            fakeFieldToForceUpdate: false,
        }
    )

    const {
        libraryModalRouterService: { goToRemoteLibraryHome },
    } = useLibraryComponentService()
    const titleContainerRef = useRef<HTMLDivElement>(null)

    const localStyleMap = useMemo(() => {
        return localStyleGroups
            .filter((group) => group.items.length > 0)
            .map((group) => {
                return {
                    ...group,
                    items: group.items.map((item): CommonStyleInfo & ITextStyle & { contentHash: string } => {
                        const { styleName, groupName } = parseStyleName(item.name)
                        return {
                            id: item.id,
                            name: item.name,
                            description: item.description,
                            styleName,
                            groupName,
                            ...createITextStyle(item as unknown as TextStyleNode),
                            contentHash: item.contentHash,
                            missFontInfo: item.missFontInfo ?? { isMissStyle: false, isMissFamily: false },
                        }
                    }),
                }
            })
    }, [localStyleGroups])

    const showStyleMap = useMemo(() => {
        const infoMap: typeof localStyleMap = []
        if (!open) {
            return infoMap
        }

        return localStyleMap
            .map((group) => ({
                ...group,
                items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
            }))
            .filter((group) => group.items.length > 0)
    }, [localStyleMap, open, searchStr])

    const hasRemoteStyle = useMemo(() => {
        const selectStyleNodeKey = selectTextStyleNode?.key ?? ''
        return (
            subscribedRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) => group.items.length > 0)
            }) ||
            inUseOtherRemoteItems.find((libraryContent) => {
                return libraryContent.groups.find((group) =>
                    group.items.find((item) => selectStyleNodeKey && item.id === selectStyleNodeKey)
                )
            })
        )
    }, [inUseOtherRemoteItems, selectTextStyleNode?.key, subscribedRemoteItems])

    const isNoStyle = localStyleMap.length === 0 && !hasRemoteStyle && inUseUnknownStyleNodeItems.length === 0

    const remoteStyleList = useMemo(() => {
        const libs = subscribedRemoteItems.map((libraryContent) => ({
            libraryId: libraryContent.libraryId,
            libraryName: libraryContent.libraryName,
            docId: libraryContent.documentId,
            groups: (isSearchMatched(libraryContent.libraryName, searchStr)
                ? libraryContent.groups
                : libraryContent.groups.map((group) => ({
                      ...group,
                      items: searchMatchedFilter(group.items, searchStr, (item) => item.name),
                  }))
            ).filter((group) => group.items.length) as unknown as Array<{ name: string; items: StyleGetVO[] }>,
        }))

        const selectTextStyleNodeKey = selectTextStyleNode?.key ?? ''
        inUseOtherRemoteItems.forEach((libraryContent) => {
            libs.push({
                libraryId: libraryContent.libraryId,
                libraryName: libraryContent.libraryName,
                docId: libraryContent.documentId,
                groups: libraryContent.groups
                    .map((group) => {
                        return {
                            ...group,
                            items: group.items.filter(
                                (item) =>
                                    selectTextStyleNodeKey &&
                                    item.id === selectTextStyleNodeKey &&
                                    isSearchMatched(item.name, searchStr)
                            ),
                        }
                    })
                    .filter((group) => group.items.length > 0) as unknown as Array<{
                    name: string
                    items: StyleGetVO[]
                }>,
            })
        })
        return sortArrayByKey(
            libs.filter(({ groups }) => groups.length),
            'libraryName'
        )
    }, [subscribedRemoteItems, selectTextStyleNode?.key, inUseOtherRemoteItems, searchStr])

    const unknownStyleGroup: UnknowStyleGroup | undefined = useMemo(() => {
        const unknowStyleNodes = inUseUnknownStyleNodeItems as unknown as TextStyleNode[]

        for (const value of unknowStyleNodes) {
            if (value.id !== selectStyleId) continue
            if (!isSearchMatched(value.name, searchStr)) continue

            return {
                libraryId: '',
                libraryName: translation('UnknownLibrary'),
                docId: value.publishFile,
                value,
                groups: groupStyleVOByName<TextStyleNode>([value]),
            }
        }
        return undefined
    }, [inUseUnknownStyleNodeItems, searchStr, selectStyleId])

    const onFirstMove = useCallback(() => {
        hasMoved.current = true
    }, [])

    const isSearchState = useRef<() => boolean>()
    isSearchState.current = () => !!searchStr

    const onClickApplyStyle = useCallback(
        ({ id }: any) => {
            if (isSearchState.current?.()) {
                WKFrogTask.textStyle.searchResults()
            } else {
                WKFrogTask.textStyle.textStyleList()
            }
            onChangeStyle?.(id, !hasMoved.current)
        },
        [onChangeStyle]
    )

    const onChangeSearchInput = useCallback((value: string) => {
        WKFrogTask.textStyle._recordSearchContent(value)
        setSearchStr(value)
    }, [])

    useEffect(() => {
        if (!open) {
            setSearchStr('')
            hasMoved.current = false
        }
    }, [open])

    const noSearchResult = useMemo(() => {
        return (
            showStyleMap.length === 0 &&
            !remoteStyleList?.filter((v) => v.groups.length > 0).length &&
            !unknownStyleGroup?.groups.size
        )
    }, [remoteStyleList, showStyleMap.length, unknownStyleGroup?.groups.size])

    const needSplitLineWithRemoteStyleListItem = (index: number) => {
        const hasItemBeforeRemoteStyle = showStyleMap.length > 0 || !!unknownStyleGroup?.groups.size
        if (hasItemBeforeRemoteStyle) {
            return true
        }
        const firstItemIndex = remoteStyleList?.findIndex((library) => library.groups.length > 0) ?? -1
        if (firstItemIndex !== -1 && index > firstItemIndex) {
            return true
        }
        return false
    }
    const maxPreSelectIndex = useMemo(() => {
        let optionSum = 0
        for (const { items } of showStyleMap) {
            optionSum += items.length ?? 0
        }
        if (unknownStyleGroup) {
            for (const [_, styles] of unknownStyleGroup.groups) {
                optionSum += styles.length ?? 0
            }
        }
        if (remoteStyleList) {
            for (const { groups } of remoteStyleList) {
                for (const { items } of groups) {
                    optionSum += items.length ?? 0
                }
            }
        }
        return Math.max(optionSum - 1, 0)
    }, [remoteStyleList, showStyleMap, unknownStyleGroup])

    const keyboardStyleStyleList = useKeyboardStyleStyleList({ maxPreSelectIndex })

    const renderItems = () => {
        let optionIndex = 0
        const _selectStyleId = selectStyleId ? selectStyleId : undefined
        return (
            <>
                {showStyleMap.map(({ name, items }, index) => (
                    <div key={index}>
                        {name ? <div className={style.h2}>{name}</div> : null}
                        <div>
                            {items.map((item) => (
                                <StyleTextListItem
                                    key={item.id}
                                    item={item}
                                    onContextMenu={onClickApplyStyle}
                                    onSelectItem={onClickApplyStyle}
                                    onClickHoverIcon={undefined}
                                    dataTestId={`${LibraryStyleTestId.LocalTextStyleItem}-${item.id}`}
                                    index={optionIndex++}
                                    selectStyleId={_selectStyleId}
                                    setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                    trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                    recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                />
                            ))}
                        </div>
                        <div className={style.paddingBottom8}></div>
                    </div>
                ))}
                {!!unknownStyleGroup?.groups.size && (
                    <div key={unknownStyleGroup.docId}>
                        <div
                            className={classnames(style.h1, {
                                [style.splitLine]: !!showStyleMap.length,
                            })}
                        >
                            {unknownStyleGroup.libraryName}
                        </div>
                        {[...unknownStyleGroup.groups.entries()].map(([groupName, styles], index) => (
                            <div key={index}>
                                {groupName ? <div className={style.h2}>{groupName}</div> : null}
                                {styles.map((item) => (
                                    <UnknowStyleTextListItem
                                        key={item.id}
                                        item={item}
                                        docId={unknownStyleGroup.docId || ''}
                                        onContextMenu={onClickApplyStyle}
                                        onSelectItem={onClickApplyStyle}
                                        index={optionIndex++}
                                        selectStyleId={_selectStyleId}
                                        setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                        trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                        recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                    />
                                ))}
                                <div className={style.paddingBottom8}></div>
                            </div>
                        ))}
                    </div>
                )}
                {remoteStyleList?.map(
                    (library, index) =>
                        library.groups.length > 0 && (
                            <div key={library.libraryId}>
                                <div
                                    className={classnames(style.h1, {
                                        [style.splitLine]: needSplitLineWithRemoteStyleListItem(index),
                                    })}
                                >
                                    {library?.libraryName}
                                </div>
                                {library.groups.map(({ name, items }, index2) => (
                                    <div key={index2}>
                                        {name ? <div className={style.h2}>{name}</div> : null}
                                        {items.map((item) => (
                                            <RemoteStyleTextListItem
                                                key={item.id}
                                                item={item}
                                                selectStyleKey={selectTextStyleNode?.key ?? ''}
                                                docId={library.docId || ''}
                                                onContextMenu={onClickApplyStyle}
                                                onSelectItem={onClickApplyStyle}
                                                index={optionIndex++}
                                                selectStyleId={_selectStyleId}
                                                setPreselectIndex={keyboardStyleStyleList.setPreselectIndex}
                                                trySetPreselectIndex={keyboardStyleStyleList.trySetPreselectIndex}
                                                recordEnterCallback={keyboardStyleStyleList.recordEnterCallback}
                                            ></RemoteStyleTextListItem>
                                        ))}
                                        <div className={style.paddingBottom8}></div>
                                    </div>
                                ))}
                            </div>
                        )
                )}
            </>
        )
    }

    return (
        <div>
            <DraggablePopupV2
                visible={open}
                position={position}
                closable={false}
                width={width ? width : 216}
                positionRightBase
                onFirstMove={onFirstMove}
                header={
                    <div className={style.titleContainer} ref={titleContainerRef}>
                        <StyleTitle styleType={StyleType.TextStyle} hiddenLibraryIcon={true} disabledAddIcon={true} />
                    </div>
                }
                bodyClassName="p-0"
                footer={null}
            >
                <div className={style.contentContainer} ref={containerRef}>
                    <InputV2.Search
                        style={{ margin: 8 }}
                        onSearch={onChangeSearchInput}
                        autoFocus
                        onInput={keyboardStyleStyleList.onInput}
                        onKeyDown={keyboardStyleStyleList.onKeyDown}
                        placeholder={translation('Search')}
                        data-testid={'text-style-search'}
                    />
                    {isNoStyle ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoTextStyles')}</div>
                            <div className={style.emptyStyle_button}>
                                <WKButton
                                    type="secondary"
                                    className={style.button}
                                    onClick={goToRemoteLibraryHome}
                                    icon={<IconComponentLibrary16 />}
                                >
                                    {translation('BrowseLibraries')}
                                </WKButton>
                            </div>
                        </div>
                    ) : noSearchResult ? (
                        <div className={style.emptyStyle}>
                            <div className={style.emptyStyle_text}>{translation('NoStylesMatch')}</div>
                        </div>
                    ) : (
                        <ScrollView
                            className={classnames(style.content)}
                            key={`${open}`}
                            selectKey={keyboardStyleStyleList.preselectIndex}
                            ref={keyboardStyleStyleList.scrollViewRef}
                            block="nearest"
                            scrollbar={{ autoHeight: true, autoHeightMin: 0, autoHeightMax: 352 }}
                        >
                            <KeyboardReceiver keyCode={ToKeyCode.All} onKeydown={keyboardStyleStyleList.onKeyDown}>
                                {renderItems()}
                            </KeyboardReceiver>
                        </ScrollView>
                    )}
                </div>
            </DraggablePopupV2>
        </div>
    )
}
