import { forwardRef, useCallback } from 'react'
import { ScrubbableInput, ScrubbableInputProps, ScrubbableInputRef } from './components/scrubbable-input'
import { formateToFixed2 } from './utils/format'
import { Value } from './utils/type'
import { useBaseFormatterTool } from './utils/use-base-formatter-tool'

export type ScrubbableInputScaleRef = ScrubbableInputRef
/**
 * @param props
 * @returns
 * @description 缩放专用
 */
function _ScrubbableInputScale(props: ScrubbableInputProps, ref: React.Ref<ScrubbableInputScaleRef>) {
    const baseFormatter = useBaseFormatterTool(props)
    const parse = useCallback(
        (inputValue: string, _oldValue?: Value): Value => {
            // 旧值永远是 1
            const res = baseFormatter.parse(inputValue, 1)
            return res
        },
        [baseFormatter]
    )

    return (
        <ScrubbableInput
            {...props}
            ref={ref}
            formatter={{
                format: (value) => formateToFixed2(value) + 'x',
                parse,
            }}
            bigNudgeAmount={1}
            smallNudgeAmount={0.5}
            scrubMultiplier={0.01}
            shiftScrubMultiplier={10}
            resolution={0.01}
            allowedUnits={'x'}
            min={0.01}
            max={100}
            notUseUserConfig
        />
    )
}

export const ScrubbableInputScale = forwardRef(_ScrubbableInputScale)
