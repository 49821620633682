import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    分离变量: '分离变量',
} as const

export const enTranslation = {
    分离变量: 'Detach alias',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
