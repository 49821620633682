import { getTranslationValue } from '../../../util/src/i18n'

export const zhTranslation = {
    AdminIsUpdating: '管理员正在升级「{{teamName}}」团队，当前无法执行编辑操作。',
} as const

export const enTranslation = {
    AdminIsUpdating: 'The admin is upgrading the "{{teamName}}" team, editing operations cannot be performed currently',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
