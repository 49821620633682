import { PopupStateType } from '../../../../../../document/node/node'
import { ComponentPropNodeField, type ComponentPropEditPopupVal } from '../../types'
import { useComponentProp } from '../component-prop/hook'
import { BaseComponentPropPill, convertRealComponentPropType2Virtual } from './base-component-prop-pill'
import style from './index.module.less'

export function BoolComponentPropPill() {
    const {
        showComponentPropPillOfBoolean,
        showDisabledPillOfBoolean,
        showZombiePillOfBoolean,
        showAddZombiePropBtnOfBoolean,
        sharedBoolPropDefOfSelectedNodes,
        componentPropDefs,
        detachProp,
        applyProp,
        createAndApplyProp,
        locateComponentProp,
        locateInstanceOfRef,
        toggleComponentPropPopup,
    } = useComponentProp()

    const showPill = showComponentPropPillOfBoolean || showDisabledPillOfBoolean || showZombiePillOfBoolean

    const getPillStatus = () => {
        if (showComponentPropPillOfBoolean) {
            return 'normal'
        }
        if (showDisabledPillOfBoolean) {
            return 'disabled'
        }
        if (showZombiePillOfBoolean) {
            return 'zombie'
        }
        return 'normal'
    }

    if (!showPill) {
        return null
    }

    return (
        <BaseComponentPropPill
            className={{ container: style.boolPropPillCotainer }}
            dataTestId="bool-prop-pill"
            propDefs={componentPropDefs}
            displayProp={sharedBoolPropDefOfSelectedNodes}
            pillStatus={getPillStatus()}
            zombiePillAddBtn={showAddZombiePropBtnOfBoolean}
            detachProp={() => {
                detachProp(sharedBoolPropDefOfSelectedNodes.detachDatas)
            }}
            applyProp={(defId: string) => {
                applyProp(ComponentPropNodeField.COMPONENT_PROP_NODE_FIELD_VISIBLE, defId)
            }}
            createProp={() => {
                toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_CREATE_COMPONENT_BOOL_PROP_OF_LAYER_AREA)
            }}
            editProp={() => {
                const shouldOpenEditPopup = locateComponentProp(sharedBoolPropDefOfSelectedNodes.editedPropId)
                if (shouldOpenEditPopup.value) {
                    toggleComponentPropPopup(PopupStateType.POPUP_STATE_TYPE_EDIT_COMPONENT_BOOL_PROP, {
                        id: sharedBoolPropDefOfSelectedNodes.editedPropId,
                        name: sharedBoolPropDefOfSelectedNodes.name,
                        type: convertRealComponentPropType2Virtual(sharedBoolPropDefOfSelectedNodes.type),
                        varValue: sharedBoolPropDefOfSelectedNodes.varValue,
                    } as ComponentPropEditPopupVal)
                }
            }}
            zombiePillAddProp={() => [
                detachProp(sharedBoolPropDefOfSelectedNodes.detachDatas),
                createAndApplyProp({
                    name: sharedBoolPropDefOfSelectedNodes.name,
                    type: sharedBoolPropDefOfSelectedNodes.type,
                    value: sharedBoolPropDefOfSelectedNodes.varValue.value,
                }),
            ]}
            disabledPillLocateProp={() => {
                locateInstanceOfRef(sharedBoolPropDefOfSelectedNodes.editedPropId)
            }}
        />
    )
}
