/* eslint-disable no-restricted-imports */
import { isNil } from 'lodash-es'
import { forwardRef, HTMLAttributes, useCallback, useImperativeHandle, useRef } from 'react'
import { CommitType } from '../../../../../document/command/commit-type'
import { NumberUnit } from '../../../../../document/node/node'
import { TextValueUnit, Value } from '../utils/type'
import { FormattedInput, FormattedInputProps, FormattedInputRef } from './formatted-input'
import { ScrubbableControl, ScrubbableControlCustomProps } from './scrubbable-control'
import classes from './scrubbable-input.module.less'

export interface ScrubbableInputTextProps
    extends Omit<FormattedInputProps<TextValueUnit>, 'mixedMathHandler' | 'value' | 'onClick' | 'divertKeyboardEvent'>,
        Omit<ScrubbableControlCustomProps, 'mixedMathHandler' | 'value' | 'onValueChange'>,
        Pick<HTMLAttributes<HTMLLabelElement>, 'onClick'> {
    inputClassName?: string
    mixedMathHandler?: FormattedInputProps<TextValueUnit>['mixedMathHandler'] &
        ScrubbableControlCustomProps['mixedMathHandler']
    icon?: JSX.Element | string
    value?: TextValueUnit
    allowedUnits?: string
    leftScrubbable?: boolean
    rightScrubbable?: boolean
    max?: number
    min?: number
}

export interface ScrubbableTextInputRef {
    getInputElement: () => HTMLInputElement
}

function _ScrubbableTextInput(props: ScrubbableInputTextProps, ref: React.Ref<ScrubbableTextInputRef>) {
    const formattedInputRef = useRef<FormattedInputRef>(null)
    const scrubNeedToSelectAll = useRef<boolean>(false)

    const _onScrubStart = useCallback(() => {
        const input = formattedInputRef.current?.getInputElement()
        const isSelectAll = input === document.activeElement && window.getSelection()?.toString() === input?.value
        scrubNeedToSelectAll.current = isSelectAll
    }, [])

    const _onScrubChange = useCallback(() => {
        if (scrubNeedToSelectAll.current) {
            formattedInputRef.current?.getInputElement()?.select()
        }
    }, [])

    const _onClick = useCallback(
        (e: React.MouseEvent<HTMLLabelElement>) => {
            e.stopPropagation()
            props.onClick?.(e)
        },
        [props]
    )

    const onValueChange = useCallback(
        (value: Value) => {
            if (typeof value === 'number') {
                const unit = props?.value?.unit === NumberUnit.Percent ? NumberUnit.Percent : NumberUnit.Pixels
                return props.onChange({ value: value, unit }, { commitType: CommitType.Noop })
            }
        },
        [props]
    )

    useImperativeHandle(ref, () => ({
        getInputElement: () => formattedInputRef.current?.getInputElement()!,
    }))

    return (
        <ScrubbableControl
            scrubbingDisabled={props.scrubbingDisabled}
            disabled={props.disabled}
            value={props.value?.value ?? 0}
            isMixed={props.isMixed}
            onValueChange={onValueChange}
            valueFilter={props?.valueFilter}
            mixedMathHandler={props?.mixedMathHandler}
            min={props?.min}
            max={props?.max}
            onScrubBegin={_onScrubStart}
            onScrubChange={_onScrubChange}
            className={props.className}
            leftScrubbable={props.leftScrubbable}
            rightScrubbable={props.rightScrubbable}
            notUseUserConfig={props.notUseUserConfig}
            scrubMultiplier={props.scrubMultiplier}
            shiftScrubMultiplier={props.shiftScrubMultiplier}
            onClick={_onClick}
        >
            {isNil(props.icon) ? null : <span className={classes.spanIcon}>{props.icon}</span>}
            <FormattedInput
                ref={formattedInputRef}
                className={props.inputClassName}
                noRightPadding={props.noRightPadding}
                formatter={props.formatter}
                value={props.value ?? { value: 0, unit: NumberUnit.Pixels }}
                isMixed={props.isMixed}
                customMixedValue={props.customMixedValue}
                onChange={props.onChange}
                mixedMathHandler={props.mixedMathHandler}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                disabled={props.disabled}
                placeholder={props.placeholder}
                autoFocus={props.autoFocus}
                defaultShowEmpty={props.defaultShowEmpty}
                shouldClearOnFocus={props.shouldClearOnFocus}
                focusWithoutBorder
                notUseUserConfig={props.notUseUserConfig}
                bigNudgeAmount={props.bigNudgeAmount}
                smallNudgeAmount={props.smallNudgeAmount}
                testId={props.testId}
            />
        </ScrubbableControl>
    )
}

export const ScrubbableTextInput = forwardRef(_ScrubbableTextInput)
