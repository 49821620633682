import classnames from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import { ExplicitUndefined } from '../../../../utils/explicit-undefined'
import { PopUpScroll, PopUpScrollProps, PopUpScrollRef } from '../../pop-up-scroll/pop-up-scroll'
import { Rect } from '../../type'
import { CustomNode, DataNode, RenderStructNodeItem, StructNode } from './type'
import { hasChildWithStructNode } from './utils'

type PickPopUpScrollProps = Omit<
    PopUpScrollProps,
    | 'triggerRect'
    | 'placement'
    | 'removeMask'
    | 'removeTopPadding'
    | 'removeBottomPadding'
    | 'onClickMask'
    | 'overMajorClassName'
    | 'overMinorClassName'
    | 'children'
    | 'autoAdjustTop'
    | 'className'
>

export interface RenderStructNodeProps<T extends CustomNode> extends PickPopUpScrollProps {
    preselectKey?: string
    interactiveKey?: string
    interactiveAction?: string
    structNode: StructNode<T>
    enterStructNode?: (structNode: StructNode<T>, e: React.MouseEvent) => void
    mousemoveStructNode?: (structNode: StructNode<T>, e: React.MouseEvent) => void
    leaveStructNode?: (structNode: StructNode<T>, e: React.MouseEvent, itemRect?: Rect, itemChildrenRect?: Rect) => void
    clickStructNode?: (structNode: StructNode<T>, e: React.MouseEvent) => void
    renderItemPrev?: RenderStructNodeItem<T>
    renderItem: RenderStructNodeItem<T>
    renderItemNext?: RenderStructNodeItem<T>
    getPortalElement?: () => HTMLElement | undefined
    getSameLevelDataList: () => DataNode<T>[]
    getIndexInSameLevelDataList: () => number
}
export function RenderStructNode<T extends CustomNode, K extends RenderStructNodeProps<T> = RenderStructNodeProps<T>>(
    props: K
) {
    const {
        preselectKey,
        interactiveKey,
        interactiveAction,
        structNode,
        visibleClassName,
        enterStructNode,
        mousemoveStructNode,
        leaveStructNode,
        clickStructNode,
        getPortalElement,
        getSameLevelDataList,
        getIndexInSameLevelDataList,
        renderItemPrev: RenderItemPrev,
        renderItem: RenderItem,
        renderItemNext: RenderItemNext,
    } = props
    const customItemRef = useRef<HTMLDivElement>(null)
    const popUpScrollRef = useRef<PopUpScrollRef>(null)

    const hasChild = useMemo(() => hasChildWithStructNode(structNode), [structNode])

    const triggerRect = useCallback(() => {
        return customItemRef.current?.getBoundingClientRect()!
    }, [])

    const isHidden = useMemo(() => {
        if (typeof structNode.data.hidden === 'function') {
            return structNode.data.hidden()
        } else {
            return !!structNode.data.hidden
        }
    }, [structNode.data])

    const isOpenState = useMemo(() => {
        const isTopKey = () => {
            return structNode.key === interactiveKey || !!interactiveKey?.startsWith(`${structNode.key}-`)
        }
        return (
            !isHidden &&
            !!hasChild &&
            isTopKey() &&
            (interactiveAction === 'keyboard' ? interactiveKey !== structNode.key : true)
        )
    }, [hasChild, interactiveAction, interactiveKey, isHidden, structNode.key])

    const isDisabled = useMemo(() => structNode.data.disabled, [structNode.data.disabled])

    const mouseenter = useCallback(
        (e: React.MouseEvent) => {
            if (isDisabled) {
                return
            }
            enterStructNode?.(structNode, e)
        },
        [enterStructNode, isDisabled, structNode]
    )

    const mousemove = useCallback(
        (e: React.MouseEvent) => {
            if (isDisabled) {
                return
            }
            mousemoveStructNode?.(structNode, e)
        },
        [isDisabled, mousemoveStructNode, structNode]
    )

    const mouseleave = useCallback(
        (e: React.MouseEvent) => {
            if (isDisabled) {
                return
            }
            const itemChildrenRect = popUpScrollRef.current?.getContainer().getBoundingClientRect()
            const itemRect = (e.currentTarget as HTMLDivElement).getBoundingClientRect()
            leaveStructNode?.(structNode, e, itemRect, itemChildrenRect)
        },
        [isDisabled, leaveStructNode, structNode]
    )

    const click = useCallback(
        (e: React.MouseEvent) => {
            if (isDisabled) {
                return
            }
            clickStructNode?.(structNode, e)
        },
        [clickStructNode, isDisabled, structNode]
    )

    const customStructNodeProps = useMemo(
        () => ({
            data: structNode.data,
            hasChild: hasChild,
            isExtend: isOpenState,
            isPreselect: preselectKey === structNode.key,
            getSameLevelDataList: getSameLevelDataList,
            getIndexInSameLevelDataList: getIndexInSameLevelDataList,
        }),
        [
            getIndexInSameLevelDataList,
            getSameLevelDataList,
            hasChild,
            isOpenState,
            preselectKey,
            structNode.data,
            structNode.key,
        ]
    )

    return (
        <>
            {RenderItemPrev && !isHidden ? <RenderItemPrev {...customStructNodeProps} /> : null}
            {isHidden ? null : (
                <div
                    ref={customItemRef}
                    className={classnames({ [visibleClassName ?? '']: customStructNodeProps.isPreselect })}
                    onMouseEnter={mouseenter}
                    onMouseMove={mousemove}
                    onMouseLeave={mouseleave}
                    onClick={click}
                >
                    <RenderItem {...customStructNodeProps} />
                </div>
            )}
            {RenderItemNext && !isHidden ? <RenderItemNext {...customStructNodeProps} /> : null}
            {isOpenState
                ? ReactDOM.createPortal(
                      <PopUpScroll<ExplicitUndefined<PopUpScrollProps>>
                          worldRect={props.worldRect}
                          visibleClassName={props.visibleClassName}
                          visibleTrigger={props.visibleTrigger}
                          isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                          minWidth={props.minWidth}
                          width={props.width}
                          maxWidth={props.maxWidth}
                          dataTestIds={props.dataTestIds}
                          isNotCoverTriggerRect={props.isNotCoverTriggerRect}
                          ref={popUpScrollRef}
                          triggerRect={triggerRect}
                          placement={'right top'}
                          autoAdjustTop={true}
                          removeMask={true}
                          onClickMask={undefined}
                          overMajorClassName={undefined}
                          overMinorClassName={undefined}
                          className={undefined}
                          removeTopPadding={structNode.data.popUpScrollProps?.removeTopPadding}
                          removeBottomPadding={structNode.data.popUpScrollProps?.removeBottomPadding}
                      >
                          {structNode.children?.map((child, index) => (
                              <RenderStructNode<T, ExplicitUndefined<RenderStructNodeProps<T>>>
                                  worldRect={props.worldRect}
                                  visibleClassName={props.visibleClassName}
                                  visibleTrigger={props.visibleTrigger}
                                  isMinWidthFromTrigger={props.isMinWidthFromTrigger}
                                  minWidth={props.minWidth}
                                  width={props.width}
                                  maxWidth={props.maxWidth}
                                  renderItemPrev={props.renderItemPrev}
                                  renderItem={props.renderItem}
                                  renderItemNext={props.renderItemNext}
                                  dataTestIds={props.dataTestIds}
                                  key={child.key}
                                  structNode={child}
                                  preselectKey={preselectKey}
                                  interactiveKey={interactiveKey}
                                  interactiveAction={interactiveAction}
                                  enterStructNode={enterStructNode}
                                  mousemoveStructNode={mousemoveStructNode}
                                  leaveStructNode={leaveStructNode}
                                  clickStructNode={clickStructNode}
                                  getPortalElement={getPortalElement}
                                  getSameLevelDataList={() => structNode.children!.map((v) => v.data)}
                                  getIndexInSameLevelDataList={() => index}
                                  isNotCoverTriggerRect={props.isNotCoverTriggerRect}
                              />
                          ))}
                      </PopUpScroll>,
                      getPortalElement?.() ?? document.body
                  )
                : null}
        </>
    )
}
