import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    CommentActions: '更多操作',
    Edit: '编辑',
    Delete: '删除',
} as const

export const enTranslation = {
    CommentActions: 'Comment actions',
    Edit: 'Edit',
    Delete: 'Delete',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
