import classnames from 'classnames'
import React, { forwardRef, HTMLAttributes, useCallback, useImperativeHandle, useRef } from 'react'
import { TabElement, TabElementRef } from '../../../tab-element'
import classes from './trigger-focus.module.less'

export interface TriggerFocusProps {
    disabled?: boolean
    outlineMode?: 'inset' | 'outset' // 聚焦边框是向内收拢还是向外扩散
    onClick?: HTMLAttributes<HTMLDivElement>['onClick']
    onEnter?: () => void
    onBlankSpace?: () => void
    dataTestIds?: { triggerFocus?: string; tabElement?: string }
}
export interface TriggerFocusRef {
    getContainer: () => HTMLElement
    getFocusElement: () => HTMLElement
}

function _TriggerFocus<T extends TriggerFocusProps = TriggerFocusProps>(
    props: T & HTMLAttributes<HTMLDivElement>,
    ref?: React.Ref<TriggerFocusRef>
) {
    const { className, dataTestIds, disabled, outlineMode = 'inset', onEnter, onBlankSpace, ...otherProps } = props
    const containerRef = useRef<HTMLDivElement>(null)
    const tabElementRef = useRef<TabElementRef>(null)

    const keydown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.keyCode === 32) {
                onBlankSpace?.()
            } else if (e.keyCode === 13) {
                onEnter?.()
            } else if (e.keyCode === 27) {
                ;(e.currentTarget as HTMLDivElement).blur()
            }
        },
        [onBlankSpace, onEnter]
    )

    useImperativeHandle(
        ref,
        () => ({
            getContainer: () => containerRef.current!,
            getFocusElement: () => tabElementRef.current!,
        }),
        []
    )

    return (
        <div
            ref={containerRef}
            className={classnames(
                classes.triggerFocus,
                {
                    [classes.inset]: outlineMode === 'inset',
                    [classes.outset]: outlineMode === 'outset',
                },
                [className]
            )}
            data-testid={dataTestIds?.triggerFocus}
            data-forbid-shortcuts
            {...otherProps}
        >
            <TabElement
                ref={tabElementRef}
                disabled={disabled}
                data-testid={dataTestIds?.tabElement}
                onKeyDown={keydown}
            />
            {props.children}
        </div>
    )
}

export const TriggerFocus = forwardRef(_TriggerFocus) as <T extends TriggerFocusProps = TriggerFocusProps>(
    props: T & HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<TriggerFocusRef> }
) => React.ReactElement
