export function getTemplateMaxVersion(): number {
    let maxVersion = 0
    for (const page of motiff.currentPage.parent?.children ?? []) {
        if (page.name.startsWith('releases')) {
            const versionStr = page.name.slice(page.name.lastIndexOf('-') + 1)
            if (versionStr.length != 0) {
                try {
                    const version = Number.parseInt(versionStr)
                    const nonNan = isNaN(version) ? 0 : version
                    maxVersion = Math.max(nonNan, maxVersion)
                } catch {}
            }
        }
    }
    return maxVersion + 1
}
