import { Wukong } from '@wukong/bridge-proto'
import { FC, useMemo } from 'react'
import { LayerPanelItemDragProvider } from '../render-item-drag/context'
import { LayerPanelItemStatusProvider } from '../render-item-status/context'
import { LayerPanelRenderObject } from '../render-item/render'
import { TopLevelFixedFrameItem } from '../top-level-fixed-frame-item'
import styles from './index.module.less'

interface LayerPanelRenderListProps {
    renderList: Wukong.DocumentProto.VLayerPanelItemInfo[]
    translateHeight: number
    totalHeight: number
    hasScrolled: boolean
    layerPanelContainerRef: () => HTMLDivElement
    topLevelFixedFrame: Wukong.DocumentProto.TopLevelFixedFrame
    layerUsingModesMap: { [k: string]: Wukong.DocumentProto.IVariableLayerModeStateVector }
}

function getLayerPanelRenderObjectKey(item: Wukong.DocumentProto.VLayerPanelItemInfo) {
    switch (item.type) {
        case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_NODE:
            return item.layerPanelNodeInfo.id
        case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SECTION:
            return item.layerPanelSectionInfo.sectionId
        case Wukong.DocumentProto.LayerPanelItemType.LAYER_PANEL_ITEM_TYPE_SEE_ALL_LAYERS:
            return item.layerPanelSeeAllLayersInfo.seeAllLayerId
    }
}

export const LayerPanelRenderList: FC<LayerPanelRenderListProps> = ({
    renderList,
    translateHeight,
    totalHeight,
    hasScrolled,
    layerPanelContainerRef,
    topLevelFixedFrame,
    layerUsingModesMap,
}) => {
    return useMemo(
        () => (
            <>
                <div className={styles['fake-scroll-area']} style={{ height: translateHeight + 'px' }} />
                <div
                    data-testid={`layer-panel-render-list`}
                    style={{
                        height: totalHeight - translateHeight + 'px',
                    }}
                >
                    <LayerPanelItemDragProvider
                        renderList={renderList}
                        layerPanelContainerRef={layerPanelContainerRef}
                        totalHeight={totalHeight}
                        translateHeight={translateHeight}
                    >
                        <LayerPanelItemStatusProvider>
                            <TopLevelFixedFrameItem
                                hasScrolled={hasScrolled}
                                layerPanelContainerRef={layerPanelContainerRef}
                                topLevelFixedFrame={topLevelFixedFrame}
                                layerUsingModes={
                                    topLevelFixedFrame.fixedNode?.id &&
                                    topLevelFixedFrame.fixedNode?.id in layerUsingModesMap
                                        ? layerUsingModesMap[topLevelFixedFrame.fixedNode.id]
                                        : ({ states: [] } as Wukong.DocumentProto.IVariableLayerModeStateVector)
                                }
                            />

                            <>
                                {renderList.map((item, index) => (
                                    <LayerPanelRenderObject
                                        renderList={renderList}
                                        item={item as Wukong.DocumentProto.VLayerPanelItemInfo}
                                        index={index}
                                        key={getLayerPanelRenderObjectKey(item)}
                                        layerUsingModes={
                                            item.layerPanelNodeInfo.id! in layerUsingModesMap
                                                ? layerUsingModesMap[item.layerPanelNodeInfo.id!]
                                                : ({ states: [] } as Wukong.DocumentProto.IVariableLayerModeStateVector)
                                        }
                                    />
                                ))}
                            </>
                        </LayerPanelItemStatusProvider>
                    </LayerPanelItemDragProvider>
                </div>
            </>
        ),
        [
            translateHeight,
            totalHeight,
            renderList,
            layerPanelContainerRef,
            hasScrolled,
            topLevelFixedFrame,
            layerUsingModesMap,
        ]
    )
}
