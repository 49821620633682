import { CreateAIGenBaseFrameCommand, Wukong } from '@wukong/bridge-proto'
import { useCallback } from 'react'
import { CommitType } from '../../../../document/command/commit-type'
import { PrototypePropMapper } from '../../../../plugin-vm/mapper'
import { useCommand } from '../../../context/document-context'
import skeletonIconNetwork from './vector-network.json'

export function useCreateBaseFrame() {
    const command = useCommand()
    return useCallback(
        async (prompt: string, width: number, height: number) => {
            const vectorNetwork = PrototypePropMapper.VectorNetwork.fromFigma(skeletonIconNetwork as any)
            const vectorNetworkProto = Wukong.DocumentProto.VectorNetwork.create(vectorNetwork)
            const { frameNode } = command.invokeBridge(CommitType.Noop, CreateAIGenBaseFrameCommand, {
                prompt,
                width,
                height,
                vectorNetwork: vectorNetworkProto,
            })
            return frameNode
        },
        [command]
    )
}
