import { ONE_DAY, isEnglishLanguage } from '../../../../../util/src'
import constate from 'constate'
import { lastDayOfMonth, startOfDay, startOfMonth, subDays } from 'date-fns'
import { chunk, inRange } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { WKDatepickerValue, GET_YEARS } from '../data'
export interface CalendarProps {
    disableTime?: number // 在这个日期之前的全部都禁用掉
    range: WKDatepickerValue
    onChange: (param: WKDatepickerValue) => void
}

export interface CalendarItemType {
    label: string
    time: number // 当天 0 点时间戳
    disable: boolean
    isThisMonth: boolean
    rangeStart: boolean
    rangeEnd: boolean
    inRange: boolean
    overing: boolean
}

const useCalendar = (props: CalendarProps) => {
    const { range, onChange, disableTime } = props
    const onChangeRef = useRef(onChange)
    const [start, setStart] = useState(range.start)
    // 因为结束时间 可能不是当天 0 点， 所以取 0 点时间戳，方便比较
    const [end, setEnd] = useState<number | null>(startOfDay(range.end).getTime())
    const [year, setYear] = useState(new Date(range.end).getFullYear())
    const [showYear, setShowYear] = useState(false)
    const [month, setMonth] = useState(new Date(range.end).getMonth())
    const [showMonth, setShowMonth] = useState(false)
    const yearRef = useRef<HTMLDivElement>(null)
    const monthRef = useRef<HTMLDivElement>(null)
    useClickAway(yearRef, () => {
        setShowYear(false)
    })
    useClickAway(monthRef, () => {
        setShowMonth(false)
    })
    const [hoverDate, setHoverDate] = useState<number | null>(null)
    const updateHoverDate = useCallback(
        (t: number | null) => {
            // 之后 选了开始没有选结束，才会出现 hover 效果
            if (start && !end) {
                setHoverDate(t)
            }
        },
        [end, start]
    )
    const _disableTime = disableTime ?? startOfDay(new Date(GET_YEARS()[0], 0, 1)).getTime()
    // X x Y 为 7 * 6 的二维数组
    // 已当月第一天的星期为基准，向前向后把数组填满
    // 一 二 三 四 五 六 日 的坐标为 1 2 3 4 5 6 0
    // 数组项包含了 日期 和 当天 0 点时间戳
    const dates = useMemo(() => {
        const arr = new Array<CalendarItemType>(7 * 6)
        // 月 第一天
        const date = new Date(year, month, 1)
        // 月 第一天 是 星期几 从 1 开始 0 是星期天
        const monthStartDay = date.getDay()
        // 月 最后一天
        const monthEnd = lastDayOfMonth(date)
        // 月 有多少天
        const monthDateNums = monthEnd.getDate()

        // 上一个月最后一天
        const prevtMonthEnd = subDays(date, 1)
        // 上个月一共有多少天
        const prevtMonthDateNums = prevtMonthEnd.getDate()

        // 找到第一个填充的位置
        let firstIndex = 0
        if (isEnglishLanguage()) {
            // date-fns 默认规则和海外版一致，不需要特殊处理
            firstIndex = monthStartDay
        } else {
            // 国内版需要把周一放第一位
            if (monthStartDay === 0) {
                firstIndex = 6
            } else {
                firstIndex = monthStartDay - 1
            }
        }

        // 向后填充
        for (let i = firstIndex; i < 7 * 6; i++) {
            const d = i - firstIndex + 1
            const _time = date.getTime() + ONE_DAY * (d - 1)
            arr[i] = {
                label: `${d % monthDateNums == 0 ? monthDateNums : d % monthDateNums}`,
                time: _time,
                disable: _time > Date.now() || _time <= _disableTime,
                isThisMonth: d <= monthDateNums,
                rangeStart: _time === start,
                rangeEnd: _time === end,
                inRange: end ? inRange(_time, start, end) : false,
                overing: hoverDate ? inRange(_time, hoverDate, start) || hoverDate == _time : false,
            }
        }
        // 向前填充
        for (let i = firstIndex - 1; i >= 0; i--) {
            const d = firstIndex - i
            const _time = date.getTime() - ONE_DAY * d
            arr[i] = {
                label: `${prevtMonthDateNums - (d - 1)}`,
                time: _time,
                disable: _time > Date.now() || _time <= _disableTime,
                isThisMonth: false,
                rangeStart: _time === start,
                rangeEnd: _time === end,
                inRange: end ? inRange(_time, start, end) : false,
                overing: hoverDate ? inRange(_time, hoverDate, start) || hoverDate == _time : false,
            }
        }

        return chunk(arr, 7)
    }, [year, month, _disableTime, start, end, hoverDate])

    const addMonth = useCallback(() => {
        const dt = new Date(_disableTime)
        const startOfDisableMonth = startOfMonth(dt).getTime()
        const startOfSelectMonth = startOfMonth(new Date(year, month, 1)).getTime()
        if (startOfDisableMonth > startOfSelectMonth) {
            setYear(dt.getFullYear())
            setMonth(dt.getMonth())
        } else {
            if (month === 11) {
                setYear(year + 1)
                setMonth(0)
            } else {
                setMonth(month + 1)
            }
        }
    }, [_disableTime, month, year])
    const addMothDisabled = useMemo(() => {
        return year === new Date().getFullYear() && month >= new Date().getMonth()
    }, [month, year])
    const subMonth = useCallback(() => {
        const current = new Date()
        const startOfCurrentMonth = startOfMonth(current).getTime()
        const startOfSelectMonth = startOfMonth(new Date(year, month, 1)).getTime()
        if (startOfSelectMonth > startOfCurrentMonth) {
            setYear(current.getFullYear())
            setMonth(current.getMonth())
        } else {
            if (month === 0) {
                setYear(year - 1)
                setMonth(11)
            } else {
                setMonth(month - 1)
            }
        }
    }, [month, year])
    const subMonthDisabled = useMemo(() => {
        if (year < new Date(_disableTime).getFullYear()) {
            return true
        } else {
            return year == new Date(_disableTime).getFullYear() && month <= new Date(_disableTime).getMonth()
        }
    }, [_disableTime, month, year])
    const [clickTimes, setClickTimes] = useState(0)
    const clickDate = useCallback(
        (t: number) => {
            // 如果开始和结束都有值 就说明要重新开始选择区间了
            if (start && end) {
                setStart(t)
                setEnd(null)
                if (new Date(t).getMonth() !== month || new Date(t).getFullYear() !== year) {
                    setMonth(new Date(t).getMonth())
                    setYear(new Date(t).getFullYear())
                }
            }
            // 点击结束日期
            if (end == null && start) {
                if (t > start) {
                    setEnd(t)
                } else {
                    setStart(t)
                    setEnd(start)
                }
            }
            setClickTimes((n) => n + 1)
        },
        [end, month, start, year]
    )

    useEffect(() => {
        // 开始和结束都选择 并且和入参不同才会触发回调
        if (start && end && clickTimes !== 0 && clickTimes % 2 === 0) {
            // 如果结束时间 要把当前天加进去
            onChangeRef.current({ start, end: end + ONE_DAY - 1 })
        }
    }, [clickTimes, end, range.end, range.start, start])

    return {
        year,
        addMothDisabled,
        subMonthDisabled,
        month,
        setYear,
        setMonth,
        showYear,
        setShowYear,
        showMonth,
        setShowMonth,
        yearRef,
        monthRef,
        dates,
        clickDate,
        addMonth,
        subMonth,
        start,
        end,
        hoverDate,
        updateHoverDate,
    }
}
export const [CalendarrProvider, useCalendarrContext] = constate(useCalendar)
