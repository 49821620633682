import { getTranslationValue } from '../../../../../../util/src/i18n'

export const zhTranslation = {
    title: '磁盘空间不足',
    ok: '我知道了',
    description: '{{name}}可能无法正确运行，请尝试删除一些不需要的文件以释放磁盘空间。',
    learnMore: '了解更多',
} as const

export const enTranslation = {
    title: 'Insufficient disk space',
    ok: 'OK',
    description: '{{name}} may not function correctly. Try deleting some unnecessary files to free up disk space. ',
    learnMore: 'Learn more',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
