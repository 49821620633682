import { translation } from './use-line-height-formatter-tool.translation'
/* eslint-disable no-restricted-imports */
import { useCallback } from 'react'
import { NumberUnit } from '../../../../../document/node/node'
import { FormattedInputCustomProps } from '../components/formatted-input'
import { parseString } from './parse-string'
import { TextValueUnit } from './type'
import { useUserConfig } from './user-config'

export interface BaseFormatterToolProps extends Pick<FormattedInputCustomProps<any>, 'notUseUserConfig'> {
    value?: TextValueUnit
    min?: number
    max?: number
}

export function useLineHeightFormatterTool(props: BaseFormatterToolProps) {
    const userConfig = useUserConfig()

    const getBigNudgeAmount = useCallback(
        (unit: NumberUnit) => {
            if (unit !== NumberUnit.Percent && !props.notUseUserConfig) {
                return userConfig.bigNudgeAmount
            }
            return 10
        },
        [props.notUseUserConfig, userConfig.bigNudgeAmount]
    )

    const getSmallNudgeAmount = useCallback(
        (unit: NumberUnit) => {
            if (unit !== NumberUnit.Percent && !props.notUseUserConfig) {
                return userConfig.smallNudgeAmount
            }
            return 1
        },
        [props.notUseUserConfig, userConfig.smallNudgeAmount]
    )
    const parse = useCallback(
        (inputValue: string): TextValueUnit => {
            inputValue = inputValue.trim().toLocaleLowerCase()
            if (
                (inputValue === '' && props.value?.unit !== NumberUnit.Auto) ||
                'auto%'.startsWith(inputValue) ||
                '自动%'.startsWith(inputValue)
            ) {
                return { value: 1, unit: NumberUnit.Auto }
            }
            let unit =
                inputValue.indexOf('%') !== -1
                    ? NumberUnit.Percent
                    : inputValue.indexOf('px') !== -1
                    ? NumberUnit.Pixels
                    : undefined
            inputValue = inputValue.replace(new RegExp('%|px', 'ig'), '')
            const value = parseString(inputValue, NaN)
            unit = unit !== undefined ? unit : NumberUnit.Pixels
            return { value, unit }
        },
        [props.value?.unit]
    )

    const format = useCallback((value: TextValueUnit): string => {
        const numValue = parseFloat(value.value.toFixed(1))
        switch (value.unit) {
            case NumberUnit.Percent:
                return `${numValue}%`
            case NumberUnit.Pixels:
                return `${numValue}`
            default:
                return translation('Auto')
        }
    }, [])

    const increment = useCallback(
        (value: TextValueUnit, isIncrement: boolean, shiftKey: boolean): TextValueUnit => {
            const unit = value.unit === NumberUnit.Percent ? NumberUnit.Percent : NumberUnit.Pixels
            const bigNudgeAmount = getBigNudgeAmount(unit)
            const smallNudgeAmount = getSmallNudgeAmount(unit)
            let numValue = value.value
            if (shiftKey) {
                numValue = isIncrement ? numValue + bigNudgeAmount : numValue - bigNudgeAmount
            } else {
                numValue = isIncrement ? numValue + smallNudgeAmount : numValue - smallNudgeAmount
            }
            return { value: numValue, unit }
        },
        [getBigNudgeAmount, getSmallNudgeAmount]
    )

    const clamp = useCallback((value: TextValueUnit): TextValueUnit => {
        const numValue = Math.max(value.value, 0)
        return { value: numValue, unit: value.unit }
    }, [])

    const defaultSelection = useCallback((value: string) => {
        const res = /[0-9.]+/.exec(value)
        return res !== null
            ? {
                  start: res.index,
                  end: res.index + res[0].length,
              }
            : {
                  start: 0,
                  end: value.length,
              }
    }, [])

    return { parse, format, increment, clamp, defaultSelection }
}
