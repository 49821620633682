import { WKTabsV2 } from '../../../../../../../ui-lib/src'
import { SelectedVariablesEditorActiveKey } from '../utils'
import { translation } from './index.translation'

export function SelectedVariablesEditorTitle({
    activeKey,
    onActiveTabChange,
}: {
    activeKey: SelectedVariablesEditorActiveKey
    onActiveTabChange: (activeKey: SelectedVariablesEditorActiveKey) => void
}) {
    return (
        <div data-testid="selected-variables-editor-title">
            <WKTabsV2
                activeKey={activeKey}
                size="small"
                onActiveTabChange={(tabKey) => onActiveTabChange(tabKey as SelectedVariablesEditorActiveKey)}
            >
                <WKTabsV2.Item
                    tabKey={SelectedVariablesEditorActiveKey.DETAIL}
                    label={translation('Detail')}
                ></WKTabsV2.Item>
                <WKTabsV2.Item
                    tabKey={SelectedVariablesEditorActiveKey.SCOPE}
                    label={translation('Scope')}
                ></WKTabsV2.Item>
            </WKTabsV2>
        </div>
    )
}
