import { usePluginService } from '../../../../main/app-context'
import { useDocInfoContext } from '../../../context/top-area-context'
import { PluginDevelopmentCreate } from './component/plugin-dev-create'
import { PluginDevelopmentCreateSuccess } from './component/plugin-dev-create-success'
import { PluginDevelopmentEditorPublishInfo } from './component/plugin-dev-editor-publish-info'
import { PluginDevelopmentMain } from './component/plugin-dev-main'
import { PluginDevelopmentPermission } from './component/plugin-dev-permission'
import { PluginDevelopmentPublish } from './component/plugin-dev-publish'
import { PluginDevelopmentPublishSuccess } from './component/plugin-dev-publish-success'
import { PluginDevelopmentRepublish } from './component/plugin-dev-republish'
import { PluginDevelopmentProvider, PluginDevStatus, usePluginDevelopment } from './use-plugin-development'
import { isPluginDevelopmentEnabled } from './util'

export const PluginDevelopment = () => {
    const pluginService = usePluginService()
    const orgId = useDocInfoContext().docData?.orgId
    const isOpenPluginDevelopment = pluginService.states.use.isOpenPluginDevelopment()

    if (!isPluginDevelopmentEnabled()) {
        return null
    }

    if (!isOpenPluginDevelopment) {
        return null
    }

    if (!orgId || orgId === '-1') {
        return null
    }

    return (
        <PluginDevelopmentProvider>
            <PluginDevelopmentContainer />
        </PluginDevelopmentProvider>
    )
}

const PluginDevelopmentContainer = () => {
    const { pluginDevStatus } = usePluginDevelopment()

    return (
        <>
            <PluginDevelopmentMain />
            {pluginDevStatus === PluginDevStatus.Create && <PluginDevelopmentCreate />}
            {pluginDevStatus === PluginDevStatus.CreateSuccess && <PluginDevelopmentCreateSuccess />}
            {pluginDevStatus === PluginDevStatus.Publish && <PluginDevelopmentPublish />}
            {pluginDevStatus === PluginDevStatus.PublishSuccess && <PluginDevelopmentPublishSuccess />}
            {pluginDevStatus === PluginDevStatus.EditorPublishInfo && <PluginDevelopmentEditorPublishInfo />}
            {pluginDevStatus === PluginDevStatus.Republish && <PluginDevelopmentRepublish />}
            {pluginDevStatus === PluginDevStatus.Permission && <PluginDevelopmentPermission />}
        </>
    )
}
