import {
    AnswerReactionType,
    ConversationId,
    ConversationMetaVO,
    ConversationType,
    ConversationVO,
    DialogueId,
    DialogueInput,
    DialogueResponse,
} from '../../../kernel/interface/chatbot'
import {
    Chat,
    DeleteConversation,
    GetUserConversationById,
    GetUserConversationHistory,
    UpdateDialogueReaction,
} from '../../../kernel/request/chatbot'

export const fetchCreateChatbotConversation = async (
    docId: string,
    dialogueInput: DialogueInput,
    conversationType: ConversationType
): Promise<ConversationVO> => {
    const response = await new Chat(dialogueInput, docId, conversationType).start()
    response.conversationMeta.startTime = Date.now()
    response.conversationMeta.lastTime = Date.now()

    if (!response.questionAnswer.messages) {
        response.questionAnswer.messages = [{ content: [{ text: 'error' }], role: 'assistant' }]
    }

    return {
        conversationMeta: response.conversationMeta,
        conversationDataList: [
            {
                dialogueId: response.dialogueId,
                questionInput: dialogueInput,
                questionAnswer: response.questionAnswer,
                answerReactionType: AnswerReactionType.NONE,
            },
        ],
    }
}

export const fetchSendChatbotMessage = async (
    conversationId: string,
    docId: string,
    dialogueInput: DialogueInput,
    conversationType: ConversationType
): Promise<DialogueResponse> => {
    const response = await new Chat(dialogueInput, docId, conversationType, conversationId).start()
    response.conversationMeta.lastTime = Date.now()

    if (!response.questionAnswer.messages) {
        response.questionAnswer.messages = [{ content: [{ text: 'error' }], role: 'assistant' }]
    }
    return response
}

export const fetchGetUserConversationHistory = async (): Promise<ConversationMetaVO[]> => {
    const response = await new GetUserConversationHistory().start()
    return response
}

export const fetchGetWholeConversation = async (conversationId: ConversationId): Promise<ConversationVO> => {
    const response = await new GetUserConversationById(conversationId).start()
    return response
}

export const fetchDeleteConversation = async (conversationId: ConversationId): Promise<void> => {
    await new DeleteConversation(conversationId).start()
}

export const fetchUpdateDialogueReaction = async (
    conversationId: ConversationId,
    dialogueId: DialogueId,
    answerReactionType: AnswerReactionType
): Promise<void> => {
    await new UpdateDialogueReaction(conversationId, dialogueId, answerReactionType).start()
}
