import { getTranslationValue } from '../../../../../../../util/src/i18n'

export const zhTranslation = {
    Size: '尺寸',
    Color: '颜色',
    LayoutGrid: '布局网格',
} as const

export const enTranslation = {
    Size: 'Size',
    Color: 'Color',
    LayoutGrid: 'Color',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
