import classnames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import classes from './scene-block-full.module.less'

export type SceneBlockFullPlacement =
    | 'center'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'near-top'
    | 'near-right'
    | 'near-bottom'
    | 'near-left'
export interface SceneBlockFullProps {
    play?: boolean // 通过切换这个值可以动态的切换 from|fromStyle -> to|toStyle
    transition?: string
    from?: SceneBlockFullPlacement
    to?: SceneBlockFullPlacement
    fromStyle?: React.CSSProperties
    toStyle?: React.CSSProperties
    backgroundColor?: 'gray' | 'white' | 'transparent'
    browHeight?: number // 蓝色方块的高度
    browHeightFrom?: number
    browHeightTo?: number
    dataTestIds?: {
        block?: string
        brow?: string
    }
}
export function SceneBlockFull(props: SceneBlockFullProps) {
    const {
        play,
        transition = 'unset',
        from = 'center',
        to = from,
        fromStyle,
        toStyle,
        backgroundColor = 'white',
        dataTestIds,
        browHeight,
        browHeightFrom,
        browHeightTo,
    } = props
    const getCssFromPlacement = useCallback((placement: SceneBlockFullPlacement) => {
        switch (placement) {
            case 'center':
                return { opacity: 1, top: 0, left: 0 }
            case 'top':
                return { opacity: 1, top: 'calc(-100% - 1px)', left: 0 }
            case 'right':
                return { opacity: 1, top: 0, left: 'calc(100% + 1px)' }
            case 'bottom':
                return { opacity: 1, top: 'calc(100% + 1px)', left: 0 }
            case 'left':
                return { opacity: 1, top: 0, left: 'calc(-100% - 1px)' }
            case 'near-top':
                return { opacity: 0.2, top: '-14px', left: 0 }
            case 'near-right':
                return { opacity: 0.2, top: 0, left: '14px' }
            case 'near-bottom':
                return { opacity: 0.2, top: '14px', left: 0 }
            case 'near-left':
                return { opacity: 0.2, top: 0, left: '-14px' }
        }
    }, [])

    const style = useMemo((): React.CSSProperties => {
        const calcStyle = getCssFromPlacement(play ? to : from)
        const customAction = play ? toStyle : fromStyle
        return {
            transition: play ? transition : 'all ease-out 300ms',
            ...calcStyle,
            ...customAction,
        }
    }, [from, fromStyle, getCssFromPlacement, play, to, toStyle, transition])

    const browStyle = useMemo(() => {
        if (browHeightFrom === undefined || browHeightTo === undefined) {
            return { height: browHeight }
        }

        return {
            transition: play ? transition : 'all ease-out 300ms',
            height: play ? browHeightTo : browHeightFrom,
        }
    }, [browHeightFrom, browHeightTo, play, transition, browHeight])

    return (
        <div
            className={classnames(classes.sceneBlockFull, classes[backgroundColor])}
            style={style}
            data-direction={`${from}-to-${to}`}
            data-testid={dataTestIds?.block}
        >
            <div className={classes.brow} style={{ ...browStyle }}></div>
        </div>
    )
}
