import classnames from 'classnames'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import styles from './tag.module.less'

export interface LayerPanelTagProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    name: string // part1
    number?: number // part2
    color: 'gray' | 'purple'
    isShallow?: boolean
}

interface TagRef {
    focus: () => void // 作为纯标签的tag调用focus并不能聚焦
    getRootElement: () => HTMLDivElement
}

function _LayerPanelTag({ name, color, number, isShallow, ...rest }: LayerPanelTagProps, ref: React.Ref<TagRef>) {
    const rootRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
        ref,
        () => ({ focus: () => rootRef.current?.focus(), getRootElement: () => rootRef.current! }),
        []
    )
    return (
        <div className={classnames(styles.tag, styles[color])} ref={rootRef} {...rest}>
            <span className={classnames(styles.name, styles[`text-${color}`], isShallow && styles.shallow)}>
                {name}
            </span>
            {number ? (
                <span className={classnames(styles[`number-${color}`], isShallow && styles.shallow)}>
                    {'+' + number}
                </span>
            ) : null}
        </div>
    )
}

export const LayerPanelTag = forwardRef(_LayerPanelTag)
