import { omit } from 'lodash-es'
import * as React from 'react'

export interface HtmlInputProps extends React.AllHTMLAttributes<HTMLInputElement> {
    value?: any
    disabled?: boolean
    autoFocus?: boolean
    testId?: string
}

function _HtmlInput(props: HtmlInputProps, ref: React.Ref<HTMLInputElement>) {
    const otherProps = omit(props, 'testId')
    // 删掉这个 htmlInput testid
    return <input data-testid={props.testId ?? 'htmlInput'} ref={ref} spellCheck={false} {...otherProps} />
}

export const HtmlInput = React.forwardRef(_HtmlInput)
