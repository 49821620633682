/* eslint-disable no-restricted-imports */
import { ChangeTextSearchCommand } from '@wukong/bridge-proto'
import { KeyboardEventHandler, RefObject, UIEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollViewRef, toMixed } from '../../../../../../../../ui-lib/src'
import { CommitType } from '../../../../../../document/command/commit-type'
import type { FontInfo } from '../../../../../../document/node/node'
import { useFeatureSwitch } from '../../../../../../kernel/switch'
import { useCommand } from '../../../../../../main/app-context'
import { WKFrogTask } from '../../../../../../share/wk-frog-task'
import { useViewState } from '../../../../../../view-state-bridge'
import { searchMatchedSorted } from '../../../../../utils/search-sort'
import { useFontSelectContext } from '../font-select-context'

/**
 * @param fontInfos 字体列表
 * @param recentFontInfos 最近使用的字体列表
 * @param showRecent 是否展示最近使用
 * @param currentSelectedFamily 当前选中的字体
 * @param maxHeight 最大高度
 * @returns
 */
export function useFontSelect(
    fontInfos: FontInfo[],
    recentFontInfos: FontInfo[],
    showRecent: boolean,
    currentSelectedFamily: string,
    maxHeight: number,
    searchStringBelongTo: string
) {
    const { onFontSelectChange, setExpend } = useFontSelectContext()
    const [preSelectedFontIndex, setPreSelectedFontIndex] = useState(-1)
    const [scrollTop, setScrollTop] = useState(0)
    const command = useCommand()
    const textSearch = useViewState('textSearch', { value: '', id: '' })
    const isMixed = currentSelectedFamily === toMixed()
    // 一屏最多展示的字体数量
    const baseCount = Math.max(Math.ceil(maxHeight / 32), 2)

    const textSearchString = useMemo(() => {
        return searchStringBelongTo === textSearch.id ? textSearch.value : ''
    }, [searchStringBelongTo, textSearch.id, textSearch.value])

    const setSearchContent = useCallback(
        (str: string) => {
            WKFrogTask.fontSelector._recordSearchContent(str)
            command.invokeBridge(CommitType.Noop, ChangeTextSearchCommand, { value: str, id: searchStringBelongTo })
        },
        [command, searchStringBelongTo]
    )

    const filterFontInfos = useMemo(
        () => searchMatchedSorted(command, fontInfos, textSearchString, (item) => item.localizedFamily),
        [command, fontInfos, textSearchString]
    )

    const filterRecentFontInfos = useMemo(() => {
        // NOTE: 搜索时不展示最近使用字体
        if (!showRecent || textSearchString) {
            return []
        }

        return recentFontInfos
    }, [recentFontInfos, textSearchString, showRecent])

    const allFilterFontInfos = useMemo(
        () => [...filterRecentFontInfos, ...filterFontInfos],
        [filterFontInfos, filterRecentFontInfos]
    )

    const offsetHeight = useMemo(() => {
        let height = 0
        if (isMixed) {
            height += 53
        }
        if (showRecent && filterRecentFontInfos.length > 0) {
            height += 45 + filterRecentFontInfos.length * 32
        }
        return height
    }, [isMixed, filterRecentFontInfos, showRecent])

    const totalHeight = useMemo(() => {
        let height = offsetHeight
        if (filterFontInfos.length > 0) {
            height += 16 + filterFontInfos.length * 32
        }
        return height
    }, [offsetHeight, filterFontInfos])

    const topIndex = useMemo(() => {
        return Math.max(Math.floor((scrollTop - offsetHeight - 8) / 32 - baseCount), 0)
    }, [scrollTop, offsetHeight, baseCount])

    const renderFontInfos = useMemo(() => {
        // 虚拟列表长度为 baseCount * 3
        return filterFontInfos.slice(topIndex, Math.min(topIndex + baseCount * 3, filterFontInfos.length))
    }, [filterFontInfos, topIndex, baseCount])

    const preSelectEnabled = useFeatureSwitch('font-select')

    useEffect(() => {
        // 有搜索内容才预选第一个
        if (!preSelectEnabled || textSearchString.length > 0) {
            setPreSelectedFontIndex(0)
        }
    }, [allFilterFontInfos, textSearchString, preSelectEnabled])

    // 处理上下键切换及Enter键确认选中
    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        /** NOTE: 输入搜索内容时禁用Tab 避免触发焦点切换*/
        if (event.key === 'Tab') {
            event.stopPropagation()
            event.preventDefault()
        }

        if (event.key === 'ArrowDown') {
            if (preSelectedFontIndex + 1 < allFilterFontInfos.length) {
                setPreSelectedFontIndex(preSelectedFontIndex + 1)
            } else if (preSelectedFontIndex + 1 === allFilterFontInfos.length) {
                setPreSelectedFontIndex(0)
            }
            event.stopPropagation()
            event.preventDefault()
        }

        if (event.key === 'ArrowUp') {
            if (preSelectedFontIndex - 1 >= 0) {
                setPreSelectedFontIndex(preSelectedFontIndex - 1)
            } else if (preSelectedFontIndex - 1 === -1) {
                setPreSelectedFontIndex(allFilterFontInfos.length - 1)
            }
            event.stopPropagation()
            event.preventDefault()
        }

        if (event.key === 'Enter') {
            if (event.nativeEvent.isComposing) {
                return
            }

            const fontInfo = allFilterFontInfos[preSelectedFontIndex]
            if (fontInfo) {
                setPreSelectedFontIndex(-1)
                onFontSelectChange(fontInfo)
            }
            event.stopPropagation()
            event.preventDefault()
        }

        if (preSelectEnabled && event.key === 'Escape') {
            setExpend(false)
            event.stopPropagation()
            event.preventDefault()
        }
    }

    const onScroll: UIEventHandler<HTMLDivElement> = (e) => {
        const { scrollTop: top } = e.target as HTMLDivElement
        setScrollTop(top)
    }

    // 不在虚拟列表中的字体，手动滚动到可视区域
    const onPreSelectedChange = (index: number, ref: RefObject<ScrollViewRef>) => {
        if ((index >= topIndex && index < topIndex + renderFontInfos.length) || index < 0) {
            return
        }
        let position = 0
        if (index < filterRecentFontInfos.length) {
            position = isMixed ? 53 + 36 + index * 32 : 36 + index * 32
        } else {
            position = offsetHeight + 8 + (index - filterRecentFontInfos.length) * 32
        }

        if (position < scrollTop) {
            ref.current?.getOriginRef()?.scrollTop(position)
        } else if (position > scrollTop + maxHeight) {
            ref.current?.getOriginRef()?.scrollTop(position - maxHeight + 32)
        }
    }

    const setPreSelected = useCallback(
        (index: number) => {
            setPreSelectedFontIndex(index)
        },
        [setPreSelectedFontIndex]
    )

    return {
        textSearchString,
        preSelectedFontIndex,
        setPreSelected,
        filterRecentFontInfos,
        filterFontInfos,
        isMixed,
        offsetHeight,
        totalHeight,
        topIndex,
        renderFontInfos,
        setSearchContent,
        onKeyDown,
        onScroll,
        onPreSelectedChange,
    }
}
