import { getTranslationValue } from '../../../../util/src/i18n'

export const zhTranslation = {
    NhUZtq: '无权限',
    CanEdit: '可编辑',
    CanView: '可查看',
    Admin: '管理员',
    Owner: '所有者',
    CanViewPrototype: '可查看原型',
} as const

export const enTranslation = {
    NhUZtq: 'No permission',
    CanEdit: 'Can edit',
    CanView: 'Can view',
    Admin: 'Admin',
    Owner: 'Owner',
    CanViewPrototype: 'Can view prototypes',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
