/* eslint-disable no-restricted-imports */
import { useCallback, useRef } from 'react'
import { RootComponentId } from '../../../../main/canvas/canvas'

export function useEventWheel() {
    const canvasOverlayRef = useRef<HTMLElement>()

    const dispatchWheelHandle = useCallback((e: WheelEvent) => {
        if (!canvasOverlayRef.current) {
            const element = document.getElementById(RootComponentId.CanvasOverlay)
            if (element) {
                canvasOverlayRef.current = element
            } else {
                return
            }
        }
        const needDispatch =
            e.metaKey || e.ctrlKey || !isScroll(e.target as HTMLElement, e.currentTarget as HTMLElement)
        if (needDispatch) {
            e.preventDefault()
            const wheelEvent = new WheelEvent('wheel', e)
            canvasOverlayRef.current.dispatchEvent(wheelEvent)
        }
    }, [])

    const dispatchWheelToCanvas = useCallback(
        (target: HTMLElement) => {
            target.addEventListener('wheel', dispatchWheelHandle, { passive: false })
            return () => target?.removeEventListener('wheel', dispatchWheelHandle)
        },
        [dispatchWheelHandle]
    )

    return { dispatchWheelToCanvas }
}

function isScroll(target: HTMLElement, container: HTMLElement = document.body): boolean {
    if (container.contains(target)) {
        const { scrollHeight, clientHeight, scrollWidth, clientWidth } = target
        if (
            scrollHeight > clientHeight &&
            clientHeight > 0 &&
            ['scroll', 'auto', 'overlay'].includes(window.getComputedStyle(target).overflowY)
        ) {
            return true
        }

        // input左右滚动时, 优先响应input的左右移动
        if (target.tagName === 'INPUT' && scrollWidth > clientWidth && clientWidth > 0) {
            return true
        }

        const parent = target.parentElement
        if (!parent) {
            return false
        }
        return isScroll(parent, container)
    } else {
        return false
    }
}
