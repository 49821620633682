import {
    MonoIconCommonArrowRight16,
    ScrollView,
    WKDivider,
    WKTextButton,
    WKUserAvatar,
} from '../../../../../../ui-lib/src'
import { timeDesc } from '../../../../../../util/src'
import { AISearchDocumentResult } from '../../../../kernel/interface/ai-search'
import { translation } from './ai-search-result-file-list.translation'
import { AISearchResultFileNode } from './ai-search-result-file-node'
import { AiSearchResultModel } from './use-ai-search'

function AISearchResultFileHeader({ model, file }: { model: AiSearchResultModel; file: AISearchDocumentResult }) {
    const ownerInfo = model.getFileOwnerInfo(file)

    return (
        <div className="flex flex-col gap-4px">
            <div className="wk-font-semibold wk-text-14 text-black overflow-hidden text-ellipsis whitespace-nowrap">
                {file.documentVO.name}
            </div>
            <div className="flex flex-row items-center justify-between gap-48px">
                <div className="flex-1 flex flex-row items-center gap-4px overflow-hidden wk-font-regular wk-text-12 text-gray whitespace-nowrap">
                    <div>
                        {translation('LastModified')}
                        {timeDesc(file.documentVO.lastEditedTime)}
                    </div>
                    <div className="mx-3px w-1px h-12px bg-$wk-gray-3"></div>
                    <div className="flex flex-row items-center overflow-hidden">
                        <div className="whitespace-pre">{translation('Owner')}</div>
                        <div className="flex flex-row items-center gap-4px overflow-hidden">
                            {ownerInfo && <WKUserAvatar userInfo={ownerInfo} size={24} style={{ zoom: 0.833 }} />}
                            {ownerInfo && (
                                <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                    {ownerInfo.nickname}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex-none flex flex-row items-center">
                    <WKTextButton type={'secondary'} size={12} onClick={() => model.setCurrentFile(file)}>
                        {translation('TotalResult', { count: String(file.candidates.length) })}{' '}
                        <MonoIconCommonArrowRight16 width={12} height={12} />
                    </WKTextButton>
                </div>
            </div>
        </div>
    )
}

function AISearchResultFileItem({ model, file }: { model: AiSearchResultModel; file: AISearchDocumentResult }) {
    return (
        <div className="w-full flex flex-col gap-16px">
            <AISearchResultFileHeader model={model} file={file} />
            <div className="flex flex-row items-center gap-16px">
                {file.candidates.slice(0, 3).map((candidate) => {
                    return <AISearchResultFileNode key={candidate.nodeId} file={file} candidate={candidate} />
                })}
            </div>
        </div>
    )
}

function AISearchResultEmpty() {
    return (
        <div className="mt-208px py-40px wk-font-regular wk-text-14 text-gray text-center">
            {translation('NoResult')}
        </div>
    )
}

export function AISearchResultFileList({ model, resultHeight }: { model: AiSearchResultModel; resultHeight: number }) {
    if (model.searchResult.type !== 'Success') {
        return null
    }

    if (model.searchResult.result.length === 0) {
        return <AISearchResultEmpty />
    }

    const fileList = model.searchResult.result.map((file, index) => {
        return (
            <div key={file.documentVO.id}>
                {index === 0 ? null : <WKDivider className="my-20px" />}
                <AISearchResultFileItem model={model} file={file} />
            </div>
        )
    })

    return (
        <div style={{ height: `${resultHeight}px` }}>
            <ScrollView>
                <div className="px-24px pt-24px pb-32px">{fileList}</div>
            </ScrollView>
        </div>
    )
}
