import { getTranslationValue } from './i18n'

export const zhTranslation = {
    FileName: '文件名称',
    AFileMust: '文件名称不可为空',
    TeamName: '团队名称',
    ATeamMust: '团队名称不可为空',
    ProjectName: '项目名称',
    AProjectMust: '项目名称不可为空',
    PleaseProvideYour: '请输入密码',
    PleaseSetA: '密码请不要少于8位',
    iRiBkm: '密码必须包括数字、大写字母、小写字母中的两种',
    WordCount: '{{label}}请不要超过 {{count}} 字',
} as const

export const enTranslation = {
    FileName: 'File name',
    AFileMust: 'A file must have a name.',
    TeamName: 'Team name',
    ATeamMust: 'A team must have a name.',
    ProjectName: 'Project name',
    AProjectMust: 'A project must have a name.',
    PleaseProvideYour: 'Provide your password',
    PleaseSetA: 'Passwords must have at least 8 characters.',
    iRiBkm: 'Passwords must contain at least two of the following: numbers, upper case letters and lower case letters.',
    WordCount: '{{label}} is too long (Maximum is {{count}} characters)',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
