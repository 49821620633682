import { Wukong } from '@wukong/bridge-proto'
import classnames from 'classnames'
import {
    MonoIconPanelOptionOff16,
    MonoIconPanelTextDecorationStrikethrough16,
    MonoIconPanelTextDecorationUnderline16,
    Tooltip,
    WKTabsV2,
} from '../../../../../../../../ui-lib/src'
import { ITextStyle, TextDecoration, TextStyleNode } from '../../../../../../document/node/node'
import { SelectIconGroup } from '../../../../atom/button/select-button-group'
import { SingleGrid } from '../../../../atom/grid/single-grid'
import { VariationState } from '../../../text/hooks/use-text-state'
import { TextVariable } from '../../../text/text-setting-variable'
import classes from './text-style-setting.module.less'
import { translation } from './text-style-setting.translation'

interface TextStyleBasicProps {
    textStyleData: ITextStyle & Pick<TextStyleNode, 'id' | 'name' | 'description' | 'paragraphSpacing'>
    onChangeTextDecoration: (v: TextDecoration) => void
    dataTestIds: {
        decorationNone?: string
        decorationUnderline?: string
        decorationStrikethrough?: string
    }
    readonly: boolean
}

export interface TextStyleSettingProps extends TextStyleBasicProps {
    activeKey: Wukong.DocumentProto.TextSettingTabKey
    onChangeActiveKey: (activeKey: any) => void
    variationInfo: VariationState | undefined
}

export function TextStyleSetting(props: TextStyleSettingProps) {
    const { activeKey, onChangeActiveKey, variationInfo, ...otherProps } = props
    const showTextVariable = !!variationInfo?.hasValue
    const TextSettingTabKey = Wukong.DocumentProto.TextSettingTabKey

    return (
        <div data-testid="design-panel-text-style-setting">
            {showTextVariable && (
                <div className={'flex flex-row items-center gap-4'}>
                    <WKTabsV2
                        defaultActiveKey={activeKey}
                        onActiveTabChange={onChangeActiveKey}
                        navListClassName={classes.navListClassName}
                    >
                        <WKTabsV2.Item
                            tabKey={TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS}
                            label={translation('Basics')}
                        ></WKTabsV2.Item>

                        <WKTabsV2.Item
                            tabKey={TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE}
                            label={translation('Variable')}
                        ></WKTabsV2.Item>
                    </WKTabsV2>
                </div>
            )}
            {activeKey == TextSettingTabKey.TEXT_SETTING_TAB_KEY_BASICS && <TextStyleBasic {...otherProps} />}
            {activeKey == TextSettingTabKey.TEXT_SETTING_TAB_KEY_VARIABLE && showTextVariable && (
                <TextVariable
                    variationInfo={variationInfo}
                    styleNodeId={props.textStyleData.id}
                    readonly={props.readonly}
                />
            )}
        </div>
    )
}

function TextStyleBasic(props: TextStyleBasicProps) {
    const { textStyleData, readonly } = props
    const textDecorationOptions = [TextDecoration.None, TextDecoration.Underline, TextDecoration.Strikethrough]

    return (
        <div className="py-3 flex">
            <SingleGrid className={classnames(classes.grid)}>
                <SingleGrid.Item start={5} span={28}>
                    {translation('Decoration')}
                </SingleGrid.Item>
                <SingleGrid.Item start={37} span={20} className={classes.flexEnd}>
                    <SelectIconGroup
                        disabled={readonly}
                        onClickIcon={props.onChangeTextDecoration}
                        optionValue={textDecorationOptions}
                    >
                        <Tooltip title={translation('None')}>
                            <SelectIconGroup.Item
                                value={TextDecoration.None}
                                icon={<MonoIconPanelOptionOff16 />}
                                selected={textStyleData?.textDecoration === TextDecoration.None}
                                dataTestId={props.dataTestIds?.decorationNone}
                            />
                        </Tooltip>
                        <Tooltip title={translation('Underline')}>
                            <SelectIconGroup.Item
                                value={TextDecoration.Underline}
                                icon={<MonoIconPanelTextDecorationUnderline16 />}
                                selected={textStyleData?.textDecoration === TextDecoration.Underline}
                                dataTestId={props.dataTestIds?.decorationUnderline}
                            />
                        </Tooltip>
                        <Tooltip title={translation('Strokethrough')}>
                            <SelectIconGroup.Item
                                value={TextDecoration.Strikethrough}
                                icon={<MonoIconPanelTextDecorationStrikethrough16 />}
                                selected={textStyleData?.textDecoration === TextDecoration.Strikethrough}
                                dataTestId={props.dataTestIds?.decorationStrikethrough}
                            />
                        </Tooltip>
                    </SelectIconGroup>
                </SingleGrid.Item>
            </SingleGrid>
        </div>
    )
}
