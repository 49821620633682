import { getTranslationValue } from '../../../../../util/src/i18n'

export const zhTranslation = {
    FilesDeletedForever: '个文件已永久删除',
    FileDeletedForever: '文件已永久删除',
    FilesRemovedFrom: '个文件已移除',
    FileRemovedFrom: '文件已移除',
    FilesRemovedFrom_synonyms1: '个文件已取消收藏',
    FileRemovedFrom_synonyms1: '文件已取消收藏',
    FilesAddedTo: '个文件已收藏',
    FileAddedTo: '文件已收藏',
    Untitled: '未命名',
    FileImported: '导入成功',
    FileMoved: '移动成功',
    FilesMoved: '{{count}} 个文件已移动',
    Undo: '撤销',
    Deleted: '文件已删除',
    DeletedCount: '{{count}} 个文件已删除',
    ProhibitDuplicate: '当前文件被设置为禁止查看者创建文件副本',
} as const

export const enTranslation = {
    FilesDeletedForever: 'files deleted forever',
    FileDeletedForever: 'File deleted forever',
    FilesRemovedFrom: 'files removed from recents',
    FileRemovedFrom: 'File removed from recents',
    FilesRemovedFrom_synonyms1: 'files removed from favorites',
    FileRemovedFrom_synonyms1: 'File removed from favorites',
    FilesAddedTo: 'files added to favorites',
    FileAddedTo: 'File added to favorites',
    Untitled: 'Untitled',
    FileImported: 'Import completed',
    FileMoved: 'File moved',
    FilesMoved: '{{count}} files moved',
    Undo: 'Undo',
    Deleted: 'File deleted',
    DeletedCount: '{{count}} files deleted',
    ProhibitDuplicate: 'This file settings disable viewers from duplicating.',
} as const

export const translation = (key: keyof typeof enTranslation, insert?: Record<string, string | number>) => {
    return getTranslationValue(enTranslation, zhTranslation, key, insert)
}
