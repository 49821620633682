import { FeatureSwitchConfig, SwitchEnvironmentScopeType, SwitchStrategyType } from '../feature-switch-config'

const Config: FeatureSwitchConfig = {
    name: 'dev-tool',
    owner: '',
    description: '',
    strategies: [
        {
            config: {
                type: SwitchStrategyType.SPECIFIC_USER_LIST,
                userList: ['7e0a41831dcc0d46b6bc3965590112752b6e62cd'],
            },
            env: SwitchEnvironmentScopeType.TESTING,
        },
    ],
    alwaysDisableInTests: true,
}

export default Config
