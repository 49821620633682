import { command, computed, state } from 'ccstate'
import { traceable } from '../../../../util/src/ccstate-helper/traceable'
import { RootComponentId } from '../../main/canvas/canvas'
import { canvasMaxTextureSize$ } from './canvas-max-texture-size'
import { useGet } from 'ccstate-react'

const readAndWriteCanvasMounted$ = state(false)

export const canvasMounted$ = traceable(
    'hulh01@kanyun.com',
    computed((get) => get(readAndWriteCanvasMounted$))
)

export const appendCanvasToReact$ = traceable(
    'hulh01@kanyun.com',
    command(({ get, set }, container: HTMLDivElement) => {
        const canvas = get(editorCanvas$)

        container.insertBefore(canvas, container.firstChild)

        set(readAndWriteCanvasMounted$, true)

        return () => {
            container.removeChild(canvas)
            set(readAndWriteCanvasMounted$, false)
        }
    })
)

export const [editorCanvas$, refreshEditorCanvas$] = (() => {
    const refresh$ = state(0)

    return [
        computed((get) => {
            get(refresh$)
            const maxTextureSize = get(canvasMaxTextureSize$)

            const canvas = document.createElement('canvas')

            canvas.id = RootComponentId.Canvas
            canvas.setAttribute('data-testid', 'canvas')
            canvas.style.width = '100%'
            canvas.style.height = '100%'
            canvas.style.maxWidth = `${Math.floor(maxTextureSize / devicePixelRatio)}px`
            canvas.style.maxHeight = `${Math.floor(maxTextureSize / devicePixelRatio)}px`
            canvas.style.minWidth = '1px'
            canvas.style.minHeight = '1px'

            return canvas
        }),
        command(({ set }) => {
            set(refresh$, (v) => v + 1)
        }),
    ]
})()

export const useCanvas = () => useGet(editorCanvas$)
