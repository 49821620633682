import { translation } from './component-service.translation'
/* eslint-disable no-restricted-imports */
import {
    CloseBackToInstanceToastCommand,
    JumpToOriginDocument,
    OnClickBackToInstanceToastCommand,
    ToggleBackToInstanceToastCommand,
    Wukong,
} from '@wukong/bridge-proto'
import { IconBackTo16, WKToast } from '../../../../../ui-lib/src'
import { createSelectors, createStore, domLocation } from '../../../../../util/src'
import { CommandInvoker } from '../../../document/command/command-invoker'
import { NodeId } from '../../../document/node/node'
import { environment } from '../../../environment'
import { Bridge } from '../../../kernel/bridge/bridge'
import { DocID, OrgID } from '../../../kernel/interface/type'
import { GetDocRequest } from '../../../kernel/request/document'
import { GetImportLibraryInfoRequest, GetOrgImportLibraryInfoRequest } from '../../../kernel/request/library'
import { featureSwitchManager } from '../../../kernel/switch'
import { openIndependentWindow } from '../../../kernel/util/open-window'
import { ServiceClass } from '../../../kernel/util/service-class'
import { FigmaImportStylePublishFile } from '../component-style-library-v2/library-service/library-wasm-sync-service'

export interface JumpToOriginDocumentParams {
    docId: DocID | undefined | null
    nodeId: NodeId | undefined | null
    fromFig?: boolean
    name?: string
    isShape?: boolean
}

export interface JumpToFigmaModalParams {
    publishFile: string
    componentName: string
}

export class ComponentService extends ServiceClass {
    public states = createSelectors(
        createStore<{
            jumpToFigmaModalState:
                | {
                      visible: false
                  }
                | {
                      visible: true
                      data: JumpToFigmaModalParams
                  }
            jumpToFigmaStyleModalState: {
                visible: boolean
            }
        }>(
            () => ({
                jumpToFigmaModalState: { visible: false },
                jumpToFigmaStyleModalState: { visible: false },
            }),
            environment.isDev
        )
    )

    private backToInstanceToastId?: string

    constructor(
        public readonly docId: DocID,
        private readonly orgId: OrgID,
        private readonly commandInvoker: CommandInvoker,
        protected override readonly bridge: Bridge
    ) {
        super(bridge)

        this.bindJsCall(JumpToOriginDocument, (proto) => {
            this.jumpToOriginDocument({
                docId: proto.publishFile,
                nodeId: proto.publishId,
                fromFig: proto.fromFig!,
                name: proto.name!,
                isShape: proto.isShape!,
            })
        })

        this.bindJsCall(ToggleBackToInstanceToastCommand, this.onToggleBackToInstanceToast)
    }

    public override destroy(): void {
        super.destroy()
        this.closeBackToInstanceToast()
    }

    public jumpToOriginDocument = async (params: JumpToOriginDocumentParams) => {
        if (featureSwitchManager.isEnabled('asset-migration-mode')) {
            // 增加了样式不存在时跳转的弹窗
            this.jumpToOriginDocumentV2(params)
        } else {
            this.jumpToOriginDocumentV1(params)
        }
    }

    private jumpToOriginDocumentV2 = async (params: JumpToOriginDocumentParams) => {
        const { docId, nodeId, fromFig, name, isShape } = params

        if (!docId) return WKToast.error(translation('MainComponentNot'))
        const regex = new RegExp(`/file/([^\\?]+(\\?=\\?|))`)
        const fileId = domLocation().href.match(regex)?.[1]
        if (!fileId) return

        const currentDoc = await new GetDocRequest(this.docId).start().catch(() => undefined)

        if (currentDoc?.teamId) {
            const list = await (this.orgId === '-1'
                ? new GetImportLibraryInfoRequest(currentDoc.teamId)
                : new GetOrgImportLibraryInfoRequest(this.orgId)
            )
                .start()
                .then((v) => v.importLibraryRelations)
                .catch(() => [])
            const importLibInfo = list.find((item) => item.docId === docId)

            // fromFig == true 一定是导入的组件
            if (fromFig) {
                // 是导入的文档，但是文档不存在，不可跳转
                if (importLibInfo && !importLibInfo.docExist) {
                    this.showJumpToFigmaModal({
                        publishFile: importLibInfo.publishFile,
                        componentName: name ?? '',
                    })
                    return
                } else {
                    // 已导入了对应组件库，可跳转
                }
                // fromFig == false，可能是样式或 motiff 组件
            } else {
                // 有导入信息但 fromFig == false，一定是「组件迁移」的样式
                if (importLibInfo) {
                    // 未导入组件库的组件迁移样式，不可跳转
                    if (!importLibInfo.docExist) {
                        this.showJumpToFigmaStyleModal()
                        return
                    } else {
                        // 已导入组件库的组件迁移样式，可跳转
                    }
                } else {
                    // FigmaImportStylePublishFile 前缀为普通导入的样式，不可跳转
                    if (docId === FigmaImportStylePublishFile) {
                        this.showJumpToFigmaStyleModal()
                        return
                    } else {
                        // motiff 的样式或组件，直接跳转
                    }
                }
            }
        }

        const pathName = domLocation().pathname.replace(fileId, docId)
        openIndependentWindow(
            `${domLocation().origin}${pathName}${
                nodeId ? `?nodeId=${window.encodeURIComponent(nodeId)}${isShape ? '&mainComponent=1' : ''}` : ''
            }`
        )
    }

    private jumpToOriginDocumentV1 = async (params: JumpToOriginDocumentParams) => {
        const { docId, nodeId, fromFig, name, isShape } = params

        if (!docId) return WKToast.error(translation('MainComponentNot'))
        const regex = new RegExp(`/file/([^\\?]+(\\?=\\?|))`)
        const fileId = domLocation().href.match(regex)?.[1]
        if (!fileId) return

        const currentDoc = await new GetDocRequest(this.docId).start().catch(() => undefined)

        if (fromFig && currentDoc?.teamId) {
            const list = await (this.orgId === '-1'
                ? new GetImportLibraryInfoRequest(currentDoc.teamId)
                : new GetOrgImportLibraryInfoRequest(this.orgId)
            )
                .start()
                .then((v) => v.importLibraryRelations)
                .catch(() => [])
            const info = list.find((item) => item.docId === docId)
            // 是导入的文档，但是文档不存在
            if (info && !info.docExist) {
                this.showJumpToFigmaModal({
                    publishFile: info.publishFile,
                    componentName: name ?? '',
                })
                return
            }
        }

        const pathName = domLocation().pathname.replace(fileId, docId)
        openIndependentWindow(
            `${domLocation().origin}${pathName}${
                nodeId ? `?nodeId=${window.encodeURIComponent(nodeId)}${isShape ? '&mainComponent=1' : ''}` : ''
            }`
        )
    }

    private showJumpToFigmaModal = (params: JumpToFigmaModalParams) => {
        this.states.setState({
            jumpToFigmaModalState: {
                visible: true,
                data: params,
            },
        })
    }

    public closeJumpToFigmaModal = () => {
        this.states.setState({
            jumpToFigmaModalState: {
                visible: false,
            },
        })
    }

    private showJumpToFigmaStyleModal = () => {
        this.states.setState({
            jumpToFigmaStyleModalState: {
                visible: true,
            },
        })
    }

    public closeJumpToFigmaStyleModal = () => {
        this.states.setState({
            jumpToFigmaStyleModalState: {
                visible: false,
            },
        })
    }

    private onToggleBackToInstanceToast = (args: Wukong.DocumentProto.IBridgeProtoBoolean) => {
        if (!args.value) {
            this.closeBackToInstanceToast()
        } else if (!this.backToInstanceToastId) {
            this.backToInstanceToastId = WKToast.show(translation('ReturnToInstance'), {
                duration: -1,
                icon: <IconBackTo16 className="text-white" />,
                firstButton: {
                    type: 'x',
                    onClick: () => {
                        this.commandInvoker.DEPRECATED_invokeBridge(CloseBackToInstanceToastCommand)
                        this.commandInvoker.commitUndo()
                    },
                },
                onClick: () => {
                    this.commandInvoker.DEPRECATED_invokeBridge(OnClickBackToInstanceToastCommand)
                    this.commandInvoker.commitUndo()
                },
            })
        }
    }

    private closeBackToInstanceToast = () => {
        if (this.backToInstanceToastId) {
            const toastId = this.backToInstanceToastId
            this.backToInstanceToastId = undefined
            // NOTE: 加 setTimeout 避免出现 Warning: Attempted to synchronously unmount a root while React was already rendering
            setTimeout(() => {
                WKToast.close(toastId)
            }, 0)
        }
    }
}
