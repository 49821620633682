import { BaseNode, NodeType, Rect, Transform } from '../node/node'
import { isSceneNode } from '../node/node-assertion'
import { Matrix } from './matrix'

// 给定一个 transform 和宽高, 得到其外接正矩形的 rect
function layoutToBoundingRect(transform: Transform, width: number, height: number) {
    const matrix = new Matrix(transform)
    return matrix.mapRect({ x: 0, y: 0, width, height })
}

export function getAbsoluteRectByNode(node: BaseNode | null): Rect | null {
    const absoluteTransform = getAbsoluteTransformByNode(node)
    if (!node || !absoluteTransform) {
        return null
    }

    if (!isSceneNode(node)) {
        return null
    }
    // 若为无效的宽高，则认为没有 rect
    if (node.type === NodeType.Line) {
        if (!node.width) {
            return null
        }
    } else if (!node.width || !node.height) {
        return null
    }
    return layoutToBoundingRect(absoluteTransform, node.width, node.height)
}

export function getAbsoluteTransformByNode(node: BaseNode | null): Transform | null {
    if (!node || !isSceneNode(node)) {
        return null
    }

    const matrix = new Matrix(node.relativeTransform)
    node = node.getParent()
    while (node && isSceneNode(node)) {
        matrix.postConcat(new Matrix(node.relativeTransform))
        node = node.getParent()
    }
    return matrix.valueOf()
}
